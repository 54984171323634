import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import '../css/shoppingCart.css';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import { useCookies } from 'react-cookie';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router';
import jwtDecoder from '../utils/jwtDecoder';
const {
  REACT_APP_STRIPE_PUBLISHABLE_KEY: STRIPE_KEY,
  REACT_APP_API_DOMAIN: API_DOMAIN,
} = process.env;

const stripePromise = loadStripe(STRIPE_KEY);

function ShoppingCart() {
  const [setComments] = useState('');
  const [setName] = useState('');
  const [setEmail] = useState('');
  const [total, setTotal] = useState('');
  const [cart, setCart] = useState('');
  const [setModal] = useState(false);
  const [cookies, setCookies, removeCookie] = useCookies(['cart']);
  const history = useHistory();

  useEffect(() => {
    setCart(cookies.cart);
    setTotal(cart.price);
  }, [cart, cookies]);

  const handleCheckout = async (e) => {
    try {
      e.preventDefault();
      const stripe = await stripePromise;

      // get token from cookie
      const { token } = cookies?.tokens;
      // get cart item from cookie...maybe change from url query param
      const { item } = cookies?.cart;

      // decode the jwt to get the userId...potentially update the endpoint to not need userId
      const decoded = jwtDecoder(token);
      const {
        user: { id: userId },
      } = decoded;

      if (!userId) throw Error('Missing user id');

      // parse string for cart item text...potentially clean up and pull from somewhere else
      const checkoutUrl =
        item?.indexOf('60') > -1
          ? `payment/create-60-checkout-session`
          : `payment/create-30-checkout-session`;

      // make the request to our API to get a checkout session
      const response = await fetch(`${API_DOMAIN}/${checkoutUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          userId: userId,
        },
      });

      const session = await response.json();

      // redirect to the checkout session on stripe to finish checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.success) {
        setModal(true);
      }

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeItem = (el) => {
    removeCookie('cart', { path: '/' });
    let hardCopy = [cart];
    hardCopy = hardCopy.filter((cartItem) => cartItem.id !== el.id);
    setCart(hardCopy);
    setCookies('cart', cart);
    history.push('/get-help');
  };

  return (
    <>
      <div className="main">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="checkout-title">
              <button>
                <ArrowBackIcon className="arrow" />
              </button>
              <h1>Shopping Cart</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <h2 className="items-number">
              <ShoppingCartIcon />
              You have {cart.quantity} item in your cart
            </h2>
          </Grid>
          <Grid item xs={6}>
            <ul className="shopping">
              <li>
                <div className="total" key={cart.id}>
                  <i>{cart.item}</i> <i>{cart.quantity}</i> <i>${cart.price}</i>{' '}
                  <button onClick={() => removeItem(cart)} className="trash">
                    <DeleteIcon />
                  </button>
                </div>
              </li>
              <li>
                <div className="comment">
                  <b>Can you tell us about what you need help with?</b>
                  <textarea
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </li>
            </ul>
          </Grid>
          <Grid item xs>
            <form className="checkout-form" onSubmit={handleCheckout}>
              <ul className="checkout-list">
                <li>
                  <h1>Order Details</h1>
                </li>
                <li>
                  <div className="checkout-input">
                    <b>Name</b>{' '}
                    <input onChange={(e) => setName(e.target.value)} />
                  </div>
                </li>
                <li>
                  <div className="checkout-input">
                    <b>Email</b>{' '}
                    <input onChange={(e) => setEmail(e.target.value)} />
                  </div>
                </li>
                <li>
                  <div className="checkout-input">
                    <b>Total</b> $
                    <input
                      onChange={(e) => setTotal(e.target.value)}
                      value={total}
                    />
                  </div>
                </li>
              </ul>
              <div className="checkout-button-container">
                <button role="link" className="checkout-button">
                  Proceed to Checkout
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ShoppingCart;
