export const syncExamAdminUserAccess = async (cookies) => {
  let url = '/users/access/sync';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies?.tokens?.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) {
    const {
      body: { message, count, syncedAt },
    } = await response.json();
    return {
      message,
      count,
      syncedAt,
    };
  }

  if (!response.ok) {
    const { error, message } = await response.json();
    return { error, message };
  }
};
