import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import React, { useState, useEffect } from 'react';
import { updateProfile } from './functions/requests';
import { snakeCaseKeys } from '../../../utils/snakeCaseKeys';
import { validateProfileEdit } from './functions/validations';
import { useAuthUser } from 'store/UserContext';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const profileDefault = {
  first_name: '',
  last_name: '',
  gender: '',
  email: '',
  pronoun: '',
  role: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
  grade: '',
};

export function EditProfileForm({ profile, setProfile, handleClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies('tokens');
  const [loading, setLoading] = useState(false);
  const [profileFormDetails, setProfileFormDetails] = useState(profileDefault);
  const user = useAuthUser();

  useEffect(() => {
    if (profile) {
      setProfileFormDetails(profile);
    }
  }, [profile]);

  const [cityError, setCityError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [stateError, setStateError] = useState('');
  const [gradeError, setGradeError] = useState('');
  const [genderError, setGenderError] = useState('');
  const [pronounError, setPronounError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const validate = () => {
    const { message, type, pass } = validateProfileEdit(profileFormDetails);
    switch (type) {
      case 'email':
        setEmailError(message);
        return pass;
      case 'firstName':
        setFirstNameError(message);
        return pass;
      case 'lastName':
        setLastNameError(message);
        return pass;
      case 'phoneNumber':
        setPhoneNumberError(message);
        return pass;
      case 'grade':
        setGradeError(message);
        return pass;
      case 'pronoun':
        setPronounError(message);
        return pass;
      case 'gender':
        setGenderError(message);
        return pass;
      case 'city':
        setCityError(message);
        return pass;
      case 'state':
        setStateError(message);
        return pass;
      case 'zipCode':
        setZipCodeError(message);
        return pass;
      default:
        return pass;
    }
  };

  const errors = [
    setEmailError,
    setFirstNameError,
    setLastNameError,
    setPhoneNumberError,
    setGradeError,
    setGenderError,
    setPronounError,
    setStateError,
    setCityError,
    setZipCodeError,
  ];

  const clearErrors = () => errors.forEach((error) => error(''));

  const handleChange = (event, type) => {
    const value = event.target.value;
    console.log({ value, type });
    setProfileFormDetails((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clearErrors();
    if (validate()) {
      setLoading(true);
      handleEditRequest(snakeCaseKeys(profileFormDetails));
    }
  };

  const handleEditRequest = async (data) => {
    const {
      message: { error, body },
    } = await updateProfile(data, cookies);

    if (error) {
      const errorMessage = await body;
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      setLoading(false);
    } else {
      const {
        data: { attributes },
      } = await body;
      if (setProfile instanceof Function) {
        setProfile(attributes);
      }
      enqueueSnackbar('Profile Updated', {
        variant: 'success',
      });
      setLoading(false);
      handleClose();
    }
  };

  const formMap = [
    {
      label: 'Email',
      value: profileFormDetails.email,
      event: 'email',
      error: emailError,
    },
    {
      label: 'First Name',
      value: profileFormDetails.first_name,
      event: 'first_name',
      error: firstNameError,
    },
    {
      label: 'Last Name',
      value: profileFormDetails.last_name,
      event: 'last_name',
      error: lastNameError,
    },
    {
      label: 'City',
      value: profileFormDetails.city,
      event: 'city',
      error: cityError,
    },
    {
      label: 'Zip Code',
      value: profileFormDetails.zip_code,
      event: 'zip_code',
      error: zipCodeError,
    },
    {
      label: 'Phone',
      value: profileFormDetails.phone_number,
      event: 'phone_number',
      error: phoneNumberError,
    },
    {
      label: 'Gender',
      value: profileFormDetails.gender,
      event: 'gender',
      error: genderError,
    },
    {
      label: 'State Code',
      value: profileFormDetails.state,
      event: 'state',
      error: stateError,
    },
  ];

  const formViewInputs =
    user.role === 'student' ? formMap : formMap.slice(0, 3);

  return (
    <Grid container>
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        noValidate
        autoComplete="off"
      >
        <Grid container item xs={12} spacing={3}>
          {formViewInputs.map((item) => {
            return (
              <Grid key={item.label} item xs={12} md={6}>
                <TextField
                  key={item.label}
                  id="filled-multiline-flexible"
                  label={item.label}
                  multiline
                  maxRows={4}
                  sx={{ width: '106%' }}
                  value={item.value}
                  onChange={(event) => handleChange(event, item.event)}
                  variant="filled"
                  helperText={item.error}
                  error={!!item.error}
                />
              </Grid>
            );
          })}

          <Grid item xs={12} md={6}>
            {user.role === 'student' ? (
              <FormControl
                variant="filled"
                sx={{ width: '100%' }}
                error={!!pronounError}
              >
                <InputLabel id="pronoun-select-label">Pronouns</InputLabel>
                <Select
                  labelId="pronoun-select-label"
                  id="pronoun-select"
                  value={profileFormDetails.pronoun || 'Other'}
                  label="Pronouns"
                  onChange={(event) => handleChange(event, 'pronoun')}
                >
                  {[
                    'Other',
                    'They/them',
                    'She/her',
                    'He/him',
                    'It/it',
                    'Zie/Zim',
                    'Sie/sie',
                    'Ey/em',
                    'Ve/ver',
                    'Tey/ter',
                    'E/em',
                    'Ze/hir',
                    'Xe/xem',
                    'Hy/hym',
                    'Co/cos',
                  ].map((pronoun) => {
                    return (
                      <MenuItem key={pronoun} value={pronoun}>
                        {pronoun}
                      </MenuItem>
                    );
                  })}
                </Select>

                {gradeError && <FormHelperText>{gradeError}</FormHelperText>}
              </FormControl>
            ) : (
              <></>
            )}
          </Grid>
          {/*  */}
          <Grid item xs={12} md={6} pb={2}>
            {user.role === 'student' ? (
              <FormControl
                variant="filled"
                sx={{ width: '100%' }}
                error={!!pronounError}
              >
                <InputLabel id="grade-select-label">Current Grade</InputLabel>
                <Select
                  labelId="grade-select-label"
                  id="grade-select"
                  value={profileFormDetails.grade || '9th'}
                  label="grades"
                  onChange={(event) => handleChange(event, 'grade')}
                >
                  {['9th', '10th', '11th', '12th', 'Other'].map((grade) => {
                    return (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    );
                  })}
                </Select>
                {gradeError && <FormHelperText>{gradeError}</FormHelperText>}
              </FormControl>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button sx={{ width: '100%' }} type="submit" variant="contained">
            {loading ? 'loading' : 'Submit'}
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
}
