import Search from './Search';
import CollegeCard from './CollegeCard';
import { useCookies } from 'react-cookie';
import debounce from '../../../utils/debounce';
import { useInfiniteScroll } from './functions/hooks';
import { useSnackbar } from 'notistack';
import { getSearchColleges } from './functions/requests';
import React, { useState, useRef, useCallback } from 'react';
import CollegeResultModal from '../colleges/college-survey/CollegeResultModal';

//MUI IMPORTS
import { Box, Grid, Paper, Typography, Grow } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const StyledPaper = ({ sx, children }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        ...sx,
        border: '3px solid',
        borderColor: '#1A3AD5',
        p: 3,
        borderRadius: '15px',
        width: '100%',
      }}
    >
      {children}
    </Paper>
  );
};

const Colleges = ({ savedColleges, handleCollegeAdd }) => {
  const observer = useRef(null);
  const [ACPage, setACPage] = useState(1);
  const [cookies] = useCookies(['token']);
  const [isSearching, setIsSearching] = useState(false);
  const [searchColleges, setSearchColleges] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { allColleges, isLoading, serverError, hasMore } =
    useInfiniteScroll(ACPage);

  const handleGetSearch = async (searchTerm, cancel) => {
    if (cancel) {
      setIsSearching(false);
      return;
    }
    if (searchTerm === '') return;
    const { data, error } = await getSearchColleges(cookies, searchTerm);
    if (error) {
      enqueueSnackbar('No Results Found', {
        variant: 'error',
      });
    }
    setIsSearching(true);
    setSearchColleges(data);
  };

  const debouncedSearch = debounce(
    (searchTerm, cancel) => handleGetSearch(searchTerm, cancel),
    800,
  );

  const lastCollege = useCallback(
    (node) => {
      if (isLoading) return;
      if (isSearching) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        const [firstEntry] = entries;
        if (firstEntry?.isIntersecting && hasMore) {
          setACPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
      return () => {
        if (observer.current) observer.current.disconnect();
      };
    },
    [isLoading, hasMore, isSearching],
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h1" component="h2" mb={2}>
          Colleges
        </Typography>
        <CollegeResultModal />
        <StyledPaper>
          <Grid container>
            <Grid item xs={6}>
              <Typography component="p" variant="body1">
                Search for colleges by name. The colleges will be autoloaded if
                they exist on the{' '}
                <Box
                  sx={{
                    display: 'inline',
                    color: 'primary.main',
                    fontWeight: 'bold',
                  }}
                >
                  VIZE
                </Box>{' '}
                platform.
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ p: 2 }}>
              <Search placeHolder="Colleges" onHandleSearch={debouncedSearch} />
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid
        container
        item
        alignItems="stretch"
        rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        columnSpacing={{ xs: 1, sm: 5, md: 5, lg: 5 }}
      >
        {isSearching ? (
          <CollegesContainer
            savedColleges={savedColleges}
            handleCollegeAdd={handleCollegeAdd}
            colleges={searchColleges}
            lastCollege={lastCollege}
            heading="Search Results"
          />
        ) : (
          <CollegesContainer
            heading="All Colleges"
            savedColleges={savedColleges}
            handleCollegeAdd={handleCollegeAdd}
            colleges={allColleges}
            lastCollege={lastCollege}
          />
        )}
        {serverError && <Box>{serverError}</Box>}
        {isLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Grid>
    </>
  );
};

const CollegesContainer = ({
  savedColleges,
  handleCollegeAdd,
  colleges,
  lastCollege,
  heading,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          {heading}
        </Typography>
      </Grid>
      {colleges.map((college, i) => {
        const { attributes } = college;
        if (colleges.length === i + 1) {
          return (
            <Grid
              item
              xs={12}
              xl={6}
              key={attributes.ceebCode}
              ref={lastCollege}
            >
              <CollegeCard
                {...attributes}
                savedColleges={savedColleges}
                handleCollegeAdd={handleCollegeAdd}
              />
            </Grid>
          );
        } else {
          return (
            <Grid key={attributes.ceebCode} item xs={12} xl={6}>
              <Grow in={true} {...{ timeout: 1000 }}>
                <div style={{ width: '100%' }}>
                  <CollegeCard
                    {...attributes}
                    savedColleges={savedColleges}
                    handleCollegeAdd={handleCollegeAdd}
                  />
                </div>
              </Grow>
            </Grid>
          );
        }
      })}
    </>
  );
};

export default Colleges;
