import React from 'react';

// MUI IMPORTS
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TestSelect = ({ value, handleChange }) => {
  return (
    <div>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="test-select-label">Test Type</InputLabel>
        <Select
          labelId="test-select"
          id="test-select"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          defaultValue={value}
          label="test-select"
        >
          {['PSAT', 'SAT', 'ACT', 'TOEFL'].map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TestSelect;
