import jwtDecoder from '../../utils/jwtDecoder.js';

const listSessionsByUserId = (cookie) => {
  // get user id from token
  const {
    user: { id: userId },
  } = jwtDecoder(cookie.tokens.token);

  let url = `/sessions/requests/user/${userId}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw new Error(
        `Unable to process API request to: ${url} REASON: ${e.message}`,
      );
    });
};

export default listSessionsByUserId;
