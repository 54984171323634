import React from 'react';

// MUI IMPORTS
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const CourseSelect = ({ value, handleChange, options, title, error }) => {
  return (
    <div>
      <FormControl variant="filled" fullWidth error={!!error}>
        <InputLabel id="course-select-label">{title}</InputLabel>
        <Select
          labelId="course-select"
          id="course-select"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          defaultValue={value}
          label="course-select"
        >
          {options.map((el) => (
            <MenuItem key={el} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default CourseSelect;
