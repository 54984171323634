const addHoursToDate = (hours = 0) => {
  const today = new Date();
  today.setHours(today.getHours() + hours);
  return today;
};

const formatDateToLocal = (date) => {
  let formattedDate = '';
  if (new Date(date).getFullYear() > 2020) {
    formattedDate = new Date(date).toLocaleDateString('en-US', {
      timeZone: process.env.REACT_APP_TZ,
    });
  }

  return formattedDate;
};

export { addHoursToDate, formatDateToLocal };
