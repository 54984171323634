import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  description: {
    textTransform: 'none',
  },
}));

const CustomTabLabel = ({ title, description }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography align="left" variant="h7" component="p">
        {title}
      </Typography>
      <Typography
        className={classes.description}
        align="left"
        variant="subtitle2"
        component="p"
      >
        {description}
      </Typography>
    </div>
  );
};

export default CustomTabLabel;
