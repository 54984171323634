import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoutes({ gaurd, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return gaurd ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateRoutes;
