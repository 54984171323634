import FieldSelect from './FieldSelect';
import DateTaken from './DateTaken';
import TestSelect from './TestSelect';
import { useCookies } from 'react-cookie';
import { postTest } from './functions/requests';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  validateSATScore,
  validateTOEFLScore,
  validateACTScore,
} from './functions/validations';

// MUI IMPORTS
import { Box, Stack, Button, FormHelperText } from '@mui/material';

const actScoresDefault = {
  math: '',
  english: '',
  reading: '',
  science: '',
};

const toefulScoresDefault = {
  reading: '',
  speaking: '',
  listening: '',
  writing: '',
};

const satScoreDefault = {
  verbal: '',
  math: '',
};

const Form = ({ setExams, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies('tokens');

  const [test, setTest] = useState('SAT');
  const [loading, setLoading] = useState('');

  const [date, setDate] = useState(new Date());
  const [dateError, setDateError] = useState('');

  const [actScores, setACTScores] = useState(actScoresDefault);
  const [actError, setACTError] = useState('');

  const [toefulScores, setTOEFULScores] = useState(toefulScoresDefault);
  const [toeflError, setTOEFLError] = useState('');

  const [satScores, setSATScores] = useState(satScoreDefault);
  const [satError, setSATError] = useState('');

  const [pSatScores, setPSATScores] = useState(satScoreDefault);
  const [pSatError, setPSATError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    let scores = {};
    switch (test) {
      case 'ACT': {
        const { message, pass } = validateACTScore(actScores);
        if (pass) {
          scores = actScores;
          setACTScores(actScoresDefault);
        } else {
          setACTError(message);
          return;
        }
        break;
      }
      case 'SAT': {
        const { message, pass } = validateSATScore(satScores);
        if (pass) {
          scores = satScores;
          setSATScores(satScoreDefault);
        } else {
          setSATError(message);
          return;
        }
        break;
      }
      case 'PSAT': {
        const { message, pass } = validateSATScore(pSatScores);
        if (pass) {
          scores = pSatScores;
          setPSATScores(satScoreDefault);
        } else {
          setPSATError(message);
          return;
        }
        break;
      }
      case 'TOEFL': {
        const { message, pass } = validateTOEFLScore(toefulScores);
        if (pass) {
          scores = toefulScores;
          setTOEFULScores(toefulScoresDefault);
        } else {
          setTOEFLError(message);
          return;
        }
        break;
      }
      default:
        break;
    }
    const obj = {
      type: test,
      scores,
      dateTaken: date,
    };
    handlePost(obj);
  };

  const handlePost = async (payload) => {
    try {
      const {
        message: { error, body },
      } = await postTest(payload, cookies);
      console.log({ error, body });
      if (error) {
        setDateError('You can not have the same test on the same date.');
      } else {
        const {
          data: { id, attributes },
        } = await body;
        setExams((prev) => [{ id, attributes }, ...prev]);
        enqueueSnackbar('Exam Successfully Added.', {
          variant: 'success',
        });
        setOpen((prev) => !prev);
      }
    } catch (error) {
      enqueueSnackbar('Error', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const renderSwitch = (test) => {
    switch (test) {
      case 'ACT': {
        const formProps = [
          {
            label: 'reading',
            value: actScores['reading'],
          },
          {
            label: 'english',
            value: actScores['english'],
          },
          {
            label: 'science',
            value: actScores['science'],
          },
          {
            label: 'math',
            value: actScores['math'],
          },
        ];
        return (
          <>
            <FieldSelect
              formProps={formProps}
              heading={'The values of a ACT test range from 1 - 36'}
              min={1}
              max={36}
              step={-1}
              error={actError}
              setError={setACTError}
              setScores={setACTScores}
            />
            {actError && <FormHelperText error>{actError}</FormHelperText>}
          </>
        );
      }
      case 'SAT': {
        const formProps = [
          {
            label: 'verbal',
            value: satScores['verbal'],
          },
          {
            label: 'math',
            value: satScores['math'],
          },
        ];
        return (
          <>
            <FieldSelect
              formProps={formProps}
              heading={'The values of a SAT test range from 200 - 800'}
              min={200}
              max={800}
              step={-10}
              setError={setSATError}
              error={satError}
              setScores={setSATScores}
            />
            {satError && <FormHelperText error>{satError}</FormHelperText>}
          </>
        );
      }
      case 'PSAT': {
        const formProps = [
          {
            label: 'verbal',
            value: pSatScores['verbal'],
          },
          {
            label: 'math',
            value: pSatScores['math'],
          },
        ];
        return (
          <>
            <FieldSelect
              formProps={formProps}
              heading={'The values of a PSAT test range from 160 - 760'}
              min={160}
              max={760}
              step={-10}
              setError={setPSATError}
              error={pSatError}
              setScores={setPSATScores}
            />
            {pSatError && <FormHelperText error>{pSatError}</FormHelperText>}
          </>
        );
      }
      case 'TOEFL': {
        const formProps = [
          {
            label: 'reading',
            value: toefulScores['reading'],
          },
          {
            label: 'speaking',
            value: toefulScores['speaking'],
          },
          {
            label: 'writing',
            value: toefulScores['writing'],
          },
          {
            label: 'listening',
            value: toefulScores['listening'],
          },
        ];
        return (
          <>
            <FieldSelect
              formProps={formProps}
              heading={'The values of a TOEFL test range from 1 - 30'}
              min={1}
              max={30}
              step={-1}
              setError={setTOEFLError}
              error={toeflError}
              scores={toefulScores}
              setScores={setTOEFULScores}
            />
            {toeflError && <FormHelperText error>{toeflError}</FormHelperText>}
          </>
        );
      }

      default:
        return null;
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      autoComplete="off"
      sx={{
        mt: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Stack spacing={2}>
        <TestSelect value={test} handleChange={setTest} />
        {renderSwitch(test)}
        <DateTaken setError={setDateError} date={date} setDate={setDate} />
        {dateError && <FormHelperText error>{dateError}</FormHelperText>}
      </Stack>
      <Button sx={{ mx: 5, my: 2 }} type="submit" variant="contained">
        {loading ? 'loading' : 'Submit'}
      </Button>
    </Box>
  );
};

export default Form;
