import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { SearchRounded } from '@material-ui/icons';

const TaskSearch = ({ onSearch, loading }) => {
  const [inputValue, setInputValue] = useState(null);

  const onSearchInputHandler = (e) => {
    const { value } = e?.target;
    if (!value || value.length === 0) {
      setInputValue('');
    } else {
      setInputValue(value);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ mt: 3 }}
    >
      <TextField
        id="filled-multiline-flexible"
        label="External Task Id"
        value={inputValue}
        onChange={onSearchInputHandler}
        variant="filled"
        type="text"
        onKeyDown={(e) => {
          if (e.key === 'Enter') onSearch(inputValue);
        }}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
      <LoadingButton
        sx={{ ml: 3 }}
        loading={loading}
        type="submit"
        variant="contained"
        onClick={() => onSearch(inputValue)}
        endIcon={<SearchRounded />}
      >
        {!loading && 'Search'}
      </LoadingButton>
    </Grid>
  );
};

export default TaskSearch;
