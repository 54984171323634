import Form from './Form';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import ModalDialog from '../../ModalDialog';
import { Card as CourseWorkCard } from './Card';
import AddProfileDetail from '../AddProfileDetail';
import { postCourse } from './functions/requests';

// MUI IMPORTS
import { Box, List, ListItem, ListSubheader, Fade } from '@mui/material';

const courseDefault = {
  name: '',
  gradeYear: '9th',
  semester1: 'A+',
  semester2: 'A+',
};

export const Container = ({ courseWork, setCourseWork }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();
  const [initialCW, setInitialCW] = useState(courseDefault);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePost = async (course) => {
    const postData = {
      name: course.name,
      gradeYear: course.gradeYear,
      semesterGrades: {
        semester1: course.semester1,
        semester2: course.semester2,
      },
    };
    try {
      const {
        message: { error, body },
      } = await postCourse(postData, cookies);

      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        const {
          data: { id, attributes },
        } = await body;
        enqueueSnackbar('Course Work Successfully Added.', {
          variant: 'success',
        });
        setInitialCW(courseDefault);
        setOpen((prev) => !prev);
        setCourseWork((prev) => [{ id, ...attributes }, ...prev]);
      }
    } catch (error) {
      console.error('error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          '& ul': { padding: 0 },
          overflowY: 'scroll',
          maxHeight: '85vh',
          mb: 2,
        }}
        subheader={<li />}
      >
        <ListSubheader>
          <AddProfileDetail onClick={handleClickOpen}>
            + Add Course
          </AddProfileDetail>
        </ListSubheader>
        {courseWork.map((item, index) => {
          return (
            <Fade in={true} {...{ timeout: 1000 + 700 * index }}>
              <ListItem key={item.id}>
                <CourseWorkCard {...item} setCourseWork={setCourseWork} />
              </ListItem>
            </Fade>
          );
        })}
        <Box sx={{ height: '100px' }}></Box>
      </List>
      <ModalDialog
        isOpen={open}
        handleClose={handleClose}
        content={
          <Form
            setOpen={setOpen}
            setCourseWork={setCourseWork}
            loading={loading}
            setLoading={setLoading}
            handleRequest={handlePost}
            id={"new course work"}
            initialCW={initialCW}
          />
        }
        title="Add Course"
      />
    </>
  );
};
