import React, { useState } from 'react';

// MUI IMPORTS
import { OutlinedInput, IconButton, InputAdornment } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ placeholder, onHandleSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(() => {
      const { value } = e.target;
      onHandleSearch(value, false);
      return value;
    });
  };

  const onCancel = () => {
    setSearchTerm(() => {
      onHandleSearch('', true);
      return '';
    });
  };

  return (
    <OutlinedInput
      type="name"
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleSearch}
      endAdornment={
        searchTerm ? <SearchCancelIconButton onCancel={onCancel} /> : null
      }
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

const SearchCancelIconButton = ({ onCancel }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onCancel} edge="end">
        <CancelIcon />
      </IconButton>
    </InputAdornment>
  );
};

export default Search;
