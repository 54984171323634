export const postCourse = async (data, cookies) => {
  let url = '/users/course';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 201 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status < 299 && status > 204) {
    message = { error: true, body: response.text() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};
export const patchCourse = async (data, id, cookies) => {
  let url = `/users/course/${id}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 201 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status < 299 && status > 204) {
    message = { error: true, body: response.text() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const deleteCourse = async (id, cookies) => {
  let url = `/users/course/${id}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'DELETE',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 204 || status === 200) {
    message = { error: false, body: 'Delete Successful' };
  }
  if (status < 299 && status > 204) {
    message = { error: true, body: response.text() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};
