import React from 'react';
import classes from './StyledFormWithInput.module.css';
import { FormControl } from '@mui/material';

const StyledFormWithInput = ({ children, onSubmit }) => {
  return (
    <form className={classes.styledFormWithInput} onSubmit={onSubmit}>
      <FormControl>{children}</FormControl>
    </form>
  );
};

export default StyledFormWithInput;
