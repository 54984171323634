import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { validateOrderedFavorites } from '../functions/validations';
import { postAboutMeData } from '../functions/requests';

//MUI Imports
import { TextField, Box, Stack, Button } from '@mui/material';

export const Form = ({ favorites, setAbout, setOpen }) => {
  const [orderedFavorites, setOrderedFavorites] = useState({
    favorite1: '',
    favorite2: '',
  });

  useEffect(() => {
    if (favorites) {
      setOrderedFavorites(favorites);
    }
  }, [favorites]);

  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies('tokens');
  const [loading, setLoading] = useState(false);

  const [favorite1Error, setFavorite1Error] = useState('');
  const [favorite2Error, setFavorite2Error] = useState('');

  const clearErrors = () =>
    [setFavorite1Error, setFavorite2Error].forEach((setError) => setError(''));

  const handleChange = (e, key) => {
    clearErrors();
    setOrderedFavorites((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    clearErrors();
    if (validate()) {
      setLoading(true);
      const {
        message: { error, body },
      } = await postAboutMeData({ orderedFavorites }, cookies);
      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        let {
          data: { attributes },
        } = await body;

        setAbout((prev) => {
          return {
            ...prev,
            ...attributes,
          };
        });
        enqueueSnackbar('Favorites Updated', {
          variant: 'success',
        });
      }
      setLoading(false);
      setOpen(false);
    }
  };

  const validate = () => {
    const { message, type, pass } = validateOrderedFavorites(orderedFavorites);
    console.log({ message, type, pass });
    switch (type) {
      case 'favorite1':
        setFavorite1Error(message);
        return pass;
      case 'favorite2':
        setFavorite2Error(message);
        return pass;
      default:
        return pass;
    }
  };

  const formProps = [
    {
      label: 'Favorite 1',
      value: orderedFavorites.favorite1,
      event: 'favorite1',
      error: favorite1Error,
    },
    {
      label: 'Favorite 2',
      value: orderedFavorites.favorite2,
      event: 'favorite2',
      error: favorite2Error,
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        {formProps.map((stateObj) => {
          const { label, value, event, error } = stateObj;
          return (
            <TextField
              key={label}
              id="filled-multiline-flexible"
              label={label}
              multiline
              maxRows={4}
              value={value}
              onChange={(e) => handleChange(e, event)}
              variant="filled"
              helperText={error}
              error={!!error}
            />
          );
        })}
      </Stack>
      <Button sx={{ my: 2, width: '100%' }} type="submit" variant="contained">
        {loading ? 'loading' : 'Submit'}
      </Button>
    </Box>
  );
};
