import React, { useState } from 'react';
import { newParagraphFormatting } from '../../../../utils/newParagraphFormatting';
import CollegeSearch from '../search-components/CollegeSearch';
import UserSearch from '../search-components/UserSearch';
import { convertIsoStringToReadableDate } from '../../../dateConverters/convertIsoStringToReadableDate';
import { addCollegesForCollegeTask } from '../functions/requests';
import { addStudentsForCustomTask } from '../functions/requests';
import AssignTask from '../reusable-components/AssignTask';
import { Link as RouterLink } from 'react-router-dom';
//MUI IMPORTS
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import Paper from '@mui/material/Paper';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Delete, Close, Edit } from '@material-ui/icons';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const NOT_SET = 'Not Set';

const TaskLayout = ({ task, onDeleteConfirm }) => {
  const [currentTab, setCurrentTab] = useState('1');
  const [isDeleting, setIsDeleting] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [addedIds, setAddedIds] = useState([]);

  const handleChange = (event, value) => {
    setCurrentTab(value);
  };

  const onCancel = () => {
    setIsDeleting(false);
  };

  const {
    id,
    attributes: {
      external_id,
      importance,
      notes,
      due_date,
      due_date_can_be_modified,
      name,
      type,
      weighted_importance,
      description,
      approx_total_hours,
    },
    relationships: { college_tasks },
  } = task;

  const showColleges = type === 'college' && college_tasks?.length > 0;
  return (
    <Container sx={{ mb: 5 }}>
      <Box component={Paper} sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={`Task Details`} value="1" />
              <Tab label="How" value="2" />
              <Tab label="Why" value="3" />
              {showColleges && <Tab label="College" value="4" />}
              {type !== 'standard' && <Tab label="Assign" value="5" />}
            </TabList>
          </Box>
          <TabPanel value="1">
            <p>External Id: {external_id}</p>
            <p>Name: {name || NOT_SET}</p>
            <p>Description: {description || NOT_SET}</p>
            <p>
              Due Date: {convertIsoStringToReadableDate(due_date) || NOT_SET}
            </p>
            <p>
              Can Be Modified:{' '}
              {due_date_can_be_modified === true ? 'Yes' : 'No'}
            </p>
            <p>Type: {type}</p>
            <p>Weighted Importance: {weighted_importance || NOT_SET}</p>
            <p>Approx Total Hours: {approx_total_hours || NOT_SET}</p>
            <p>
              {isDeleting && (
                <Button
                  sx={{ mr: 1 }}
                  variant="outlined"
                  startIcon={<Close />}
                  onClick={onCancel}
                >
                  CANCEL
                </Button>
              )}
              <Button
                variant="outlined"
                startIcon={<Delete />}
                onClick={() =>
                  isDeleting ? onDeleteConfirm() : setIsDeleting(true)
                }
              >
                {isDeleting ? 'ARE YOU SURE?' : 'DELETE'}
              </Button>
              <Button
                sx={{ mx: 2 }}
                variant="outlined"
                startIcon={<Edit />}
              >
                <RouterLink style={{ textDecoration: 'none', color: '#455fe8' }} state={{ from: "/admin/tasks" }} to={{ pathname: "/admin-edit-task", task }}>Edit</RouterLink>
              </Button>
            </p>
          </TabPanel>
          <TabPanel value="2">
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {newParagraphFormatting(notes)}
            </p>
          </TabPanel>
          <TabPanel value="3">
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {newParagraphFormatting(importance)}
            </p>
          </TabPanel>
          {showColleges && (
            <TabPanel value="4">
              {college_tasks.map((ct) => {
                const {
                  id,
                  attributes: { due_date },
                  relationships: { college },
                } = ct;
                return (
                  <div key={id}>
                    <p>
                      {college.id}, {college.attributes.name}
                    </p>
                    <p>
                      Assigned Due Date:{' '}
                      {convertIsoStringToReadableDate(due_date)}
                    </p>
                  </div>
                );
              })}
            </TabPanel>
          )}
          {type !== 'standard' && (
            <TabPanel value="5">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applyToAll}
                    onChange={(event) => {
                      setApplyToAll(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={
                  type === 'college'
                    ? 'Apply to all Colleges'
                    : 'Apply to all Students'
                }
              />
              {type !== 'standard' && (
                <>
                  {type === 'college' && (
                    <CollegeSearch
                      applyToAll={applyToAll}
                      addedIds={addedIds}
                      setAddedIds={setAddedIds}
                    />
                  )}

                  {type === 'custom' && (
                    <UserSearch
                      applyToAll={applyToAll}
                      addedIds={addedIds}
                      setAddedIds={setAddedIds}
                    />
                  )}

                  <AssignTask
                    taskId={id}
                    addedIds={addedIds}
                    setAddedIds={setAddedIds}
                    applyToAll={applyToAll}
                    callbackPost={
                      type === 'college'
                        ? addCollegesForCollegeTask
                        : addStudentsForCustomTask
                    }
                  />
                </>
              )}
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </Container>
  );
};

export default TaskLayout;
