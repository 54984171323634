import React, { useState, useEffect } from 'react';
import Day from './Day';

import {
  format,
  startOfWeek,
  startOfMonth,
  isBefore,
  endOfMonth,
  sub,
  add,
} from 'date-fns';

// MUI IMPORTS
import DatePicker from '@mui/lab/DatePicker';
import { useTheme } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import {
  Box,
  Grid,
  Typography,
  Stack,
  TextField,
  IconButton,
} from '@mui/material';

const makeDateKey = (date) => {
  const dateKey = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;

  return { dateKey };
};

const Layout = ({ setTasks, tasks, taskLoading, taskError }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [startDate, setStartDate] = useState(new Date());
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);

  useEffect(() => {
    setStartDay(startOfWeek(startOfMonth(startDate), { weekStartsOn: 0 }));
    setEndDay(endOfMonth(startDate, { weekStartsOn: 0 }));
  }, [tasks, startDate, setEndDay, setStartDay]);

  // Create the Map for the tasks on each day
  const [tasksMap, tasksMapSet] = useState({});

  useEffect(() => {
    const tempMap = tasks.reduce((acc, task) => {
      let date;
      task.completed
        ? (date = new Date(task.completedDate))
        : (date = new Date(task.dueDate));
      const { dateKey } = makeDateKey(date);
      !!acc[dateKey] ? acc[dateKey].push(task) : (acc[dateKey] = [task]);
      return acc;
    }, {});
    tasksMapSet(tempMap);
  }, [tasks, tasksMapSet]);

  // Set up the days for the calendar

  const [calendar, calendarSet] = useState([]);

  useEffect(() => {
    const cal = [];
    let day = sub(startDay, { days: 1 });
    while (isBefore(day, endDay)) {
      cal.push(
        [...Array(7)].map((_, i) => {
          const { dateKey } = makeDateKey(day);
          const config = {
            day,
            dateKey,
            tasks: tasksMap[dateKey] || [],
          };

          day = add(day, { days: 1 });
          return config;
        }),
      );
    }

    calendarSet(cal);
  }, [tasksMap, endDay, startDay]);

  return (
    <>
      <CalendarHeader startDate={startDate} setStartDate={setStartDate} />
      <Box
        sx={{
          width: '100%',
          border: '1px solid',
          borderColor: 'gray',
        }}
      >
        <Grid container columns={matches ? 7 : 12}>
          {calendar.map((week, i) => {
            return week.map((day, j) => {
              return (
                <Day
                  key={`week-${i}-day${j}`}
                  day={day}
                  startDate={startDate}
                  endDay={endDay}
                />
              );
            });
          })}
        </Grid>
      </Box>
    </>
  );
};

const CalendarHeader = ({ startDate, setStartDate }) => {
  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={2}></Grid>
        <Grid container item xs={8} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ mb: 2 }}
              justifyContent="center"
            >
              <IconButton
                size="large"
                color="primary"
                aria-label="navigate to previous month"
                component="button"
                onClick={() => setStartDate((prev) => sub(prev, { months: 1 }))}
              >
                <ArrowCircleLeftOutlinedIcon />
              </IconButton>
              <Typography variant="h1" component="h6" gutterBottom>
                {format(startDate, 'MMMM')}
              </Typography>
              <IconButton
                size="large"
                color="primary"
                aria-label="navigate to next month"
                component="button"
                onClick={() => setStartDate((prev) => add(prev, { months: 1 }))}
              >
                <ArrowCircleRightOutlinedIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['year', 'month']}
                label="Year and Month"
                minDate={new Date('2022-01-01')}
                maxDate={new Date('2023-06-01')}
                value={startOfMonth(startDate)}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={null}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Grid container textAlign="center" columns={7}>
        {[
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ].map((day, i) => {
          return (
            <Grid
              key={`${day}-${i}`}
              item
              sx={{ display: { xs: 'none', md: 'block' } }}
              md={1}
            >
              <Typography
                key={i}
                variant="body2"
                component="p"
                sx={{
                  fontWeight: 'bold',
                  color: 'textPrimary',
                  textAlign: 'center',
                  fontSize: { md: '.75rem', lg: '1.5rem' },
                }}
              >
                {day}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export default Layout;
