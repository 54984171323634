import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

//MUI IMPORTS
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Typography,
  Container,
  Row,
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';

const Bold = ({ children }) => {
  return (
    <Box sx={{ display: 'inline', fontWeight: 'bold' }} inline>
      {children}
    </Box>
  );
};

const ReportsButton = ({ studentId }) => {
  const history = useHistory();
  return (
    <TableCell>
      <Button
        onClick={() => {
          // push onto the navstack
          history.push(`/counselor/student-exam-report?studentId=${studentId}`);
        }}
        variant="contained"
      >
        {' '}
        VIEW REPORT
      </Button>
    </TableCell>
  );
};

const DeleteButton = ({}) => {
  return (
    <TableCell>
      <Button variant="contained"> DELETE ENTRY</Button>
    </TableCell>
  );
};

export default function CounselorGradedPracticeTest() {
  return (
    <Box>
      <Container maxWidth>
        <Typography variant="h1" component="h2" mb={2}>
          Graded Practice Tests
        </Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="enter-student-name">John Smith</InputLabel>
          <Select labelId="select-test-code" id="test-code" label="Exam">
            <MenuItem>John Smith</MenuItem>
          </Select>
        </FormControl>
        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Bold>Date Entered</Bold>
                </TableCell>
                <TableCell>
                  <Bold>Date Taken</Bold>
                </TableCell>
                <TableCell>
                  <Bold>Test Type</Bold>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>John</TableCell>
                <TableCell>Smith</TableCell>
                <TableCell>jsmith@mail.mail</TableCell>
                <ReportsButton />
                <DeleteButton />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
}
