import React from 'react';

//MUI IMPORTS
import {
  Box,
  Typography,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
} from '@mui/material';

import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDialog = ({ isOpen, handleClose, content, title}) => {
  
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="college-task-description"
      PaperProps={{
        sx: {
          minWidth: '30rem',
          maxHeight: '95vh',
          py: 1,
        },
      }}
    >
      <Box
        sx={{
          zIndex: '10',
          mt: 4,
          mb: 0,
          backgroundColor: '#1A3AD5',
          position: 'relative',
        }}
      >
        <Stack direction="row" justify="space-between" align="center">
          <Typography
            color="white"
            variant="h1"
            component="h6"
            sx={{ mr: 2, transform: 'scale(.5)' }}
          >
            {title}
          </Typography>
          <Avatar
            sx={{
              width: '5rem',
              height: '5rem',
              borderRadius: '50%',
              border: '2px solid',
              borderColor: '#1A3AD5',
              backgroundColor: 'white',
              position: 'absolute',
              top: '-.75rem',
              transform: 'scale(1.25)',
              right: '2rem',
              zIndex: '10',
            }}
          >
            <SchoolOutlinedIcon
              sx={{ color: 'black', transform: 'scale(2.5)' }}
            />
          </Avatar>
        </Stack>
      </Box>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Avatar
          sx={{
            mr: 2,
            width: '4rem',
            height: '4rem',
            borderRadius: '50%',
            backgroundColor: '#1A3AD5',
            cursor: "pointer"
          }}
          onClick={handleClose}
        >
          <Typography variant="h1" style={{cursor: "pointer"}}>X</Typography>
        </Avatar>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
