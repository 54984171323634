import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentAccess } from '../student/functions/requests';
import { BrowserRouter as Route } from 'react-router-dom';
import SignUp from 'navigation/SignUp';
import { useAuthUser, useUserRole } from '../../store/UserContext';

//MUI IMPORTS
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableHead,
  TableContainer,
  Button,
  Typography,
  Grid,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

const SnapshotButton = ({ studentId }) => {
  const history = useHistory();
  const role = useUserRole();

  const handleSnapshotNavigation = (id) => {
    if (role === 'counselor') {
      history.push(`/counselor/students/${studentId}/snapshot`);
    }

    if (role === 'account_admin') {
      history.push(`/account-admin/students/${studentId}/snapshot`);
    }
  };

  return (
    <TableCell>
      <Button
        style={{ cursor: 'pointer' }}
        onClick={handleSnapshotNavigation}
        variant="contained"
      >
        {' '}
        VIEW SNAPSHOT
      </Button>
    </TableCell>
  );
};

const TestsButton = ({ onClick }) => {
  return (
    <TableCell>
      <Button onClick={onClick} variant="contained">
        {' '}
        VIEW REPORTS
      </Button>
    </TableCell>
  );
};

const AddTestButton = ({ onClick }) => {
  return (
    <TableCell>
      <Button onClick={onClick} variant="contained">
        {' '}
        ADD TEST
      </Button>
    </TableCell>
  );
};

const GenerateSignUpLink = () => {
  const [link, setLink] = useState('');
  const user = useAuthUser();
  const { enqueueSnackbar } = useSnackbar();
  let accountCreatorId;

  if (user.role === 'account_admin') {
    ({ id: accountCreatorId } = user);
  } else if (user.role === 'counselor') {
    ({ id: accountCreatorId } = user);
  }

  useEffect(() => {
    if (link) {
      enqueueSnackbar('Link copied to clipboard.', { variant: 'success' });
    }
  }, [link, enqueueSnackbar]);

  const getPort = () => {
    if (window.location.hostname === 'localhost') {
      return `:${window.location.port}`;
    }
    return '';
  };

  const handleClick = async () => {
    const url = `${window.location.protocol}${
      window.location.hostname
    }${getPort()}/register?id=${accountCreatorId}`;
    setLink(url);

    await navigator.clipboard.writeText(url);
  };

  return (
    <Grid>
      <Button variant="contained" onClick={handleClick}>
        Generate Sign Up Link
      </Button>

      <Route exact path="/register" component={SignUp} />
    </Grid>
  );
};

const StudentsCounselor = ({ students, showStudent }) => {
  const [counselorStudents, setCounselorStudents] = useState([]);
  const [accountAdminStudents, setAccountAdminStudents] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [cookie] = useCookies(['tokens']);
  const role = useUserRole();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCurrentAccess(cookie);

      if (typeof response === 'string') {
        enqueueSnackbar(response, { variant: 'error' });
      } else {
        const filteredResults = response.data.filter((request) => {
          return request.attributes.relation === 'student';
        });

        const result = filteredResults.map((request) => {
          const { attributes, relationships } = request;
          return { attributes, relationships };
        });

        setCounselorStudents(result);
      }
    };

    fetchData();
  }, [enqueueSnackbar, cookie]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCurrentAccess(cookie);

      if (typeof response === 'string') {
        enqueueSnackbar(response, { variant: 'error' });
      } else {
        const filteredResults = response.data.filter((request) => {
          return request.attributes.relation === 'student';
        });

        const result = filteredResults.map((request) => {
          const { attributes, relationships } = request;
          return { attributes, relationships };
        });

        setAccountAdminStudents(result);
      }
    };

    fetchData();
  }, [enqueueSnackbar, cookie]);

  const handleNavigation = function (id) {
    if (role === 'counselor') {
      history.push(`/counselor/student-exam-entry?studentId=${id}`);
    }

    if (role === 'account_admin') {
      history.push(`/account-admin/student-exam-entry?studentId=${id}`);
    }

    if (role === 'exam_admin') {
      history.push(`/exam-admin/exam-entry?studentId=${id}`);
    }
  };

  const navigateToTests = function (id, attributes) {
    if (role === 'counselor') {
      history.push(
        `/counselor/student-exam-report?studentId=${id}`,
        attributes,
      );
    }
    if (role === 'account_admin') {
      history.push(
        `/account-admin/student-exam-report?studentId=${id}`,
        attributes,
      );
    }
  };

  return (
    <Container maxWidth>
      <Typography variant="h1" component="h2" mb={2}>
        Students
      </Typography>
      <GenerateSignUpLink />
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              {role === 'counselor' || role === 'account_admin' ? (
                <TableCell></TableCell>
              ) : (
                <></>
              )}
              {role === 'counselor' || role === 'account_admin' ? (
                <TableCell></TableCell>
              ) : (
                <></>
              )}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {role === 'counselor' ? (
            <TableBody>
              {counselorStudents.length > 0 ? (
                <>
                  {counselorStudents.map((record) => {
                    const {
                      relationships: {
                        student: {
                          data: { id, attributes },
                        },
                      },
                    } = record;
                    return (
                      <TableRow key={id}>
                        <TableCell>{attributes.firstName}</TableCell>
                        <TableCell>{attributes.lastName}</TableCell>
                        <TableCell>{attributes.email}</TableCell>
                        {role === 'counselor' ? (
                          <SnapshotButton studentId={id} />
                        ) : (
                          <></>
                        )}
                        {role === 'counselor' ? (
                          <TestsButton
                            onClick={() => navigateToTests(id, attributes)}
                          />
                        ) : (
                          <></>
                        )}
                        <AddTestButton onClick={() => handleNavigation(id)} />
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell>No current students.</TableCell>
                </TableRow>
              )}
            </TableBody>
          ) : (
            <></>
          )}
          {role === 'account_admin' ? (
            <TableBody>
              {accountAdminStudents.length > 0 ? (
                <>
                  {accountAdminStudents.map((record) => {
                    const {
                      relationships: {
                        student: {
                          data: { id, attributes },
                        },
                      },
                    } = record;

                    return (
                      <TableRow key={id}>
                        <TableCell>{attributes.firstName}</TableCell>
                        <TableCell>{attributes.lastName}</TableCell>
                        <TableCell>{attributes.email}</TableCell>
                        {role === 'account_admin' ? (
                          <SnapshotButton studentId={id} />
                        ) : (
                          <></>
                        )}
                        {role === 'account_admin' ? (
                          <TestsButton
                            onClick={() => navigateToTests(id, attributes)}
                          />
                        ) : (
                          <></>
                        )}
                        <AddTestButton onClick={() => handleNavigation(id)} />
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell>No current students.</TableCell>
                </TableRow>
              )}
            </TableBody>
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>
    </Container>
  );
};

export default StudentsCounselor;
