import React from 'react';
import { Footer as HonorsFooter } from './Footer';

// MUI IMPORTS
import { Stack, Divider, Typography, Box, Grid } from '@mui/material';

export const Card = ({
  id,
  title,
  description,
  dateReceived,
  type,
  setHonors,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: '2px solid',
        borderColor: '#455FE8',
        borderRadius: '17px',
      }}
    >
      <Grid container item spacing={1} sx={{ p: 2, width: '100%' }}>
        <Grid item xs={6}>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>
          <Typography component="p">{description}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              borderColor: '#8F8D93',
              borderLeftWidth: '3px',
              borderRightWidth: '0',
              height: '80%',
            }}
          />
        </Grid>
        <Grid item xs={5} sx={{ p: 2 }}>
          <Stack direction="row" spacing={3}>
            <Typography variant="h6" component="p" align="center">
              Type
              <Box fontWeight="fontWeightBold" sx={{ color: '#455FE8' }}>
                {type}
              </Box>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <HonorsFooter
        id={id}
        setHonors={setHonors}
        title={title}
        description={description}
        type={type}
        dateReceived={dateReceived}
      />
    </Box>
  );
};
