import React from 'react';

// MUI IMPORTS
import Button from '@mui/material/Button';

const AddProfileDetail = ({ onClick, children }) => {
  return (
    <Button
      aria-label="add activity"
      onClick={onClick}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '15px',
        border: '1px solid',
        borderColor: 'divider',
        color: 'black',
        backgroundColor: '#BDD1F9',
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
      }}
    >
      {children}
    </Button>
  );
};

export default AddProfileDetail;
