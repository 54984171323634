import React, { useEffect, useState, useContext } from 'react';
import { MessagingComponent } from './MessagingComponent';
import { getCurrentAccess } from 'components/student/functions/requests';
import { useCookies } from 'react-cookie';
import { useUserRole } from 'store/UserContext';

// MUI IMPORTS
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItemButton,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const Bold = ({ children }) => {
  return <Box sx={{ display: 'inline', fontWeight: 'bold' }}>{children}</Box>;
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  sideWindow: {
    width: '100%',
    height: '80vh',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '70vh',
    width: '100%',
    overflowY: 'auto',
  },
  searchBar: {
    [`& fieldset`]: {
      borderRadius: 100,
    },
  },
}));

export const DrawerAndNavMessages = () => {
  const [userContacts, setUserContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const userRole = useUserRole();
  const [cookie] = useCookies(['token']);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let userList = [];
        const response = await getCurrentAccess(cookie);

        // TODO: consolidate this and remove the conditional because I believe we can use one
        // ...and remove some dupe code
        if (userRole === 'account_admin' || userRole === 'counselor') {
          userList = response.data
            .filter((user) => {
              return user.attributes.relation === 'student';
            })
            .map((user) => {
              const { relation, email } = user.attributes;
              const { student } = user.relationships;
              return {
                id: student.data.id,
                relation,
                email,
                firstName: student.data.firstName,
                lastName: student.data.lastName,
              };
            });
        } else {
          userList = response.data.map((user) => {
            const { relation, email } = user.attributes;

            return {
              id:
                userRole === 'student'
                  ? user.relationships[relation].data.id
                  : user.relationships.student.data.id,
              relation,
              email,
            };
          });
        }

        setUserContacts(userList);
      } catch (error) {}
    };
    if (userRole) {
      fetchData();
    }
  }, [cookie, userRole]);

  useEffect(() => {
    if (userContacts.length) {
      const [firstContact] = userContacts;
      setSelectedContact(firstContact);
    }
  }, [setSelectedContact, userContacts]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ p: 1 }} variant="h4" component="h2">
            <Bold>Messages</Bold>
          </Typography>
        </Grid>
      </Grid>
      <Grid container component={Paper} sx={{ borderRadius: '15px' }}>
        <Grid item xs={3} className={classes.borderRight500}>
          <Grid item xs={12} style={{ padding: '10px' }}>
            <TextField
              className={classes.searchBar}
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>

          {userContacts.map((contact) => {
            return (
              <List key={contact.id}>
                <ListItemButton>
                  <ListItem
                    onClick={() => setSelectedContact(contact)}
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      backgroundColor:
                        selectedContact === contact ? '#DCDCDC' : '',
                    }}
                  >
                    <ListItemIcon>
                      <Avatar
                        sx={{
                          backgroundColor:
                            contact.relation === 'counselor'
                              ? '#425ce8'
                              : contact.relation === 'exam_admin'
                              ? '#F44336'
                              : contact.relation === 'student'
                              ? '#2196F3'
                              : contact.relation === 'account_admin'
                              ? '#42e884'
                              : '',
                        }}
                        alt="N/A"
                      >
                        {contact.email.charAt(0)}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={contact.email} />
                  </ListItem>
                  <Divider />
                </ListItemButton>
              </List>
            );
          })}
        </Grid>
        <Grid item xs={9}>
          {selectedContact === null ? (
            <></>
          ) : (
            <MessagingComponent selectedContact={selectedContact} />
          )}
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};
