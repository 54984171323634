import React, { useState, useEffect } from 'react';
import { studentSnapshot } from '../functions/requests';
import { useCookies } from 'react-cookie';
import DynamicTable from './DynamicTable';
import { useSnackbar } from 'notistack';
import StudentAttributesTable from './StudentAttributesTable';
//MUI IMPORTS
import Grid from '@mui/material/Grid';

import { useParams } from 'react-router-dom';
import { Divider } from '@material-ui/core';

export const SnapshotLayout = () => {
  const { id: studentId } = useParams();
  const [studentData, setStudentData] = useState();
  const [cookie] = useCookies(['tokens']);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getSnapshotData = async () => {
      const response = await studentSnapshot(cookie, studentId);
      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        setStudentData(response.data);
      }
    };

    getSnapshotData();
  }, [cookie, enqueueSnackbar, studentId]);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        {studentData?.attributes && (
          <StudentAttributesTable attributes={studentData.attributes} />
        )}
        <div sx={{ mt: 10 }}></div>
        <Divider />
        {studentData?.relationships &&
          Object.entries(studentData.relationships).map(([key, value]) => {
            console.log(45, key, value);
            if (value?.data?.length === 0) return <></>;
            return <DynamicTable key={key} name={key} data={value?.data} />;
          })}
      </Grid>
    </Grid>
  );
};
