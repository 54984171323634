import { getCurrentAccess } from 'components/student/functions/requests';
import { useState, useEffect } from 'react';

const useAccessibleStudents = ({ cookie }) => {
  const [students, setStudents] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCurrentAccess(cookie);

        if (typeof response === 'string') {
          setError(response);
        } else {
          const result = response.data
            .filter((record) => {
              return record.attributes.relation === 'student';
            })
            .map((record) => {
              const {
                attributes: { firstName, lastName, email },
                id,
              } = record.relationships.student.data;
              return {
                id: id,
                firstName,
                lastName,
                name: `${firstName} ${lastName}`,
                email,
              };
            });

          setStudents(result);
        }
      } catch (e) {
        setError(e.message);
      }
    };

    fetchData();
  }, [cookie]);

  return {
    students,
    error,
  };
};

export default useAccessibleStudents;
