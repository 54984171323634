import { entitiesByUserId } from 'components/functions/entitiesByUserId';
import { useState, useEffect } from 'react';

// This gets the very first entity in the list of entities for the user
// ...in the event the user has multiple entities
const useUserEntity = ({ cookie, userId }) => {
  const [entity, setEntity] = useState({});

  useEffect(() => {
    const fetchUserEntities = async () => {
      try {
        const response = await entitiesByUserId(cookie, userId);

        const [firstEntity] = response.data.map((entity) => {
          return {
            id: entity.id,
            name: entity.attributes.name,
          };
        });

        setEntity(firstEntity);
      } catch (e) {
        console.warn(e);
      }
    };

    if (cookie && userId) {
      fetchUserEntities();
    }
  }, [cookie, userId]);

  return entity;
};

export default useUserEntity;
