import React from 'react';
import ParsedString from './ParsedString';

import { Grid, Typography, Card, Box, CardContent } from '@mui/material';

const CollegeContent = ({ why, how }) => {
  // check if why or how are empty or null
  if (!why && !how) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" component="h2">
          No notes or importance given
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card
          elevation={1}
          sx={{
            overflow: 'visible',
            width: '95%',
            marginTop: '10px',
            borderRadius: '15px',
            border: '1px solid',
            borderColor: '#1A3AD5',
          }}
          pb={4}
        >
          <CardContent sx={{ p: 2 }}>
            <Typography variant="h6" color="primary" component="h6">
              Why?
            </Typography>
            {why && <ParsedString content={why} />}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          elevation={0}
          sx={{
            overflow: 'visible',
            width: '95%',
            marginTop: '10px',
            borderRadius: '15px',
            border: '1px solid',
            borderColor: '#1A3AD5',
          }}
          pb={4}
        >
          <CardContent>
            <Typography variant="h6" component="h6">
              How?
            </Typography>
            {how && <ParsedString content={how} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CollegeContent;
