import Joi from 'joi';
import {
  regstr,
  strMessages,
  errorReader,
} from 'components/student/functions/validation-helpers';

const title = Joi.string()
  .regex(regstr)
  .min(2)
  .max(30)
  .messages(strMessages('Title'))
  .required();

const description = Joi.string()
  .max(255)
  .regex(regstr)
  .messages(strMessages('Description'))
  .required();

const weeksPerYear = Joi.number()
  .min(1)
  .max(52)
  .messages(
    Object.assign(strMessages('Weeks Per Year'), {
      'string.pattern.base': `Must be a valid number between 1 and 52`,
    }),
  )
  .required();

const hoursPerWeek = Joi.number()
  .min(1)
  .max(168)
  .messages(
    Object.assign(strMessages('Hours Per Week'), {
      'string.pattern.base': `Must be a valid number between 1 and 168`,
    }),
  )
  .required();

const startYear = Joi.number()
  .min(1950)
  .max(2050)
  .messages(
    Object.assign(strMessages('Start Year'), {
      'string.pattern.base': `Must be a valid number`,
    }),
  )
  .required();

const endYear = Joi.number()
  .min(1950)
  .max(2050)
  .messages(
    Object.assign(strMessages('Start Year'), {
      'string.pattern.base': `Must be a valid number`,
    }),
  )
  .required();

const validateActivities = (data) => {
  const schema = Joi.object().keys({
    id: Joi.optional().allow(''),
    title: title,
    description: description,
    weeksPerYear: weeksPerYear,
    hoursPerWeek: hoursPerWeek,
    startYear: startYear,
    endYear: endYear,
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

export { validateActivities };
