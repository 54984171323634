export const deleteUser = (cookie) => {
  let url = `/auth/delete-account`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw new Error(
        `Unable to process API DELETE request to: ${url} REASON: ${e.message}`,
      );
    });
};

export const updateProfile = async (data, cookies) => {
  let url = '/users/profile';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 204 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status < 299 && status > 204) {
    message = { error: true, body: response.text() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const postAboutMeData = async (data, cookies) => {
  let url = '/users/about';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 204 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const getAdditionalUserData = async (cookies) => {
  let url = '/users/profile';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  
  const myHeaders = new Headers();
  myHeaders.append('token', cookies?.tokens?.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 200) {
    const body = await response.json();
    message = { error: false, body };
  }
  if (status > 299) {
    const body = await response.text();
    message = { error: true, body };
  }
  return { message };
};
