import React from 'react';
import {
  Grid,
  Paper,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HeightSpacer from '../HeightSpacer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '1280px',
    margin: '0 auto',
    height: '90vh',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fullHeight: {
    height: '100%',
  },
  card: {
    minWidth: 275,
    maxWidth: 350,
    margin: '0 auto',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  padded: {
    padding: theme.spacing(2),
  },
}));

const Lobby = ({
  roomName,
  handleRoomNameChange,
  handleSubmit,
  connecting,
  roomNameDisabled = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.fullHeight}>
        <Grid item xs={12} className={classes.fullHeight}>
          <Paper className={`${classes.paper} ${classes.fullHeight}`}>
            <Card className={classes.card}>
              <CardContent>
                <form
                  className={`${classes.padded}`}
                  noValidate
                  autoComplete="off"
                >
                  <h2>Enter room</h2>
                  <HeightSpacer />
                  <TextField
                    disabled={roomNameDisabled}
                    fullWidth
                    id="room"
                    value={roomName}
                    onChange={handleRoomNameChange}
                    readOnly={connecting}
                    required
                    label="Room Id"
                  />
                </form>
              </CardContent>
              <CardActions>
                <Button
                  disabled={connecting}
                  onClick={handleSubmit}
                  size="small"
                  color="primary"
                >
                  {connecting ? 'JOINING' : 'JOIN'}
                </Button>
              </CardActions>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Lobby;
