import React, { useState, useEffect, useCallback } from 'react';
import { Grid, LinearProgress, Button, Box } from '@material-ui/core';
import SuccessMessage from '../components/SuccessMessage';
import ErrorMessage from '../components/ErrorMessage';
import { useCookies } from 'react-cookie';
import listSessionRequests from './sessionManagementFunctions/listSessionRequests';
import StudentList from '../components/StudentList';
import UserSearch from './admin/UserSearch';
import debounce from '../utils/debounce';
import getUsersByNameOrRole from './admin/functions/getUsersByNameOrRole';
import addCounselorToSession from './sessionManagementFunctions/addCounselorToSession';
import { formatDateToLocal } from 'utils/dateHelpers';
import ContactLineInfo from './ContactLineInfo';

const ConfirmationModal = ({ onCancel, data }) => {
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  const [error] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCounselor, setSelectedCounselor] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [userSearch, setUserSearch] = useState('');

  useEffect(() => {
    const fetchUsersByInput = async () => {
      try {
        setLoading(true);
        const response = await getUsersByNameOrRole(
          userSearch,
          cookie,
          'counselor',
        );
        const jsonResponse = await response.json();
        const users = jsonResponse?.data;
        setUsersList(users);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (userSearch && userSearch !== '') {
      fetchUsersByInput();
    }
  }, [userSearch, cookie]);

  const addCounselorHandler = useCallback(async () => {
    try {
      const response = await addCounselorToSession(
        data?.selectedRequest?.id,
        selectedCounselor?.id,
        cookie,
      );
      const jsonResponse = await response.json();

      if (response.status === 200) {
        if (
          selectedCounselor?.id !==
          jsonResponse?.data?.relationships?.counselor?.id
        )
          throw new Error('Counselor id mismatch');
        onCancel({
          sessionId: data?.selectedRequest?.id,
          counselor: selectedCounselor,
        });
      } else {
        console.log(response.status);
        console.log('WE GOT AN ERROR...');
      }
    } catch (err) {
      console.log(err);
    }
  }, [cookie, data?.selectedRequest?.id, onCancel, selectedCounselor]);

  const setUserSearchHandler = (value) => {
    if (value === '') {
      setSelectedCounselor(null);
      setUsersList([]);
    }

    if (value?.length > 3) {
      setUserSearch(value);
    }
  };

  // debounced set user search handler
  const onSearchHandler = debounce(
    (currentValue) => setUserSearchHandler(currentValue),
    400,
  );

  const onSelectedStudent = (currentValue) => {
    setSelectedCounselor(currentValue);
  };

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <h1>Add Counselor</h1>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserSearch
                onHandleSearchInput={onSearchHandler}
                placeholder="Search by Counselor Name"
              />
            </Grid>
            {usersList?.length > 0 && (
              <Grid item xs={12}>
                <StudentList
                  students={usersList}
                  onStudentSelected={onSelectedStudent}
                  clearList={() => {
                    setUsersList([]);
                    setUserSearch('');
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3}>
            {error && <ErrorMessage message={error} />}
            {loading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}

            <Grid item xs={12}>
              {selectedCounselor && (
                <p>
                  <strong>
                    Add {selectedCounselor?.attributes?.firstName}{' '}
                    {selectedCounselor?.attributes?.lastName}{' '}
                    {selectedCounselor?.attributes?.email} as counselor?
                  </strong>
                </p>
              )}
              <p>Session Information:</p>
              <p>
                {formatDateToLocal(data?.selectedRequest?.date)}
                <br />
                {data?.selectedRequest?.notes}
              </p>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addCounselorHandler()}
                >
                  SUBMIT
                </Button>
                <Button variant="contained" onClick={() => onCancel()}>
                  CANCEL
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

// this is slightly different from the users request session cards, but may be able to be created from the same component
const RequestedSessionCard = ({
  id,
  notes,
  date,
  student,
  onClick,
  length,
  counselor = null,
}) => {
  return (
    <div>
      <ContactLineInfo
        prefix={'Student'}
        firstName={student.attributes.firstName}
        lastName={student.attributes.lastName}
        email={student.attributes.email}
      />
      <p>
        {counselor?.id ? 'Booked Time: ' : 'Proposed Time: '}
        {formatDateToLocal(date)}, {`${length} mins`}
      </p>
      <p>Notes: {notes}</p>
      {counselor?.id && (
        <ContactLineInfo
          prefix={'Counselor'}
          firstName={counselor.attributes.firstName}
          lastName={counselor.attributes.lastName}
          email={counselor.attributes.email}
        />
      )}
      <Grid container spacing={1}>
        <Grid item>
          <Button
            disabled={counselor?.id ? true : false}
            variant="outlined"
            onClick={() => onClick(id)}
          >
            Add Counselor
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const DEFAULT_MODAL_ACTIVE_STATE = {
  id: null,
  selectedRequest: {
    id: null,
    notes: null,
    date: null,
  },
};

const AdminManageSessions = () => {
  const [errorMessage] = useState(false);
  const [successMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  const [sessionRequests, setSessionRequests] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [modalActiveData, setModalActiveData] = useState({
    ...DEFAULT_MODAL_ACTIVE_STATE,
  });

  useEffect(() => {
    const getSessionRequests = async () => {
      try {
        setLoading(true);
        const response = await listSessionRequests(cookie);
        const jsonResponse = await response.json();

        // TODO: add error handling
        if (response.status === 200) {
          setSessionRequests(jsonResponse?.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getSessionRequests();
  }, [cookie]);

  return (
    <>
      <div className="main">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>Session Management</h1>
          </Grid>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {successMessage && <SuccessMessage message={successMessage} />}
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {sessionRequests?.length === 0 && <p>No session requests found.</p>}
          {sessionRequests.map((request) => {
            const {
              id,
              attributes: { notes, timeRequested, length },
              relationships: { counselor, student },
            } = request;
            return (
              <Grid key={id} item xs={12}>
                <RequestedSessionCard
                  id={id}
                  notes={notes}
                  date={timeRequested}
                  length={length}
                  counselor={counselor}
                  student={student}
                  onClick={(id) => {
                    setModalActive(true);
                    setModalActiveData({
                      sessionId: id,
                      selectedRequest: {
                        id,
                        notes,
                        date: timeRequested,
                        student,
                      },
                    });
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        {modalActive && (
          <ConfirmationModal
            data={modalActiveData}
            onCancel={(data) => {
              setModalActive(false);
              setModalActiveData({ ...DEFAULT_MODAL_ACTIVE_STATE });

              // update UI with counselor info from modal
              // ...TODO: switch this to update state to show that counselor has been added and move into different column?
              if (data?.counselor?.id && data?.sessionId) {
                const { sessionId, counselor } = data;
                setSessionRequests((prevState) => {
                  return [...prevState].map((s) => {
                    const newSession = s;

                    if (sessionId === s.id) {
                      newSession.relationships.counselor = { ...counselor };
                    }

                    return newSession;
                  });
                });
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default AdminManageSessions;
