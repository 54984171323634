import React from 'react';
import { Grid } from '@material-ui/core';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="main">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>Privacy Policy</h1>
          </Grid>
          <Grid item xs={12}>
            <p>
              <span>
                This Privacy Policy describes how your personal information is
                collected, used, and shared when you visit or make a purchase
                from thevizeapp.com.
              </span>
            </p>
            <p>
              <span>
                Our principles regarding user privacy and data protection
              </span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>
                We believe user privacy and data protection are human rights
              </span>
              .
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>
                We take protecting your privacy seriously, and we recognize we
                have a duty of care to the people whose data we hold.
              </span>
              <span></span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>
                We will only collect and process data when it is absolutely
                necessary, and when we do, we will make it clear why we are
                doing so and how it will be used.
              </span>
              <span></span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>
                We will not send you regular email newsletters that you have not
                subscribed to – we hate spam as much as you do! We will always
                give you the choice to unsubscribe.
              </span>
              <span></span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>
                We will not share your personal information with anyone else
                without your permission.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>WHAT PERSONAL INFORMATION WE COLLECT</span>
            </p>
            <p>
              <span>
                When you visit vize.community (the Site), we automatically
                collect certain information about your device, including
                information about your web browser, IP address, time zone, and
                some of the cookies that are installed on your device.{' '}
              </span>
            </p>
            <p>
              <span>
                Additionally, as you browse the Site, we collect information
                about the individual web pages or products that you view, what
                websites or search terms referred you to the Site, and
                information about how you interact with the Site. We refer to
                this automatically collected information as
              </span>
              <span>Device Information</span>
              <span>.</span>
            </p>
            <p>
              <span>
                We collect Device Information using the following technologies:
              </span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>Cookies</span>
              <span>
                are data files that are placed on your device or computer and
                often include an anonymous unique identifier.
              </span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>Log files</span>
              <span>
                track actions occurring on the Site, and collect data including
                your IP address, browser type, Internet service provider,
                referring/exit pages, and date/time stamps.
              </span>
            </p>
            <p>
              <span>
                Also, when you make a purchase or attempt to make a purchase
                through the Site, we collect certain information from you,
                including your name, billing address, shipping address, payment
                information (including credit card numbers, email address, and
                phone number. This is called
              </span>
              <span>Order Information</span>
              <span>.</span>
            </p>
            <p>
              <span>By </span>
              <span>Personal Information</span>
              <span>
                in this Privacy Policy, we are talking both about Device
                Information and Order Information.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>HOW DO WE USE YOUR PERSONAL INFORMATION</span>
            </p>
            <p>
              <span>
                We use the Order Information that we collect generally to
                fulfill any orders placed through the Site (including processing
                your payment information, arranging for shipping, and providing
                you with invoices and/or order confirmations).{' '}
              </span>
            </p>
            <p>
              <span>Additionally, we use this Order Information to: </span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>Communicate with you. </span>
            </p>
            <p>
              <span>•</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span>•</span>
              <span>&nbsp;</span>
              <span>Screen our orders for potential risk or fraud. </span>
              <span></span>
            </p>
            <p>
              <span>
                When in line with the preferences you have shared with us,
                provide you with information or advertising relating to our
                products or services.
              </span>
            </p>
            <p>
              <span>
                We use the Device Information that we collect to help us screen
                for potential risk and fraud (in particular, your IP address),
                and more generally to improve and optimize our Site.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>SHARING YOUR PERSONAL INFORMATION</span>
            </p>
            <p>
              <span>
                Finally, we may also share your Personal Information to comply
                with applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful requests for information we
                receive, or to otherwise protect our rights.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>YOUR RIGHTS</span>
            </p>
            <p>
              <span>
                If you are a European resident, you have the right to access the
                personal information we hold about you and to ask that your
                personal information is corrected, updated, or deleted. If you
                would like to exercise this right, please contact us.
              </span>
            </p>
            <p>
              <span>
                Additionally, if you are a European resident we note that we are
                processing your information in order to fulfill contracts we
                might have with you (for example if you make an order through
                the Site), or otherwise to pursue our legitimate business
                interests listed above.
              </span>
              <span>
                Please note that your information will be transferred outside of
                Europe, including to Canada and the United States.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>DATA RETENTION</span>
            </p>
            <p>
              <span>
                When you place an order through the Site, we will maintain your
                Order Information for our records unless and until you ask us to
                delete this information.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>CALIFORNIA PRIVACY RIGHTS</span>
            </p>
            <p>
              <span>
                California Civil Code Section 1798.83, also known as the “Shine
                The Light” law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </span>
            </p>
            <p>
              <span>
                If you are under 18 years of age, reside in California, and have
                a registered account with the Site, you have the right to
                request removal of unwanted data that you publicly post on the
                Site. To request removal of such data, please contact us using
                the contact information provided below, and include the email
                address associated with your account and a statement that you
                reside in California.&nbsp; We will make sure the data is not
                publicly displayed on the Site, but please be aware that the
                data may not be completely or comprehensively removed from our
                systems.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>MINORS</span>
            </p>
            <p>
              <span>
                The Site is not intended for individuals under the age of 13.
              </span>
            </p>
            <p>
              <span>
                We do not knowingly solicit information from or market to
                children under the age of 13. If you become aware of any data we
                have collected from children under age 13, please contact us
                using the contact information provided below.{' '}
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>CHANGES</span>
            </p>
            <p>
              <span>
                We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal or regulatory reasons.
              </span>
            </p>
            <p>
              <span>
                If you have questions and/or require more information, do not
                hesitate to contact us at{' '}
              </span>
              <span>vizecommunityapp@gmail.com</span>
              <span>.</span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PrivacyPolicy;
