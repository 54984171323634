const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
let message = { error: false, body: null };

export const postTest = async (data, cookies) => {
  let url = '/users/test';

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);
  const { status } = response;

  if (status <= 204 || status === 200) {
    const body = await response.json();

    message = { error: false, body: body };
  }
  if (status > 204) {
    const body = await response.text();
    message = { error: true, body: body };
  }
  return { message };
};

export const deleteTest = async (id, cookies) => {
  let url = `/users/test/${id}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'DELETE',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  const { status } = response;

  if (status <= 204) {
    message = { error: false, body: 'Delete Successful' };
  } else {
    const body = await response.text();
    message = { error: true, body: body };
  }
  return { message };
};
