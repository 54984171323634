const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
let message = { error: false, body: '' };

export const updatePassword = async (data, cookies) => {
  let url = '/auth/update-pass';

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;

  if (status <= 204) {
    message = { error: false, body: 'Password updated.' };
  }
  if (status > 204) {
    message = { error: true, body: 'Password is incorrect' };
  }
  return { message };
};
