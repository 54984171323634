import React from 'react';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EditTaskForm from './EditTaskForm';

const EditTaskFormLayout = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item xs={6}>
        <EditTaskForm />
      </Grid>
    </Box>
  );
};

export default EditTaskFormLayout;
