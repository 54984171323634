import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

//MUI imports
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const SharedSearchComponent = ({
  callbackGet,
  extraParameter,
  addedIds,
  setAddedIds,
  applyToAll,
  getOptionLabel,
  label,
}) => {
  const [cookies] = useCookies('tokens');
  const [dataForSearch, setDataForSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!inputValue) return;
    const fetchData = async () => {
      try {
        const response = await callbackGet(cookies, extraParameter, inputValue);
        if (response.error) {
          throw response.message;
        } else {
          const { data } = response.body;
          if (data) {
            setDataForSearch(data);
          } else {
            const jsonResponse = await response.json();
            setDataForSearch(jsonResponse.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (inputValue && inputValue !== '') {
      fetchData();
    }
  }, [inputValue, cookies, callbackGet, extraParameter]);

  const handleChangeInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleInput = (event, value) => {
    setAddedIds(value);
  };

  return (
    <Box display="flex" flexDirection="column" width="50%" p={2}>
      {!applyToAll && (
        <Box m={1} p={2}>
          <Autocomplete
            multiple
            id="tags-standard"
            value={addedIds}
            options={[...dataForSearch, ...addedIds]}
            filterSelectedOptions
            onChange={handleInput}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={handleChangeInput}
                variant="standard"
                label={label}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default SharedSearchComponent;
