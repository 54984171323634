import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { validateHonors } from './functions/validations';
import { useSnackbar } from 'notistack';
import { patchHonor } from './functions/requests';
import DateReceived from './DateRecieved';

// MUI IMPORTS
import {
  Box,
  Stack,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

export const Edit = ({
  setOpen,
  setHonors,
  id,
  type,
  title,
  description,
  dateReceived,
}) => {
  const [honor, setHonor] = useState({
    type,
    title,
    description,
    dateReceived,
  });

  const handleChange = (value, label) => {
    errorSetters.forEach((setError) => setError(''));
    setHonor((prev) => {
      return { ...prev, [label]: value };
    });
  };

  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [typeError, setTypeError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [dateReceivedError, setDateReceivedError] = useState('');

  const errorSetters = [
    setTypeError,
    setTitleError,
    setDescriptionError,
    setDateReceivedError,
  ];

  const validate = (data) => {
    const { message, type, pass } = validateHonors(data);
    switch (type) {
      case 'title':
        setTitleError(message);
        return pass;
      case 'dateReceived':
        setDateReceivedError(message);
        return pass;
      case 'type':
        setTypeError(message);
        return pass;
      case 'description':
        setDescriptionError(message);
        return pass;
      default:
        return pass;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate(honor)) {
      setLoading(true);
      handlePatch(honor);
    }
  };

  const handlePatch = async (data) => {
    try {
      const {
        message: { error, body },
      } = await patchHonor(data, id, cookies);

      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('Honor edited successfully', { variant: 'success' });
        setOpen((prev) => !prev);
        setHonors((prev) => {
          return prev.map((item) => {
            if (item.id === id) {
              return { ...honor };
            }
            return item;
          });
        });
      }
    } catch (error) {
      console.error('error:', error);
    } finally {
      setLoading(false);
    }
  };

  const formProps = [
    {
      label: 'Title',
      value: honor.title,
      change: 'title',
      error: titleError,
    },
    {
      label: 'Description',
      value: honor.description,
      change: 'description',
      error: descriptionError,
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      autoComplete="off"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <Stack spacing={2}>
        {formProps.map((stateObj, index) => {
          return (
            <TextField
              key={index}
              id="filled-multiline-flexible"
              label={stateObj.label}
              multiline
              maxRows={4}
              value={stateObj.value}
              onChange={(event) =>
                handleChange(event.target.value, stateObj.change)
              }
              variant="filled"
              helperText={stateObj.error}
              error={!!stateObj.error}
            />
          );
        })}
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="award-type-select-label">Type</InputLabel>
          <Select
            labelId="award-type-select-label"
            id="award-type-select"
            value={honor.type}
            label="Age"
            onChange={(event) => handleChange(event.target.value, 'type')}
          >
            {['Local', 'National', 'International', 'School'].map((t) => {
              return (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {typeError && <FormHelperText error={true}>{typeError}</FormHelperText>}
        <DateReceived
          error={dateReceivedError}
          date={honor.dateReceived}
          label="Date Taken"
          setDate={handleChange}
        />
      </Stack>
      <Button
        sx={{ mx: 5, my: 2 }}
        type="submit"
        variant="contained"
        disabled={loading}
      >
        {loading ? 'loading' : 'Submit'}
      </Button>
    </Box>
  );
};
