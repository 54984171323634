const getUsersByNameOrRole = (cookie, role, name) => {
  let url = `/admin/all?role=${encodeURIComponent(role)}`;
  if (name.length >= 3) {
    url = url + `&q=${encodeURIComponent(name)}`;
  }

  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(async (response) => {
      return response;
    })
    .catch((e) => {
      throw new Error(
        `Unable to process API request to: ${url} REASON: ${e.message}`,
      );
    });
};

export default getUsersByNameOrRole;
