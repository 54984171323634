import React from 'react';
import Button from '@mui/material/Button';

const DownloadButton = ({ link }) => {
  return (
    // TODO: look into this linting issue @evan
    // eslint-disable-next-line react/jsx-no-target-blank
    <a style={{ textDecoration: 'none' }} href={link} target="_blank" download>
      <Button variant="contained">Download</Button>
    </a>
  );
};

export default DownloadButton;
