import React, { useState, useEffect } from 'react';
import { ExamRadioButtonChange } from 'components/studentExamEntry/studentExamEntry';
import { useParams } from 'react-router-dom';
import { getExamById } from './functions';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

export default function CounselorExamEntry() {
  const [examData, setExamData] = useState();
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  useEffect(() => {
    const fetchExamData = async () => {
      const response = await getExamById(cookie, params.id, params.examId);
      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        const responseFormatted = response.data.attributes;
        setExamData({ ...responseFormatted, id: response.data.id });
      }
    };

    if (params) {
      fetchExamData();
    }
  }, [cookie, params, enqueueSnackbar]);
  return (
    <>
      {examData?.id ? (
        <ExamRadioButtonChange
          defaultState={examData}
          editStudentId={params.id}
        />
      ) : (
        <></>
      )}
    </>
  );
}
