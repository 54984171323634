import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';

import DateReceived from '../../student/profile-details/honors/DateRecieved';
import { addDeadlines, editDeadlines } from './functions/requests';
import { formatDateToLocal } from '../../../utils/dateHelpers';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Typography, Stack } from '@mui/material';

const CURRENT_DATE = new Date();

const DeadlinesForm = ({
  deadlinesData,
  isEditing,
  resetDeadlinesEditingState,
}) => {
  const [cookies] = useCookies('tokens');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState('');

  const [eaDeadline, setEaDeadline] = useState(CURRENT_DATE);
  const [ed1Deadline, setEd1Deadline] = useState(CURRENT_DATE);
  const [ed2Deadline, setEd2Deadline] = useState(CURRENT_DATE);
  const [regDeadline, setRegDeadline] = useState(CURRENT_DATE);

  const clearDeadlinesForm = useCallback(() => {
    setEaDeadline(CURRENT_DATE);
    setEd1Deadline(CURRENT_DATE);
    setEd2Deadline(CURRENT_DATE);
    setRegDeadline(CURRENT_DATE);
  }, []);

  const submitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      setMessage('');
      setError('');
      try {
        const transformFormStateToData = {
          ea_deadline: eaDeadline,
          ed1_deadline: ed1Deadline,
          ed2_deadline: ed2Deadline,
          reg_deadline: regDeadline,
        };

        // clearing empty values from the object
        Object.entries(transformFormStateToData).forEach(([key, value]) => {
          if (!value || value === '') {
            delete transformFormStateToData[key];
          } else {
            transformFormStateToData[key] = formatDateToLocal(value);
          }
        });

        const response = isEditing
          ? await editDeadlines(
              cookies,
              transformFormStateToData,
              deadlinesData?.ceebCode,
            )
          : await addDeadlines(
              cookies,
              transformFormStateToData,
              deadlinesData?.ceebCode,
            );

        if (response?.error) {
          setError(response.message);
        } else {
          setMessage(isEditing ? 'Deadlines Updated' : 'Deadlines Added');
          clearDeadlinesForm();
          resetDeadlinesEditingState();
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [
      eaDeadline,
      ed1Deadline,
      ed2Deadline,
      regDeadline,
      deadlinesData?.ceebCode,
      clearDeadlinesForm,
      cookies,
      isEditing,
      resetDeadlinesEditingState,
    ],
  );

  useEffect(() => {
    if (!deadlinesData) return;
    const { eaDeadline, ed1Deadline, ed2Deadline, regDeadline } = deadlinesData;
    if (eaDeadline) setEaDeadline(eaDeadline);
    if (ed1Deadline) setEd1Deadline(ed1Deadline);
    if (ed2Deadline) setEd2Deadline(ed2Deadline);
    if (regDeadline) setRegDeadline(regDeadline);
  }, [deadlinesData]);

  const formTitleHelper = () => {
    let title;
    if (deadlinesData?.ceebCode) {
      title = 'Add Deadline Dates';

      if (isEditing) {
        title = 'Edit Deadline Dates';
      }
      return title + ` for ceeb: ${deadlinesData?.ceebCode}`;
    } else {
      title = 'Pick college';
    }
    return title;
  };

  return (
    <Paper sx={{ p: '2rem' }}>
      <Typography align="center" variant="h5">
        {formTitleHelper()}
      </Typography>
      {error && <p>{error}</p>}
      {message && <p>{message}</p>}
      <Box
        component="form"
        onSubmit={submitHandler}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={3}>
          <DateReceived
            date={eaDeadline}
            setDate={setEaDeadline}
            label="Ea Deadline"
            disabled={deadlinesData?.ceebCode == null}
          />
          <DateReceived
            date={ed1Deadline}
            setDate={setEd1Deadline}
            label="Ed1 Deadline"
            disabled={deadlinesData?.ceebCode == null}
          />
          <DateReceived
            date={ed2Deadline}
            setDate={setEd2Deadline}
            label="Ed2 Deadline"
            disabled={deadlinesData?.ceebCode == null}
          />
          <DateReceived
            date={regDeadline}
            setDate={setRegDeadline}
            label="Reg Deadline"
            disabled={deadlinesData?.ceebCode == null}
          />
          <Button sx={{ mx: 5, my: 2 }} type="submit" variant="contained">
            {loading ? 'Processing...' : 'Submit'}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default DeadlinesForm;
