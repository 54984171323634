import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useCookies } from 'react-cookie';
import { camelCaseFormatter } from '../../../utils/camelCaseFormatter';
import { convertTimeStampToReadableDate } from '../../dateConverters/convertTimeStampToReadableDate';

import { deleteCollege, deleteDeadlines } from './functions/requests';

const AdminCollegeCard = ({
  ceebCode,
  name,
  state,
  location,
  division,
  airport,
  facStu,
  geography,
  isPublic,
  schoolType,
  smallClass,
  urbanCenter1,
  urbanCenter2,
  status,
  eaDeadline,
  ed1Deadline,
  ed2Deadline,
  regDeadline,
  setCollegeDataToEdit,
  setDeadlinesToEdit,
  setIsDeadlinesDataEditing,
}) => {
  const [cookies] = useCookies('tokens');
  const [deleteCollegeStatus, setDeleteCollegeStatus] =
    useState('delete college');
  const [deleteDeadlinesStatus, setDeleteDeadlinesStatus] =
    useState('reset deadlines');
  const [disableButton, setDisableButton] = useState(false);

  const collegeData = {
    name,
    ceebCode,
    state,
    location,
    division,
    airport,
    facStu,
    geography,
    isPublic,
    schoolType,
    smallClass,
    urbanCenter1,
    urbanCenter2,
    status,
  };

  const deadlinesData = {
    eaDeadline,
    ed1Deadline,
    ed2Deadline,
    regDeadline,
  };

  const collegeDeleteMessage =
    'Are you sure you want to delete this college?\nAll data related to this college will be deleted!';
  const deadlinesDeleteMessage =
    'Are you sure you want to reset these deadlines?\nAll deadlines for this college will be deleted!';

  const confirmDelete = (message, deleteStatusSetter, apiCall) => {
    if (window.confirm(message)) {
      onHandleDelete(deleteStatusSetter, apiCall);
    }
  };

  const onHandleDelete = async (deleteStatusSetter, apiCall) => {
    try {
      deleteStatusSetter('Deleting...');
      const deleted = await apiCall(cookies, ceebCode);
      if (deleted) {
        deleteStatusSetter('Deleted!');
      } else {
        deleteStatusSetter(deleted.message);
      }
    } catch (e) {
      setDeleteDeadlinesStatus(e.message);
    } finally {
      setDisableButton(true);
    }
  };

  const editCollegeData = () => {
    setCollegeDataToEdit(collegeData);
  };

  // deadlines are equal to 0 if they don't exist
  const isEditDeadline =
    eaDeadline !== 0 ||
    ed1Deadline !== 0 ||
    ed2Deadline !== 0 ||
    regDeadline !== 0;

  const editDeadlines = () => {
    deadlinesData.ceebCode = ceebCode;
    setIsDeadlinesDataEditing(isEditDeadline);
    setDeadlinesToEdit(deadlinesData);
  };

  return (
    <Card sx={{ width: '100%', marginTop: '10px' }}>
      <CardContent>
        {/*college data block*/}
        <b style={{ fontSize: 25 }}>College Data</b>
        <Button onClick={editCollegeData} disabled={disableButton}>
          Edit College Data
        </Button>
        {Object.entries(collegeData).map(([key, value]) => (
          <Typography key={key} gutterBottom variant="h6" component="div">
            {`${camelCaseFormatter(key)}: ${
              typeof value === 'boolean'
                ? value.toString()
                : value ?? 'Not specified'
            }`}
          </Typography>
        ))}
        {/*deadlines block*/}
        <b style={{ fontSize: 25 }}>Deadlines</b>
        <Button onClick={editDeadlines} disabled={disableButton}>
          {isEditDeadline ? 'Edit Deadlines' : 'Add Deadlines'}
        </Button>
        {Object.entries(deadlinesData).map(([key, value]) => (
          <Typography key={key} gutterBottom variant="h6" component="div">
            {`${camelCaseFormatter(key)}: ${
              value ? convertTimeStampToReadableDate(value) : 'Not offered'
            }`}
          </Typography>
        ))}

        <Button
          onClick={() =>
            confirmDelete(
              collegeDeleteMessage,
              setDeleteCollegeStatus,
              deleteCollege,
            )
          }
          disabled={disableButton}
        >
          {deleteCollegeStatus}
        </Button>
        {isEditDeadline && (
          <Button
            onClick={() =>
              confirmDelete(
                deadlinesDeleteMessage,
                setDeleteDeadlinesStatus,
                deleteDeadlines,
              )
            }
            disabled={disableButton}
          >
            {deleteDeadlinesStatus}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default AdminCollegeCard;
