import React, { useState, useEffect } from 'react';
import { allStudents } from './functions/requests';
import { useCookies } from 'react-cookie';
import StudentTable from './StudentTable';
import StudentLayout from './StudentLayout';
import { useSnackbar } from 'notistack';
//MUI IMPORTS
import Grid from '@mui/material/Grid';

export const IndexLayout = () => {
  const [students, setStudents] = useState([]);
  const [cookie] = useCookies(['tokens']);
  const [student, setStudent] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getAllStudents = async () => {
      const response = await allStudents(cookie);
      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        setStudents(response.data);
      }
    };
    getAllStudents();
  }, [cookie, enqueueSnackbar]);

  const showStudent = (student, id) => {
    setStudent({ ...student, id });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {student && (
        <Grid container item xs={12} sx={{ mt: 5 }}>
          <StudentLayout student={student} />
        </Grid>
      )}
      <Grid item xs={12}>
        <StudentTable students={students} showStudent={showStudent} />
      </Grid>
    </Grid>
  );
};
