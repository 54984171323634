import React, { useState, useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import DateReceived from '../../../student/profile-details/honors/DateRecieved';
import { editTask } from '../functions/requests';
import { useLocation, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography, Select, MenuItem, CardHeader, Card } from '@mui/material';
import TextField from '@mui/material/TextField';
import { formatDateToLocal } from '../../../../utils/dateHelpers';

const EditTaskForm = () => {
  const location = useLocation();
  const history = useHistory();
  
  const { attributes: { external_id, name, description, due_date, importance, notes, type, weighted_importance, approx_total_hours }} = location.task;
  
  const taskSnapshot = useMemo(() => {
    return { external_id, name, description, due_date, importance, notes, type, weighted_importance, approx_total_hours}
  }, [external_id, name, description, due_date, importance, notes, type, weighted_importance, approx_total_hours]);

  const [cookies] = useCookies('tokens');
  const [loading, setLoading] = useState(false);
  const [currentName, setCurrentName] = useState(name);
  const [currentDescription, setCurrentDescription] = useState(description);
  const [currentDueDate, setCurrentDueDate] = useState(formatDateToLocal(due_date));
  const [currentImportance, setCurrentImportance] = useState(importance);
  const [currentHowImportant, setCurrentHowImportant] = useState(weighted_importance);
  const [currentHours, setCurrentHours] = useState(approx_total_hours);
  const [currentNotes, setCurrentNotes] = useState(notes);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const submitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      
      try {
        setLoading(true);
        
        let filteredData = {};

        // TODO: make this more reusable and iterable, but do checks against snapshot data and current value
        if (taskSnapshot?.notes !== currentNotes) filteredData.notes = currentNotes

        if (taskSnapshot?.importance !== currentImportance) filteredData.importance = currentImportance;

        if (taskSnapshot?.name !== currentName) filteredData.name = currentName;

        if (taskSnapshot?.description !== currentDescription) filteredData.description = currentDescription;

        if (taskSnapshot?.weighted_importance !== currentHowImportant) filteredData.weighted_importance = currentHowImportant;

        if (taskSnapshot?.approx_total_hours !== currentHours) filteredData.approx_total_hours = currentHours;

        if (taskSnapshot?.due_date !== formatDateToLocal(currentDueDate)) {
          filteredData.due_date = new Date(formatDateToLocal(currentDueDate)).toISOString();
        };
        

        const response = await editTask(cookies, filteredData, external_id);

        if (response !== true) {
          enqueueSnackbar(response.message, { variant: 'error' });
        } else {
          enqueueSnackbar(`Task with id: ${external_id} updated!`, { variant: 'success' });
          setHasSubmitted(true);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [cookies, currentDescription, currentDueDate, currentImportance, currentName, currentNotes, external_id, enqueueSnackbar, taskSnapshot, currentHours, currentHowImportant],
  );

  if (external_id) {
    return (
      <Paper sx={{ p: '5rem', pt: '1rem' }}>
        <FormControlLabel
          label="task type"
          control={
            <Select
              disabled
              labelId="type"
              id="type"
              value={type}
              defaultValue={type}
              label="state"
            >
              {['standard', 'custom', 'college'].map((el) => (
                <MenuItem key={el} value={el} style={{ textTransform: "capitalize" }}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          }
        />
        <Typography align="center" variant="h5">
          {`Edit ${type} task`}
        </Typography>

        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={3}>
            <TextField
              key="id"
              id="filled-multiline-flexible"
              label="ID"
              value={external_id}
              variant="filled"
              type="number"
              helperText={'Required'}
              disabled
            />
            <TextField
              key="name"
              id="filled-multiline-flexible"
              label="Task Name"
              value={currentName}
              onChange={(event) => setCurrentName(event.target.value)}
              variant="filled"
              helperText={'Required'}
            />

            <TextField
              key="description"
              id="filled-multiline-flexible"
              label="Description"
              value={currentDescription}
              onChange={(event) => setCurrentDescription(event.target.value)}
              variant="filled"
              helperText={'Required'}
            />

            <DateReceived
              error={''}
              date={currentDueDate}
              setDate={setCurrentDueDate}
              label="Due Date"
            />

          <TextField
            key="isImportant"
            id="filled-multiline-flexible"
            label="How important is it?"
            value={currentHowImportant}
            onChange={(event) => setCurrentHowImportant(event.target.value)}
            variant="filled"
            type="number"
            InputProps={{ inputProps: { min: 1, max: 10 } }}
            helperText={'Required'}
          />
          <TextField
            key="hours"
            id="filled-multiline-flexible"
            label="How many hours should it take?"
            value={currentHours}
            onChange={(event) => setCurrentHours(event.target.value)}
            variant="filled"
            InputProps={{ inputProps: { min: 1 } }}
            type="number"
            helperText={'Required'}
          />

            <TextField
              key="causeOfImportance"
              id="filled-multiline-flexible"
              label="Why is this important?"
              value={currentImportance}
              onChange={(event) => setCurrentImportance(event.target.value)}
              variant="filled"
              helperText={'Required'}
            />

            <TextField
              key="notes"
              id="filled-multiline-flexible"
              label="Any notes on how to do this?"
              value={currentNotes}
              onChange={(event) => setCurrentNotes(event.target.value)}
              variant="filled"
              helperText={'Required'}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="center"
            marginTop={5}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                history.goBack();
              }}
            >
              {hasSubmitted ? 'Back' : 'Cancel'}
            </Button>
            <Button type="submit" variant="contained" disabled={loading}>
              {loading ? 'Processing...' : 'Submit'}
            </Button>
          </Stack>
        </Box>
      </Paper>
    );
  }
  else {
    return (
      <Box>
        <Card>
          <CardHeader title="You have to find a task first"> </CardHeader>
        </Card>
      </Box>
    );
  }

};

export default EditTaskForm;
