export const postExamEntry = async (cookies, body, studentId) => {
  let url = `/exams/student-entry`;

  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', studentId);

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  const response = await fetch(url, configObj);

  if (response.status === 201) {
    return await response.json();
  }

  if (response.status === 422) {
    return 'invalid parameters';
  }

  return 'server error';
};

export const patchExamEntry = async (cookies, body, studentId, examId) => {
  let url = `/exams/student-entry/${examId}`;

  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', studentId);

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) {
    return true;
  }

  if (response.status === 422) {
    return 'invalid parameters';
  }

  return 'server error';
};
