import React from 'react';
import { Chip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';

const SelectedStudent = ({ student, handleDelete }) => {
  const { firstName, lastName } = student?.attributes;

  const onDeleteHandler = () => {
    handleDelete();
  };

  return (
    <>
      <Chip
        icon={<FaceIcon />}
        label={`${firstName} ${lastName}`}
        onDelete={onDeleteHandler}
      />
    </>
  );
};

export default SelectedStudent;
