import React, { useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import DateReceived from '../../../student/profile-details/honors/DateRecieved';
import { addNewTask } from '../functions/requests';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';

const NewTaskForm = () => {
  const [cookies] = useCookies('tokens');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('standard');
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [isDateChangeable, setIsDateChangeable] = useState(false);
  const [howImportant, setHowImportant] = useState('');
  const [hours, setHours] = useState('');
  const [importance, setImportance] = useState('');
  const [notes, setNotes] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const submitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      try {
        const transformFormStateToData = {
          externalId: id,
          type,
          name,
          description,
          due_date: dueDate,
          due_date_can_be_modified: isDateChangeable,
          weighted_importance: howImportant,
          approx_total_hours: hours,
          importance,
          notes,
        };

        const filteredData = Object.fromEntries(
          Object.entries(transformFormStateToData).filter(([_, v]) => v !== ''),
        );

        const response = await addNewTask(cookies, filteredData);

        if (response.error) {
          enqueueSnackbar(response.message, { variant: 'error' });
        } else {
          enqueueSnackbar(`New ${type} task added`, { variant: 'success' });
          clearAddTaskForm();
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [
      cookies,
      description,
      dueDate,
      enqueueSnackbar,
      hours,
      howImportant,
      id,
      importance,
      isDateChangeable,
      name,
      notes,
      type,
    ],
  );

  const clearAddTaskForm = () => {
    setId('');
    setName('');
    setDescription('');
    setDueDate(new Date());
    setIsDateChangeable(false);
    setHowImportant('');
    setHours('');
    setImportance('');
    setNotes('');
  };

  return (
    <Paper sx={{ p: '5rem', pt: '1rem' }}>
      <FormControlLabel
        label="task type"
        control={
          <Select
            labelId="type"
            id="type"
            value={type}
            onChange={(event) => setType(event.target.value)}
            defaultValue={type}
            label="state"
          >
            {['standard', 'custom', 'college'].map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        }
      />
      <Typography align="center" variant="h5">
        {`New ${type} task`}
      </Typography>

      <Box
        component="form"
        onSubmit={submitHandler}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={3}>
          <TextField
            key="id"
            id="filled-multiline-flexible"
            label="Set id for new task"
            value={id}
            onChange={(event) => setId(event.target.value)}
            variant="filled"
            type="number"
            helperText={'Required'}
          />
          <TextField
            key="name"
            id="filled-multiline-flexible"
            label="New task name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="filled"
            helperText={'Required'}
          />
          <TextField
            key="description"
            id="filled-multiline-flexible"
            label="Description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            variant="filled"
            helperText={'Required'}
          />
          <DateReceived
            error={''}
            date={dueDate}
            setDate={setDueDate}
            label="Due Date"
          />

          <FormControlLabel
            control={
              <Switch
                checked={isDateChangeable}
                onChange={(event) => setIsDateChangeable(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Is this date changeable?"
          />

          <TextField
            key="isImportant"
            id="filled-multiline-flexible"
            label="How important is it?"
            value={howImportant}
            onChange={(event) =>
              setHowImportant(event.target.value.replace(/####/g, '\n'))
            }
            variant="filled"
            type="number"
            InputProps={{ inputProps: { min: 1, max: 10 } }}
            helperText={'Required'}
          />
          <TextField
            key="hours"
            id="filled-multiline-flexible"
            label="How many hours should it take?"
            value={hours}
            onChange={(event) => setHours(event.target.value)}
            variant="filled"
            InputProps={{ inputProps: { min: 1 } }}
            type="number"
            helperText={'Required'}
          />

          <TextField
            key="causeOfImportance"
            id="filled-multiline-flexible"
            label="Why is this important?"
            value={importance}
            onChange={(event) =>
              setImportance(event.target.value.replace(/####/g, '\n'))
            }
            variant="filled"
            helperText={'Required'}
          />
          <TextField
            key="notes"
            id="filled-multiline-flexible"
            label="Any notes on how to do this?"
            value={notes}
            onChange={(event) =>
              setNotes(event.target.value.replace(/####/g, '\n'))
            }
            variant="filled"
            helperText={'Required'}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={6}
          justifyContent="center"
          marginTop={5}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              clearAddTaskForm();
            }}
          >
            Clear
          </Button>
          <Button type="submit" variant="contained">
            {loading ? 'Processing...' : 'Submit'}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default NewTaskForm;
