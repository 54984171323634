const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

export const editUserRole = async (cookies, id, role) => {
  let url = `/users/role/${id}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', id);

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify({ role }),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) {
    return true;
  }

  if (response.status === 401) {
    return 'Unauthorized'; // potentially invalid student
  }

  if (response.status === 422) {
    return 'Invalid parameters';
  }

  return 'server error';
};

export const getStudentsByUserId = async (cookies, id) => {
  let url = `/users/${id}/list-access`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', id);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unauthorized'; // potentially invalid student
  }

  if (response.status === 422) {
    return 'Invalid parameters';
  }

  return 'server error';
};

export const assignStudentToCounselor = async (
  cookies,
  studentId,
  counselorId,
  entityId,
) => {
  let url = `/users/${counselorId}/access`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ studentId, entityId }),
  };

  const response = await fetch(url, configObj);

  if (response.status === 201) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unauthorized'; // potentially invalid student
  }

  if (response.status === 422) {
    return 'Invalid parameters';
  }

  if (response.status === 409) {
    return 'Duplicate record found';
  }

  return 'server error';
};

export const removeStudentFromCounselor = async (
  cookies,
  studentId,
  counselorId,
  entityId,
) => {
  let url = `/users/${counselorId}/remove-access`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ studentId, entityId }),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) {
    return true;
  }

  if (response.status === 401) {
    return 'Unauthorized'; // potentially invalid student
  }

  if (response.status === 422) {
    return 'Invalid parameters';
  }

  if (response.status === 409) {
    return 'Unable to remove';
  }

  return 'server error';
};
