import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TEXT_FOR_GUIDES } from './constants/text';
import GuidesTabs from './GuidesTabs';

// MUI IMPORTS
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
  helperText: {
    color: '#3F4957',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    letterSpacing: '0.01em',
    marginBottom: '16px',
  },
  manual: {
    color: '#3F4957',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '150%',
    letterSpacing: '0.01em',
    marginBottom: '32px',
  },
}));

const Guides = () => {
  const classes = useStyles();
  return (
    <Grid container xs={12}>
      <div className={classes.helperText}>{TEXT_FOR_GUIDES.helperText}</div>
      <div className={classes.manual} variant="h6">
        {TEXT_FOR_GUIDES.manual}
      </div>
      <GuidesTabs />
    </Grid>
  );
};

export default Guides;
