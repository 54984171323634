import React, { useState } from 'react';
import { Form as MajorForm } from './Form';
import ListContainer from '../ListContainer';

const NullObjectComponent = ({ setAbout }) => {
  const [open, setOpen] = useState(false);

  return (
    <ListContainer
      arr={["No major choices yet.", "Please add your major choices."]}
      title="Major"
      isOpen={open}
      setIsOpen={setOpen}
      handleClick={() => setOpen(true)}
      handleClose={() => setOpen(false)}
      modalContent={
        <MajorForm setOpen={setOpen} majors={null} setAbout={setAbout} />
      }
    />
  );
};

export default NullObjectComponent;
