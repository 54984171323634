import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HeightSpacer from '../components/HeightSpacer';
import requestSessionAPI from '../components/sessionManagementFunctions/requestSession';
import { useCookies } from 'react-cookie';
import debounce from '../utils/debounce';
import ErrorMessage from '../components/ErrorMessage';
import SuccessMessage from '../components/SuccessMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '1280px',
    margin: '0 auto',
    height: '90vh',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fullHeight: {
    height: '100%',
  },
  card: {
    minWidth: 275,
    maxWidth: 350,
    margin: '0 auto',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  padded: {
    padding: theme.spacing(2),
  },
  formClass: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  radio: {
    '&$checked': {
      color: '#4661e0',
    },
  },
  checked: {},
}));

const ScheduleHelp = () => {
  const DEFAULT_LENGTH_VALUE = '30';
  const classes = useStyles();
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  const [notes, setNotes] = useState('');
  // const [date, setDate] = useState(null)
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [day, setDay] = useState('');
  const [time, setTime] = useState('');
  const [month, setMonth] = useState('');
  const [length, setLength] = useState(DEFAULT_LENGTH_VALUE);
  const [timeOptions, setTimeOptions] = useState([]);
  const [formError, setFormError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [formIsDisabled, setFormIsDisabled] = useState(false);
  // TODO: implement loading spinner that this state uses
  const [loading, setLoading] = useState(false);

  // set default values
  useEffect(() => {
    const date = new Date();
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth() + 1;
    setDay(currentDayOfMonth);
    setMonth(currentMonth);

    const hoursArray = [...Array(24).keys()];

    const hourTimeOptions = hoursArray.map((hour) => {
      let displayHour = hour;
      let AM_OR_PM = 'AM';
      if (hour >= 12) {
        AM_OR_PM = 'PM';
      }

      if (hour === 0) {
        displayHour = 12;
      }

      if (hour >= 13) {
        displayHour = hour - 12;
      }

      return {
        value: hour,
        display: `${displayHour}:00 ${AM_OR_PM}`,
      };
    });

    setTimeOptions(hourTimeOptions);
    setTime(0);
  }, []);

  useEffect(() => {
    const getDaysInMonth = (date) => {
      const dt = date;

      // dt.getMonth() will return a month between 0 - 11
      // we add one to get to the last day of the month
      // so that when getDate() is called it will return the last day of the month
      const month = dt.getMonth() + 1;
      const year = dt.getFullYear();

      // this line does the magic (in collab with the lines above)
      const daysInMonth = new Date(year, month, 0).getDate();

      return daysInMonth;
    };

    const getDaysInMonthAsArray = (days) => {
      return [...Array(days).keys()];
    };

    const dayOptions = getDaysInMonthAsArray(getDaysInMonth(new Date()));

    setDaysInMonth(dayOptions);
  }, []);

  const requestSessionHandler = async () => {
    try {
      setFormError(false);
      setLoading(true);
      setFormIsDisabled(true);
      // TODO: Add some date validation
      const dateFromState = new Date();
      dateFromState.setHours(time, 0, 0, 0);
      dateFromState.setMonth(month - 1);
      dateFromState.setDate(day);

      const data = {
        timeRequested: dateFromState,
        notes,
        lengthInMinutes: length,
      };

      const response = await requestSessionAPI(data, cookie);
      const jsonResponse = await response.json();

      if (response.status === 422) {
        setFormError(jsonResponse.body);
        setFormIsDisabled(false);
      }

      if (response.status === 201) {
        setSuccessMessage(
          `Session request sent for ${dateFromState.toLocaleString('en-US')}!`,
        );
      }
    } catch (err) {
      console.log(err);
      setFormIsDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  // debounced set user search handler
  const onNotesChangeHandler = debounce((currentValue) => {
    console.log('updating noted...');
    setNotes(currentValue);
  }, 400);

  return (
    <>
      <div className="main">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>Schedule Help</h1>
          </Grid>
          {formError && <ErrorMessage message={formError} />}
          {successMessage && <SuccessMessage message={successMessage} />}
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          <form
            className={`${classes.padded} ${classes.formClass}`}
            autoComplete="off"
          >
            <p>Proposed Date and Time</p>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="select-month-label">Month</InputLabel>
              <Select
                disabled={formIsDisabled}
                labelId="select-month-label"
                id="select-month"
                value={month}
                onChange={(e) => {
                  console.log('value changed...', e.target.value);
                  setMonth(e.target.value);
                }}
              >
                {[...Array(12).keys()].map((option) => {
                  return (
                    <MenuItem key={option} value={option + 1}>
                      {option + 1}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="select-day-label">Day</InputLabel>
              <Select
                disabled={formIsDisabled}
                labelId="select-day-label"
                id="select-day"
                value={day}
                onChange={(e) => {
                  setDay(e.target.value);
                }}
              >
                {daysInMonth.map((option) => {
                  return (
                    <MenuItem key={option} value={option + 1}>
                      {option + 1}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="select-time-label">Time</InputLabel>
              <Select
                disabled={formIsDisabled}
                labelId="select-time-label"
                id="select-time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              >
                {timeOptions.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.display}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <HeightSpacer />
            <p>Session Length</p>
            <FormControl disabled={formIsDisabled} component="fieldset">
              <RadioGroup
                aria-label="length"
                name="length1"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              >
                <FormControlLabel
                  value={'30'}
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="30 min"
                />
                <FormControlLabel
                  value={'60'}
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="60 min"
                />
              </RadioGroup>
            </FormControl>
            <HeightSpacer />
            <p>Notes</p>
            <TextField
              disabled={formIsDisabled}
              fullWidth
              id="filled-multiline-flexible"
              label="Please tell us as much as possible about how we can help!"
              multiline
              minRows={4}
              maxRows={6}
              onChange={(e) => onNotesChangeHandler(e.target.value)}
              variant="filled"
            />
            <HeightSpacer />
          </form>
          <Button
            disabled={formIsDisabled}
            className={`create_task_btn ${classes.button}`}
            type="submit"
            variant="outlined"
            onClick={() => requestSessionHandler()}
          >
            Schedule
          </Button>
        </Grid>
      </div>
    </>
  );
};

export default ScheduleHelp;
