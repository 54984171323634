import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import AnalyticsService from '../../../../analyticsService';
import { data } from './data.json';
import { state } from './state.json';
import { useCookies } from 'react-cookie';
import { postCollegeSurvey } from '../functions/requests';
import { useSnackbar } from 'notistack';
import CollegeCard from '../CollegeCard';

//MUI IMPORTS
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/Card';
import List from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Link from '@mui/material/Link';
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  DialogTitle,
  DialogContentText,
  useMediaQuery,
} from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useTheme } from '@mui/material/styles';

export const SurveyQuestionsSubmit = ({ data, isDisabled }) => {
  const [open, setOpen] = useState(false);
  const [surveyResults, setSurveyResults] = useState([]);
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();

  const submitButtonHandler = useCallback(async () => {
    for (const [key, value] of Object.entries(data.test.act)) {
      if (!value || value?.length === 0) {
        delete data.test.act[key];
      }
    }

    for (const [key, value] of Object.entries(data.test.sat)) {
      if (!value || value?.length === 0) {
        delete data.test.sat[key];
      }
    }

    if (Object.values(data.test.act).length === 0) {
      delete data.test.act;
    }

    if (Object.values(data.test.sat).length === 0) {
      delete data.test.sat;
    }

    const response = await postCollegeSurvey(cookies, data);

    // we have an error, display it in a snackbar
    if (typeof response === 'string') {
      enqueueSnackbar(response, {
        variant: 'error',
      });
    } else {
      // success, we have survey results from the API
      setSurveyResults(response.body.data);
      localStorage.setItem('surveyFormData', JSON.stringify(data));
    }
  }, [cookies, data, enqueueSnackbar]);

  useEffect(() => {
    if (surveyResults?.length > 0) {
      localStorage.setItem('surveyResults', JSON.stringify(surveyResults));
    }
  }, [surveyResults]);

  // When survey results are set on state, we open up modal displaying the data
  useEffect(() => {
    if (surveyResults.length > 0) {
      setOpen(true);
    }
  }, [surveyResults]);

  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Button
        variant="contained"
        onClick={submitButtonHandler}
        disabled={isDisabled}
      >
        Submit Completed Survey
      </Button>
      <Grid>
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="college-match-results"
          PaperProps={{
            sx: {
              minWidth: '50rem',
              maxHeight: '70vh',
              py: 1,
            },
          }}
        >
          <Box
            sx={{
              zIndex: '10',
              mt: 2,
              mb: 0,
              backgroundColor: '#1A3AD5',
              position: 'relative',
            }}
          >
            <Stack direction="row" justify="space-between" align="center">
              <Typography
                color="white"
                variant="h3"
                component="h5"
                sx={{ mr: 2, transform: 'scale(.7)' }}
              >
                Here are your college match results!
              </Typography>
              <Avatar
                sx={{
                  width: '5rem',
                  height: '5rem',
                  borderRadius: '50%',
                  border: '2px solid',
                  borderColor: '#1A3AD5',
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: '-.75rem',
                  transform: 'scale(1.25)',
                  right: '2rem',
                  zIndex: '10',
                }}
              >
                <SchoolOutlinedIcon
                  sx={{ color: 'black', transform: 'scale(2.5)' }}
                />
              </Avatar>
            </Stack>
          </Box>
          <DialogContent>
            {surveyResults.map((college, index) => {
              const { attributes } = college;
              return (
                <Grid pt={4}>
                  <CollegeCard
                    name={attributes.name}
                    state={attributes.state}
                    status={attributes.status}
                    ceebCode={attributes.ceeb_code}
                    location={attributes.location}
                    handleCollegeAdd={(data) => console.log(data)}
                  />
                </Grid>
              );
            })}
          </DialogContent>
          <DialogActions sx={{ p: 0 }}>
            <Avatar
              sx={{
                mr: 2,
                width: '4rem',
                height: '4rem',
                borderRadius: '50%',
                backgroundColor: '#1A3AD5',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <Typography variant="h1" style={{ cursor: 'pointer' }}>
                X
              </Typography>
            </Avatar>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const marks = [
  {
    Value: 0,
    label: (
      <Typography>
        Select <br /> answer
      </Typography>
    ),
  },
  {
    value: 1,
    label: (
      <Typography>
        I don't <br /> want this
      </Typography>
    ),
  },
  {
    value: 2,
    label: (
      <Typography>
        I'd prefer <br /> not
      </Typography>
    ),
  },
  {
    value: 3,
    label: <Typography>I'm neutral</Typography>,
  },
  {
    value: 4,
    label: (
      <Typography>
        I'd like <br /> this
      </Typography>
    ),
  },
  {
    value: 5,
    label: (
      <Typography>
        I need <br /> this
      </Typography>
    ),
  },
];

function valuetext(value) {
  return `${value}°C`;
}

export const QuestionSlider = ({ initialValue, updateData, questionId }) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateData({ id: questionId, value: newValue });
  };
  return (
    <Slider
      value={value}
      onChange={handleChange}
      sx={{ width: 600 }}
      aria-label={`Question ${questionId} Slider`}
      getAriaValueText={valuetext}
      valueLabelDisplay="auto"
      step={1}
      marks={marks}
      min={0}
      max={5}
    />
  );
};

export const EntryFields = ({
  entry,
  setEntry,
  errors,
  setErrors,
  setBasicInfoIsValid,
}) => {
  const [testTaken, setTestTaken] = useState('sat');

  const handleStateChange = (evt) => {
    setEntry((prevState) => {
      return { ...prevState, state: evt.target.value };
    });
  };

  const validateData = () => {
    // keep track of any errors in a local object
    const newErrorState = {};

    if (
      testTaken === 'sat' &&
      (entry?.satVerbal < 400 || entry?.satVerbal > 1600)
    ) {
      newErrorState['satVerbal'] = 'SAT verbal must be between 400 and 1600';
    }

    if (
      testTaken === 'sat' &&
      (entry?.satMath < 400 || entry?.satMath > 1600)
    ) {
      newErrorState['satMath'] = 'SAT math must be between 400 and 1600';
    }

    if (
      testTaken === 'act' &&
      (entry?.actEnglish < 1 || entry?.actEnglish > 36)
    ) {
      newErrorState['actEnglish'] = 'ACT english must be between 1 and 36';
    }

    if (testTaken === 'act' && (entry?.actMath < 1 || entry?.actMath > 36)) {
      newErrorState['actMath'] = 'ACT math must be between 1 and 36';
    }

    if (
      testTaken === 'act' &&
      (entry?.actReading < 1 || entry?.actReading > 36)
    ) {
      newErrorState['actReading'] = 'ACT reading must be between 1 and 36';
    }

    if (
      testTaken === 'act' &&
      (entry?.actScience < 1 || entry?.actScience > 36)
    ) {
      newErrorState['actScience'] = 'ACT science must be between 1 and 36';
    }

    if (
      entry?.gpa.length === 0 ||
      parseFloat(entry?.gpa) < 1 ||
      parseFloat(entry?.gpa) > 100
    ) {
      newErrorState['gpa'] = 'Gpa must be between 1 and 100';
    }

    if (entry.state?.length === 0) {
      alert('Please enter the state you currently reside in');
    }

    if (
      entry?.gpaScale.length === 0 ||
      parseFloat(entry?.gpaScale) < 1 ||
      parseFloat(entry?.gpaScale) > 100
    ) {
      newErrorState['gpaScale'] = 'Gpa scale must be between 1 and 100';
    }

    // update the state of the errors object
    setErrors(newErrorState);

    // basic info is valid, update parent state to enable next button
    if (Object.keys(newErrorState).length === 0) {
      setBasicInfoIsValid(true);
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setEntry({
      ...entry,
      [evt.target.name]: value,
    });
  };

  const onRadioGroupChange = (evt) => {
    setTestTaken(evt.target.value);
  };

  return (
    <Grid>
      <RadioGroup
        justifyContent="center"
        onChange={onRadioGroupChange}
        value={testTaken}
        row={true}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="sat" control={<Radio />} label="SAT" />
        <FormControlLabel value="act" control={<Radio />} label="ACT" />
      </RadioGroup>
      <form>
        <Grid container item xs={8}>
          {testTaken === 'sat' ? (
            <TextField
              type="text"
              name="satVerbal"
              value={entry.satVerbal}
              onChange={handleChange}
              label="SAT Verbal"
              variant="outlined"
              placeholder="Your SAT Verbal Score"
              helperText={errors?.satVerbal}
              error={errors?.satVerbal?.length}
            />
          ) : null}
          {testTaken === 'sat' ? (
            <TextField
              type="text"
              name="satMath"
              value={entry.satMath}
              onChange={handleChange}
              label="SAT Math"
              variant="outlined"
              placeholder="Your SAT Math Score"
              helperText={errors?.satMath}
              error={errors?.satMath?.length}
            />
          ) : null}{' '}
        </Grid>
        <Grid container item xs={6}>
          {testTaken === 'act' ? (
            <TextField
              type="text"
              name="actEnglish"
              value={entry.actEnglish}
              onChange={handleChange}
              label="ACT English"
              variant="outlined"
              placeholder="Your ACT English Score"
              helperText={errors?.actEnglish}
              error={errors?.actEnglish?.length}
            />
          ) : null}
          {testTaken === 'act' ? (
            <TextField
              type="text"
              name="actMath"
              value={entry.actMath}
              onChange={handleChange}
              label="ACT Math"
              variant="outlined"
              placeholder="Your ACT Math Score"
              helperText={errors?.actMath}
              error={errors?.actMath?.length}
            />
          ) : null}
          {testTaken === 'act' ? (
            <TextField
              type="text"
              name="actReading"
              value={entry.actReading}
              onChange={handleChange}
              label="ACT Reading"
              variant="outlined"
              placeholder="Your ACT Reading Score"
              helperText={errors?.actReading}
              error={errors?.actReading?.length}
            />
          ) : null}
          {testTaken === 'act' ? (
            <TextField
              type="text"
              name="actScience"
              value={entry.actScience}
              onChange={handleChange}
              label="ACT Science"
              variant="outlined"
              placeholder="Your ACT Science Score"
              helperText={errors?.actScience}
              error={errors?.actScience?.length}
            />
          ) : null}
        </Grid>
        <Grid container item xs={6}>
          <TextField
            required
            type="text"
            name="gpa"
            value={entry.gpa}
            onChange={handleChange}
            label="GPA"
            variant="outlined"
            placeholder="Your GPA"
            helperText={errors?.gpa}
            error={errors?.gpa?.length}
          />
          <TextField
            type="text"
            name="gpaScale"
            value={entry.gpaScale}
            onChange={handleChange}
            label="GPA Scale"
            variant="outlined"
            placeholder="Your GPA Scale"
            helperText={errors?.gpaScale}
            error={errors?.gpaScale?.length}
          />
          <TextField
            type="text"
            name="zipcode"
            value={entry.zipcode}
            onChange={handleChange}
            label="Zipcode"
            variant="outlined"
            placeholder="Zipcode"
          />
          <FormControl>
            <InputLabel id="stateSelect">State</InputLabel>
            <select value={entry?.state} onChange={handleStateChange}>
              {state.map((item) => (
                <option key={item.abbreviation} value={item.abbreviation}>
                  {item.abbreviation}
                </option>
              ))}
            </select>
          </FormControl>
        </Grid>
      </form>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        paddingTop={3}
      >
        <Button variant="contained" onClick={validateData}>
          Submit
        </Button>
      </Stack>
    </Grid>
  );
};

const Bold = ({ children, color }) => {
  let c = color === 'main' ? 'primary.main' : 'black';
  return (
    <Box sx={{ color: c, display: 'inline', fontWeight: 'bold' }} inline>
      {children}
    </Box>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DirectionsModal = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const handleDirectionsClickOpen = () => {
    setOpen(true);
  };

  const handleDirectionsClose = () => {
    setOpen(false);
  };
  return (
    <Stack sx={{ mt: 2 }} direction="row">
      <Typography
        sx={{ fontSize: isScreenSmall ? '16px' : '24px', textAlign: 'left' }}
      >
        <strong>Detailed Instructions</strong>
      </Typography>
      <ArrowCircleRightOutlinedIcon
        onClick={handleDirectionsClickOpen}
        sx={{
          color: '#455FE8',
          width: 35,
          height: 35,
          ml: 5,
          cursor: 'pointer',
        }}
      />
      <Dialog
        open={open}
        onClose={handleDirectionsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: isScreenSmall ? '16px' : '24px',
            color: '#FFFFFF',
            backgroundColor: '#455FE8',
            textAlign: 'right',
          }}
        >
          <strong>Detailed Instructions</strong>
          <Button
            onClick={handleDirectionsClose}
            sx={{
              color: '#FFFFFF',
              width: '22.2',
              height: '52',
              textAlign: 'right',
            }}
          >
            X
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: isScreenSmall ? '16px' : '20px', mt: 2 }}
          >
            <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
              <Typography textAlign="left">
                For users taking the survey through free accounts, results will
                be limited to two <strong color="main">"VIZE </strong>
                <strong>Top Match" </strong>
                schools and two other recommendations. Any user purchasing the{' '}
                <strong color="main">"VIZE </strong>
                <strong>Tasking System"</strong>, our electronic college
                advising service, will be able to unlock a full list of
                suggested schools including more{' '}
                <strong color="main">"VIZE </strong>
                <strong>Top Match"</strong> colleges.
              </Typography>
            </Grid>
            <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
              <Typography textAlign="left">
                The survey will take about 5-10 minutes to complete. You won't
                be able to save your answers, so please answer as many questions
                as possible before submitting to receive the most accurate
                results possible. You'll always be able to take the survey again
                if your preferences change.
              </Typography>
            </Grid>
            <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
              <Typography textAlign="left">
                For most questions, you'll answer on a scale of 1-5. Enter a 1
                if you do NOT want something in your college experience, a 3 if
                it doesn't matter to you, or a 5 if you REALLY want what the
                question is asking about. 2s and 4s are for slight-to-medium
                preferences in either direction.
              </Typography>
            </Grid>
            <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
              <Typography textAlign="left">
                When you're finished, please use the "Submit" Tab below to
                receive your results. Thanks, and good luck!
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Stack>
  );
};

export default function CollegeSurvey() {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Student Match Survey' });
  }, []);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  // state for keeping track of all basic info
  const [entry, setEntry] = useState({
    satVerbal: '',
    satMath: '',
    actEnglish: '',
    actMath: '',
    actReading: '',
    actScience: '',
    gpa: '',
    zipcode: '',
    gpaScale: '',
    state: 'AL',
  });

  const [errors, setErrors] = useState({});
  const [basicInfoIsValid, setBasicInfoIsValid] = useState(false);

  // state that keeps track of all slider/question/answers
  const [dataMap, setDataMap] = useState({});

  const updateData = useCallback((evt) => {
    setDataMap((prevState) => {
      const newState = { ...prevState };

      // if the new value is 0, we delete the property on the state object so the question needs to be answered
      if (evt.value === 0) {
        delete newState[evt.id];
        return newState;
      }

      newState[evt.id] = evt.value;
      return newState;
    });
  }, []);

  const currentQuestionLength = Object.keys(dataMap).length;

  const handleNext = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ['Basic', 'Geographical', 'Professional', 'Academic', 'Social'];

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mb: 2, fontSize: 48 }}>
        <strong>College Match</strong>
      </Typography>
      <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
        <Typography sx={{ textAlign: 'left', fontSize: 24 }}>
          In order to suggest colleges that meet your unique needs, we'll need
          to know a little more about what you're looking for in a college{' '}
          <br />
          experience. To do that, we’ve designed a short survey (5-10 min).
        </Typography>
      </Grid>
      <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
        <Typography sx={{ textAlign: 'left' }}>
          <DirectionsModal />
        </Typography>
      </Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid item xs={12}>
        <Stack direction="row">
          <Box
            sx={{ fontWeight: 'bold', fontSize: 18, m: 1 }}
          >{`Progress: ${currentQuestionLength}/33 Questions Complete`}</Box>
        </Stack>
      </Grid>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          overflow="auto"
          height="500px"
        >
          {activeStep === 0 && (
            <>
              <Grid
                container
                item
                xs={12}
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <Typography sx={{ textAlign: 'left', fontSize: 24 }}>
                  To get started, we'll need some basic information to make sure
                  that our suggestions match your academic profile. <br />
                  Please enter your current best scores for the SAT and ACT,
                  GPA, your home state, and zip code below:
                </Typography>
              </Grid>

              <EntryFields
                entry={entry}
                setEntry={setEntry}
                errors={errors}
                setErrors={setErrors}
                setBasicInfoIsValid={setBasicInfoIsValid}
              />
            </>
          )}
          {activeStep === 1 && (
            <Grid
              overflow="auto"
              container
              item
              xs={12}
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              <List aria-label="list" centered>
                {data.Geographical.map((item, i) => {
                  return (
                    <Typography padding={2} key={i} value={item}>
                      {item.questionText}
                      <Typography padding={1}>
                        <QuestionSlider
                          initialValue={dataMap[item.questionId] || 0}
                          updateData={updateData}
                          questionId={item.questionId}
                        />
                      </Typography>
                    </Typography>
                  );
                })}
              </List>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid
              overflow="auto"
              container
              item
              xs={12}
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              <List aria-label="list" centered>
                {data.Professional.map((item, i) => {
                  return (
                    <Typography padding={2} key={i} value={item}>
                      {item.questionText}
                      <Typography padding={1}>
                        <QuestionSlider
                          initialValue={dataMap[item.questionId] || 0}
                          updateData={updateData}
                          questionId={item.questionId}
                        />
                      </Typography>
                    </Typography>
                  );
                })}
              </List>
            </Grid>
          )}
          {activeStep === 3 && (
            <Grid
              overflow="auto"
              container
              item
              xs={12}
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              <List aria-label="list" centered>
                {data.Academic.map((item, i) => {
                  return (
                    <Typography padding={2} key={i} value={item}>
                      {item.questionText}
                      <Typography padding={1}>
                        <QuestionSlider
                          initialValue={dataMap[item.questionId] || 0}
                          updateData={updateData}
                          questionId={item.questionId}
                        />
                      </Typography>
                    </Typography>
                  );
                })}
              </List>
            </Grid>
          )}
          {activeStep === 4 && (
            <Grid
              overflow="auto"
              container
              item
              xs={12}
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              <List aria-label="list" centered>
                {data.Social.map((item, i) => {
                  return (
                    <Typography padding={2} key={i} value={item}>
                      {item.questionText}
                      <Typography padding={1}>
                        <QuestionSlider
                          initialValue={dataMap[item.questionId] || 0}
                          updateData={updateData}
                          questionId={item.questionId}
                        />
                      </Typography>
                    </Typography>
                  );
                })}
              </List>
            </Grid>
          )}
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        paddingTop={3}
      >
        <Button
          onClick={handleBack}
          variant="contained"
          sx={activeStep === 0 && { display: 'none' }}
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          variant="contained"
          sx={activeStep === 4 && { display: 'none' }}
        >
          Next
        </Button>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        paddingTop={3}
      >
        <SurveyQuestionsSubmit
          isDisabled={currentQuestionLength < 33 || !basicInfoIsValid}
          data={{
            basicInfo: {
              gpa: entry?.gpa,
              zipcode: entry?.zipcode,
              gpaScale: entry?.gpaScale,
              state: entry?.state,
            },
            answers: { ...dataMap }, // destructure the dataMap so it becomes an object set as the answers prop
            test: {
              sat: {
                satVerbal: entry?.satVerbal,
                satMath: entry?.satMath,
              },
              act: {
                actEnglish: entry?.actEnglish,
                actMath: entry?.actMath,
                actReading: entry?.actReading,
                actScience: entry?.actScience,
              },
            },
          }}
        />
      </Stack>
    </Box>
  );
}
