import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import ModalDialog from '../../ModalDialog';
import { readableDate } from '../../functions/utils';
import { deleteHonor } from './functions/requests';
import { Edit as EditHonor } from './Edit';

// MUI IMPORTS
import {
  Stack,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const Footer = ({
  setHonors,
  id,
  type,
  title,
  description,
  dateReceived,
}) => {
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    const { error, body } = await deleteHonor(id, cookies);
    if (error) {
      enqueueSnackbar(body, {
        variant: 'error',
      });
    } else {
      enqueueSnackbar('Honor Deleted', {
        variant: 'warning',
      });
      setHonors((prev) => prev.filter((honor) => honor.id !== id));
      setAnchorEl(false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#455FE8',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        color: 'white',
        p: 1,
      }}
    >
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1" component="p">
          <Box component="span" fontWeight="fontWeightBold" display="inline">
            Received :
          </Box>{' '}
          {readableDate(dateReceived)}
        </Typography>
        <IconButton
          onClick={handleClick}
          aria-label="expand more options"
          sx={{ color: 'white' }}
        >
          <MoreHorizIcon sx={{ transform: 'scale(2)' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleOpenModal}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Stack>
      <ModalDialog
        isOpen={openModal}
        handleClose={handleCloseModal}
        content={
          <EditHonor
            setOpen={setOpenModal}
            setHonors={setHonors}
            id={id}
            type={type}
            title={title}
            description={description}
            dateReceived={dateReceived}
          />
        }
        title="Edit Honor"
      />
    </Box>
  );
};
