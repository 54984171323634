import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserMessages } from 'components/student/functions/requests';
import { patchUpdateHasSeenMessages } from 'components/student/functions/requests';
import { postUserMessages } from 'components/student/functions/requests';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useUserRole, useAuthUser } from 'store/UserContext';

// MUI IMPORTS
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Fab,
  TextField,
  FormControl,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import MessageIcon from '@mui/icons-material/Message';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@mui/material/InputAdornment';

const Bold = ({ children }) => {
  return <Box sx={{ display: 'inline', fontWeight: 'bold' }}>{children}</Box>;
};

const StyledBadgeCount = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 5,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatWindow: {
    width: '100%',
    height: '100vh',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  messageArea: {
    height: '70vh',
    width: '100%',
    overflowY: 'auto',
  },

  messageButton: {
    borderRadius: 100,
  },
  chatBubbleContainerSentTo: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatBubbleSentTo: {
    backgroundColor: '#B0E0E6',
    justifyContent: 'flex-end',
    borderRadius: '10px',
    margin: '5px',
    padding: '10px',
    display: 'inline-block',
    float: 'right',
  },
  sentToTimestamp: {
    fontSize: '0.8rem',
    color: '#999',
    justifyContent: 'flex-end',
    float: 'right',
  },
  chatBubbleContainerSentBy: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatBubbleSentBy: {
    backgroundColor: '#DCDCDC',
    justifyContent: 'flex-start',
    borderRadius: '10px',
    margin: '5px',
    padding: '10px',
    display: 'inline-block',
    align: 'right',
  },
  sentByTimestamp: {
    fontSize: '0.8rem',
    color: '#999',
    justifyContent: 'flex-start',
    align: 'right',
  },
  searchBar: {
    [`& fieldset`]: {
      borderRadius: 100,
      padding: '8px',
    },
  },
  messageBar: {
    [`& fieldset`]: {
      borderRadius: 100,
    },
  },
  footer: {
    position: 'fixed',
    bottom: -60,
    height: 50,
    textAlign: 'center',
  },
  menuScrollBar: {
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#aaa',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#E0E0E0',
      borderRadius: 3,
    },
    overflowX: 'hidden',
    p: 1,
    maxHeight: '300px',
    overflow: 'auto',
  },
}));

const Timestamp = ({ sent_at }) => {
  const startTime = new Date(sent_at);
  const formattedTimestamp =
    new Date(
      startTime.getTime() - startTime.getTimezoneOffset() * 60000,
    ).toLocaleDateString([], {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    new Date(
      startTime.getTime() - startTime.getTimezoneOffset() * 60000,
    ).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

  return <div>{formattedTimestamp}</div>;
};

export const MessagesIconNav = ({ unseen }) => {
  const userRole = useUserRole();
  const history = useHistory();

  const handleNavOnClick = () => {
    if (userRole === 'counselor') {
      history.push('/counselor/messages');
    } else if (userRole === 'exam_admin') {
      history.push('/exam-admin/messages');
    } else if (userRole === 'account_admin') {
      history.push('/account-admin/messages');
    } else {
      history.push('/messages');
    }
  };

  return (
    <Tooltip title="Messages">
      <IconButton onClick={handleNavOnClick} size="small" sx={{ ml: 2 }}>
        <StyledBadgeCount color="error">
          <MessageIcon sx={{ color: '#425ce8' }} />
        </StyledBadgeCount>
      </IconButton>
    </Tooltip>
  );
};

const MessagingUiListItem = ({
  variant = 'sentTo',
  userInitials,
  content,
  sent_at,
  ref,
}) => {
  const classes = useStyles();
  return (
    <ListItem ref={ref}>
      <Grid container>
        <Grid item xs={12}>
          <ListItem>
            {variant === 'sentTo' ? (
              <></>
            ) : (
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: '#7f3939' }}>
                  {userInitials}
                </Avatar>
              </ListItemAvatar>
            )}

            <ListItemText
              primary={
                <Grid
                  container
                  xs={12}
                  className={classes.chatBubbleContainerSentTo}
                >
                  <Grid item>
                    <ListItemText
                      className={
                        variant === 'sentTo'
                          ? classes.chatBubbleSentTo
                          : classes.chatBubbleSentBy
                      }
                    >
                      {' '}
                      {content}
                    </ListItemText>
                  </Grid>
                </Grid>
              }
              secondary={
                <Grid
                  container
                  xs={12}
                  className={classes.chatBubbleContainerSentTo}
                >
                  <Grid item>
                    <ListItemText
                      className={
                        variant === 'sentTo'
                          ? classes.sentToTimestamp
                          : classes.sentByTimestamp
                      }
                    >
                      <Timestamp sent_at={sent_at} />
                    </ListItemText>
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const MessagingUiList = ({ messages, loggedInUserId }) => {
  const refs = messages.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    const id = messages[messages.length - 1];
    if (id && refs[id?.current]) {
      refs[id]?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [refs, messages]);

  const classes = useStyles();
  return (
    <List
      sx={{
        '&::-webkit-scrollbar': {
          width: 10,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#aaa',
          borderRadius: 3,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#E0E0E0',
          borderRadius: 3,
        },
        overflowX: 'hidden',
        p: 1,
        overflow: 'auto',
      }}
      className={classes.messageArea}
    >
      {messages.map((message) => {
        return (
          <MessagingUiListItem
            variant={loggedInUserId === message.sent_by ? 'sentTo' : 'sentBy'}
            key={message.id}
            content={message.content}
            userInitials={'N/A'}
            sent_at={message.sent_at}
            ref={refs[message.id]}
          />
        );
      })}
    </List>
  );
};

const MessageSubmit = ({ handleMessageSubmit, isSending }) => {
  const [text, setText] = useState('');
  const classes = useStyles();

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async () => {
    const result = await handleMessageSubmit(text);

    if (result) {
      setText('');
    }
  };

  return (
    <FormControl className={classes.messageBar} fullWidth>
      <TextField
        label="Chat Message"
        value={text}
        onChange={handleChange}
        disabled={isSending}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Fab aria-label="send" size="small" color="primary">
                <SendIcon onClick={handleSubmit} />
              </Fab>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export const MessagingComponent = ({ selectedContact }) => {
  const [userMessages, setUserMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [unseen, setUnseen] = useState(0);
  const user = useAuthUser();
  const { enqueueSnackbar } = useSnackbar();
  const [cookie] = useCookies(['token']);

  const handleMessageSubmit = useCallback(
    async (text) => {
      setIsSending(true);
      try {
        const response = await postUserMessages(cookie, {
          content: text,
          sentTo: selectedContact.id,
        });
        const newMessage = {
          id: response.body.id,
          content: text,
          sent_at: response.body.attributes.sent_at,
          seen_at: response.body.attributes.seen_at,
          sent_by: user.id,
        };

        setUserMessages((prevState) => {
          return [...prevState, newMessage];
        });
        return true;
      } catch (error) {
        enqueueSnackbar('Error sending message: ' + error.message, {
          variant: 'error',
        });
        return false;
      } finally {
        setIsSending(false);
      }
    },
    [cookie, enqueueSnackbar, selectedContact.id, user.id],
  );

  useEffect(() => {
    const getAllUserMessages = async () => {
      const response = await getUserMessages(cookie, selectedContact.id);

      if (response?.data) {
        const messages = response.data.map((message) => {
          const { content, sent_at, seen_at } = message.attributes;
          const { id } = message;
          return {
            id,
            content,
            sent_at,
            seen_at,
            sent_by: message.relationships.user.id,
          };
        });

        setUserMessages(messages);
      }
    };
    if (selectedContact) {
      getAllUserMessages();
    }
  }, [cookie, selectedContact]);

  useEffect(() => {
    const calculatedHasSeen = userMessages.reduce((acc, curr) => {
      if (curr.seen_at === null) {
        acc++;
      }
      return acc;
    }, 0);
    setUnseen(calculatedHasSeen);
  }, [userMessages]);

  const handleHasSeen = async (id) => {
    try {
      const response = await patchUpdateHasSeenMessages(id, cookie);
      if (response === true) {
        setUserMessages((prevState) => {
          const foundIndex = prevState.findIndex((message) => {
            return message.id === id;
          });
          if (foundIndex >= 0) {
            const newState = [...prevState];
            newState[foundIndex].seen_at = new Date().toISOString();
            return newState;
          }
          return prevState;
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Grid sx={{ borderRadius: '15px' }}>
      <Typography
        sx={{
          p: 1,
          backgroundColor: '#DCDCDC',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          borderRadius: '15px',
        }}
        variant="h4"
        component="h2"
        align={'center'}
      >
        <Bold>{selectedContact.email}</Bold>
      </Typography>

      <Divider />

      <MessagingUiList messages={userMessages} loggedInUserId={user.id} />

      <Divider />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} sx={{ p: 2 }}>
          <MessageSubmit
            handleMessageSubmit={handleMessageSubmit}
            isSending={isSending}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
