import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress, Button } from '@material-ui/core';
import SuccessMessage from './SuccessMessage';
import ErrorMessage from './ErrorMessage';
import { useCookies } from 'react-cookie';
import listSessionsByUser from './sessionManagementFunctions/listSessionsByUserId';
import { formatDateToLocal } from '../utils/dateHelpers';
import ContactLineInfo from './ContactLineInfo';
import { useHistory } from 'react-router';

const RequestedSessionCard = ({
  notes,
  date,
  length,
  counselor = null,
  joinSessionHandler = null,
}) => {
  return (
    <div>
      <p>
        {counselor?.id ? 'Booked Time: ' : 'Proposed Time: '}
        {formatDateToLocal(date)}, {`${length} mins`}
      </p>
      <p>Notes: {notes}</p>
      {counselor?.id && (
        <ContactLineInfo
          prefix={'Counselor'}
          firstName={counselor?.attributes?.firstName}
          lastName={counselor?.attributes?.lastName}
          email={counselor?.attributes?.email}
        />
      )}
      <Grid container spacing={1}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              // TODO: Add reschedule or edit
              console.log('reschedule');
            }}
          >
            Reschedule
          </Button>
        </Grid>
        {joinSessionHandler && (
          <Grid item>
            <Button
              disabled={counselor?.id?.length ? false : true}
              variant="outlined"
              onClick={() => joinSessionHandler()}
            >
              Join
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const MyStudentSessions = () => {
  const [errorMessage] = useState(false);
  const [successMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  const [sessionRequests, setSessionRequests] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const getMySessions = async () => {
      try {
        setLoading(true);
        const response = await listSessionsByUser(cookie);
        const jsonResponse = await response.json();

        // TODO: add error handling
        if (response.status === 200) {
          setSessionRequests(jsonResponse?.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getMySessions();
  }, [cookie]);

  const joinSessionHandler = (sessionId) => {
    history.push(`/lobby?roomId=${sessionId}`);
  };

  return (
    <>
      <div className="main">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>My Sessions</h1>
          </Grid>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {successMessage && <SuccessMessage message={successMessage} />}
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {sessionRequests?.length === 0 && <p>No session requests found.</p>}
          {sessionRequests.map((request) => {
            const {
              id,
              attributes: { notes, timeRequested, length },
              relationships: { counselor },
            } = request;
            return (
              <Grid key={id} item xs={12}>
                <RequestedSessionCard
                  notes={notes}
                  date={timeRequested}
                  counselor={counselor}
                  length={length}
                  joinSessionHandler={() => joinSessionHandler(id)}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default MyStudentSessions;
