import { capitalize } from './capitalize';

export const camelCaseFormatter = (string) => {
  if (typeof string === 'string') {
    const addedSpaces = string.replace(/([0-9A-Z])/g, ' $1');
    const capitalized = addedSpaces
      ?.split(' ')
      ?.map((word) => {
        return capitalize(word);
      })
      .join(' ');
    return capitalized;
  }
  return string;
};
