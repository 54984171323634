const listSessionRequests = (cookie, hasCounselor = false) => {
  let url = `/sessions/requests`;
  if (typeof hasCounselor === 'boolean') {
    url += `?hasCounselor=${hasCounselor}`;
  }

  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw new Error(
        `Unable to process API request to: ${url} REASON: ${e.message}`,
      );
    });
};

export default listSessionRequests;
