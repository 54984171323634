import React from 'react';

const ContactLineInfo = ({ prefix, firstName, lastName, email }) => {
  return (
    <p>
      {prefix}: {firstName} {lastName}, {email}
    </p>
  );
};

export default ContactLineInfo;
