import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Form as MajorForm } from './Form';
import { ResourceHOC } from './HOC';
import ListContainer from '../ListContainer';

const MajorList = ({ about, isAboutLoading, aboutError, setAbout }) => {
  const empty =
    isEmpty(about) || isEmpty(about?.orderedMajorChoices) || about === null;
  const [open, setOpen] = useState(false);

  return (
    <ResourceHOC
      isLoading={isAboutLoading}
      serverError={aboutError}
      isEmpty={empty}
      setOpen={setOpen}
      setAbout={setAbout}
    >
      {about && (
        <ListContainer
          arr={Object.values(about.orderedMajorChoices)}
          title="Major"
          isOpen={open}
          setIsOpen={setOpen}
          handleClick={() => setOpen(true)}
          handleClose={() => setOpen(false)}
          modalContent={
            <MajorForm
              setOpen={setOpen}
              majors={about.orderedMajorChoices}
              setAbout={setAbout}
            />
          }
        />
      )}
    </ResourceHOC>
  );
};

export default MajorList;
