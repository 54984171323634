const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
const myHeaders = (cookies) => {
  const headers = new Headers();
  headers.append('token', cookies.tokens.token);
  headers.append('Content-Type', 'application/json');
  return headers;
};

export const updateComplete = async (cookies, task) => {
  let url = `/v2/tasks/student/${task.id}`;

  if (NODE_ENV !== 'development') {
    url = `${REACT_APP_API_DOMAIN}${url}`;
  }

  const configObj = {
    method: 'PATCH',
    headers: myHeaders(cookies),
    body: JSON.stringify({
      completed_date: task.completed ? null : new Date(),
    }),
  };

  const { status } = await fetch(url, configObj);

  if (status === 204) {
    return true;
  }
  return false;
};

export const getAllTasks = async (cookies) => {
  let url = `/v2/tasks/all`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'GET',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);

  return await response.json();
};

export const syncAllTasks = async (cookies, type = 'standard') => {
  let url = `/v2/tasks/sync`;

  if (type === 'college') {
    url = `/v2/tasks/sync-college-tasks`;
  }

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'POST',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) {
    const {
      body: { message, count, syncedAt },
    } = await response.json();
    return {
      message,
      count,
      syncedAt,
    };
  }

  if (!response.ok) {
    const { error, message } = await response.json();
    return { error, message };
  }
};

export const getSortedTasks = async (cookies) => {
  let url = `/v2/tasks/sorted`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'GET',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);

  return await response.json();
};
