import React, { useEffect, useState, useContext } from 'react';
import { Typography, Box, Grid, CircularProgress } from '@mui/material';
import { refreshSession } from '../components/functions/refreshSession';
import { UserContext } from '../store/UserContext';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';

const sleep = (duration = 3000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

const RefreshSession = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['tokens']);
  const { setUserFromToken } = useContext(UserContext);

  useEffect(() => {
    const refresh = async () => {
      try {
        await sleep(5000);
        const { token, refreshToken } = await refreshSession(cookies);
        setCookie('tokens', { token, refreshToken });
        await setUserFromToken(token);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        history.push('/dashboard');
      }
    };

    refresh();
  }, [setCookie, setUserFromToken, history, cookies]);

  return (
    <Grid sx={{ maxWidth: '800px', margin: '0 auto', padding: 2 }}>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          marginTop: 2,
          padding: 5,
          borderRadius: 1,
          boxShadow: 1,
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="h4">Thank you for your purchase!</Typography>
        <Typography paragraph>
          Everyone at VIZE is looking forward to helping you with each of the
          steps along your path to college via our Tasking System!
          <br />
          Please wait while we redirect you back to the app.
        </Typography>
        {loading && <CircularProgress />}
      </Box>
    </Grid>
  );
};

export default RefreshSession;
