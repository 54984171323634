import { createTheme } from '@mui/material/styles';
import '@fontsource/dm-sans';

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: 'rgba(69, 95, 232, 1)',
    },
    primaryLight: {
      main: '#42a5f5',
    },
    primaryLightV2: {
      main: '#EDF1FF',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: 'rgba(31, 31, 31, 1)',
      secondary: 'rgba(63, 73, 87, 1)',
    },
    background: {
      default: '#FBFBFD',
    },
  },
  typography: {
    fontFamily: 'DM Sans',
    fontWeightLight: 400,
    h1: {
      fontSize: '48px',
      fontWeight: 'bold',
    },
    body2: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '2.3',
    },
    subtitle1: {
      fontWeight: '400',
      fontSize: '16px',
      color: '#3F4957',
      opacity: '0.5',
    },
    h6: {
      '& :visited': {
        color: 'rgba(69, 95, 232, 1)',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: 0,
    },
  },
});
