import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { addNewCollege, editCollege } from './functions/requests';
import StateSelect from './StateSelect';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
  Typography,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import TextField from '@mui/material/TextField';
//
const CollegeForm = ({ isEditing, collegeData, resetCollegeEditingState }) => {
  const [cookies] = useCookies('tokens');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');

  const [ceeb, setCeeb] = useState('');
  const [name, setName] = useState('');
  const [about, setAbout] = useState('');
  const [usState, setUsState] = useState('AL');
  const [location, setLocation] = useState('');
  const [geography, setGeography] = useState('');
  const [division, setDivision] = useState('');
  const [urbanCenter1, setUrbanCenter1] = useState('');
  const [urbanCenter2, setUrbanCenter2] = useState('');
  const [airport, setAirport] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [schoolType, setSchoolType] = useState('');
  const [facStu, setFacStu] = useState('');
  const [smallClass, setSmallClass] = useState('');
  const [status, setStatus] = useState('review');

  useEffect(() => {
    // when editing is triggered setting college data to the form cause add and edit college form is the same component
    const setDataToEdit = () => {
      setCeeb(collegeData?.ceebCode ?? '');
      setName(collegeData?.name ?? '');
      setAbout(collegeData?.about ?? '');
      setUsState(collegeData?.state?.trim() ?? 'AL');
      setLocation(collegeData?.location ?? '');
      setGeography(collegeData?.geography ?? '');
      setDivision(collegeData?.division ?? '');
      setUrbanCenter1(collegeData?.urbanCenter1 ?? '');
      setUrbanCenter2(collegeData?.urbanCenter2 ?? '');
      setAirport(collegeData?.airport ?? '');
      setIsPublic(collegeData?.isPublic ?? true);
      setSchoolType(collegeData?.schoolType ?? '');
      setFacStu(collegeData?.facStu ?? '');
      setSmallClass(collegeData?.smallClass ?? '');
      setStatus(collegeData?.status ?? 'review');
    };
    setDataToEdit();
  }, [collegeData]);

  const submitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      setMessage('');
      setError('');
      try {
        const transformFormStateToData = {
          ceeb_code: ceeb,
          name,
          about,
          state: usState,
          location,
          geography,
          division,
          urban_center_1: urbanCenter1,
          urban_center_2: urbanCenter2,
          airport,
          is_public: isPublic,
          school_type: schoolType,
          fac_stu: facStu,
          small_class: smallClass,
          status,
        };
        const filteredData = Object.fromEntries(
          Object.entries(transformFormStateToData).filter(([_, v]) => v !== ''),
        );
        const response = isEditing
          ? await editCollege(cookies, filteredData)
          : await addNewCollege(cookies, filteredData);
        if (!response.error) {
          setMessage(isEditing ? 'College Data Updated' : 'College Added');
          resetCollegeEditingState();
        } else {
          setError(response.message);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [
      ceeb,
      name,
      about,
      usState,
      location,
      geography,
      division,
      urbanCenter1,
      urbanCenter2,
      airport,
      isPublic,
      schoolType,
      facStu,
      smallClass,
      status,
      isEditing,
      cookies,
      resetCollegeEditingState,
    ],
  );

  return (
    <Paper sx={{ p: '1rem' }}>
      <Typography align="center" variant="h5">
        {isEditing ? 'Edit College Data' : 'Add New College'}
      </Typography>
      {/* //TODO: Change the way errors and messages are broadcast  */}
      {error && <p>{error}</p>}
      {message && <p>{message}</p>}
      <Box
        component="form"
        onSubmit={submitHandler}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={3}>
          {!isEditing && (
            <TextField
              key="ceeb_code"
              id="ceeb_code"
              label="CEEB code"
              value={ceeb}
              onChange={(event) => setCeeb(event.target.value)}
              variant="filled"
              helperText={isEditing ? '' : 'Required'}
            />
          )}

          <TextField
            key="name"
            id="name"
            label="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Required'}
          />
          <TextField
            key="about"
            id="about"
            label="About"
            value={about}
            onChange={(event) => setAbout(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Required'}
          />
          <StateSelect
            value={usState}
            handleChange={(event) => setUsState(event.target.value)}
          />
          <TextField
            key="location"
            id="location"
            label="Location"
            value={location}
            onChange={(event) => setLocation(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Required'}
          />
          <TextField
            key="division"
            id="division"
            label="Division"
            value={division}
            onChange={(event) => setDivision(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Required'}
          />
          <InputLabel id="state">Public</InputLabel>
          <Select
            labelId="state"
            id="state"
            value={isPublic}
            onChange={(event) => setIsPublic(event.target.value)}
            defaultValue={isPublic}
            label="state"
          >
            {[true, false].map((el) => (
              <MenuItem key={el} value={el}>
                {el.toString()}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{isEditing ? '' : 'Required'}</FormHelperText>
          <TextField
            key="type"
            id="type"
            label="School Type"
            value={schoolType}
            onChange={(event) => setSchoolType(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Required'}
          />
          <TextField
            key="fac-stu"
            id="fac-stu"
            label="Fac/Stu"
            value={facStu}
            onChange={(event) => setFacStu(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Required'}
          />

          <TextField
            key="geography"
            id="geography"
            label="Geography"
            value={geography}
            onChange={(event) => setGeography(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Not Required'}
          />
          <TextField
            key="urban-center-1"
            id="urban-center-1"
            label="Urban Center 1"
            value={urbanCenter1}
            onChange={(event) => setUrbanCenter1(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Not Required'}
          />
          <TextField
            key="urban-center-2"
            id="urban-center-2"
            label="Urban Center 2"
            value={urbanCenter2}
            onChange={(event) => setUrbanCenter2(event.target.value)}
            variant="filled"
            helperText={isEditing ? '' : 'Not Required'}
          />
          <>
            <InputLabel id="status">State</InputLabel>
            <Select
              labelId="status"
              id="status"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              defaultValue={status}
              label="state"
            >
              {['review', 'published'].map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </>

          <Button sx={{ mx: 5, my: 2 }} type="submit" variant="contained">
            {loading ? 'Processing...' : 'Submit'}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default CollegeForm;
