import { Grid } from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import '../css/threads.css';

const getList = (setList, cookie) => {
  axios
    .get('/threads/all?currentPage=2&pageSize=10', {
      headers: {
        token: cookie.tokens.token,
      },
    })
    .then(
      (response) => {
        setList(response.data.data);
      },
      (error) => {
        console.log(error);
      },
    );
};

const checkCookie = (setSignedIn, cookie) => {
  if (cookie.tokens.token) {
    setSignedIn(true);
  }
};

function Threads() {
  const [list, setList] = useState([]);
  const [signedIn, setSignedIn] = useState(false);
  const [cookie] = useCookies(['tokens']);

  useEffect(() => {
    getList(setList, cookie);
    checkCookie(setSignedIn, cookie);
  }, [cookie]);

  return (
    <>
      <div className="main">
        <Grid container spacing={3}>
          <Grid item xs>
            <ul className="thread-list">
              <li className={signedIn ? 'invisible' : 'visible'}>
                <h3>
                  <a href="login">Sign in or Create an Account to see more!</a>
                </h3>
              </li>
              {list.map((item, i) => {
                return (
                  <li
                    key={item.id}
                    className={i > 2 && !signedIn ? 'blur' : ''}
                  >
                    <div className="thread">
                      <h1>{item.attributes.title}</h1>
                      <div>
                        - {item.relationships.author.first_name}
                        {item.relationships.author.last_name}
                      </div>
                      <div>{item.attributes.categories}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Threads;
