import {
  studentRoutes,
  adminRoutes,
  counselorRoutes,
  examAdminRoutes,
} from '../../routes/routes';

export const checkAuthorized = (role, path) => {
  const ar = adminRoutes.map((route) => route.path);
  const sr = studentRoutes.map((route) => route.path);
  const c = counselorRoutes.map((route) => route.path);
  const ea = examAdminRoutes.map((route) => route.path);
  switch (role) {
    case 'student':
      return sr.includes(path);
    case 'admin':
      return ar.includes(path);
    case 'counselor':
      return c.includes(path);
    case 'exam_admin':
      return ea.includes(path);
    default:
      return null;
  }
};
