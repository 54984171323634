const downloadSubscribers = (cookies) => {
  let url = `/admin/subscribers`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Content-disposition',
    'attachment; filename=subscribers.json',
  );

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log(e);
      throw new Error(
        `Unable to process API request to: ${url} REASON: ${e.message}`,
      );
    });
};

export default downloadSubscribers;
