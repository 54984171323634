import { New } from './New';
import React, { useState } from 'react';
import { Card as HonorCard } from './Card';
import ModalDialog from '../../ModalDialog';
import AddProfileDetail from '../AddProfileDetail';

// MUI IMPORTS
import { Box, List, ListItem, ListSubheader, Fade } from '@mui/material';

export const Container = ({ honors, setHonors }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          '& ul': { padding: 0 },
          overflowY: 'scroll',
          maxHeight: '85vh',
          mb: 2,
        }}
        subheader={<li />}
      >
        <ListSubheader>
          <AddProfileDetail onClick={handleClickOpen}>
            + Add Honor
          </AddProfileDetail>
        </ListSubheader>
        {honors.map((item, index) => {
          return (
            <Fade key={item.id} in={true} {...{ timeout: 1000 + 700 * index }}>
              <ListItem>
                <HonorCard {...item} setHonors={setHonors} />
              </ListItem>
            </Fade>
          );
        })}
        <Box sx={{ height: '100px' }}></Box>
      </List>
      <ModalDialog
        isOpen={open}
        handleClose={handleClose}
        content={<New setOpen={setOpen} setHonors={setHonors} />}
        title="Add Honor"
      />
    </>
  );
};
