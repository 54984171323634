import { Grid } from '@material-ui/core';
import React from 'react';
import logo from '../assets/logo.jpg';
import '../css/download.css';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

function DownloadApp() {
  return (
    <>
      <div className="main">
        <Grid className="download-container" container spacing={4}>
          <Grid item xs={12}>
            <div className="download-title fade-in-1">Hi, we're</div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <img src={logo} alt="logo"></img>
            </div>
            <i className="download-vize fade-in-2">VIZE</i>
          </Grid>
          <Grid item xs={12}>
            <div className="download-title-2 fade-in-3">We can help</div>
          </Grid>
          <Grid className="download-icons" item xs={12}>
            <a href="https://www.apple.com/">
              <AppleIcon />
            </a>
            <a href="https://www.play.google.com">
              <AndroidIcon />
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default DownloadApp;
