import React from 'react';

// MUI IMPORTS
import { TextField } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const DateReceived = ({ date, setDate, error, setError, label, disabled }) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        error={error}
        helperText={error}
        label={label}
        value={date}
        onError={setError}
        disabled={disabled ?? false}
        onChange={(newValue) => setDate(newValue, 'dateReceived')}
        renderInput={(params) => (
          <TextField error={error} helperText={error} {...params} />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateReceived;
