import React from 'react';

//MUI IMPORTS
import { Grid, Skeleton } from '@mui/material';

export const LoadingComponent = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={175} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={175} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={175} />
      </Grid>
    </Grid>
  );
};
