import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Paper } from '@material-ui/core';
import Participant from './Participant';
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';
import sessionInfo from '../sessionManagementFunctions/sessionInfo';
import jwtDecoder from '../../utils/jwtDecoder';
import ErrorMessage from '../ErrorMessage';

// const PLACEHOLDER_IMG = "https://i1.wp.com/lanecdr.org/wp-content/uploads/2019/08/placeholder.png?w=1200&ssl=1"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '1280px',
    margin: '0 auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  contents: {
    'border-radius': '4px',
  },
  responsiveImg: {
    width: '100%',
  },
  centeredItem: {
    margin: '0 auto',
  },
  noMargin: {
    margin: 0,
  },
  fullHeight: {
    height: '100%',
  },
  noPadding: {
    padding: 0,
  },
  bordered: {
    borderRadius: '4px 4px 0 0',
  },
  participantContainer: {
    borderRadius: '4px',
    marginBottom: '-6px',
    padding: 0,
  },
}));

const Room = ({ roomName, room, handleLogout }) => {
  const [participants, setParticipants] = useState([]);
  const classes = useStyles();
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  const [otherName, setOtherName] = useState('...');
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const getSessionInfo = async () => {
      try {
        const response = await sessionInfo(roomName, cookie);
        const jsonResponse = await response.json();

        if (response.status === 200) {
          const { student, counselor } = jsonResponse?.data?.relationships;
          // get user id from token
          const {
            user: { id: userId },
          } = jwtDecoder(cookie.tokens.token);
          // find out if I am the counselor or student
          if (userId === student.data.id) {
            setOtherName(
              `${counselor.data.attributes.firstName} ${counselor.data.attributes.lastName}`,
            );
          }

          if (userId === counselor.data.id) {
            setOtherName(
              `${student.data.attributes.firstName} ${student.data.attributes.lastName}`,
            );
          }
        } else {
          throw Error('Unable to get session information.');
        }
      } catch (err) {
        console.log(err);
        setErrorMessage(err.message);
      }
    };

    getSessionInfo();
  }, [cookie, roomName]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant),
      );
    };

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ));

  const firstParticipant =
    participants.length > 0 ? (
      <Participant key={participants[0].key} participant={participants[0]} />
    ) : null;

  return (
    <div className={classes.root}>
      {errorMessage?.length && <ErrorMessage message={errorMessage} />}
      <Grid container className={classes.fullHeight}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <p className={`${classes.noMargin}`}>
                  You are speaking with <strong>{otherName}</strong>
                </p>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogout}
                >
                  Leave Room
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.fullHeight}>
          <Paper className={`${classes.paper} ${classes.fullHeight}`}>
            <Grid container spacing={3}>
              {/* ACTIVE REMOTE PARTICIPANT */}
              {firstParticipant && (
                <Grid item xs={12} sm={8}>
                  <Paper
                    className={`${classes.paper} ${classes.participantContainer}`}
                  >
                    {firstParticipant}
                  </Paper>
                </Grid>
              )}
              {/* CURRENT USER */}
              <Grid
                item
                xs={12}
                sm={remoteParticipants.length === 0 ? 8 : 4}
                className={
                  remoteParticipants.length === 0 ? classes.centeredItem : ''
                }
              >
                <Paper
                  className={`${classes.paper} ${classes.noPadding} ${classes.bordered}`}
                >
                  {room ? (
                    <Participant
                      key={room.localParticipant.sid}
                      participant={room.localParticipant}
                      isLocal
                    />
                  ) : (
                    ''
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Room;
