import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useFetch } from './functions/hooks';
import React, { useEffect, useState } from 'react';
import CollegesContainer from './CollegesContainer';
import { postUserCollege } from './functions/requests';
import { deleteUserCollege } from './functions/requests';
import CollegeLogo from '../../../components/CollegeLogo';
import StyledCollegeCard from './StyledCollegeCard';
import AnalyticsService from '../../../analyticsService';

// MUI IMPORTS
import {
  Box,
  Chip,
  Grid,
  Menu,
  Card,
  Paper,
  Stack,
  Avatar,
  MenuItem,
  IconButton,
  Typography,
  CardContent,
  CardActions,
  Tooltip,
  Button,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const StyledPaper = ({ sx, children }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        ...sx,
        border: '1px solid',
        borderColor: '#E4EAF3',
        p: 3,
        borderRadius: '15px',
        width: '100%',
      }}
    >
      {children}
    </Paper>
  );
};

const PlaceHolder = ({ text }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" component="p" pb={2}>
        {text}
      </Typography>
    </Grid>
  );
};

const filters = [
  {
    label: 'Interested',
    value: 'interested',
  },
  {
    label: 'Applied',
    value: 'applied',
  },
  {
    label: 'Applying',
    value: 'applying',
  },
  {
    label: 'All',
    value: 'all',
  },
];

const CollegeFilters = ({ setSelected, selected }) => {
  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      {filters.map(({ label, value }) => (
        <Chip
          key={label}
          label={label}
          color="primary"
          variant={selected === value ? 'default' : 'outlined'}
          onClick={() => setSelected(value)}
        />
      ))}
    </Stack>
  );
};

export const Layout = () => {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Colleges' });
  }, []);

  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();

  const [SCPage] = useState(1);
  const savedCollegesURL = `/users/saved-colleges?currentPage=${SCPage}&pageSize=10`;
  const [savedCollegeData, SCLoading, SCError] = useFetch(savedCollegesURL);
  const [savedColleges, setSavedColleges] = useState([]);

  const [selected, setSelected] = useState('all');

  useEffect(() => {
    if (savedCollegeData) {
      const collegeData = savedCollegeData.map((d) => {
        const { ceeb_code: ceebCode, ...rest } = d.attributes;
        return { id: d.record_id, ceebCode, ...rest };
      });
      setSavedColleges((prev) => [...prev, ...collegeData]);
    }
  }, [savedCollegeData]);

  const handleDelete = async (id) => {
    try {
      const response = await deleteUserCollege(cookies, id);
      const { status } = response;
      if (status === 204) {
        enqueueSnackbar('College removed from saved list', {
          variant: 'success',
        });
        setSavedColleges(savedColleges.filter((college) => college.id !== id));
      } else {
        enqueueSnackbar('Error removing college', {
          variant: 'error',
        });
      }
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
      });
    }
  };

  const handleCollegeAdd = (college) => {
    setSavedColleges((prevState) => {
      const filteredState = prevState.filter((c) => c.id !== college.id);
      return [college, ...filteredState];
    });
    setSelected(college.status);
  };

  const handleFilter = (filter) => {
    switch (filter) {
      case 'all': {
        return savedColleges;
      }
      case 'applied': {
        return savedColleges.filter((college) => college.status === 'applied');
      }
      case 'applying': {
        return savedColleges.filter((college) => college.status === 'applying');
      }
      case 'interested': {
        return savedColleges.filter(
          (college) => college.status === 'interested',
        );
      }
      default:
        return savedColleges;
    }
  };

  return (
    <Grid container spacing={5} style={{ position: 'relative' }}>
      <Grid
        container
        item
        md={12}
        lg={6}
        xl={8}
        spacing={2}
        sx={{
          maxHeight: '95vh',
          overflowY: 'auto',
        }}
      >
        {SCLoading ? (
          <PlaceHolder text="Loading colleges..." />
        ) : (
          <CollegesContainer
            savedColleges={savedColleges}
            handleCollegeAdd={handleCollegeAdd}
          />
        )}
      </Grid>
      <Grid
        container
        item
        md={12}
        lg={6}
        xl={4}
        justifyContent="center"
        sx={{
          maxHeight: '95vh',
          overflowY: 'auto',
        }}
      >
        <Grid item alignItems="stretch" sx={{ width: '100%' }}>
          <Typography variant="h1" component="h2" pb={2}>
            My College List
          </Typography>
          <StyledPaper>
            <Grid container spacing={3}>
              <Grid item>
                <CollegeFilters setSelected={setSelected} selected={selected} />
              </Grid>
              {SCLoading ? (
                <PlaceHolder text={'Loading...'} />
              ) : (
                <Grid container item spacing={3}>
                  {savedColleges.length === 0 && (
                    <PlaceHolder text={'No Saved Colleges.'} />
                  )}
                  {handleFilter(selected).map((college) => (
                    <Grid item key={college.id} sx={{ width: '100%' }}>
                      <CollegePreview
                        {...college}
                        status={selected}
                        handleDelete={handleDelete}
                        savedColleges={savedColleges}
                        handleCollegeAdd={handleCollegeAdd}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {SCError && <PlaceHolder text={'Error loading colleges'} />}
            </Grid>
          </StyledPaper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;

const StyledCard = ({ children }) => {
  return (
    <Card
      elevation={0}
      sx={{
        overflow: 'visible',
        width: '95%',
        marginTop: '10px',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        border: '3px solid',
        borderColor: '#1A3AD5',
        position: 'relative',
      }}
      pb={4}
    >
      {children}
    </Card>
  );
};

export const CollegePreview = ({
  id,
  name,
  state,
  status,
  location,
  ceebCode,
  handleDelete,
  handleCollegeAdd,
  variant = 'version1',
}) => {
  const [cookies] = useCookies('tokens');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [SCPage] = useState(1);
  const savedCollegesURL = `/users/saved-colleges?currentPage=${SCPage}&pageSize=10`;
  const [savedCollegeData] = useFetch(savedCollegesURL);
  const [savedColleges, setSavedColleges] = useState([]);
  const [currentInterestedIndex, setCurrentInterestedIndex] = useState(0);
  const [currentApplyingIndex, setCurrentApplyingIndex] = useState(0);
  const [currentAppliedIndex, setCurrentAppliedIndex] = useState(0);

  const handleInterestedLeftArrowClick = () => {
    if (currentInterestedIndex > 0) {
      setCurrentInterestedIndex(currentInterestedIndex - 1);
    }
  };

  const handleInterestedRightArrowClick = () => {
    if (currentInterestedIndex < interestedColleges.length - 1) {
      setCurrentInterestedIndex(currentInterestedIndex + 1);
    }
  };

  const handleApplyingLeftArrowClick = () => {
    if (currentApplyingIndex > 0) {
      setCurrentApplyingIndex(currentApplyingIndex - 1);
    }
  };

  const handleApplyingRightArrowClick = () => {
    if (currentApplyingIndex < applyingColleges.length - 1) {
      setCurrentApplyingIndex(currentApplyingIndex + 1);
    }
  };

  const handleAppliedLeftArrowClick = () => {
    if (currentAppliedIndex > 0) {
      setCurrentAppliedIndex(currentAppliedIndex - 1);
    }
  };

  const handleAppliedRightArrowClick = () => {
    if (currentAppliedIndex < appliedColleges.length - 1) {
      setCurrentAppliedIndex(currentAppliedIndex + 1);
    }
  };

  const interestedColleges = savedColleges
    .filter((college) => college.status === 'interested')
    .map((college) => ({
      name: college.name,
      ceebCode: college.ceebCode,
      status: college.status,
    }));

  const applyingColleges = savedColleges
    .filter((college) => college.status === 'applying')
    .map((college) => ({
      name: college.name,
      ceebCode: college.ceebCode,
      status: college.status,
    }));

  const appliedColleges = savedColleges
    .filter((college) => college.status === 'applied')
    .map((college) => ({
      name: college.name,
      ceebCode: college.ceebCode,
      status: college.status,
    }));

  useEffect(() => {
    if (savedCollegeData) {
      const collegeData = savedCollegeData.map((d) => {
        const { ceeb_code: ceebCode, ...rest } = d.attributes;
        return { id: d.record_id, ceebCode, ...rest };
      });
      setSavedColleges((prev) => [...prev, ...collegeData]);
    }
  }, [savedCollegeData]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    handleClose();
    handleDelete(id);
  };

  const { enqueueSnackbar } = useSnackbar();

  const onHandleAdd = async (value) => {
    handleClose();
    const body = {
      ceeb_code: ceebCode,
      state: value,
    };
    try {
      const response = await postUserCollege(cookies, body);
      if (response.error) {
        enqueueSnackbar(response.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('College Added.', {
          variant: 'success',
        });

        const newSavedCollege = {
          id: response.body.data.id,
          ceebCode,
          location,
          name,
          state,
          status: value,
        };
        handleCollegeAdd(newSavedCollege);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  if (variant === 'version2')
    return (
      <Grid container alignItems="center" justifyContent="space-evenly">
        <Grid item xs={3} textAlign="center">
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1 }}
          >
            <Typography variant={'h5'} sx={{ fontWeight: 'bold', m: 5 }}>
              Interested
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1 }}
          >
            <Button
              onClick={handleInterestedLeftArrowClick}
              sx={{
                borderRadius: '50%',
                mr: -3,
              }}
            >
              <ArrowLeftIcon sx={{ color: 'primary.main', fontSize: 40 }} />
            </Button>
            <Paper elevation={5} sx={{ borderRadius: '50%' }}>
              <Tooltip title="college" arrow>
                <Avatar
                  sx={{
                    width: {
                      xs: '4rem',
                      sm: '5rem',
                      m: '6rem',
                      lg: '6rem',
                      xl: '8rem',
                    },
                    height: {
                      xs: '4rem',
                      sm: '5rem',
                      m: '6rem',
                      lg: '6rem',
                      xl: '8rem',
                    },
                    borderRadius: '50%',
                    border: '3px solid',
                    borderColor: 'primary.main',
                  }}
                >
                  {interestedColleges[currentInterestedIndex] && (
                    <CollegeLogo
                      name={interestedColleges[currentInterestedIndex].name}
                      ceeb_code={
                        interestedColleges[currentInterestedIndex].ceebCode
                      }
                      status={interestedColleges[currentInterestedIndex].status}
                    />
                  )}
                </Avatar>
              </Tooltip>
            </Paper>
            <Button
              onClick={handleInterestedRightArrowClick}
              sx={{ borderRadius: '50%', ml: -3 }}
            >
              <ArrowRightIcon sx={{ color: 'primary.main', fontSize: 40 }} />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={3} textAlign="center">
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1 }}
          >
            <Typography variant={'h5'} sx={{ fontWeight: 'bold', m: 5 }}>
              Applying
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1 }}
          >
            <Button
              onClick={handleApplyingLeftArrowClick}
              sx={{ borderRadius: '50%', mr: -3 }}
            >
              <ArrowLeftIcon sx={{ color: 'primary.main', fontSize: 40 }} />
            </Button>
            <Paper elevation={5} sx={{ borderRadius: '50%' }}>
              <Tooltip title="college" arrow>
                <Avatar
                  sx={{
                    width: {
                      xs: '4rem',
                      sm: '5rem',
                      m: '6rem',
                      lg: '6rem',
                      xl: '8rem',
                    },
                    height: {
                      xs: '4rem',
                      sm: '5rem',
                      m: '6rem',
                      lg: '6rem',
                      xl: '8rem',
                    },
                    borderRadius: '50%',
                    border: '3px solid',
                    borderColor: 'primary.main',
                  }}
                >
                  {applyingColleges[currentApplyingIndex] && (
                    <CollegeLogo
                      name={applyingColleges[currentApplyingIndex].name}
                      ceeb_code={
                        applyingColleges[currentApplyingIndex].ceebCode
                      }
                      status={applyingColleges[currentApplyingIndex].status}
                    />
                  )}
                </Avatar>
              </Tooltip>
            </Paper>
            <Button
              onClick={handleApplyingRightArrowClick}
              sx={{ borderRadius: '50%', ml: -3 }}
            >
              <ArrowRightIcon sx={{ color: 'primary.main', fontSize: 40 }} />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={3} textAlign="center">
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1 }}
          >
            <Typography variant={'h5'} sx={{ fontWeight: 'bold', m: 5 }}>
              Applied
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1 }}
          >
            <Button
              onClick={handleAppliedLeftArrowClick}
              sx={{ borderRadius: '50%', mr: -3 }}
            >
              <ArrowLeftIcon sx={{ color: 'primary.main', fontSize: 40 }} />
            </Button>
            <Paper elevation={5} sx={{ borderRadius: '50%' }}>
              <Tooltip title="college" arrow>
                <Avatar
                  sx={{
                    width: {
                      xs: '4rem',
                      sm: '5rem',
                      m: '6rem',
                      lg: '6rem',
                      xl: '8rem',
                    },
                    height: {
                      xs: '4rem',
                      sm: '5rem',
                      m: '6rem',
                      lg: '6rem',
                      xl: '8rem',
                    },
                    borderRadius: '50%',
                    border: '3px solid',
                    borderColor: 'primary.main',
                  }}
                >
                  {appliedColleges[currentAppliedIndex] && (
                    <CollegeLogo
                      name={appliedColleges[currentAppliedIndex].name}
                      ceeb_code={appliedColleges[currentAppliedIndex].ceebCode}
                      status={appliedColleges[currentAppliedIndex].status}
                    />
                  )}
                </Avatar>
              </Tooltip>
            </Paper>

            <Button
              onClick={handleAppliedRightArrowClick}
              sx={{ borderRadius: '50%', ml: -3 }}
            >
              <ArrowRightIcon sx={{ color: 'primary.main', fontSize: 40 }} />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    );

  return (
    <StyledCard>
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Avatar
              sx={{
                width: '5rem',
                height: '5rem',
                borderRadius: '50%',
                border: '2px solid',
                borderColor: '#1A3AD5',
                backgroundColor: 'white',
              }}
            >
              <CollegeLogo name={name} ceeb_code={ceebCode} />
            </Avatar>
          </Grid>
          <Grid item xs={8}>
            <StyledCollegeCard name={name} location={location} state={state} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          overflow: 'hidden',
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
          backgroundColor: '#BDD1F9',
          py: 0,
          my: 0,
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          onClick={handleClick}
          aria-label="expand more options"
          sx={{ color: 'primary.main' }}
        >
          <MoreHorizIcon sx={{ transform: 'scale(2)' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {status !== 'interested' && (
            <MenuItem onClick={() => onHandleAdd('interested')}>
              Interested
            </MenuItem>
          )}
          {status !== 'applying' && (
            <MenuItem onClick={() => onHandleAdd('applying')}>
              Applying
            </MenuItem>
          )}{' '}
          {status !== 'applied' && (
            <MenuItem onClick={() => onHandleAdd('applied')}>Applied</MenuItem>
          )}
          <MenuItem onClick={() => handleRemove()}>Remove</MenuItem>
        </Menu>
      </CardActions>
    </StyledCard>
  );
};
