import React, { useState } from 'react';
import SignIn from './SignIn';
import PasswordResetRequest from './PasswordResetRequest';

//MUI IMPORTS
import { Grid } from '@mui/material';

const colorStyle = {
  // backgroundColor: '#BDD1F9',
  // backgroundImage: '-webkit-linear-gradient(94deg, #BDD1F9 50%, #FBFBFD 50%)',
  minHeight: '450px',
  position: 'fixed',
  textAlign: 'left',
  bottom: '0px',
  width: '100%',
  zIndex: '-1',
};

const signInStyle = {};

const textStyle = {
  position: 'fixed',
  bottom: '0',
  left: '0',
  padding: '20px',
};

export default function LandingPage() {
  const [status, setStatus] = useState('si');

  return (
    <>
      <Grid container component="main">
        {
          {
            si: <SignIn setStatus={setStatus} />,
            rp: <PasswordResetRequest setStatus={setStatus} />,
          }[status]
        }
        <div style={status === 'si' ? signInStyle : colorStyle}>
          <div style={textStyle}>
            <h4>
              <strong>
                Questions? Need help? Email us at contact@vize.io <br />
                Copyright © 2022 VIZE, LLC
              </strong>
            </h4>
          </div>
        </div>
      </Grid>
    </>
  );
}
