import React, { useEffect, useState, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { getColleges } from './functions/requests';
import AdminCollegeCard from './AdminCollegeCard';
import { useCookies } from 'react-cookie';
import LoadingButton from '@mui/lab/LoadingButton';
import { SearchRounded } from '@material-ui/icons';
import Grid from '@mui/material/Grid';

const AdminColleges = ({
  setCollegeDataToEdit,
  setDeadlinesToEdit,
  setIsDeadlinesDataEditing,
}) => {
  const [colleges, setColleges] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [queryData, setQueryData] = useState({
    currentPage: 1,
    searchQuery: '',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [pages] = useState([]);
  const [cookies] = useCookies('tokens');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchColleges = async () => {
      setError('');
      setLoading(true);

      try {
        const response = await getColleges(
          cookies,
          queryData.currentPage,
          queryData.searchQuery,
        );

        if (!response) {
          setColleges([]);
          return;
        }

        if (response?.error) {
          setError(response.message);
        } else {
          const { data, pageCount } = response?.body;
          setPageCount(pageCount);
          if (data) {
            setColleges(data);
          }
        }
      } catch (error) {
        setError(error.toString());
      } finally {
        setLoading(false);
      }
    };
    fetchColleges();
  }, [queryData, cookies]);

  const search = useCallback(() => {
    setQueryData((prevState) => {
      return { ...prevState, currentPage: 1, searchQuery: searchQuery };
    });
  }, [searchQuery]);

  function goToNextPage() {
    setQueryData((prevState) => {
      return { ...prevState, currentPage: prevState.currentPage + 1 };
    });
  }

  function goToPreviousPage() {
    setQueryData((prevState) => {
      return { ...prevState, currentPage: prevState.currentPage - 1 };
    });
  }

  function changePage(event) {
    setQueryData((prevState) => {
      return { ...prevState, currentPage: parseInt(event.target.textContent) };
    });
  }

  const getPageNumbers = () => {
    return [...Array(pageCount).keys()];
  };

  return (
    <>
      <Grid item xs={6}>
        <TextField
          margin="normal"
          label="Search colleges"
          type="name"
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearchQuery(e?.target?.value);
              search();
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <LoadingButton
          loading={loading}
          type="submit"
          onClick={() => search()}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          endIcon={<SearchRounded />}
        >
          Search
        </LoadingButton>
      </Grid>
      {error ? (
        <p>{error}</p>
      ) : (
        <>
          {colleges.length !== 0 ? (
            <>
              {colleges.map((college) => {
                const {
                  ceebCode,
                  name,
                  state,
                  location,
                  division,
                  airport,
                  facStu,
                  geography,
                  isPublic,
                  schoolType,
                  smallClass,
                  urbanCenter1,
                  urbanCenter2,
                  eaDeadline,
                  ed1Deadline,
                  ed2Deadline,
                  regDeadline,
                  status,
                } = college?.attributes;
                return (
                  <AdminCollegeCard
                    key={ceebCode}
                    ceebCode={ceebCode}
                    name={name}
                    state={state}
                    location={location}
                    division={division}
                    airport={airport}
                    facStu={facStu}
                    geography={geography}
                    isPublic={isPublic}
                    schoolType={schoolType}
                    smallClass={smallClass}
                    urbanCenter1={urbanCenter1}
                    urbanCenter2={urbanCenter2}
                    status={status}
                    //deadlines
                    eaDeadline={eaDeadline}
                    ed1Deadline={ed1Deadline}
                    ed2Deadline={ed2Deadline}
                    regDeadline={regDeadline}
                    //state setters
                    setCollegeDataToEdit={setCollegeDataToEdit}
                    setDeadlinesToEdit={setDeadlinesToEdit}
                    setIsDeadlinesDataEditing={setIsDeadlinesDataEditing}
                  />
                );
              })}

              {getPageNumbers().length !== 0 && (
                <div className="pagination">
                  {/* previous button */}
                  <button
                    onClick={goToPreviousPage}
                    disabled={queryData.currentPage === 1}
                  >
                    prev
                  </button>

                  {/* page numbers */}
                  {getPageNumbers().map((item) => (
                    <button
                      key={item}
                      onClick={changePage}
                      disabled={queryData.currentPage === item + 1}
                    >
                      <span>{item + 1}</span>
                    </button>
                  ))}

                  {/* next button */}
                  <button
                    onClick={goToNextPage}
                    disabled={queryData.currentPage === pages - 1}
                  >
                    next
                  </button>
                </div>
              )}
            </>
          ) : (
            <p>No colleges found</p>
          )}
        </>
      )}
    </>
  );
};

export default AdminColleges;
