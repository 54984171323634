const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

export const getCounselorProfileById = async (counselorId) => {
  let url = `/users/counselor/${counselorId}/profile`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200 || response.status === 304) {
    return await response.json();
  }

  return { error: 'server error' };
};
