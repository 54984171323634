const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
const myHeaders = (cookies) => {
  const headers = new Headers();
  headers.append('token', cookies.tokens.token);
  headers.append('Content-Type', 'application/json');
  return headers;
};

export const addNewTask = async (cookies, data) => {
  let url = `/v2/tasks/`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'POST',
    headers: myHeaders(cookies),
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  return await response.json();
};

export const editTask = async (cookies, data, taskId) => {
  let url = `/v2/tasks/${taskId}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'PATCH',
    headers: myHeaders(cookies),
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) return true;

  return { message: `Unable to update task with id: ${taskId}` };
};

export const editTaskByStudentId = async (cookies, data, taskId, studentId) => {
  let url = `/v2/tasks/${taskId}/student/${studentId}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  const headers = myHeaders(cookies);
  headers.append('studentid', studentId);
  const configObj = {
    method: 'PATCH',
    headers,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) return true;

  return { message: `Unable to update task with id: ${taskId}` };
};

export const deleteTaskById = async (id, cookies) => {
  let url = `/v2/tasks/${id}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'DELETE',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);
  if (response.status === 204) {
    return true;
  }
  return await response.json();
};

export const deleteTaskByStudentId = async (id, studentId, cookies) => {
  let url = `/v2/tasks/${id}/student/${studentId}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  const headers = myHeaders(cookies);
  headers.append('studentid', studentId);
  const configObj = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(url, configObj);
  if (response.status === 204) {
    return true;
  }
  return await response.json();
};

export const getTaskByExternalId = async (id, cookies) => {
  let url = `/v2/tasks?externalId=${id}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'GET',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);
  if (response.status === 200 && response?.body) {
    const {
      body: { attributes, id, relationships },
    } = await response.json();
    return { attributes, id, relationships };
  }

  if (response.status === 404)
    return { error: true, message: `Task not found with External Id: ${id}` };

  return await response.json();
};

export const getStudentTaskByStudentId = async (cookies, studentId) => {
  let url = `/v2/tasks/${studentId}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('studentid', studentId);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (!response.ok) return 'Server error.';
  return await response.json();
};

export const addStudentsForCustomTask = async (
  id,
  cookies,
  data,
  applyToAll,
) => {
  let url = `/v2/tasks/custom/${id}/students`;
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'POST',
    headers: myHeaders(cookies),
    body: JSON.stringify(
      applyToAll ? { apply_to_all: applyToAll } : { user_ids: data },
    ),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) {
    return true;
  }
  return await response.json();
};

export const addCollegesForCollegeTask = async (
  id,
  cookies,
  data,
  applyToAll,
) => {
  let url = `/v2/tasks/standard/${id}/colleges`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  const configObj = {
    method: 'POST',
    headers: myHeaders(cookies),
    body: JSON.stringify(
      applyToAll ? { apply_to_all: applyToAll } : { ceeb_codes: data },
    ),
  };

  const response = await fetch(url, configObj);

  return await response.json();
};
