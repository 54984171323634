import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Profile from './Profile';
import { useFetch } from './functions/hooks';
import MajorList from './major-choices/MajorList';
import CollegeList from './colleges/CollegeList';
import FavoritesList from './favorites/FavoritesList';
import ProfileDetails from '../profile-details/ProfileDetails';
import Notes from './notes/Notes';
import { useStudentName } from '../../../store/UserContext';
import getInitialsFromName from '../../../utils/getInitialsFromName';
import AnalyticsService from '../../../analyticsService';
import { useHistory } from 'react-router-dom';
import { Layout as EditPassword } from '../edit-password/Layout';
import ModalDialog from '../ModalDialog';

// REACTLY - ICONS
import { Location, EditSquare as EditIcon } from 'react-iconly';

// MUI IMPORTS
import {
  Grid,
  Stack,
  IconButton,
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  CardActions,
} from '@mui/material';

const userAboutURL = '/users/about';
const userProfileURL = '/users/profile';

const deconstructAttributes = (data) => {
  return data.attributes;
};

export const Layout = () => {
  const [passwordOpen, setPasswordOpen] = useState(false);
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Student Profile' });
  }, []);

  const {
    resource: profile,
    setResource: setProfile,
    // isLoading: isProfileLoading,
    // serverError: profileError,
  } = useFetch(userProfileURL, deconstructAttributes);

  const {
    resource: about,
    setResource: setAbout,
    isLoading: isAboutLoading,
    serverError: aboutError,
  } = useFetch(userAboutURL, deconstructAttributes);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={7} sx={{ pt: 4 }}>
        <Typography
          variant="h1"
          component="h1"
          sx={{ my: 2, display: { xs: 'block', md: 'none', lg: 'block' } }}
        >
          My Profile
        </Typography>
        <StyledCard>
          {profile && <CardAvatar profile={profile} />}
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                {profile && <Profile {...profile} setProfile={setProfile} />}
              </Grid>
              <Grid item xs={12} md={4}>
                <MajorList
                  about={about}
                  setAbout={setAbout}
                  isAboutLoading={isAboutLoading}
                  aboutError={aboutError}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CollegeList />
              </Grid>
              <Grid item xs={12} md={4} sx={{ p: 0 }}>
                <FavoritesList
                  about={about}
                  setAbout={setAbout}
                  isAboutLoading={isAboutLoading}
                  aboutError={aboutError}
                />
              </Grid>
              <Grid item xs={12}>
                {about && <Notes notes={about.notes} setAbout={setAbout} />}
              </Grid>
            </Grid>
            <ModalDialog
              isOpen={passwordOpen}
              handleClose={() => setPasswordOpen(false)}
              content={
                <EditPassword handleClose={() => setPasswordOpen(false)} />
              }
              title="Edit Password"
            />
            <IconButton
              siz="small"
              sx={{ height: '25px' }}
              onClick={(e) => setPasswordOpen(true)}
            >
              <EditIcon set="bold" color="#455FE8" style={{ height: '25px' }} />
              Update Password
            </IconButton>{' '}
          </CardContent>
          <CardActions sx={{ p: 0, m: 0, mt: 'auto' }}>
            <Footer
              about={about}
              setAbout={setAbout}
              isAboutLoading={isAboutLoading}
              aboutError={aboutError}
            />
          </CardActions>
        </StyledCard>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Box sx={{ my: 1 }}></Box>
        <ProfileDetails />
      </Grid>
    </Grid>
  );
};

const StyledCard = ({ children, sx, elevation = 0 }) => {
  return (
    <Card
      elevation={elevation}
      sx={{
        ...sx,
        mt: { sm: 5, md: 10, lg: 0 },
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '9px solid ',
        borderColor: '#455FE8',
        borderRadius: '25px',
        position: 'relative',
      }}
    >
      {children}
    </Card>
  );
};

const CardAvatar = ({ profile }) => {
  const studentName = useStudentName();
  const initials = getInitialsFromName(studentName);

  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'block' },
      }}
    >
      <Avatar
        sx={{
          width: '10rem',
          height: '10rem',
          borderRadius: '50%',
          border: '9px solid',
          borderColor: '#455FE8',
          backgroundColor: 'white',
          position: 'absolute',
          top: '-5rem',
          right: '2rem',
          zIndex: '10',
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            color: 'black',
            transform: 'scale(4)',
            zIndex: '11',
          }}
        >
          {initials}
        </Typography>
      </Avatar>
    </Box>
  );
};
