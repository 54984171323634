import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import ModalDialog from '../../ModalDialog';
import React, { useState, useEffect } from 'react';
import { validateNotes } from '../functions/validations';
import { postAboutMeData } from '../functions/requests';

// MUI IMPORTS
import {
  Typography,
  Box,
  Stack,
  TextField,
  Button,
  IconButton,
} from '@mui/material';

// REACTLY - ICONS
import { EditSquare as EditIcon } from 'react-iconly';

const Notes = ({ notes, setAbout }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography variant="body1">
        <Box component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
          <IconButton
            siz="small"
            sx={{ height: '25px' }}
            onClick={(e) => setOpen(true)}
          >
            <EditIcon set="bold" color="#455FE8" style={{ height: '25px' }} />
          </IconButton>{' '}
          Notes:
        </Box>{' '}
        {notes}{' '}
      </Typography>
      <ModalDialog
        isOpen={open}
        handleClose={() => setOpen(false)}
        content={
          <NoteForm
            notes={notes}
            open={open}
            setOpen={setOpen}
            setAbout={setAbout}
          />
        }
        title="Edit Notes"
      />
    </>
  );
};

const NoteForm = ({ notes, open, setOpen, setAbout }) => {
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [notesError, setNotesError] = useState('');
  const [notesFormDetails, setNotesFormDetails] = useState('');

  useEffect(() => {
    if (notes) {
      setNotesFormDetails(notes);
    }
  }, [notes]);

  const validate = () => {
    if (notesFormDetails === '') {
      setNotesError('Please enter some notes.');
      return false;
    }
    const { message, type, pass } = validateNotes({ notes: notesFormDetails });
    switch (type) {
      case 'notes':
        setNotesError(message);
        return pass;
      default:
        return pass;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setNotesError('');
    if (validate()) {
      setLoading(true);
      const {
        message: { error, body },
      } = await postAboutMeData({ notes: notesFormDetails }, cookies);
      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        let {
          data: { attributes },
        } = await body;

        setAbout((prev) => {
          return {
            ...prev,
            ...attributes,
          };
        });
        enqueueSnackbar('Notes Updated', {
          variant: 'success',
        });
      }
      setLoading(false);
      setOpen(false);
    }
  };

  const placeHolderText = `
💸 A rough idea of your financial aid needs
📚 Anything that makes you or your life story unique
🏫 Information about your high school
🎖 Any legacy connections to certain schools
✨ Anything else you think we might want to know
`;

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        <TextField
          id="filled-multiline-flexible"
          label="Notes"
          multiline
          rows={8}
          value={notesFormDetails}
          onChange={(event) => setNotesFormDetails(event.target.value)}
          variant="filled"
          aria-placeholder="Enter your notes here..."
          placeholder={placeHolderText}
          helperText={notesError}
          error={!!notesError}
        />
      </Stack>
      <Button sx={{ my: 2, width: '100%' }} type="submit" variant="contained">
        {loading ? 'loading' : 'Submit'}
      </Button>
    </Box>
  );
};

export default Notes;
