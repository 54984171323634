import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import useQuery from '../hooks/useQuery';
import ErrorMessage from '../components/ErrorMessage';
import StyledButton from '../components/StyledButton/StyledButton';
import StyledLoadingIndicator from '../components/StyledLoadingIndicator/StyledLoadingIndicator';
import TextField from '@material-ui/core/TextField';
import updatePassword from '../components/userManagmentFunctions/updatePassword';

const PasswordResetForm = () => {
  // const [successMessage, setSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  // TODO: move this to a context/hook
  const [setCookie] = useCookies();
  const query = useQuery();

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let errorToReturn;
    setErrorMessage('');
    try {
      setLoading(true);
      let pwTokenQuery;
      if (query) {
        pwTokenQuery = query.get('token');
      }
      const response = await updatePassword(data, pwTokenQuery);

      if (response.status === 200) {
        const jsonResponse = await response.json();
        const { newToken, refreshToken } = jsonResponse;
        setCookie('tokens', { token: newToken, refreshToken }, { path: '/' });
        history.push('/');
      } else if (response.status === 422) {
        errorToReturn = 'Wrong input';
      } else if (response.status === 403) {
        errorToReturn = 'Token is not valid';
      } else if (response.status >= 500) {
        errorToReturn = response.statusText;
      } else {
        errorToReturn = response.text;
      }
    } catch (err) {
      console.log(err);
      errorToReturn = err.message;
    } finally {
      setLoading(false);
      if (errorToReturn) {
        console.log(errorToReturn);
        setErrorMessage(errorToReturn);
      }
    }
  };

  return (
    <>
      <div className="main">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>Update Password</h1>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form style={{ width: '50%' }} onSubmit={handleSubmit(onSubmit)}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                // error={!!passwordError}
                // helperText={passwordError}
                autoComplete="current-password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="rePassword"
                label="Confirm Password"
                type="password"
                id="rePassword"
                autoComplete="current-password"
              />
              <Box mt={2}>
                <StyledButton
                  disable={loading}
                  variant="contained"
                  type="submit"
                >
                  {loading ? <StyledLoadingIndicator /> : 'Submit'}
                </StyledButton>
              </Box>
              {errorMessage && <ErrorMessage message={errorMessage} />}
            </form>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PasswordResetForm;
