import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  noMargin: {
    margin: 0,
  },
}));

const AVControl = ({ type, toggleTrack, trackOn, local }) => {
  const classes = useStyles();

  return (
    <>
      {type === 'audio' && (
        <Fab
          onClick={toggleTrack}
          size="small"
          color="primary"
          aria-label="mic"
          className={classes.margin}
        >
          {trackOn ? <MicIcon /> : <MicOffIcon />}
        </Fab>
      )}
      {type === 'video' && (
        <Fab
          onClick={toggleTrack}
          size="small"
          color="primary"
          aria-label="video"
          className={classes.margin}
        >
          {trackOn ? <VideocamIcon /> : <VideocamOffIcon />}
        </Fab>
      )}
    </>
  );
};

export default AVControl;
