import { middlewareFilterAndSort } from './functions/filtering_and_sorting';
import React, { useState, useEffect } from 'react';
import { useFetchTasks, useFetchColleges } from './functions/hooks';
import AllTasks from './AllTasks';
import TaskCalendar from '../task-calendar/Layout';
import AnalyticsService from '../../../analyticsService';

// MUI IMPORTS
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Stack,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Autocomplete,
  TextField,
  FormLabel,
  Radio,
} from '@mui/material';

const SelectGroup = ({ handleChange, value, label, options }) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel
        id={`${label}-select`}
        sx={{
          fontSize: '1rem',
          fontWeight: 'bold',
          color: 'rgba(69, 95, 232, 1)',
          backgroundColor: 'textPrimary',
          borderRadius: '4px',
          textDecoration: 'none',
        }}
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          fontSize: '1rem',
          fontWeight: 'bold',
          color: 'rgba(69, 95, 232, 1)',
          backgroundColor: 'textPrimary',
          borderRadius: '4px',
          textDecoration: 'none',
        }}
        labelId={`${label}-label`}
        id={`${label}-select`}
        value={value}
        onChange={handleChange}
      >
        {options &&
          options.map((item, index) => {
            return (
              <MenuItem key={item.value + index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

const FilterAndSort = ({
  type,
  setType,
  date,
  setDate,
  setSorter,
  status,
  setStatus,
  colleges,
  setSearch,
  view,
  changeView,
}) => {
  const selects = [
    {
      label: 'Task Type',
      value: type,
      options: [
        { label: 'General', value: 'general' },
        { label: 'School Specific', value: 'school' },
        { label: 'All', value: 'allType' },
      ],
      handleChange: (event) => {
        setType(event.target.value);
      },
    },
    {
      label: 'Date',
      value: date,
      options: [
        { label: 'Ascending', value: 'dateAsc' },
        { label: 'Descending', value: 'dateDesc' },
      ],
      handleChange: (event) => {
        setDate(event.target.value);
        setSorter(event.target.value);
      },
    },
    {
      label: 'Task Status',
      value: status,
      options: [
        { label: 'Upcoming', value: 'upcoming' },
        { label: 'Completed', value: 'completed' },
        { label: 'All', value: 'allStatus' },
      ],
      handleChange: (event) => {
        setStatus(event.target.value);
      },
    },
  ];

  let collegeOptions = [];
  if (colleges) {
    collegeOptions = colleges.reduce((filtered, college) => {
      if (college.attributes.status === 'applying') {
        filtered.push({
          label: college.attributes.name,
          value: college.attributes.ceebcode,
        });
      }
      return filtered;
    }, []);
  }

  return (
    <Grid container item direction="row">
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="view-selection-radio-buttons-label">
            View Options
          </FormLabel>
          <RadioGroup
            aria-labelledby="view-selection-radio-buttons-label"
            name="radio-buttons-group"
            value={view}
            onChange={changeView}
          >
            <Stack direction="row">
              <FormControlLabel
                value="list"
                control={<Radio sx={{ transform: 'scale(1.25)' }} />}
                label="List View"
              />
              <FormControlLabel
                value="calendar"
                control={<Radio sx={{ transform: 'scale(1.25)' }} />}
                label="Calendar View"
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(e) => setSearch(e.target.textContent)}
            options={collegeOptions}
            sx={{ width: '95%', marginTop: '10px' }}
            renderInput={(params) => (
              <TextField {...params} label="Filter by College" />
            )}
          />
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12} md={2}>
            <Typography variant="body1" component="label" sx={{ pt: 1 }}>
              Sorted by:{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            {view === 'list'
              ? selects.map((select) => (
                  <SelectGroup key={select.label} {...select} />
                ))
              : selects
                  .filter((select) => select.label !== 'Date')
                  .map((select) => (
                    <SelectGroup key={select.label} {...select} />
                  ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Layout = () => {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Tasks' });
  }, []);

  const taskUrl = `/v2/tasks/all`;
  const [tasks, setTasks, taskLoading, taskError] = useFetchTasks(taskUrl);
  const [filters, setFilters] = useState([]);
  const [view, setView] = useState('list');

  const [type, setType] = useState('allType');
  const [status, setStatus] = useState('allStatus');
  const [date, setDate] = useState('dateAsc');

  const [sorter, setSorter] = useState(date);
  const [search, setSearch] = useState(null);

  const [SCPage] = useState(1);
  const collegesUrl = `/users/saved-colleges?currentPage=${SCPage}&pageSize=10`;
  const [colleges] = useFetchColleges(collegesUrl);

  useEffect(() => {
    setFilters([type, status]);
  }, [type, status]);

  const [mutatedTasks, setMutatedTasks] = useState([]);

  useEffect(() => {
    if (tasks) {
      setMutatedTasks(middlewareFilterAndSort(tasks, filters, sorter, search));
    }
  }, [tasks, filters, sorter, search]);

  const changeView = (event) => {
    setView(event.target.value);
  };

  let contentRender = !!tasks;
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h1" component="h2">
            My Tasks
          </Typography>
          <Typography variant="body1" component="p">
            Click the <strong>checkbox</strong> next to a task to mark it as
            done{' '}
          </Typography>
          <Typography variant="body1" component="p">
            Clicking on <strong>the ellipses menu</strong> will display more
            details.
          </Typography>
          <Typography variant="body1" component="p">
            Use the <strong>filter options</strong> below to customize how your
            tasks display.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mb: 5 }}>
            <FilterAndSort
              type={type}
              status={status}
              setType={setType}
              setSearch={setSearch}
              setStatus={setStatus}
              setSorter={setSorter}
              colleges={colleges}
              date={date}
              setDate={setDate}
              view={view}
              changeView={changeView}
            />
          </Box>
        </Grid>
      </Grid>
      {contentRender &&
        (view === 'list' ? (
          <Grid container item xs={12} spacing={7}>
            <AllTasks
              setTasks={setTasks}
              tasks={mutatedTasks}
              taskLoading={taskLoading}
              taskError={taskError}
            />
          </Grid>
        ) : (
          <TaskCalendar
            setTasks={setTasks}
            tasks={mutatedTasks}
            taskLoading={taskLoading}
            taskError={taskError}
          />
        ))}
    </>
  );
};

export default Layout;
