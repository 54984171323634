import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useUserRole } from 'store/UserContext';
import {
  getStudentTaskByStudentId,
  deleteTaskByStudentId,
  editTaskByStudentId,
} from 'components/admin/tasks/functions/requests';

//MUI IMPORTS
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Box,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// MUI ICONS
import EditIcon from '@material-ui/icons/EditOutlined';

const SelectedStudentsTasks = ({ counselorStudents, onEdit }) => {
  const [selectedStudents, setSelectedStudents] = useState(() => {
    const [firstOption] = counselorStudents;
    return firstOption || {};
  });
  const options = [...counselorStudents];
  const [studentTasks, setStudentTasks] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [cookies] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getAllStudentTasks = async () => {
      const response = await getStudentTaskByStudentId(
        cookies,
        selectedStudents.id,
      );

      const result = response.data.map((task) => {
        return task.attributes;
      });
      setStudentTasks(result);
    };
    if (selectedStudents?.id) {
      getAllStudentTasks();
    }
  }, [selectedStudents, cookies, enqueueSnackbar]);

  const deleteTaskHandler = useCallback(
    async (taskId, studentId) => {
      try {
        const deleted = await deleteTaskByStudentId(taskId, studentId, cookies);
        if (deleted) enqueueSnackbar('Task deleted!');
        setStudentTasks((prevState) => {
          const filteredData = [...prevState].filter((task) => {
            return taskId !== task.taskId;
          });
          return filteredData;
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [cookies, enqueueSnackbar],
  );

  const handleChange = (event, newValue) => {
    setSelectedStudents(newValue);
  };

  const handleEditClick = (taskId) => {
    setEditingId(taskId);
  };

  const handleEditChange = async (taskId, newDueDate) => {
    setStudentTasks((prevState) => {
      const index = prevState.findIndex((task) => task.taskId === taskId);
      const updatedTasks = [...prevState];
      updatedTasks[index] = {
        ...updatedTasks[index],
        dueDate: newDueDate,
      };
      return updatedTasks;
    });

    try {
      await editTaskByStudentId(
        cookies,
        { due_date: newDueDate },
        taskId,
        selectedStudents.id,
      );
      enqueueSnackbar('Task Updated!');
      setEditingId(null);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Box sx={{ width: '100%', maxHeight: '20%' }}>
      <FormControl>
        <Autocomplete
          disablePortal
          id="students"
          options={options}
          getOptionLabel={(option) => option.name || ''}
          value={selectedStudents}
          onChange={handleChange}
          sx={{ width: 300, m: 1 }}
          renderInput={(params) => <TextField {...params} label="Search" />}
        />
      </FormControl>
      {counselorStudents === null ? (
        <></>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#aaa',
              borderRadius: 3,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#E0E0E0',
              borderRadius: 3,
            },
            overflowX: 'hidden',
            p: 1,
            overflow: 'auto',
            maxWidth: '100%',
            height: '45%',
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Edit Task</TableCell>
                <TableCell>Task ID</TableCell>
                <TableCell>Date Entered</TableCell>
                <TableCell>Date Due</TableCell>
                <TableCell>Task Name</TableCell>
                <TableCell>Importance</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentTasks.map((task, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Button
                      disabled={task.externalId !== null}
                      aria-label="done"
                      onClick={() => onEdit(task)}
                    >
                      <EditIcon></EditIcon>
                    </Button>
                  </TableCell>
                  <TableCell>{task.taskId}</TableCell>
                  <TableCell>{task.dateEntered}</TableCell>

                  <TableCell sx={{ width: 300 }}>
                    {editingId === task.taskId ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Due Date"
                          value={task.dueDate}
                          onChange={(newDate) =>
                            handleEditChange(task.taskId, newDate)
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    ) : (
                      <>
                        <span>
                          {new Date(task.dueDate).toLocaleDateString()}
                        </span>
                        <Button
                          sx={{ ml: 2 }}
                          disabled={task.externalId !== null}
                          variant="contained"
                          onClick={() => handleEditClick(task.taskId)}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </TableCell>
                  <TableCell>{task.name}</TableCell>
                  <TableCell>{task.importance}</TableCell>
                  <TableCell>
                    <Button
                      disabled={task.externalId !== null}
                      variant="contained"
                      onClick={() =>
                        deleteTaskHandler(task.taskId, selectedStudents?.id)
                      }
                    >
                      DELETE ENTRY
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export const SearchTasksCounselorLayout = ({ counselorStudents, onEdit }) => {
  return (
    <>
      <SelectedStudentsTasks
        counselorStudents={counselorStudents}
        onEdit={onEdit}
      />
    </>
  );
};
