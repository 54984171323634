import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import AVControl from './AVControl';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '1280px',
    margin: '0 auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  responsiveVideo: {
    width: '100%',
    zIndex: 1,
  },
  borderedRadiusTop: {
    borderRadius: '4px 4px 0 0',
  },
  positionRelative: {
    position: 'relative',
  },
  cameraOff: {
    height: '100%',
    width: '100%',
    zIndex: 99,
    position: 'absolute',
    backgroundColor: 'grey',
    borderRadius: '4px',
    left: 0,
  },
  bgColorGrey: {
    backgroundColor: '#b1b1b1',
  },
}));

const Participant = ({ participant, showName = false, isLocal = false }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioTrackIsOn, setAudioTrackIsOn] = useState(true);
  const [videoTrackIsOn, setVideoTrackIsOn] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();
  const classes = useStyles();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackEnabled', (result) => {
      // need to check this is a video track
      console.log(66, 'track enabled fired');
      console.log(result);
      if (result.kind === 'video') {
        setVideoEnabled(true);
      }
    });
    participant.on('trackDisabled', (result) => {
      // need to check this is a video track
      console.log(70, 'track disabled fired');
      console.log(89, result);
      if (result.kind === 'video') {
        setVideoEnabled(false);
      }
    });

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const handleToggleVideo = () => {
    videoTracks.forEach((track) => {
      if (track.isEnabled) {
        track.disable();
      } else {
        track.enable();
      }
    });

    setVideoTrackIsOn((prevState) => {
      return (prevState = !prevState);
    });
  };

  const handleToggleAudio = () => {
    audioTracks.forEach((track) => {
      if (track.isEnabled) {
        track.disable();
      } else {
        track.enable();
      }
    });

    setAudioTrackIsOn((prevState) => {
      return (prevState = !prevState);
    });
  };

  return (
    <div className={`${classes.positionRelative} ${classes.bgColorGrey}`}>
      {showName && <h3>{participant.identity}</h3>}
      {!videoEnabled && !isLocal && (
        <Box className={classes.cameraOff}>
          <p>User disabled video.</p>
        </Box>
      )}
      <video
        poster="https://i1.wp.com/lanecdr.org/wp-content/uploads/2019/08/placeholder.png?w=1200&ssl=1"
        className={`${classes.responsiveVideo} ${classes.borderedRadiusTop}`}
        ref={videoRef}
        autoPlay={true}
      />
      <audio ref={audioRef} autoPlay={true} muted={false} />
      {isLocal && (
        <>
          <Box
            className={classes.bgColorGrey}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            p={1}
          >
            <AVControl
              type={'audio'}
              trackOn={audioTrackIsOn}
              toggleTrack={handleToggleAudio}
            />
            <Box p={1} />
            <AVControl
              type={'video'}
              trackOn={videoTrackIsOn}
              toggleTrack={handleToggleVideo}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Participant;
