import React, { useState, useEffect, useCallback } from 'react';
import { deleteTaskById, getTaskByExternalId } from '../functions/requests';
import { useCookies } from 'react-cookie';
import TaskLayout from './TaskLayout';
import { useSnackbar } from 'notistack';
import TaskSearch from '../search-components/TaskSearch';

//MUI IMPORTS
import Grid from '@mui/material/Grid';

const IndexLayout = () => {
  const [cookie] = useCookies(['tokens']);
  const [selectedTask, setSelectedTask] = useState(null);
  const [externalId, setExternalId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getTaskById = async () => {
      try {
        setLoading(true);
        const response = await getTaskByExternalId(externalId, cookie);

        if (response.error) {
          enqueueSnackbar(response.message, { variant: 'error' });
        } else {
          setSelectedTask(response);
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    if (externalId) {
      getTaskById();
    }
  }, [cookie, enqueueSnackbar, externalId]);

  const deleteTaskHandler = useCallback(async () => {
    try {
      const deleted = await deleteTaskById(externalId, cookie);
      if (deleted) enqueueSnackbar('Task deleted!');
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setSelectedTask(null);
    }
  }, [cookie, enqueueSnackbar, externalId]);

  const onSearchHandler = (value) => {
    setExternalId(value);
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <TaskSearch onSearch={onSearchHandler} loading={loading} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{ mb: 10 }}
        justifyContent="center"
        alignItems="center"
      >
        {selectedTask && (
          <TaskLayout
            task={selectedTask}
            onDeleteConfirm={() => deleteTaskHandler()}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default IndexLayout;
