import React, { useEffect } from 'react';
import AnalyticsService from '../../../analyticsService';

import { TEXT_FOR_GUIDES } from './constants/text';
import Guides from './Guides';

// MUI IMPORTS
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Layout = () => {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Guides' });
  }, []);

  return (
    <Grid container spacing={5} style={{ position: 'relative' }}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h2">
          {TEXT_FOR_GUIDES.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Guides />
      </Grid>
    </Grid>
  );
};

export default Layout;
