import Joi from 'joi';
import { errorReader, strMessages } from '../../../functions/validation-helpers';

export const validateSATScore = (data) => {
  const schema = Joi.object().keys({
    math: Joi.string().messages(strMessages('Math')).alphanum().required(),
    verbal: Joi.string().messages(strMessages('Verbal')).alphanum().required(),
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

export const validateTOEFLScore = (data) => {
  const schema = Joi.object().keys({
    reading: Joi.string()
      .messages(strMessages('Reading'))
      .alphanum()
      .required(),
    writing: Joi.string()
      .messages(strMessages('Writing'))
      .alphanum()
      .required(),
    speaking: Joi.string()
      .messages(strMessages('Speaking'))
      .alphanum()
      .required(),
    listening: Joi.string()
      .messages(strMessages('Listening'))
      .alphanum()
      .required(),
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

export const validateACTScore = (data) => {
  const schema = Joi.object().keys({
    reading: Joi.string()
      .messages(strMessages('Reading'))
      .alphanum()
      .required(),
    math: Joi.string().messages(strMessages('Math')).alphanum().required(),
    english: Joi.string()
      .messages(strMessages('English'))
      .alphanum()
      .required(),
    science: Joi.string()
      .messages(strMessages('Science'))
      .alphanum()
      .required(),
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};
