import React from 'react';

//MUI IMPORTS
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { attributeKeyForDisplay } from '../../../../utils/attributeKeyForDisplay';

const DynamicTable = ({ name, data }) => {
  const [firstRow] = data;
  const columnHeaders = Object.keys(firstRow.attributes);
  return (
    <Container sx={{ mt: 5 }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="h6">
        {attributeKeyForDisplay(name)}
      </Typography>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columnHeaders.map((col) => {
                return <TableCell>{attributeKeyForDisplay(col)}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d) => {
              const { attributes } = d;
              return (
                <TableRow key={d.id}>
                  {Object.keys(attributes).map((key) => {
                    return (
                      <TableCell
                        key={attributes[key]}
                        component="th"
                        scope="row"
                      >
                        {typeof attributes[key] === 'string'
                          ? attributes[key]
                          : JSON.stringify(attributes[key])}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default DynamicTable;
