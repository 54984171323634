import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';

// MUI ICONS
import EditIcon from '@material-ui/icons/EditOutlined';

import { getCurrentAccess } from 'components/student/functions/requests';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAuthUser } from '../../store/UserContext';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { editUserRole } from './functions';

const CreateUserLink = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/account-admin/register-user');
  };

  return (
    <Grid>
      <Button variant="contained" onClick={handleClick}>
        Create User
      </Button>
    </Grid>
  );
};

const StudentsLink = ({ id }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/account-admin/account/${id}`);
  };

  return (
    <Grid>
      <Button variant="contained" onClick={handleClick}>
        Students
      </Button>
    </Grid>
  );
};

const UserList = () => {
  const [accountAdminUsers, setAccountAdminUsers] = useState([]);
  const [activeEditRoleId, setActiveEditRoleId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [cookie] = useCookies(['tokens']);
  const theme = useTheme();
  const user = useAuthUser();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCurrentAccess(cookie);

      if (typeof response === 'string') {
        enqueueSnackbar(response, { variant: 'error' });
      } else {
        const validRoles = ['account_admin', 'counselor'];
        const filteredResults = response.data.filter((request) => {
          return validRoles.includes(request.attributes.relation);
        });

        const result = filteredResults.map((request) => {
          const { attributes, relationships } = request;
          return { attributes, relationships };
        });

        setAccountAdminUsers(result);
      }
    };

    fetchData();
  }, [enqueueSnackbar, cookie]);

  const handleChange = async (e, id) => {
    try {
      const response = await editUserRole(cookie, id, e.target.value);
      if (typeof response === 'string') {
        enqueueSnackbar(response, { variant: 'error' });
      } else {
        enqueueSnackbar('Role updated!', { variant: 'success' });
        setAccountAdminUsers((prevState) => {
          const newState = [...prevState].map((user) => {
            if (user.relationships?.counselor?.data?.id === id) {
              user.relationships.account_admin = {
                ...user.relationships.counselor,
              };
              delete user.relationships.counselor;
              return user;
            }

            if (user.relationships?.account_admin?.data?.id === id) {
              user.relationships.counselor = {
                ...user.relationships.account_admin,
              };
              delete user.relationships.account_admin;
              return user;
            }

            return user;
          });
          return newState;
        });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setActiveEditRoleId(null);
    }
  };

  return (
    <Container maxWidth>
      <Typography variant="h1" component="h2" mb={2}>
        Users
      </Typography>
      <CreateUserLink />
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountAdminUsers.length > 0 ? (
              <>
                <TableRow
                  sx={{ backgroundColor: theme.palette?.primaryLightV2?.main }}
                  key={user.id}
                >
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Button disabled onClick={() => {}}>
                      <EditIcon></EditIcon>
                    </Button>
                    Admin
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {accountAdminUsers.map((record) => {
                  let role = '';
                  let id = '';
                  let attributes = {
                    firstName: '',
                    lastName: '',
                    email: '',
                  };

                  const {
                    relationships: { counselor, account_admin: accountAdmin },
                  } = record;

                  if (accountAdmin) {
                    attributes = accountAdmin.data.attributes;
                    id = accountAdmin.data.id;
                    role = 'Admin';
                  } else if (counselor) {
                    attributes = counselor.data.attributes;
                    id = counselor.data.id;
                    role = 'Standard';
                  }

                  return (
                    <TableRow key={id}>
                      <TableCell>{attributes?.firstName}</TableCell>
                      <TableCell>{attributes?.lastName}</TableCell>
                      <TableCell>{attributes.email}</TableCell>
                      {activeEditRoleId !== id ? (
                        <>
                          <TableCell>
                            <Button onClick={() => setActiveEditRoleId(id)}>
                              <EditIcon></EditIcon>
                            </Button>
                            {role}
                          </TableCell>
                          <TableCell>
                            {role.toLowerCase() === 'standard' ? (
                              <StudentsLink id={id} />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>
                            <Button onClick={() => setActiveEditRoleId(id)}>
                              <EditIcon></EditIcon>
                            </Button>
                            <FormControl>
                              <InputLabel id="select-role-label">
                                Role
                              </InputLabel>
                              <Select
                                labelId="select-role-label"
                                id="select-role"
                                value={role.toLowerCase()}
                                label="Role"
                                autoWidth
                                sx={{ minWidth: '80px' }}
                                onChange={(e) => handleChange(e, id)}
                              >
                                <MenuItem value={'admin'}>Admin</MenuItem>
                                <MenuItem value={'standard'}>Standard</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            {role.toLowerCase() === 'standard' ? (
                              <StudentsLink id={id} />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell>No current Users.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default UserList;
