import React, { useState } from 'react';
import PageContainer from './PageContainer';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import createEntity from './functions/createEntity';
import getEntities from './functions/getEntities';
import addCounselorToEntity from './functions/addCounselorToEntity';
import { useEffect } from 'react';

const AddEntityForm = () => {
  const [loading, setLoading] = useState(false);
  const [entityName, setEntityName] = useState();
  const [entityDescription, setEntityDescription] = useState();
  const [formErrors, setFormErrors] = useState({
    entityName: null,
    entityDescription: null,
  });
  const [cookie] = useCookies(['tokens']);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const data = {
        name: entityName,
        description: entityDescription,
      };

      const response = await createEntity(data, cookie);

      if (response.error) {
        enqueueSnackbar(response.error, { variant: 'error' });
      } else {
        enqueueSnackbar('Entity created successfully.', { variant: 'success' });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h3>Add Entity Form</h3>
      <Grid container direction="column" justifyContent="center">
        <TextField
          label="Entity Name"
          maxRows={4}
          value={entityName}
          onChange={(event) => setEntityName(event.target.value)}
          variant="filled"
          error={formErrors.entityName}
        />
        <Box sx={{ height: 16 }} />
        <TextField
          label="Entity Description"
          maxRows={4}
          value={entityDescription}
          onChange={(event) => setEntityDescription(event.target.value)}
          variant="filled"
          error={formErrors.entityDescription}
        />
        <Box sx={{ height: 16 }} />
        <Button
          onClick={() => handleSubmit()}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </Grid>
    </>
  );
};

const DisplayEntities = ({ entities }) => {
  const [selectedEntityId, setSelectedEntityId] = useState();
  const [counselorEmail, setCounselorEmail] = useState();
  const [cookie] = useCookies(['tokens']);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddCounselor = async () => {
    try {
      const response = await addCounselorToEntity(
        counselorEmail,
        selectedEntityId,
        cookie,
      );

      if (response?.error) {
        enqueueSnackbar(response.error, { variant: 'error' });
      } else {
        enqueueSnackbar('Added successfully.', { variant: 'success' });
        setCounselorEmail('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <h3>Entities</h3>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
        }}
        component="nav"
      >
        {entities.map((entity) => {
          return (
            <React.Fragment key={entity.id}>
              <ListItemButton
                onClick={() =>
                  setSelectedEntityId((prevState) => {
                    if (prevState === entity.id) return '';
                    return entity.id;
                  })
                }
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={entity.attributes.name} />
                {entity.id === selectedEntityId ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
              <Collapse
                in={entity.id === selectedEntityId}
                timeout="auto"
                unmountOnExit
              >
                <List
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <TextField
                      label="Counselor Email"
                      value={counselorEmail}
                      size="small"
                      onChange={(event) =>
                        setCounselorEmail(event.target.value)
                      }
                    />
                    <Box sx={{ width: 10 }} />
                    <Button
                      onClick={() => handleAddCounselor()}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      SUBMIT
                    </Button>
                  </ListItemButton>
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
};

const Entities = () => {
  const [entities, setEntities] = useState([]);
  const [cookie] = useCookies(['tokens']);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await getEntities(cookie);
        if (response.error) {
          enqueueSnackbar(response.error, { variant: 'error' });
        } else {
          setEntities(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchEntities();
  }, [cookie, enqueueSnackbar]);

  return (
    <PageContainer
      title="Entity Management"
      firstColumn={<AddEntityForm />}
      secondColumn={<DisplayEntities entities={entities} />}
    />
  );
};

export default Entities;
