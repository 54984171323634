import React, { useState, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { signupValidation } from 'utils/joiValidation';
import { registerLink } from 'components/userManagmentFunctions/register';
import { useAuthUser, UserContext } from '../store/UserContext';
import { useHistory } from 'react-router';
import Logo from '../assets/VizeLogo_NoBackgroundNoTM_new.png';
import { useSnackbar } from 'notistack';
import useQuery from 'hooks/useQuery';

// MUI IMPORTS
import { Button, TextField, Box, Grid } from '@mui/material';
import { getCounselorProfileById } from './functions/getCounselorProfileById';

const SignUp = () => {
  const history = useHistory();
  const [, setCookie] = useCookies(['tokens']);
  const { setUserFromToken } = useContext(UserContext);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [counselorId, setCounselorId] = useState('');
  const [counselorData, setCounselorData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  useEffect(() => {
    setCounselorId((prevState) => {
      if (!prevState) {
        const counselorIdQuery = query.get('id');
        if (counselorIdQuery) {
          return counselorIdQuery;
        }
      }
      return prevState;
    });
  }, [query]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCounselorProfileById(counselorId);

        if (response?.error) {
          enqueueSnackbar(response?.error, { variant: 'error' });
        } else {
          setCounselorData(response?.data?.attributes);
        }
      } catch (e) {
        console.warn(e);
      }
    };

    if (counselorId) {
      fetchData();
    }
  }, [counselorId, enqueueSnackbar]);

  const errorSetters = [
    setEmailError,
    setPasswordError,
    setFirstNameError,
    setLastNameError,
    setPhoneNumberError,
  ];

  const clearErrors = () => errorSetters.forEach((setError) => setError(''));

  const validate = (data) => {
    const { message, type, pass } = signupValidation(data);
    switch (type) {
      case 'password':
        setPasswordError(message);
        return pass;
      case 'phoneNumber':
        setPhoneNumberError(message);
        return pass;
      case 'firstName':
        setFirstNameError(message);
        return pass;
      case 'lastName':
        setLastNameError(message);
        return pass;
      case 'email':
        setEmailError(message);
        return pass;
      default:
        return pass;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clearErrors();
    const data = new FormData(event.currentTarget);

    if (data.get('password') !== data.get('rePassword')) {
      setPasswordError('Passwords are not matching');
      return;
    }

    if (
      validate({
        email: data.get('email'),
        password: data.get('password'),
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        phoneNumber: data.get('phoneNumber'),
      })
    ) {
      handleSignUp(data);
    }
  };

  const handleSignUp = async (data) => {
    try {
      const response = await registerLink({
        email: data.get('email'),
        password: data.get('password'),
        first_name: data.get('firstName'),
        last_name: data.get('lastName'),
        phone_number: data.get('phoneNumber'),
        role: 'student',
        counselor_id: counselorId,
      });
      if (response?.error) {
        enqueueSnackbar(response?.error, { variant: 'error' });
      } else {
        const { token, refreshToken } = response.body;
        // success response
        setCookie('tokens', { token, refreshToken });
        await setUserFromToken(token);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  return (
    <Grid item xs={12} sx={{ maxHeight: '100vh' }}>
      <Box
        sx={{
          mt: 15,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img style={{ maxHeight: '100px' }} src={Logo} alt={'Vize Logo'} />
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="firstName"
            error={!!firstNameError}
            helperText={firstNameError}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lastName"
            error={!!lastNameError}
            helperText={lastNameError}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            autoComplete="phoneNumber"
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            autoFocus
            placeholder="5555555555"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            error={!!emailError}
            helperText={emailError}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={!!passwordError}
            helperText={passwordError}
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="rePassword"
            label="Confirm Password"
            type="password"
            id="rePassword"
            autoComplete="current-password"
          />
          {counselorData &&
          'lastName' in counselorData &&
          'firstName' in counselorData ? (
            <TextField
              disabled
              margin="normal"
              required
              fullWidth
              id="Counselor Name"
              value={`${counselorData?.firstName} ${counselorData?.lastName}`}
              autoFocus
            />
          ) : (
            <></>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default SignUp;
