import React from 'react';
import { isEmpty } from 'lodash';
import { ResourceHOC } from './HOC';
import { Container as HonorsContainer } from './Container';
import { useFetch } from '../../profile/functions/hooks';

const honorsURL = '/users/honors?currentPage=1&pageSize=99';

const Honors = () => {
  const {
    resource: honors,
    setResource: setHonors,
    isLoading: isHonorsLoading,
    serverError: honorsError,
  } = useFetch(honorsURL, (data) => {
    return {
      id: data.id,
      ...data.attributes,
    };
  });

  const hasData = isEmpty(honors);

  return (
    <ResourceHOC
      isLoading={isHonorsLoading}
      serverError={honorsError}
      hasData={hasData}
      title="Honors"
      handleNoData={setHonors}
    >
      {honors && <HonorsContainer honors={honors} setHonors={setHonors} />}
    </ResourceHOC>
  );
};

export default Honors;
