import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 900,
    },
  },
}));

const StudentList = ({ students, onStudentSelected, clearList }) => {
  const classes = useStyles();
  return (
    <>
      <ul>
        {students.map((student) => {
          return (
            <li
              key={student.id}
              className={classes.pointer}
              onClick={() => onStudentSelected(student)}
            >
              {student.attributes.firstName} {student.attributes.lastName},{' '}
              {student.attributes.email}
            </li>
          );
        })}
      </ul>
      {students.length !== 0 && (
        <Button variant="contained" onClick={clearList}>
          Clear
        </Button>
      )}
    </>
  );
};

export default StudentList;
