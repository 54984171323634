import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import getUsersByNameOrRole from './functions/getUsersByNameOrRole';
import StudentList from '../StudentList';
import UserSearch from './UserSearch';
import SelectedStudent from './SelectedStudent';
import { useCookies } from 'react-cookie';
import ConfirmationModal from '../ConfirmationModal';

const DeleteAccount = () => {
  // used for setting the current user from the search input
  const [usersList, setUsersList] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  // selected user from search input
  const [selectedStudent, setSelectedStudent] = useState(null);

  const fetchUsersByInput = async (data) => {
    try {
      setLoading(true);
      const response = await getUsersByNameOrRole(cookie, data.role, data.name);
      const jsonResponse = await response.json();
      const users = jsonResponse?.data;
      setUsersList(users);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onSelectedStudent = (currentValue) => {
    setSelectedStudent(currentValue);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '2%' }}>
      <div style={{ width: '50%' }}>
        <h1>Admin User Search and Delete</h1>
        <UserSearch
          onHandleSearchInput={fetchUsersByInput}
          inputKey="name"
          margin="normal"
          name="name"
          label="Student name"
          placeholder="Search users by name"
          type="name"
          id="name"
          helperText="Name can be left empty or have at least 3 letters"
          loading={loading}
        />
      </div>
      <div style={{ width: '50%' }}>
        {usersList?.length > 0 && (
          <StudentList
            students={usersList}
            onStudentSelected={onSelectedStudent}
            clearList={() => {
              setUsersList([]);
            }}
          />
        )}

        {selectedStudent && (
          <Button onClick={() => setModalActive(true)} variant="contained">
            Delete Selected Account
          </Button>
        )}

        {selectedStudent && (
          <SelectedStudent
            student={selectedStudent}
            handleDelete={() => setSelectedStudent(null)}
          />
        )}
      </div>

      {modalActive && (
        <ConfirmationModal
          onCancel={(data) => {
            if (data && data === 'success') {
              setUsersList([]);
              setSelectedStudent(null);
            }

            setModalActive(false);
          }}
          student={selectedStudent}
        />
      )}
    </div>
  );
};

export default DeleteAccount;
