export const regstr = RegExp(/^[a-zA-Z0-9_]+(?:\W+[a-zA-Z0-9_]+)*\W*$/);
export const strMessages = (name) => {
  return {
    'string.pattern.base': `${name} should only contain letters.`,
    'string.base': `${name} should be a type of 'text'`,
    'string.empty': `${name} cannot be an empty field`,
    'string.min': `${name} should have a minimum length of {#limit}`,
    'any.required': `${name} is a required field`,
  };
};

export const dateMessages = {
  'date.base': 'Date Format is YYYY-MM-DD',
  'date.format': 'Date Format is YYYY-MM-DD',
};

export const errorReader = (error) => {
  if (error) {
    console.log({ error });
    const path = error.details[0].path[0];
    return {
      message: error.message,
      type: path,
      pass: false,
    };
  } else {
    return {
      message: 'No Errors',
      type: 'valid',
      pass: true,
    };
  }
};

export const numberMessages = (name, limit) => {
  return {
    'number.pattern.base': `${name} should only contain numbers.`,
    'number.base': `${name} should be a type a number`,
    'number.empty': `${name} cannot be an empty field`,
    'number.min': `${name} should have a minimum value of ${limit[0]}`,
    'number.max': `${name} should have a maximum value of ${limit[1]}`,
  };
};
