import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useStudentName } from '../../store/UserContext';
import { useHistory } from 'react-router-dom';
import getInitialsFromName from '../../utils/getInitialsFromName';

const UserAvatar = () => {
  const history = useHistory();
  const studentName = useStudentName();
  const initials = getInitialsFromName(studentName);

  return (
    <Avatar
      alt={`${studentName} Avatar`}
      sx={{ backgroundColor: '#425ce8' }}
      onClick={() => history.push(`/profile`)}
      style={{ cursor: 'pointer' }}
    >
      {initials}
    </Avatar>
  );
};

export default UserAvatar;
