import { useCookies } from 'react-cookie';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import downloadSubscribers from './functions/downloadSubscribers';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const pages = [
  { name: 'Profile', path: '/admin-profile' },
  { name: 'Students', path: '/admin/students' },
  { name: 'Colleges', path: '/admin-colleges' },
  { name: 'New Task', path: '/admin-new-task' },
  { name: 'Tasks', path: '/admin/tasks' },
];

const Navbar = ({ children }) => {
  const [cookies, , removeCookie] = useCookies(['tokens']);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogOut = () => {
    removeCookie('tokens');
    window.location.reload();
  };

  const handleDownload = async () => {
    try {
      const response = await downloadSubscribers(cookies);
      const blob = await response.blob();

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `subscribers.json`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <main>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Desktop Logo*/}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              Vize
            </Typography>
            {/* Mobile Dropdown*/}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {
                  <MenuItem onClick={handleCloseNavMenu}>Hello</MenuItem>
                  //   pages.map((page) => (
                  //   <MenuItem key={page} onClick={handleCloseNavMenu}>
                  //     <Typography textAlign="center">{page}</Typography>
                  //   </MenuItem>
                  // ))
                }

                <MenuItem download="emails.json" onClick={handleDownload}>
                  Download Emails
                </MenuItem>

                <MenuItem component={RouterLink} to={'/admin-delete-accounts'}>
                  Student Search
                </MenuItem>
              </Menu>
            </Box>
            {/* Mobile Logo*/}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              Vize
            </Typography>
            {/* Desktop NavLink*/}
            {pages.map((page) => (
              <Button
                key={page.name}
                sx={{ my: 2, color: 'white', display: 'block' }}
                component={RouterLink}
                to={page.path}
              >
                {page.name}
              </Button>
            ))}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                onClick={handleDownload}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Download Emails
              </Button>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                component={RouterLink}
                to={'/admin-delete-accounts'}
              >
                Student search
              </Button>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                component={RouterLink}
                to={'/admin/entities'}
              >
                Manage Entities
              </Button>
              <Button
                onClick={handleLogOut}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Logout
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </main>
  );
};
export default Navbar;
