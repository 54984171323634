import * as React from 'react';

//MUI IMPORTS
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function BasicList({ student }) {
  const attributes = Object.entries(student);
  const left = attributes.slice(0, attributes.length / 2);
  const right = attributes.slice(attributes.length / 2);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <List>
          {attributes &&
            left.map((attr) => {
              return (
                <div key={attr[0]}>
                  <ListItem>
                    <ListItemText
                      primary={attr[0]}
                      secondary={attr[1] ?? 'No data'}
                    />
                  </ListItem>
                  <Divider sx={{ width: '80%' }} />
                </div>
              );
            })}
        </List>
      </Grid>
      <Grid item xs={6}>
        <List>
          {attributes &&
            right.map((attr) => {
              return (
                <div key={attr[0]}>
                  <ListItem>
                    <ListItemText
                      primary={attr[0]}
                      secondary={attr[1] ?? 'No data'}
                    />
                  </ListItem>
                  <Divider sx={{ width: '80%' }} />
                </div>
              );
            })}
        </List>
      </Grid>
    </Grid>
  );
}
