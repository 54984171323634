import React, { useState } from 'react';
import { NotificationsUi } from './NotificationUi';
import { useNotificationsContext } from 'store/NotificationsContext';

// MUI IMPORTS
import { Box, Typography, Paper, Grid } from '@mui/material';
import Switch from '@mui/material/Switch';

const Bold = ({ children }) => {
  return (
    <Box sx={{ display: 'inline', fontWeight: 'bold' }} inline>
      {children}
    </Box>
  );
};

export const DrawerAndNavNotifications = () => {
  const { studentNotifications, unseen, handleHasSeen, unreadNotifications } =
    useNotificationsContext();
  const [switchChecked, setSwitchChecked] = useState(false);

  const handleChange = () => {
    setSwitchChecked((prevState) => {
      return !prevState;
    });
  };

  return (
    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
      <Typography sx={{ p: 1 }} variant="h4" component="h2">
        <Bold>Notifications</Bold>
      </Typography>
      <Grid
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
            width: 700,
            height: 700,
          },
        }}
      >
        <Paper
          elevation={3}
          sx={{
            '&::-webkit-scrollbar': {
              width: 7,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#aaa',
              borderRadius: 3,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#E0E0E0',
              borderRadius: 3,
            },
            overflowX: 'hidden',
            p: 1,
            height: '550px',
            overflow: 'auto',
          }}
        >
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>All Notifications</Grid>
            <Grid item>
              <Switch
                defaultChecked
                color="default"
                checked={switchChecked}
                onChange={handleChange}
                value={switchChecked}
              />
            </Grid>
            <Grid item>Unread Notifications</Grid>
          </Grid>
          {switchChecked === false ? (
            <NotificationsUi
              studentNotifications={studentNotifications}
              variant="version2"
            />
          ) : (
            <NotificationsUi
              studentNotifications={unreadNotifications}
              variant="version2"
            />
          )}
        </Paper>
      </Grid>
    </Box>
  );
};
