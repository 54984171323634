import React, { useEffect } from 'react';
import DownloadButton from './DownloadButton';
import Document from './ProfileDocument';
import AnalyticsService from '../../../analyticsService';

export const Layout = () => {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'About' });
  }, []);

  return (
    <div>
      <DownloadButton size={'large'} variant={'contained'}>
        <Document />
      </DownloadButton>
    </div>
  );
};
