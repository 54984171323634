export const getColleges = async (cookies, page, searchQuery) => {
  let url = `/colleges/all?currentPage=${page}`;

  if (searchQuery !== '') {
    url = url + `&name=${searchQuery}`;
  }

  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  return await response.json();
};

export const postUserCollege = async (cookies, body) => {
  let url = `/users/bookmark`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  const response = await fetch(url, configObj);
  return await response.json();
};

export const postCollegeSurvey = async (cookies, body) => {
  let url = `/colleges/college-survey`;

  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  const response = await fetch(url, configObj);

  if (response.status === 201) {
    return await response.json();
  }

  if (response.status === 422) {
    return 'invalid parameters';
  }

  return 'server error';
};

export const deleteUserCollege = async (cookies, id) => {
  let url = `/users/bookmark/${id}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'DELETE',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);
  return response;
};

export const getSearchColleges = async (cookies, searchTerm) => {
  let url = `/colleges/search?name=${searchTerm}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  try {
    const response = await fetch(url, {
      headers: myHeaders,
    });
    const {
      error,
      body: { data },
    } = await response.json();
    if (error) {
      return { data: [], error: error.message };
    }
    return { data, status: response.status };
  } catch (e) {
    return { data: [], error: e.message };
  }
};
