import React, { useState, useCallback, useEffect } from 'react';
import Lobby from './Lobby';
import Room from './Room';
import { useCookies } from 'react-cookie';
import useQuery from '../../hooks/useQuery';
import Video from 'twilio-video';
import createSessionToken from './createSessionToken';
import ErrorMessage from '../ErrorMessage';

const DEFAULT_ERROR_CONNECTING_MESSAGE =
  'Unable to connect to session...please reload and try again.';

const VideoChat = () => {
  const [roomName, setRoomName] = useState('');
  const [room, setRoom] = useState(null);
  // potentially need to clean up how these cookies are used
  const [cookie] = useCookies(['tokens']);
  const [connecting, setConnecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableRoomName, setDisableRoomName] = useState(false);
  const query = useQuery();

  // get the room name from query param on load
  // ...disable the room name if received from query
  useEffect(() => {
    const setRoomIdFromQuery = () => {
      try {
        const roomIdQueryParam = query.get('roomId');
        setRoomName(roomIdQueryParam);
        if (roomIdQueryParam) {
          setDisableRoomName(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    setRoomIdFromQuery();
  }, [query]);

  const handleRoomNameChange = useCallback((event) => {
    setRoomName(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      let token;
      event.preventDefault();
      setConnecting(true);
      try {
        const response = await createSessionToken(roomName, cookie);
        const jsonResponse = await response.json();

        if (response.status === 200 || response.status === 201) {
          token = jsonResponse.token;
        } else {
          throw new Error('Unable to create session token.');
        }
      } catch (err) {
        console.log(err);
        setConnecting(false);
        setErrorMessage(DEFAULT_ERROR_CONNECTING_MESSAGE);
      }

      Video.connect(token, {
        name: roomName,
      })
        .then((room) => {
          setConnecting(false);
          setRoom(room);
        })
        .catch((err) => {
          console.log(err);
          setConnecting(false);
          setErrorMessage(DEFAULT_ERROR_CONNECTING_MESSAGE);
        });
    },
    [roomName, cookie],
  );

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
  }, []);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener('pagehide', tidyUp);
      window.addEventListener('beforeunload', tidyUp);
      return () => {
        window.removeEventListener('pagehide', tidyUp);
        window.removeEventListener('beforeunload', tidyUp);
      };
    }
  }, [room, handleLogout]);

  let render;
  if (room) {
    render = (
      <Room roomName={roomName} room={room} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <>
        {errorMessage?.length && <ErrorMessage message={errorMessage} />}
        <Lobby
          roomName={roomName}
          handleRoomNameChange={handleRoomNameChange}
          handleSubmit={handleSubmit}
          connecting={connecting}
          roomNameDisabled={disableRoomName}
        />
      </>
    );
  }

  return render;
};

export default VideoChat;
