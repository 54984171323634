import Joi from 'joi';
import { capitalize } from '../../../../utils/capitalize';

const joi = Joi;
const joiString = joi.string();
const nameValidation = RegExp("^[a-zA-Z][a-zA-Z .,'-]*$");
const firstName = joiString.regex(nameValidation).min(2).max(30).required();
const lastName = joiString.regex(nameValidation).min(2).max(30).required();

const email = joiString
  .email({ tlds: { allow: false } })
  .min(5)
  .max(50)
  .required();

// const address = Joi.string()
//   .trim()
//   .regex(/^[a-z\d\s\-\.\,]*$/i)
//   .max(100)
//   .required();

const password = joiString.min(8).required();
// const role = joiString.required();

const searchUnderScore = /'_'/g;
const replaceUnderScore = ' ';

const joiErrorFormatter = (error) =>
  capitalize(
    error
      ?.toString()
      .replace(/"|ValidationError:/g, '')
      .replace(searchUnderScore, replaceUnderScore),
  );

const validateSignUp = (data) => {
  const schema = joi.object().keys({
    first_name: firstName,
    last_name: lastName,
    email,
    password,
  });

  const { error } = schema.validate(data);
  if (error) {
    let type = '';
    const errorMessage = joiErrorFormatter(error);

    if (errorMessage.includes('Password')) {
      type = 'password';
    } else if (errorMessage.includes('Email')) {
      type = 'email';
    } else if (errorMessage.includes('First name')) {
      type = 'firstName';
    } else if (errorMessage.includes('Last name')) {
      type = 'lastName';
    } else {
      type = 'valid';
    }

    return {
      message: errorMessage,
      type: type,
      pass: false,
    };
  }
  return {
    message: 'No Errors',
    type: 'valid',
    pass: true,
  };
};

export { validateSignUp };
