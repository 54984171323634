import React, { useState } from 'react';
import ModalDialog from '../ModalDialog';
import CollegeContent from './CollegeContent';

//MUI IMPORTS
import {
  Box,
  Grid,
  Card,
  Divider,
  Typography,
  IconButton,
  CardContent,
  CardActions,
  Checkbox,
  Avatar,
} from '@mui/material';

import CollegeLogo from '../../../components/CollegeLogo';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const TaskCard = ({
  id,
  college,
  name,
  description,
  dueDate,
  completed,
  completedDate,
  handleComplete,
  importance,
  notes,
  type,
  variant = 'version1',
}) => {
  const [open, setOpen] = useState(false);

  if (variant === 'version2') {
    return (
      <Grid>
        <Card
          elevation={0}
          sx={{
            overflow: 'visible',
            width: '100%',
            marginTop: '10px',
            borderRadius: '15px',
            border: '3px solid',
            borderColor: '#1A3AD5',
            position: 'relative',
            pb: '4',
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={2} alignItems="end">
                <Box
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: 'rgba(69, 95, 232, 1)',
                    backgroundColor: 'textPrimary',
                    borderRadius: '4px',
                    textDecoration: 'none',
                    textAlign: 'center',
                  }}
                >
                  {dueDate
                    .toLocaleString('default', { month: 'long' })
                    .substring(0, 3)}
                  <br />
                  {dueDate.getDate()}
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    borderColor: '#8F8D93',
                    borderLeftWidth: '3px',
                    borderRightWidth: '0',
                    height: '80%',
                  }}
                />
              </Grid>
              <Grid container item xs={6}>
                <Grid container item xs={12} justifyContent="space-between">
                  <Typography variant="h6" component="h2">
                    {name}
                  </Typography>
                  {college && (
                    <Typography variant="body2" component="p">
                      {college.data?.attributes?.name}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={1}>
                {/* <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  display: { xs: 'none', md: 'block' },
                  marginLeft: { sm: '3rem', lg: '5rem' },
                  borderColor: '#8F8D93',
                  borderLeftWidth: '3px',
                  borderRightWidth: '0',
                  height: '70%',
                }}
              /> */}
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    sx={{ mt: '1rem', transform: 'scale(2)' }}
                    value={id}
                    checked={completed}
                    onChange={(e) => handleComplete(e)}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions
            sx={{
              overflow: 'hidden',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
              backgroundColor: completed ? '#1A3AD5' : '#BDD1F9',
              py: 0,
              my: 0,
            }}
          >
            {completed ? (
              <Typography
                sx={{ flexGrow: 1, fontSize: '1rem', ml: 2, mt: 1 }}
                gutterBottom
                variant="h6"
                component="div"
                color="white"
              >
                Completed on {completedDate.toLocaleDateString('en-US')}
              </Typography>
            ) : (
              <Box sx={{ flexGrow: 1 }}></Box>
            )}

            <IconButton
              onClick={() => setOpen(true)}
              aria-label="expand more options"
              sx={{ color: completed ? 'white' : '#1A3AD5' }}
            >
              <MoreHorizIcon sx={{ transform: 'scale(2)' }} />
            </IconButton>

            <ModalDialog
              isOpen={open}
              handleClose={() => setOpen(false)}
              content={<CollegeContent why={importance} how={notes} />}
              title={name}
            />
          </CardActions>
        </Card>
      </Grid>
    );
  }

  return (
    <Card
      elevation={0}
      sx={{
        overflow: 'visible',
        width: '95%',
        marginTop: '10px',
        borderRadius: '15px',
        border: '3px solid',
        borderColor: '#1A3AD5',
        position: 'relative',
        pb: '4',
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={2} alignItems="end">
            <Box
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: 'rgba(69, 95, 232, 1)',
                backgroundColor: 'textPrimary',
                borderRadius: '4px',
                textDecoration: 'none',
                textAlign: 'center',
              }}
            >
              {dueDate
                .toLocaleString('default', { month: 'long' })
                .substring(0, 3)}
              <br />
              {dueDate.getDate()}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                display: { xs: 'none', md: 'block' },
                borderColor: '#8F8D93',
                borderLeftWidth: '3px',
                borderRightWidth: '0',
                height: '80%',
              }}
            />
          </Grid>
          <Grid container item xs={6}>
            <Grid container item xs={12} justifyContent="space-between">
              <Typography variant="h6" component="h2">
                {name}
              </Typography>
              {college && (
                <Typography variant="body2" component="p">
                  {college.data?.attributes?.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                {description}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                display: { xs: 'none', md: 'block' },
                marginLeft: { lg: '5rem' },
                borderColor: '#8F8D93',
                borderLeftWidth: '3px',
                borderRightWidth: '0',
                height: '80%',
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {college ? (
                <Avatar
                  sx={{
                    width: '5rem',
                    height: '5rem',
                    borderRadius: '50%',
                    border: '3px solid',
                    borderColor: '#1A3AD5',
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: '-2.5rem',
                    zIndex: '10',
                  }}
                >
                  <CollegeLogo
                    name={college?.data?.attributes?.name}
                    ceeb_code={college?.data?.id}
                  />
                </Avatar>
              ) : (
                <Avatar
                  sx={{
                    width: '5rem',
                    height: '5rem',
                    borderRadius: '50%',
                    border: '3px solid',
                    borderColor: '#1A3AD5',
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: '-2.5rem',
                    zIndex: '10',
                  }}
                >
                  <SchoolOutlinedIcon
                    sx={{ color: 'black', transform: 'scale(2.5)' }}
                  />
                </Avatar>
              )}
              <Checkbox
                sx={{ mt: '2.5rem', transform: 'scale(2)' }}
                value={id}
                checked={completed}
                onChange={(e) => handleComplete(e)}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions
        sx={{
          overflow: 'hidden',
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
          backgroundColor: completed ? '#1A3AD5' : '#BDD1F9',
          py: 0,
          my: 0,
        }}
      >
        {completed ? (
          <Typography
            sx={{ flexGrow: 1, fontSize: '1rem', ml: 2, mt: 1 }}
            gutterBottom
            variant="h6"
            component="div"
            color="white"
          >
            Completed on {completedDate.toLocaleDateString('en-US')}
          </Typography>
        ) : (
          <Box sx={{ flexGrow: 1 }}></Box>
        )}

        <IconButton
          onClick={() => setOpen(true)}
          aria-label="expand more options"
          sx={{ color: completed ? 'white' : '#1A3AD5' }}
        >
          <MoreHorizIcon sx={{ transform: 'scale(2)' }} />
        </IconButton>

        <ModalDialog
          isOpen={open}
          handleClose={() => setOpen(false)}
          content={<CollegeContent why={importance} how={notes} />}
          title={name}
        />
      </CardActions>
    </Card>
  );
};

export default TaskCard;
