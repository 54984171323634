import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import useQuery from 'hooks/useQuery';
import { getDateTakenResults } from 'components/counselor/functions';
import { TotalTestGraphs } from 'components/counselor/studentExamReport';
import { subtractDays, formatDateISO } from 'utils/dates';
import { useAuthUser } from 'store/UserContext';

//MUI IMPORTS
import {
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';

const useStyles = makeStyles((theme) => {
  const headerStyles = {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      margin: '0 0 0 -20px',
      top: 0,
      left: 0,
      width: '75%',
      height: '100%',
      // transform: 'skew(-20deg)',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      margin: '0 0 0 0px',
      bottom: 0,
      right: 0,
      width: '35%',
      height: '100%',
      // background: '#ccccff',
      // transform: 'skew(-10deg)',
    },
  };
  return {
    testHeader: {
      ...headerStyles,
      backgroundColor: '#BDD1F9',
      backgroundImage:
        '-webkit-linear-gradient(150deg, #BDD1F9 30%, #66b3ff 30%)',
    },
  };
});

export const DashboardTestGraphs = ({ testType }) => {
  const [testDates, setTestDates] = useState([]);
  const date = 7;
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();
  const user = useAuthUser();
  const studentId = user.id;

  useEffect(() => {
    const getCumulativeResults = async () => {
      const dateString = formatDateISO(subtractDays(date));
      const response = await getDateTakenResults(
        cookie,
        studentId,
        dateString,
        testType,
        3,
      );

      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else if (testType === 'sat') {
        const datesFromResponse = response.data.map((item) => {
          return {
            id: item.id,
            dateTaken: item.attributes.dateTaken,
            testType: item.attributes.testType,
            verbalScore: item.attributes.verbalScore,
            mathScore: item.attributes.mathScore,
            totalScore: item.attributes.totalScore,
          };
        });
        setTestDates(datesFromResponse);
      } else if (testType === 'act') {
        const datesFromResponse = response.data.map((item) => {
          return {
            id: item.id,
            dateTaken: item.attributes.dateTaken,
            testType: item.attributes.testType,
            mathScore: item.attributes.mathScore,
            englishScore: item.attributes.englishScore,
            readingScore: item.attributes.readingScore,
            scienceScore: item.attributes.scienceScore,
            compositeScore: item.attributes.compositeScore,
          };
        });
        setTestDates(datesFromResponse);
      }
    };

    if (typeof date === 'number') {
      getCumulativeResults();
    }
  }, [date, cookie, studentId, enqueueSnackbar, testType]);

  return (
    <Grid container>
      <strong>
        <TotalTestGraphs
          variant={'version2'}
          testType={testType}
          graphData={testDates.map((item) => {
            return {
              date: new Date(item.dateTaken).toLocaleDateString('en-CA'),
              Score: item.totalScore,
            };
          })}
        />
      </strong>
    </Grid>
  );
};

export const DashboardCumulativeScores = ({ defaultState, sId }) => {
  const [studentId, setStudentId] = useState(() => {
    if (sId) return sId;
    return;
  });
  const [testType, setTestType] = useState(() => {
    if (defaultState) {
      const { type } = defaultState;
      return type;
    }
    return 'sat';
  });
  const query = useQuery();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setStudentId((prevState) => {
      if (!prevState) {
        const studentIdQuery = query.get('studentId');
        if (studentIdQuery) {
          return studentIdQuery;
        }
      }
      return prevState;
    });
  }, [query]);

  return (
    <Card
      sx={{
        height: '350px',
        borderRadius: 5,
        maxWidth: '100%',
      }}
    >
      <CardHeader
        sx={{ height: '90px' }}
        className={classes.testHeader}
        avatar={<PercentOutlinedIcon fontSize="large" />}
        action={
          <Grid alignItems={'right'}>
            <Typography variant="h5" textAlign="right" sx={{ mt: 2 }}>
              Tests
            </Typography>
          </Grid>
        }
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h5">ACT/SAT Tests</Typography>
          </Box>
        }
      ></CardHeader>

      <CardContent
        sx={{
          '&::-webkit-scrollbar': {
            width: 10,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#aaa',
            borderRadius: 3,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#E0E0E0',
            borderRadius: 3,
          },
          overflowX: 'hidden',
          height: '220px',
          overflow: 'auto',
        }}
      >
        <DashboardTestGraphs testType={testType} />
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push('/exam-reports');
          }}
        >
          View More
        </Button>

        {''}
      </CardActions>
    </Card>
  );
};
