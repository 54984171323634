import React from 'react';
import ExamIcon from '../../assets/students-icon.png';

const StudentsIcon = () => {
  return (
    <img src={ExamIcon} alt={'Survey Icon'} width={'25px'} height={'25px'} />
  );
};

export default StudentsIcon;
