import React, { useState, useEffect } from 'react';
import { validateCourses } from './functions/validations';
import CourseSelect from './CourseSelect';

// MUI IMPORTS
import { Box, Stack, Button, TextField } from '@mui/material';

const Form = ({ id, initialCW, handleRequest, loading, setLoading }) => {
  const [courseWork, setCourseWork] = useState(initialCW);

  useEffect(() => {
    if (id) {
      setCourseWork(initialCW);
    }
  }, [initialCW, id]);

  const [gradeError, setGradeError] = useState('');
  const [nameError, setNameError] = useState('');
  const [semesterError, setSemesterError] = useState('');

  const errorSetters = [setSemesterError, setNameError, setGradeError];

  const handleChange = (value, label) => {
    errorSetters.forEach((setError) => setError(''));
    setCourseWork((prev) => {
      return { ...prev, [label]: value };
    });
  };

  const validate = (data) => {
    if (courseWork.semester1 === '' && courseWork.semester2 === '') {
      setSemesterError('At least one semester grade is required');
      return;
    } else setSemesterError('');

    const { message, type, pass } = validateCourses(data);
    switch (type) {
      case 'name':
        setNameError(message);
        return pass;
      case 'grade':
        setGradeError(message);
        return pass;
      default:
        return pass;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, gradeYear } = courseWork;
    if (validate({ name, gradeYear })) {
      setLoading(true);
      handleRequest(courseWork);
    }
  };

  const grades = ['9th', '10th', '11th', '12th', 'Other'];
  const semesterGrades = [
    'A+',
    'A',
    'A-',
    'B+',
    'B',
    'B-',
    'C+',
    'C',
    'C-',
    'D+',
    'D',
    'D-',
    'F',
    'IP',
    'INC',
  ];

  return (
    <Box component="form" onSubmit={(e) => handleSubmit(e)} noValidate>
      <Stack spacing={3}>
        <TextField
          key="name"
          id="filled-multiline-flexible"
          label="Name"
          value={courseWork.name}
          onChange={(event) => handleChange(event.target.value, 'name')}
          variant="filled"
          helperText={nameError}
          error={!!nameError}
        />
        <CourseSelect
          value={courseWork.gradeYear}
          handleChange={(event) => handleChange(event, 'gradeYear')}
          options={grades}
          title={'Year'}
          error={gradeError}
        />
        <small>
          If your school only provides yearly grades, please enter them as
          Semester 2 and leave Semester 1 blank.
        </small>
        <CourseSelect
          value={courseWork.semester1}
          handleChange={(event) => handleChange(event, 'semester1')}
          options={semesterGrades}
          title={'Semester 1'}
          error={semesterError}
        />

        <CourseSelect
          value={courseWork.semester2}
          handleChange={(event) => handleChange(event, 'semester2')}
          options={semesterGrades}
          title={'Semester 2'}
          error={semesterError}
        />
        <Button sx={{ mx: 5, my: 2 }} type="submit" variant="contained">
          {loading ? 'loading' : 'Submit'}
        </Button>
      </Stack>
    </Box>
  );
};

export default Form;
