import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

const setHeaders = (cookie) => {
  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};

const setUrl = (url) => {
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  return url;
};

export const useFetch = (url, dataMappingCallback) => {
  const [cookie] = useCookies(['token']);
  const [resource, setResource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    const uri = setUrl(url);
    const myHeaders = setHeaders(cookie);
    const configObj = {
      method: 'GET',
      headers: myHeaders,
    };

    setIsLoading(true);
    const getData = async () => {
      try {
        const res = await fetch(uri, configObj);

        if (res.status === 404) {
          setResource(null);
          return;
        }

        let json = await res.json();

        let data;
        if (json.data) {
          data = json.data;
        } else {
          data = json.body.data;
        }

        if (data === null || data === undefined || data.length === 0) {
          setResource([]);
          return;
        }

        if (Array.isArray(data)) {
          const mappedData = data.map(dataMappingCallback);
          setResource(mappedData);
        } else if (typeof data === 'object') {
          setResource(dataMappingCallback(data));
        } else {
          setResource(data);
        }
      } catch (e) {
        setServerError(
          `Unable to process API request to: ${url} REASON: ${e.message}`,
        );
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [url, cookie]);

  return { resource, setResource, isLoading, serverError };
};
