import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useUserRole } from './UserContext';
import { getStudentNotifications } from 'components/student/functions/requests';
import { patchUpdateHasSeenStudentNotification } from 'components/student/functions/requests';

const initialState = {
  studentNotifications: [],
  unseen: 0,
  handleHasSeen: () => {},
};

export const NotificationsContext = createContext(initialState);

const NotificationsProvider = ({ children }) => {
  const [studentNotifications, setStudentNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [unseen, setUnseen] = useState(0);
  const [cookie] = useCookies(['token']);
  const role = useUserRole();

  useEffect(() => {
    const getAllStudentNotifications = async () => {
      const response = await getStudentNotifications(cookie);
      if (response?.data) {
        const notifications = response.data.map((notification) => {
          const { created_at, message, seen_at } = notification.attributes;
          const { id } = notification;
          return {
            id,
            created_at,
            message,
            seen_at,
          };
        });
        setStudentNotifications(notifications);
      }
    };
    if (role === 'student') {
      getAllStudentNotifications();
    }
  }, [cookie, role]);

  useEffect(() => {
    const calculatedHasSeen = studentNotifications.reduce((acc, curr) => {
      if (curr.seen_at === null) {
        acc++;
      }
      return acc;
    }, 0);
    setUnseen(calculatedHasSeen);
  }, [studentNotifications]);

  useEffect(() => {
    const filteredItems = studentNotifications.filter((notification) => {
      return notification.seen_at === null;
    });
    setUnreadNotifications(filteredItems);
  }, [studentNotifications]);

  const handleHasSeen = async (id) => {
    try {
      const response = await patchUpdateHasSeenStudentNotification(id, cookie);
      if (response === true) {
        setStudentNotifications((prevState) => {
          const foundIndex = prevState.findIndex((notification) => {
            return notification.id === id;
          });
          if (foundIndex >= 0) {
            const newState = [...prevState];
            newState[foundIndex].seen_at = new Date().toISOString();
            return newState;
          }
          return prevState;
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const state = {
    studentNotifications,
    unseen,
    handleHasSeen,
    unreadNotifications,
  };

  return (
    <NotificationsContext.Provider value={state}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => {
  const { studentNotifications, unseen, handleHasSeen, unreadNotifications } =
    useContext(NotificationsContext);
  return { studentNotifications, unseen, handleHasSeen, unreadNotifications };
};

export default NotificationsProvider;
