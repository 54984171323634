import React from 'react';

// MUI IMPORTS
import { TextField } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const DateTaken = ({ date, setDate, setError, maxDate }) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label="Date Taken"
        value={date}
        onChange={(newValue) => {
          setError('');
          setDate(newValue);
        }}
        maxDate={maxDate}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default DateTaken;
