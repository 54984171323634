import React from 'react';
import { ResourceHOC } from './HOC';
import { useFetch } from '../../profile/functions/hooks';
import { isEmpty } from 'lodash';

import ActivitiesContainer from './Container';

const activitiesURL = '/users/extra-curricular?pageSize=99&currentPage=1';

const Activities = () => {
  const {
    resource: activities,
    setResource: setActivities,
    isLoading: isActivitiesLoading,
    serverError: activitiesError,
  } = useFetch(activitiesURL, (data) => {
    return {
      id: data.id,
      ...data.attributes,
    };
  });

  const hasData = isEmpty(activities);

  return (
    <ResourceHOC
      isLoading={isActivitiesLoading}
      serverError={activitiesError}
      hasData={hasData}
      title="Activities"
      handleNoData={setActivities}
    >
      {activities && (
        <ActivitiesContainer
          activities={activities}
          setActivities={setActivities}
        />
      )}
    </ResourceHOC>
  );
};

export default Activities;
