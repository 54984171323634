import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';

//MUI Imports
import { theme } from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import './css/transition.css';

const snackConfig = {
  vertical: 'bottom',
  horizontal: 'right',
  autoHideDuration: 2000,
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={2} anchorOrigin={snackConfig}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </CookiesProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
