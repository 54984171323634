import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import {
  useAuthUserEntity,
  useStudentName,
  useUserRole,
} from '../../store/UserContext';

import { Edit } from 'react-iconly';
import { getCurrentYear } from 'utils/dates';

// SYTLED COMPONENTS
const NavBadge = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  // height: '82px',
  borderRadius: '15px',
  backgroundColor: '#EDF1FF',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  position: 'relative',
  margin: '0 29px',
}));

const NavStudentBadge = () => {
  const studentName = useStudentName();
  const history = useHistory();
  const currentYear = getCurrentYear();
  const role = useUserRole();
  const userEntity = useAuthUserEntity();

  const handleEditUser = () => {
    history.push('/profile');
  };

  return (
    <NavBadge
      sx={{
        alignItems: 'center',
        paddingRight: '41px',
        paddingLeft: '15px',
        paddingBottom: '10px',
        paddingTop: '10px',
      }}
    >
      <Stack>
        <Edit
          onClick={handleEditUser}
          set="light"
          color="#455FE8"
          style={{
            height: '20px',
            position: 'absolute',
            right: '18px',
            top: '18px',
          }}
        />
        <Typography variant="body">
          {' '}
          <strong>{studentName}</strong>
        </Typography>
        <Typography>
          {' '}
          {role === 'student' ? `${currentYear} Applicant` : ''}{' '}
        </Typography>
        {role === 'account_admin' ? (
          <Chip
            sx={{ maxWidth: 60 }}
            label="Admin"
            size="small"
            color="error"
          />
        ) : (
          <></>
        )}

        {role === 'counselor' ? (
          <Chip
            sx={{ maxWidth: 75 }}
            label="Standard"
            size="small"
            color="error"
          />
        ) : (
          <></>
        )}
        <Typography sx={{ pt: 1 }}>{userEntity?.name}</Typography>
      </Stack>
    </NavBadge>
  );
};

export default NavStudentBadge;
