import jwtDecoder from "utils/jwtDecoder";

const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

const myHeaders = (cookies) => {
  const headers = new Headers();
  headers.append('token', cookies.tokens.token);
  headers.append('Content-Type', 'application/json');
  return headers;
};

export const purchaseAppAccess = async (cookies) => {
    let url = `/payment/app-access`;
  
    if (NODE_ENV !== 'development') {
      url = REACT_APP_API_DOMAIN + url;
    }

    const headers = myHeaders(cookies);

    // the current api users the userId passed in to the header
    const userId = jwtDecoder(cookies?.tokens?.token).user?.id;
    if (!userId) throw new Error('Missing userId from token.');

    headers.append('userId', userId)
  
    const configObj = {
      method: 'POST',
      headers: headers,
    };
  
    const response = await fetch(url, configObj);

    if (response.status === 200) {
        return response.json();
    }

    throw Error('Unable to navigate to store.')
  };