import Joi from 'joi';
import {
  strMessages,
  regstr,
  errorReader,
} from '../../functions/validation-helpers';

// const strongPasswordRegex = RegExp(
//   '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
// );

// const strongPasswordMessage = Object.assign(strMessages('Password'), {
//   'string.pattern.base': `Password must be strong. At least one upper case alphabet. At least one lower case alphabet. At least one digit. At least one special character. Minimum eight in length`,
// });

const password = Joi.string()
  .min(8)
  .messages(strMessages('Password'))
  .required();

const firstName = Joi.string()
  .regex(regstr)
  .min(2)
  .max(30)
  .messages(strMessages('First Name'))
  .allow(null, '');

const lastName = Joi.string()
  .regex(regstr)
  .min(2)
  .max(30)
  .messages(strMessages('Last Name'))
  .allow(null, '');

const cityRegex = RegExp(
  "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$",
);

const city = Joi.string()
  .regex(cityRegex)
  .min(2)
  .max(30)
  .messages(
    Object.assign(strMessages('City'), {
      'string.pattern.base': 'Please input a valid City Name.',
    }),
  )
  .allow(null, '');

const gender = Joi.string()
  .regex(regstr)
  .min(2)
  .max(30)
  .messages(strMessages('Gender'))
  .allow(null, '');

const pronoun = Joi.string()
  .messages({ 'string.empty': 'Pronoun is not allowed to be empty' })
  .allow(null, '');

const stateCodeRegex = RegExp(
  '^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$',
);

const state = Joi.string()
  .regex(stateCodeRegex)
  .min(2)
  .max(2)
  .messages(
    Object.assign(strMessages('State Code'), {
      'string.pattern.base':
        'State Code has to be valid US state. i.e. WA, KS, NY',
    }),
  )
  .allow(null, '');

const email = Joi.string()
  .email({ tlds: { allow: false } })
  .messages(
    Object.assign(strMessages('Email'), {
      'string.email': 'Email needs to be a valid format',
    }),
  )
  .min(5)
  .max(50)
  .allow(null, '');

const phoneNumber = Joi.string()
  .regex(/^[0-9]{10}$/)
  .messages(
    Object.assign(strMessages('Phone Number'), {
      'string.pattern.base': `Phone number must have 10 digits.`,
    }),
  )
  .allow(null, '');

const grade = Joi.string()
  .valid('9th', '10th', '11th', '12th', 'Other')
  .messages(
    Object.assign(strMessages('Current Grade'), {
      'any.only': `Has to be one of the following: '9th', '10th', '11th', '12th', 'Other'`,
    }),
  )
  .allow(null, '');

const zipCodeRegex = RegExp('^[0-9]{5}(-[0-9]{4})?$');

const zipCode = Joi.string()
  .regex(zipCodeRegex)
  .messages(
    Object.assign(strMessages('Zip Code'), {
      'string.pattern.base': `Must be valid Zip Code format i.e. 90210 or 98201-9912`,
    }),
  )
  .allow(null, '');

const role = Joi.string().allow(null, '');

const validatePasswordEdit = (data) => {
  const schema = Joi.object().keys({
    password: password,
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

const validateProfileEdit = (data) => {
  const schema = Joi.object().keys({
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone_number: phoneNumber,
    grade: grade,
    city: city,
    gender: gender,
    pronoun: pronoun,
    state: state,
    zip_code: zipCode,
    role: role,
  });

  const { error } = schema.validate(data);
  return errorReader(error);
};

const majorChoice = Joi.string()
  .min(1)
  .max(60)
  .regex(regstr)
  .messages(
    Object.assign(strMessages('Major'), {
      'string.pattern.base': `Must be valid major name i.e. Computer Science`,
    }),
  )
  .required();

const favorite = Joi.string()
  .min(1)
  .max(60)
  .messages(
    Object.assign(strMessages('Favorite'), {
      'string.pattern.base': `Must be valid favorite thing to do i.e. I like making web apps in ReactJs`,
    }),
  );

const gpa = Joi.number()
  // .regex(/^\d*\.?\d*$/)
  .messages(
    Object.assign(strMessages('GPA'), {
      'string.pattern.base': `Must be valid GPA format i.e. 3.75`,
    }),
  )
  .required();

const gpaScale = Joi.number()
  .messages(
    Object.assign(strMessages('GPA Scale'), {
      'string.pattern.base': `Must be valid GPA scale format i.e. 4.00`,
    }),
  )
  .required();

const classRank = Joi.number()
  .messages(
    Object.assign(strMessages('Class Rank'), {
      'string.pattern.base': `Must be valid Class Rank format i.e. 4`,
    }),
  )
  .required();

const classSize = Joi.number()
  .messages(
    Object.assign(strMessages('Class Size'), {
      'string.pattern.base': `Must be valid Class Size format i.e. 40`,
    }),
  )
  .required();

const notes = Joi.string()
  .max(255)
  .regex(regstr)
  .messages(strMessages('Notes'))
  .allow(null, '');

const validateAboutMe = (data) => {
  const schema = Joi.object().keys({
    gpa: gpa,
    gpaScale: gpaScale,
    classRank: classRank,
    classSize: classSize,
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

const validateOrderedMajorChoices = (data) => {
  const schema = Joi.object().keys({
    major1: majorChoice,
    major2: majorChoice,
    major3: majorChoice,
  });

  const { error } = schema.validate(data);
  return errorReader(error);
};

const validateOrderedFavorites = (data) => {
  const schema = Joi.object().keys({
    favorite1: favorite,
    favorite2: favorite,
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

const validateNotes = (data) => {
  const schema = Joi.object().keys({
    notes,
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

export {
  validatePasswordEdit,
  validateProfileEdit,
  validateAboutMe,
  validateOrderedMajorChoices,
  validateOrderedFavorites,
  validateNotes,
};
