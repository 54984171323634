import { Button, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import '../css/payments.css';
import axios from 'axios';

const decodeAuth = (cookie, setRole) => {
  const jwt = cookie.tokens.token;
  const jwtData = jwt.split('.')[1];
  const decodedJwtJsonData = window.atob(jwtData);
  const decodedJwtData = JSON.parse(decodedJwtJsonData);
  const role = decodedJwtData.user.role;
  setRole(role);
};

function CounselorSchedule() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [popup, setPopup] = useState(false);
  const [cookie] = useCookies(['tokens']);
  const [role, setRole] = useState('none');

  useEffect(() => {
    if (cookie.tokens) {
      decodeAuth(cookie, setRole);
    }
  }, [cookie]);

  const onSubmit = (data) => {
    axios
      .post(`/sessions`, {
        headers: {
          token: cookie.tokens.token,
        },
        body: {
          availableTime: `${data.time}:00.000Z`,
        },
      })
      .then(
        (response) => {
          console.log(response);
          setPopup(true);
        },
        (error) => {
          console.log(error);
        },
      );
    console.log(cookie.tokens.token);
  };

  return (
    <>
      {popup && (
        <div className="modal">
          <div className="modal-content">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h1>Succesfully Scheduled!</h1>
              </Grid>
              <div>
                <Button onClick={() => setPopup(false)} variant="outlined">
                  Schedule More
                </Button>
              </div>
              <div>
                <Button variant="outlined">See Schedule</Button>
              </div>
            </Grid>
          </div>
        </div>
      )}
      {role === 'counselor' && (
        <div className="content">
          <div className="greeting">
            <h1>Hello!</h1>
            <h2>Please use the form below to set your availability!</h2>
          </div>
          <div className="schedule">
            <form onSubmit={handleSubmit(onSubmit)} className="schedule-form">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div>
                    <h1>Create an available session</h1>
                    <TextField
                      {...register('time', { required: true })}
                      id="datetime-local"
                      label="Next appointment"
                      type="datetime-local"
                      sx={{ width: 250 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.time?.type === 'required' && (
                      <h3 style={{ color: 'red' }}>Missing Date</h3>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="outlined">
                    submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      )}{' '}
      {role === 'student' && (
        <div className="main">
          <h3>Must be counselor to add sessions</h3>
        </div>
      )}
      {role === 'none' && (
        <div className="main">
          <h3>Must be signed in to view this page</h3>
          <h2>
            <a href="/sign-in">Please sign in</a>
          </h2>
        </div>
      )}
    </>
  );
}

export default CounselorSchedule;
