import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import ModalDialog from '../../ModalDialog';
import { Edit as EditActivityForm } from './Edit';
import { deleteActivity } from './functions/requests';

// MUI IMPORTS
import {
  Stack,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const Footer = ({
  id,
  description,
  endYear,
  hoursPerWeek,
  startYear,
  title,
  weeksPerYear,
  setActivities,
}) => {
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    const { error, body } = await deleteActivity(id, cookies);
    if (error) {
      enqueueSnackbar(body, {
        variant: 'error',
      });
    } else {
      enqueueSnackbar('Activity Deleted', {
        variant: 'warning',
      });
      setActivities((prev) => prev.filter((activity) => activity.id !== id));
      setAnchorEl(false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#455FE8',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        color: 'white',
        p: 1,
      }}
    >
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1" component="p">
          <Box component="span" fontWeight="fontWeightBold" display="inline">
            Start Year:
          </Box>{' '}
          {startYear}
        </Typography>
        <Typography variant="body1" component="p">
          <Box component="span" fontWeight="fontWeightBold" display="inline">
            End Year:
          </Box>{' '}
          {endYear}
        </Typography>
        <IconButton
          onClick={handleClick}
          aria-label="expand more options"
          sx={{ color: 'white' }}
        >
          <MoreHorizIcon sx={{ transform: 'scale(2)' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleOpenModal}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Stack>
      <ModalDialog
        isOpen={openModal}
        handleClose={handleCloseModal}
        content={
          <EditActivityForm
            id={id}
            title={title}
            description={description}
            endYear={endYear}
            startYear={startYear}
            hoursPerWeek={hoursPerWeek}
            weeksPerYear={weeksPerYear}
            setOpen={setOpenModal}
            setActivities={setActivities}
          />
        }
        title="Add Activity"
        footerImg={true}
      />
    </Box>
  );
};

export default Footer;
