import { range } from 'lodash';
import React from 'react';
import { capitalize } from '../../../../utils/capitalize';

// MUI IMPORTS

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const FieldSelect = ({
  heading,
  formProps,
  setScores,
  error,
  setError,
  min,
  max,
  step,
}) => {
  const handleChange = (event) => {
    
    setError('');
    const {
      target: { value, name },
    } = event;

    setScores((scores) => ({
      ...scores,
      [name]: value,
    }));
  };

  const options = range(max, min, step).map((el) => el.toString());

  return (
    <>
      <p>{heading}</p>
      {formProps.map((props, i) => {
        const { label, value } = props;
        return (
          <FormControl key={`${label}${i}`} variant="filled" error={!!error}>
            <InputLabel id="test-select">{capitalize(label)}</InputLabel>
            <Select
              labelId="test-select"
              name={label}
              value={value}
              onChange={(e) => handleChange(e)}
            >
              {options.map((val, i) => {
                return (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      })}
    </>
  );
};
export default FieldSelect;
