import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  successMessage: {
    color: 'green',
    fontFamily: 'DM Sans',
    fontSize: '12px',
    overflow: 'auto',
  },
}));

const SuccessMessage = ({ message }) => {
  const classes = useStyles();
  return (
    <>
      <p className={classes.successMessage}>{message}</p>
    </>
  );
};

export default SuccessMessage;
