import React from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { postUserCollege } from './functions/requests';
import CollegeLogo from '../../../components/CollegeLogo';
import parsedDeadlineForDisplay from '../../../utils/parsedDeadlineForDisplay';
import StyledCollegeCard from './StyledCollegeCard';
import { useState, useEffect } from 'react';

//MUI IMPORTS
import {
  Typography,
  MenuItem,
  Card,
  Box,
  Grid,
  Menu,
  Avatar,
  IconButton,
  CardContent,
  CardActions,
  List,
  ListItem,
} from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const StyledCard = ({ children }) => {
  return (
    <Card
      elevation={0}
      sx={{
        overflow: 'visible',
        width: '95%',
        marginTop: '10px',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        border: '3px solid',
        borderColor: '#1A3AD5',
        position: 'relative',
      }}
      pb={4}
    >
      {children}
    </Card>
  );
};

const CollegeCard = ({
  name,
  state,
  status,
  ceebCode,
  location,
  eaDeadline,
  ed1Deadline,
  ed2Deadline,
  regDeadline,
  savedColleges,
  handleCollegeAdd,
  variant = 'version1',
}) => {
  const [cookies] = useCookies('tokens');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { enqueueSnackbar } = useSnackbar();

  const onHandleAdd = async (value) => {
    handleClose();
    const body = {
      ceeb_code: ceebCode,
      state: value,
    };

    try {
      const response = await postUserCollege(cookies, body);
      if (response.error) {
        enqueueSnackbar(response.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('College Added.', {
          variant: 'success',
        });

        const newSavedCollege = {
          id: response.body.data.id,
          ceebCode,
          location,
          name,
          state,
          status: value,
        };
        handleCollegeAdd(newSavedCollege);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  if (variant === 'version2') {
    return (
      <List>
        <ListItem>
          <Typography variant="h6">
            <strong>{name}</strong>, {location}, {state}
          </Typography>
        </ListItem>
      </List>
    );
  }

  return (
    <StyledCard>
      <CardContent>
        <Grid container>
          <Grid item xs={10}>
            <StyledCollegeCard name={name} state={state} location={location} />
          </Grid>
          <Grid item xs={2}>
            <Avatar
              sx={{
                width: '5rem',
                height: '5rem',
                borderRadius: '50%',
                border: '2px solid',
                borderColor: '#1A3AD5',
                backgroundColor: 'white',
                position: 'absolute',
                top: '-2.5rem',
                zIndex: '10',
              }}
            >
              <CollegeLogo name={name} ceeb_code={ceebCode} />
            </Avatar>
          </Grid>
          <Grid item xs={6}>
            {ed1Deadline && (
              <Typography gutterBottom variant="body" component="div">
                ED1 Deadline: {parsedDeadlineForDisplay(ed1Deadline)}
              </Typography>
            )}
            {ed2Deadline && (
              <Typography gutterBottom variant="body" component="div">
                ED2 Deadline: {parsedDeadlineForDisplay(ed2Deadline)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {eaDeadline && (
              <Typography gutterBottom variant="body" component="div">
                EAD Deadline: {parsedDeadlineForDisplay(eaDeadline)}
              </Typography>
            )}

            {regDeadline && (
              <Typography gutterBottom variant="body" component="div">
                Registration Deadline: {parsedDeadlineForDisplay(regDeadline)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          overflow: 'hidden',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          backgroundColor: '#1A3AD5',
          py: 0,
          my: 0,
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          onClick={handleClick}
          aria-label="expand more options"
          sx={{ color: 'white' }}
        >
          <MoreHorizIcon sx={{ transform: 'scale(2)' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => onHandleAdd('interested')}>
            Interested
          </MenuItem>
          <MenuItem onClick={() => onHandleAdd('applying')}>Applying</MenuItem>
          <MenuItem onClick={() => onHandleAdd('applied')}>Applied</MenuItem>
        </Menu>
      </CardActions>
    </StyledCard>
  );
};

export default CollegeCard;
