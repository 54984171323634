import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { createAdmin } from './functions/createAdmin';
import { validateSignUp } from './functions/validateSignUp';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function CreateAdminForm() {
  const [error, setError] = useState('');
  const [cookies] = useCookies('tokens');
  const [message, setMessage] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');

  const validate = (data) => {
    if (password !== rePassword) {
      setPasswordError('Passwords do not match.');
    } else {
      const { message, type, pass } = validateSignUp(data);

      switch (type) {
        case 'password':
          setPasswordError(message);
          return pass;
        case 'email':
          setEmailError(message);
          return pass;
        case 'firstName':
          setFirstNameError(message);
          return pass;
        case 'lastName':
          setLastNameError(message);
          return pass;
        default:
          return pass;
      }
    }
  };

  const clearErrors = () => {
    setError('');
    setEmailError('');
    setPasswordError('');

    setFirstNameError('');
    setLastNameError('');
  };

  const handleChange = (event, type) => {
    const value = event.target.value;
    clearErrors();
    const stateMap = {
      email: () => setEmail(value),
      password: () => setPassword(value),
      rePassword: () => setRePassword(value),
      firstName: () => setFirstName(value),
      lastName: () => setLastName(value),
    };
    stateMap[type](event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clearErrors();
    setMessage('');

    const data = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    };

    if (validate(data)) {
      handleCreateAdmin(data);
    }
  };

  const handleCreateAdmin = async (data) => {
    const { response, body } = await createAdmin(data, cookies);

    const { status } = response;

    if (status === 204) {
      // Todo: Make this a toast message?
      setMessage(`Admin ${firstName} ${lastName} has been created`);
    }
    if (status < 299 && status !== 204) {
      setError(body);
    }
    if (status > 299) {
      setError(body);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      autoComplete="off"
    >
      {error && <p>{error}</p>}
      {message && <p>{message}</p>}
      <Typography variant="h6" component="p">
        Create A New Admin
      </Typography>
      <div>
        <TextField
          id="filled-multiline-flexible"
          label="Email"
          multiline
          maxRows={4}
          value={email}
          onChange={(event) => handleChange(event, 'email')}
          variant="filled"
          helperText={emailError}
          error={!!emailError}
        />
        <TextField
          id="filled-multiline-flexible"
          label="Password"
          multiline
          maxRows={4}
          value={password}
          onChange={(event) => handleChange(event, 'password')}
          variant="filled"
          helperText={passwordError}
          error={!!passwordError}
        />
        <TextField
          id="filled-multiline-flexible"
          label="Password"
          multiline
          maxRows={4}
          value={rePassword}
          onChange={(event) => handleChange(event, 'rePassword')}
          variant="filled"
        />
        <TextField
          id="filled-multiline-flexible"
          label="First Name"
          multiline
          maxRows={4}
          value={firstName}
          onChange={(event) => handleChange(event, 'firstName')}
          variant="filled"
          helperText={firstNameError}
          error={!!firstNameError}
        />
        <TextField
          id="filled-multiline-flexible"
          label="Last Name"
          multiline
          maxRows={4}
          value={lastName}
          onChange={(event) => handleChange(event, 'lastName')}
          variant="filled"
          helperText={lastNameError}
          error={!!lastNameError}
        />
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </div>
    </Box>
  );
}
