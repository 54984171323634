import { createContext, useContext } from 'react';

export const UserContext = createContext();

export const useAppAccess = () => {
  const { user } = useContext(UserContext);
  return (
    user?.entitlements?.length &&
    user.entitlements.includes('VIZE Tasking System')
  );
};

export const useStudentName = () => {
  const {
    user: { first_name, last_name },
  } = useContext(UserContext);
  return first_name && last_name ? `${first_name} ${last_name}` : '';
};

export const useUserRole = () => {
  const {
    user: { role },
  } = useContext(UserContext);
  return role;
};

export const useAuthUser = () => {
  const { user } = useContext(UserContext);
  return user;
};

export const useAuthUserEntity = () => {
  const { entity } = useContext(UserContext);
  return entity;
};

export default UserContext;
