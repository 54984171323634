const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

const setHeaders = (cookie) => {
  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};

const setUrl = (url) => {
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  return url;
};

export const allStudents = async (cookie) => {
  let url_param = `/admin/all?role=student`;

  const url = setUrl(url_param);
  const myHeaders = setHeaders(cookie);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  try {
    const response = await fetch(url, configObj);
    const { data } = await response.json();
    return { error: false, data };
  } catch (e) {
    return { error: true, message: e.message };
  }
};

export const studentTasks = async (cookie, id) => {
  let url_param = `/v2/tasks/${id}`;
  const url = setUrl(url_param);

  const myHeaders = setHeaders(cookie);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  try {
    const response = await fetch(url, configObj);
    const { data } = await response.json();
    return { data: data.map((el) => el.attributes), error: false };
  } catch (e) {
    return { error: true, message: e.message };
  }
};

export const studentSnapshot = async (cookie, id) => {
  let url_param = `/student/snapshot/${id}`;
  const url = setUrl(url_param);

  const myHeaders = setHeaders(cookie);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  try {
    const response = await fetch(url, configObj);

    const { data } = await response.json();
    return { data, error: false };
  } catch (e) {
    return { error: true, message: e.message };
  }
};

export const editTask = async (cookie, id, body) => {
  let url_param = `/v2/tasks/${id}`;
  const url = setUrl(url_param);

  const myHeaders = setHeaders(cookie);

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, configObj);
    if (response.status === 204) return { error: false };
  } catch (e) {
    return { error: true, message: e.message };
  }
};
