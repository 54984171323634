import React from 'react';

//MUI IMPORTS
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { attributeKeyForDisplay } from '../../../../utils/attributeKeyForDisplay';

export default function StudentAttributesTable({ attributes }) {
  const atts = Object.entries(attributes);

  return (
    <Grid container spacing={2} sx={{ mt: 3, mb: 3 }}>
      <Grid item xs={2} />
      <Grid item xs={8}>
        <Typography
          sx={{ flex: '1 1 100%', mb: 2 }}
          variant="h5"
          component="h5"
        >
          Basic Info
        </Typography>
        {atts.map(([key, value]) => {
          return (
            <Typography key={key} variant="p" component="p">
              <b>{attributeKeyForDisplay(key)}:</b> {value ?? 'No Data'}
            </Typography>
          );
        })}
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}
