import React from 'react';
import ListContainer from '../ListContainer';
import { useHistory } from 'react-router-dom';

// MUI IMPORTS
import { Grid, Typography, Skeleton } from '@mui/material';

const LoadingComponent = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
    </Grid>
  );
};

const NullObjectComponent = ({ title }) => {
  const history = useHistory();
  return (
    <>
      <ListContainer
        arr={[`No ${title} Data`, `Click the edit button to go pick Colleges!`]}
        title={title}
        handleClick={() => history.push('/colleges')}
      />
    </>
  );
};

const ErrorComponent = ({ error }) => {
  return (
    <Typography variant="body1" color="error" component="p" pb={2}>
      {error}
      {/* Todo: Replace with error response */}
    </Typography>
  );
};

//Loading / Error/ Null Components can be imported or created custom in file.
export const ResourceHOC = ({
  hasData,
  isLoading,
  serverError,
  children,
  title,
}) => {
  if (isLoading) return <LoadingComponent />;
  if (serverError) return <ErrorComponent error={serverError} />;
  if (hasData) return <NullObjectComponent title={title} />;
  return children;
};
