import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFetchTasks } from '../tasks-v2/functions/hooks';
import { NotificationsUi } from '../notifications/NotificationUi';
import { useNotificationsContext } from 'store/NotificationsContext';
import { TaskDashboardUi } from './taskDashboardUi';
import { CollegePreview } from '../colleges/Layout';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DiplomaIconComponent from 'components/icons/DiplomaIcon';
import { DashboardCumulativeScores } from './testDashboard';
import { DashboardSurveyResults } from '../colleges/college-survey/CollegeResultModal';

// MUI IMPORTS
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Stack,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const Bold = ({ children }) => {
  return (
    <Box sx={{ display: 'inline', fontWeight: 'bold' }} inline>
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => {
  const headerStyles = {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      margin: '0 0 0 -20px',
      top: 0,
      left: 0,
      width: '75%',
      height: '100%',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      margin: '0 0 0 0px',
      bottom: 0,
      right: 0,
      width: '35%',
      height: '100%',
    },
  };
  return {
    taskHeader: {
      ...headerStyles,
      backgroundColor: '#BDD1F9',
      backgroundImage:
        '-webkit-linear-gradient(150deg, #BDD1F9 30%, #425ce8 30%)',
    },
    collegeHeader: {
      ...headerStyles,
      backgroundColor: '#BDD1F9',
      backgroundImage:
        '-webkit-linear-gradient(150deg, #BDD1F9 30%, #8080ff 30%)',
    },
    notificationHeader: {
      ...headerStyles,
      backgroundColor: '#BDD1F9',
      backgroundImage:
        '-webkit-linear-gradient(150deg, #BDD1F9 30%, #6666ff 30%)',
    },
  };
});

export const StudentDashboard = ({ testType, sId }) => {
  const { studentNotifications } = useNotificationsContext();
  const history = useHistory();
  const classes = useStyles();
  const taskUrl = `/v2/tasks/all`;
  const [tasks] = useFetchTasks(taskUrl);

  let totalTasks = 0;
  let completedCount = 0;

  if (tasks) {
    totalTasks = tasks.length;
    const completedTasks = tasks.filter((task) => task.completed === true);
    completedCount = completedTasks.length;
  }

  let newNotificationsCount = 0;

  if (studentNotifications) {
    const unreadNotifications = studentNotifications.filter(
      (unread) => unread.seen_at === null,
    );
    newNotificationsCount = unreadNotifications.length;
  }

  return (
    <Box sx={{ flexShrink: 4 }}>
      <Typography sx={{ p: 1 }} variant="h4" component="h2">
        <Bold>My Dashboard</Bold>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '40vh',
              borderRadius: 5,
              '@media (min-width: 1400px)': {
                height: '50vh',
              },
            }}
          >
            <CardHeader
              sx={{ height: '90px' }}
              className={classes.taskHeader}
              avatar={<CheckBoxOutlinedIcon fontSize="large" />}
              action={
                <IconButton aria-label="settings">
                  {' '}
                  <Typography variant="h5" textAlign="right">
                    {completedCount} / {totalTasks} <br />
                    Tasks Completed
                  </Typography>
                </IconButton>
              }
              title={
                <Box display="flex" alignItems="center">
                  <Typography variant="h5">Tasks</Typography>
                </Box>
              }
            />

            <CardContent
              sx={{
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aaa',
                  borderRadius: 3,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#E0E0E0',
                  borderRadius: 3,
                },
                overflowX: 'hidden',
                p: 1,
                overflow: 'auto',
                flexGrow: 1,
              }}
            >
              <TaskDashboardUi />
            </CardContent>
            <CardActions disableSpacing>
              <Button
                size="small"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/tasks');
                }}
              >
                View More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '40vh',
              borderRadius: 5,
              '@media (min-width: 1400px)': {
                height: '50vh',
              },
            }}
          >
            <CardHeader
              sx={{ height: '90px' }}
              className={classes.taskHeader}
              avatar={<DiplomaIconComponent fontSize="large" />}
              action={
                <IconButton aria-label="settings">
                  {' '}
                  <Typography variant="h5">Colleges Added</Typography>
                </IconButton>
              }
              title={
                <Box display="flex" alignItems="center">
                  <Typography variant="h5">Colleges</Typography>
                </Box>
              }
            />

            <CardContent
              sx={{
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aaa',
                  borderRadius: 3,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#E0E0E0',
                  borderRadius: 3,
                },
                overflowX: 'hidden',
                p: 1,
                overflow: 'auto',
                flexGrow: 1,
              }}
            >
              <Grid item>
                <CollegePreview variant={'version2'} />
              </Grid>
              <Grid item xs={12} sx={{ height: '40%' }}>
                <Typography variant="h4" sx={{ mt: 4 }}>
                  <strong>College Match Results</strong>
                </Typography>
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{ mx: 'auto', width: 300, p: 1, m: 1 }}
                >
                  <DashboardSurveyResults />
                </Stack>
              </Grid>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                size="small"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/colleges');
                }}
              >
                View More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboardCumulativeScores sId={sId} defaultState={testType} />
        </Grid>
        {/* ADD ONCE WE HAVE BADGE FUNCTIONALITY
        
        <Grid item xs={3}>
          <Card
            sx={{
              height: '350px',
            }}
          >
            <CardHeader
              sx={{ bgcolor: '#425ce8' }}
              avatar={
                <Avatar sx={{ bgcolor: '#111111' }} aria-label="recipe">
                  <NotificationsIcon />
                </Avatar>
              }
              action={<IconButton aria-label="settings"></IconButton>}
              title="Badges"
              subheader={<Typography textAlign="right">Earned</Typography>}
            />

            <CardContent>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
            <CardActions disableSpacing>
              <ReadMoreIcon />
            </CardActions>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            sx={{
              height: '350px',
              borderRadius: 5,
            }}
          >
            <CardHeader
              sx={{ height: '90px' }}
              className={classes.taskHeader}
              avatar={<NotificationsIcon fontSize="large" />}
              action={
                <IconButton aria-label="settings">
                  {' '}
                  <Typography variant="h5" textAlign="right">
                    {newNotificationsCount}
                    <br />
                    New Notifications
                  </Typography>
                </IconButton>
              }
              title={
                <Box display="flex" alignItems="center">
                  <Typography variant="h5">Notifications</Typography>
                </Box>
              }
            />

            <CardContent
              sx={{
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aaa',
                  borderRadius: 3,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#E0E0E0',
                  borderRadius: 3,
                },
                overflowX: 'hidden',
                p: 1,
                height: '220px',
                overflow: 'auto',
              }}
            >
              <NotificationsUi
                studentNotifications={studentNotifications}
                variant="version2"
              />
            </CardContent>
            <CardActions disableSpacing>
              <Button
                size="small"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/notifications');
                }}
              >
                View More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
