import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { SearchRounded } from '@material-ui/icons';
import { MenuItem, Select } from '@mui/material';
import { useForm } from 'react-hook-form';
import StyledFormWithInput from '../StyledFormWIthInput/StyledFormWithInput';

const UserSearch = ({ onHandleSearchInput, loading }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    onHandleSearchInput(data);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledFormWithInput onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register('name')}
          margin="normal"
          name="name"
          label="Student name"
          placeholder="Search users by name"
          type="name"
          id="name"
          helperText="Name can be left empty or have at least 3 letters"
        />
        <Select
          {...register('role')}
          defaultValue={'student'}
          labelId="Select role"
          id="role"
          fullWidth
        >
          <MenuItem defaultChecked value={'student'}>
            Student
          </MenuItem>
          <MenuItem value={'admin'}>Admin</MenuItem>
        </Select>

        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          endIcon={<SearchRounded />}
        >
          {!loading && 'Search'}
        </LoadingButton>
      </StyledFormWithInput>
    </div>
  );
};

export default UserSearch;
