import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotificationsContext } from 'store/NotificationsContext';

// MUI IMPORTS
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  IconButton,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Bold = ({ children }) => {
  return (
    <Box sx={{ display: 'inline', fontWeight: 'bold' }} inline>
      {children}
    </Box>
  );
};

const StyledBadgeCount = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 5,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -1,
    padding: '0px',
  },
}));

export const NotificationsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { unseen, studentNotifications } = useNotificationsContext();
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'notification-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <StyledBadgeCount color="warning" badgeContent={unseen}>
            <NotificationsIcon sx={{ color: '#425ce8' }} />
          </StyledBadgeCount>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="notification-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 350,
            height: 400,
            borderRadius: '15px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Grid container xs={12}>
          <Grid xs={6}>
            <Typography sx={{ p: 1 }}>
              <Bold>Notifications</Bold>
            </Typography>
          </Grid>
          <Grid xs={6} sx={{ alignItems: 'right', justifyContent: 'right' }}>
            <Link
              href="#"
              underline="always"
              sx={{ p: 1, cursor: 'pointer' }}
              onClick={() => {
                history.push('/notifications');
              }}
            >
              {'View All'}
            </Link>
          </Grid>
        </Grid>

        <Divider />
        <Box
          sx={{
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#aaa',
              borderRadius: 3,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#E0E0E0',
              borderRadius: 3,
            },
            overflowX: 'hidden',
            p: 1,
            maxHeight: '340px',
            overflow: 'auto',
          }}
        >
          <NotificationsUi studentNotifications={studentNotifications} />
        </Box>
      </Menu>
    </>
  );
};

const NotificationsVersionTwo = ({
  notification,
  handleNavOnClick,
  handleUpdateHasSeen,
}) => {
  return (
    <Card
      sx={{
        width: '100%',
        height: '50%',
        border: '1px solid blue',
      }}
    >
      <CardContent
        sx={{
          height: '40px',
          p: 1,
        }}
      >
        {new Date(notification.created_at).toDateString()} |{' '}
        {notification.message}
      </CardContent>
      <Divider />
      <CardActions
        sx={{
          backgroundColor: '#BDD1F9',
          height: '30px',
          alignItems: 'right',
          justifyContent: 'right',
        }}
      >
        <IconButton aria-label="settings">
          <ArrowForwardIosIcon
            onClick={() => {
              handleNavOnClick();
              handleUpdateHasSeen();
            }}
          />
        </IconButton>
      </CardActions>
    </Card>
  );
};

const NotificationVersionOne = ({
  notification,
  handleNavOnClick,
  handleUpdateHasSeen,
}) => {
  return (
    <MenuItem
      onClick={() => {
        handleNavOnClick();
        handleUpdateHasSeen();
      }}
    >
      <Grid>
        <Typography>{notification.message}</Typography>
        <Typography style={{ color: '#425ce8' }}>
          <Bold>{new Date(notification.created_at).toDateString()}</Bold>
        </Typography>
      </Grid>
    </MenuItem>
  );
};

export const NotificationsUi = ({
  variant = 'version1',
  studentNotifications,
}) => {
  const { handleHasSeen, unseen } = useNotificationsContext();
  const history = useHistory();

  const handleNavOnClick = () => {
    history.push('/exam-reports');
  };

  if (variant === 'version2') {
    return (
      <>
        {studentNotifications.map((notification) => {
          return (
            <Grid sx={{ m: 1 }}>
              {notification.seen_at === null ? (
                <StyledBadge
                  variant="dot"
                  color="warning"
                  badgeContent={unseen}
                  sx={{
                    width: '100%',
                  }}
                >
                  <NotificationsVersionTwo
                    notification={notification}
                    handleNavOnClick={handleNavOnClick}
                    handleUpdateHasSeen={() => handleHasSeen(notification.id)}
                  />
                </StyledBadge>
              ) : (
                <NotificationsVersionTwo
                  notification={notification}
                  handleNavOnClick={handleNavOnClick}
                  handleUpdateHasSeen={() => handleHasSeen(notification.id)}
                />
              )}
            </Grid>
          );
        })}
      </>
    );
  }

  return (
    <>
      {studentNotifications.map((notification) => {
        return (
          <>
            {notification.seen_at === null ? (
              <StyledBadge variant="dot" color="warning" badgeContent={unseen}>
                <NotificationVersionOne
                  notification={notification}
                  handleNavOnClick={handleNavOnClick}
                  handleUpdateHasSeen={() => handleHasSeen(notification.id)}
                />
              </StyledBadge>
            ) : (
              <NotificationVersionOne
                notification={notification}
                handleNavOnClick={handleNavOnClick}
                handleUpdateHasSeen={() => handleHasSeen(notification.id)}
              />
            )}
            <Divider />
          </>
        );
      })}
    </>
  );
};
