import React, { useState } from 'react';
import { signupValidation } from '../../utils/joiValidation';
import registerUser from '../userManagmentFunctions/register';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { capitalize } from '../../utils/capitalize';

import {
  TextField,
  Box,
  Stack,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';

const SignUpForm = ({ roles }) => {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [cookie] = useCookies(['tokens']);
  const [selectedRole, setSelectedRole] = useState(() => {
    if (!roles) return;
    const [firstRoleOption] = roles;
    return firstRoleOption;
  });

  const errorSetters = [
    setEmailError,
    setPasswordError,
    setFirstNameError,
    setLastNameError,
    setPhoneNumberError,
  ];

  const clearErrors = () => errorSetters.forEach((setError) => setError(''));

  const validate = (data) => {
    const { message, type, pass } = signupValidation(data);
    switch (type) {
      case 'password':
        setPasswordError(message);
        return pass;
      case 'phoneNumber':
        setPhoneNumberError(message);
        return pass;
      case 'firstName':
        setFirstNameError(message);
        return pass;
      case 'lastName':
        setLastNameError(message);
        return pass;
      case 'email':
        setEmailError(message);
        return pass;
      default:
        return pass;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clearErrors();
    const data = new FormData(event.currentTarget);

    if (data.get('password') !== data.get('rePassword')) {
      setPasswordError('Passwords are not matching');
      return;
    }
    const isValid = validate({
      email: data.get('email'),
      password: data.get('password'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      phoneNumber: data.get('phoneNumber'),
    });
    if (isValid) {
      handleSignUp(data);
    }
  };

  const handleSignUp = async (data) => {
    try {
      const response = await registerUser(
        {
          email: data.get('email'),
          password: data.get('password'),
          first_name: data.get('firstName'),
          last_name: data.get('lastName'),
          phone_number: data.get('phoneNumber'),
          role: selectedRole,
        },
        cookie,
      );

      if (response?.error) {
        enqueueSnackbar(response?.error, { variant: 'error' });
      } else {
        // success response
        enqueueSnackbar('Account Created', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };
  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      {roles?.length > 0 ? (
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="exam-code">Role</InputLabel>
          <Select
            labelId="exam-code"
            label="Role"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            {roles.map((option) => {
              return (
                <MenuItem value={option}>
                  {capitalize(option).split('_').join(' ')}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
      <TextField
        margin="normal"
        required
        fullWidth
        id="firstName"
        label="First Name"
        name="firstName"
        autoComplete="firstName"
        error={!!firstNameError}
        helperText={firstNameError}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="lastName"
        error={!!lastNameError}
        helperText={lastNameError}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="phoneNumber"
        label="Phone Number"
        name="phoneNumber"
        autoComplete="phoneNumber"
        error={!!phoneNumberError}
        helperText={phoneNumberError}
        autoFocus
        placeholder="5555555555"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        error={!!emailError}
        helperText={emailError}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        error={!!passwordError}
        helperText={passwordError}
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="rePassword"
        label="Confirm Password"
        type="password"
        id="rePassword"
        autoComplete="current-password"
      />
      <Stack>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          CREATE ACCOUNT
        </Button>
      </Stack>
    </Box>
  );
};

export default SignUpForm;
