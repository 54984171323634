import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { addNewTask } from 'components/admin/tasks/functions/requests';
import { SearchTasksCounselorLayout } from './searchTasksCounselor';
import { getCurrentAccess } from 'components/student/functions/requests';
import { addStudentsForCustomTask } from 'components/admin/tasks/functions/requests';

//MUI IMPORTS
import {
  Paper,
  Container,
  Typography,
  Button,
  Stack,
  Box,
  TextField,
  Grid,
  Tab,
  Tabs,
  Divider,
  Autocomplete,
  Chip,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { editTask } from 'components/admin/students/functions/requests';

export const NewTaskForm = ({ counselorStudents, defaultState }) => {
  const [cookies] = useCookies('tokens');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const type = 'custom';
  const [name, setName] = useState(() => {
    return defaultState?.name || '';
  });
  const [description, setDescription] = useState(() => {
    return defaultState?.description || '';
  });
  const [dueDate, setDueDate] = useState(() => {
    return new Date(defaultState?.dueDate) || new Date();
  });
  const [howImportant, setHowImportant] = useState(() => {
    return defaultState?.weightedImportance || '';
  });
  const [hours, setHours] = useState(() => {
    return defaultState?.approxTotalHours || '';
  });
  const [importance, setImportance] = useState(() => {
    return defaultState?.importance || '';
  });
  const [notes, setNotes] = useState(() => {
    return defaultState?.notes || '';
  });
  const { enqueueSnackbar } = useSnackbar();

  const allOption = { name: 'All', id: 'all' };
  const options = [allOption, ...counselorStudents];
  const [applyToAll, setApplyToAll] = useState(false);

  const submitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      try {
        let response;
        const data = selectedStudents.map((student) => student.id);
        const formData = {
          name,
          description,
          due_date: dueDate,
          weighted_importance: howImportant,
          approx_total_hours: hours,
          importance,
          notes,
          type,
        };
        if (defaultState.taskId) {
          response = await editTask(cookies, defaultState.taskId, formData);
          if (response.error) {
            enqueueSnackbar(response.message, { variant: 'error' });
          } else {
            await addStudentsForCustomTask(
              defaultState.taskId,
              cookies,
              data,
              applyToAll,
            );

            enqueueSnackbar(`${type} task editted`, { variant: 'success' });
            clearAddTaskForm();
          }
        } else {
          response = await addNewTask(cookies, formData);

          if (response.error) {
            enqueueSnackbar(response.message, { variant: 'error' });
          } else {
            await addStudentsForCustomTask(
              response.body.id,
              cookies,
              data,
              applyToAll,
            );

            enqueueSnackbar(`New ${type} task added`, { variant: 'success' });
            clearAddTaskForm();
          }
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [
      defaultState.taskId,
      cookies,
      selectedStudents,
      name,
      description,
      dueDate,
      type,
      howImportant,
      hours,
      importance,
      notes,
      enqueueSnackbar,
      applyToAll,
    ],
  );

  const clearAddTaskForm = () => {
    setSelectedStudents([]);
    setName('');
    setDescription('');
    setDueDate(new Date());
    setImportance('');
    setHowImportant('');
    setHours('');
    setNotes('');
  };

  const handleChange = (event, newValue) => {
    if (newValue.length > 0 && newValue.find((value) => value.id === 'all')) {
      setApplyToAll(true);
      setSelectedStudents(options.filter((option) => option.id !== 'all'));
    } else {
      setApplyToAll(false);
      setSelectedStudents(newValue);
    }
  };

  const removeStudent = (id) => {
    if (id === 'all') {
      setSelectedStudents([]);
      setApplyToAll(false);
    } else {
      setSelectedStudents(selectedStudents.filter((s) => s.id !== id));
    }
  };

  return (
    <Container sx={{ p: 2 }}>
      <Paper sx={{ p: '5rem', pt: '1rem' }}>
        <Typography align="center" variant="h5" paddingBottom={2}>
          {defaultState?.id ? 'Editing Custom Task' : 'Custom Task'}
        </Typography>

        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={3}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={options}
              getOptionLabel={(option) => option.name}
              value={selectedStudents}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Student Name"
                  placeholder="Search"
                  helperText={
                    <>
                      <Typography
                        sx={{ fontSize: 12, component: 'span', color: 'red' }}
                      >
                        Required*
                      </Typography>
                    </>
                  }
                />
              )}
              renderTags={(value, getTagProps) => {
                if (applyToAll) {
                  return (
                    <Chip
                      label="All"
                      {...getTagProps({ index: -1 })}
                      onDelete={() => removeStudent('all')}
                      style={{ backgroundColor: '#425ce8', color: 'white' }}
                    />
                  );
                } else {
                  return value.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      onDelete={() => removeStudent(option.id)}
                      style={{
                        backgroundColor: '#425ce8',
                        color: 'white',
                      }}
                    />
                  ));
                }
              }}
            />
            <TextField
              key="id"
              id="filled-multiline-flexible"
              label="Task Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              variant="filled"
              helperText={
                <>
                  <Typography
                    sx={{
                      fontSize: 12,
                      component: 'span',
                      color: 'red',
                      display: 'inline-block',
                    }}
                  >
                    Required*
                  </Typography>
                  <span style={{ display: 'inline-block' }}>
                    NOTE: This text will appear in bold to all of your students.
                    (Max characters: 50)
                  </span>
                </>
              }
            />
            <TextField
              key="name"
              id="filled-multiline-flexible"
              label="Task Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              variant="filled"
              helperText={
                <>
                  <Typography
                    sx={{
                      fontSize: 12,
                      component: 'span',
                      color: 'red',
                      display: 'inline-block',
                    }}
                  >
                    Required*
                  </Typography>
                  <span style={{ display: 'inline-block' }}>
                    NOTE: This text will appear below the Task Name. (No max
                    length)
                  </span>
                </>
              }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Due Date"
                value={dueDate}
                onChange={(newDate) => {
                  setDueDate(newDate);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <TextField
              key="isImportant"
              id="filled-multiline-flexible"
              label="How important is it?"
              value={howImportant}
              onChange={(event) => setHowImportant(event.target.value)}
              variant="filled"
              type="number"
              InputProps={{ inputProps: { min: 1, max: 10 } }}
              helperText={
                <>
                  <Typography
                    sx={{
                      fontSize: 12,
                      component: 'span',
                      color: 'red',
                      display: 'inline-block',
                    }}
                  >
                    Required*
                  </Typography>
                  <span style={{ display: 'inline-block' }}>
                    NOTE: On a scale from 1-9, where one is barely worth
                    mentioning and nine is extremely important.
                  </span>
                </>
              }
            />
            <TextField
              key="hours"
              id="filled-multiline-flexible"
              label="How many hours should it take?"
              value={hours}
              onChange={(event) => setHours(event.target.value)}
              variant="filled"
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              helperText={
                <>
                  <Typography
                    sx={{
                      fontSize: 12,
                      component: 'span',
                      color: 'red',
                      display: 'inline-block',
                    }}
                  >
                    Required*
                  </Typography>
                  <span style={{ display: 'inline-block' }}>
                    NOTE: Enter time in .25 increments. I.e. a half hour would
                    be noted as .5, etc.
                  </span>
                </>
              }
            />
            <TextField
              multiline
              key="causeOfImportance"
              id="filled-multiline-flexible"
              label="Why is this important?"
              value={importance}
              onChange={(event) =>
                setImportance(event.target.value.replace(/####/g, '\n'))
              }
              variant="filled"
              helperText={
                <>
                  <Typography
                    sx={{ fontSize: 12, component: 'span', color: 'red' }}
                  >
                    Required*
                  </Typography>
                </>
              }
            />
            <TextField
              multiline
              key="notes"
              id="filled-multiline-flexible"
              label="Any notes on how to do this?"
              value={notes}
              onChange={(event) =>
                setNotes(event.target.value.replace(/####/g, '\n'))
              }
              variant="filled"
              helperText={
                <>
                  <Typography
                    sx={{
                      fontSize: 12,
                      component: 'span',
                      color: 'red',
                      display: 'inline-block',
                    }}
                  >
                    Required*
                  </Typography>
                  <span style={{ display: 'inline-block' }}>
                    NOTE: Any notes on how you want your students to do this?
                  </span>
                </>
              }
            />
          </Stack>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="center"
            marginTop={5}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                clearAddTaskForm();
              }}
            >
              Clear
            </Button>
            <Stack
              direction="row"
              spacing={6}
              justifyContent="left"
              marginTop={1}
            >
              <Button type="submit" variant="contained">
                {loading ? 'Processing...' : 'SUBMIT'}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </Container>
  );
};

const CounselorTaskTabs = () => {
  const [counselorStudents, setCounselorStudents] = useState([]);
  const [currentlyEdittingTask, setCurrentlyEdittingTask] = useState({});
  const [value, setValue] = useState(0);
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCurrentAccess(cookies);

      if (typeof response === 'string') {
        enqueueSnackbar(response, { variant: 'error' });
      } else {
        const result = response.data
          .filter((record) => {
            return record.attributes.relation === 'student';
          })
          .map((record) => {
            const {
              attributes: { firstName, lastName },
              id,
            } = record.relationships.student.data;
            return {
              id: id,
              name: `${firstName} ${lastName}`,
            };
          });

        setCounselorStudents(result);
      }
    };

    fetchData();
  }, [enqueueSnackbar, cookies]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 0) {
      setCurrentlyEdittingTask({});
    }
  }, [value]);

  return (
    <>
      <Typography variant="h1" component="h2" mb={2}>
        Tasks
      </Typography>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="SUBMITTED TASKS" />
        <Tab label="ADD A NEW TASK" />
      </Tabs>
      <Divider />
      <Grid container sx={{ height: 'auto' }}>
        {value === 0 && (
          <SearchTasksCounselorLayout
            counselorStudents={counselorStudents}
            onEdit={(task) => {
              setCurrentlyEdittingTask(task);
              setValue(1);
            }}
          />
        )}
        {value === 1 && (
          <Grid item xs={6}>
            <NewTaskForm
              counselorStudents={counselorStudents}
              defaultState={currentlyEdittingTask}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CounselorTaskTabs;
