import React from 'react';
import { Typography, Box } from '@mui/material';

const StyledCollegeCard = ({ name, state, location }) => {
  return (
    <>
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        sx={{ mb: 0, pb: 0 }}
      >
        {name} <br />
        {location && state && (
          <Box
            sx={{
              color: 'primary.main',
              display: 'inline',
            }}
            inline
          >
            {location}, {state}
          </Box>
        )}
      </Typography>
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        sx={{ pt: 0, mt: 0 }}
      ></Typography>
    </>
  );
};

export default StyledCollegeCard;
