const { NODE_ENV, REACT_APP_API_DOMAIN } = process.env;

export const entitiesByUserId = async (cookies, id) => {
  let url = `/users/${id}/entities`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', id);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unauthorized'; // potentially invalid student
  }

  if (response.status === 422) {
    return 'Invalid parameters';
  }

  return 'server error';
};
