export const createAdmin = async (data, cookies) => {
  let url = '/admin/create';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);
  const body = await response.text();

  return { response, body };
};
