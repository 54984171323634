// import Form from "./Form";
import React, { useState } from 'react';
import AdminColleges from './AdminColleges';
import CollegeForm from './CollegeForm';
import DeadlinesForm from './DeadlinesForm';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Layout = () => {
  const [collegeData, setCollegeData] = useState();
  const [isCollegeDataEditing, setIsCollegeDataEditing] = useState(false);

  const [deadlinesData, setDeadlinesData] = useState();
  const [isDeadlinesDataEditing, setIsDeadlinesDataEditing] = useState(false);

  const setCollegeDataToEdit = (data) => {
    setIsCollegeDataEditing(true);
    setCollegeData(data);
  };

  const resetCollegeEditingState = () => {
    setIsCollegeDataEditing(false);
    setCollegeData({});
  };

  const setDeadlineDataToEdit = (data) => {
    setDeadlinesData(data);
  };

  const resetDeadlinesEditingState = () => {
    setIsDeadlinesDataEditing(false);
    setDeadlinesData({});
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="h2">Colleges</Typography>
          <CollegeForm
            isEditing={isCollegeDataEditing}
            collegeData={collegeData}
            resetCollegeEditingState={resetCollegeEditingState}
          />

          <DeadlinesForm
            isEditing={isDeadlinesDataEditing}
            deadlinesData={deadlinesData}
            resetDeadlinesEditingState={resetDeadlinesEditingState}
          />
        </Grid>
        <Grid item xs={6}>
          <AdminColleges
            setCollegeDataToEdit={setCollegeDataToEdit}
            setDeadlinesToEdit={setDeadlineDataToEdit}
            setIsDeadlinesDataEditing={setIsDeadlinesDataEditing}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Layout;
