import React from 'react';
import Activities from './activities/Activities';
import Honors from './honors/Layout';
import Coursework from './coursework/Layout';
import Exams from './exam/Layout';

// MUI IMPORTS
import { Card, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const ProfileDetails = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Card sx={{ maxHeight: '84vh', height: '100%', borderRadius: '15px' }}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label="profile details header"
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label="Activities" value="1" />
              <Tab label="Coursework" value="2" />
              <Tab label="Honors" value="3" />
              <Tab label="Tests" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Activities />
          </TabPanel>
          <TabPanel value="2">
            <Coursework />
          </TabPanel>
          <TabPanel value="3">
            <Honors />
          </TabPanel>
          <TabPanel value="4">
            <Exams />
          </TabPanel>
        </TabContext>
      </Box>
    </Card>
  );
};

export default ProfileDetails;
