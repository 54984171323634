import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import ModalDialog from '../ModalDialog';
import { useFetch } from './functions/hooks';
import { postAboutMeData } from '../functions/requests';
import { validateAboutMe } from './functions/validations';

// MUI IMPORTS
import {
  Box,
  Stack,
  Tooltip,
  Grid,
  Typography,
  Skeleton,
  IconButton,
  TextField,
  Button,
} from '@mui/material';

// REACTLY - ICONS
import { EditSquare as EditIcon } from 'react-iconly';

const testsURL = '/users/test?currentPage=1&pageSize=99';

const Footer = ({ about, isAboutLoading, aboutError, setAbout }) => {
  const {
    resource: tests,
    //setResource: setTests,
    isLoading: isTestLoading,
    serverError: testError,
  } = useFetch(testsURL, (data) => {
    const { attributes, id } = data;
    return { ...attributes, id };
  });

  if (isAboutLoading || isTestLoading) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
      </Grid>
    );
  }

  if (aboutError || testError) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            {aboutError || testError}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return <FooterContent about={about} tests={tests} setAbout={setAbout} />;
};

const displayTestScore = (testType, scores) => {
  // from Jeremy: v/m for sat, e/m/r/s for act, r/l/s/w for toefl
  if (testType === 'SAT') {
    const { math, verbal } = scores;
    return `${verbal}/${math}`;
  }

  if (testType === 'TOEFL') {
    const { reading, writing, speaking, listening } = scores;
    return `${reading}/${listening}/${speaking}/${writing}`;
  }

  if (testType === 'ACT') {
    const { english, math, reading, science } = scores;
    return `${english}/${math}/${reading}/${science}`;
  }
};

const ariaLabelByTestType = (testType) => {
  if (testType === 'SAT') {
    return `verbal/math`;
  }

  if (testType === 'TOEFL') {
    return `reading/listening/speaking/writing`;
  }

  if (testType === 'ACT') {
    return `english/math/reading/science`;
  }
};

const FooterContent = ({ about, tests, setAbout }) => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#BDD1F9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        p: 2,
        py: 3,
      }}
    >
      <Stack direction="row" spacing={3}>
        <Typography variant="body1" component="p">
          <Box component="span" fontWeight="fontWeightBold" display="inline">
            <IconButton
              siz="small"
              sx={{ height: '25px' }}
              onClick={(e) => setOpen(true)}
            >
              <EditIcon set="bold" color="#455FE8" style={{ height: '25px' }} />
            </IconButton>{' '}
            GPA:
          </Box>{' '}
          {about?.gpa}
        </Typography>
        <Typography variant="body1" component="p">
          <Box component="span" fontWeight="fontWeightBold" display="inline">
            Class Rank:
          </Box>{' '}
          {about?.classRank}
        </Typography>
        {tests &&
          tests.map(({ type, scores }, index) => {
            return (
              <Tooltip
                key={index}
                sx={{ cursor: 'pointer' }}
                title={ariaLabelByTestType(type)}
                aria-label="college-score-tooltip"
              >
                <Typography key={index} variant="body1" component="p">
                  <Box
                    component="span"
                    fontWeight="fontWeightBold"
                    display="inline"
                  >
                    {type.toUpperCase()}:
                  </Box>{' '}
                  {displayTestScore(type, scores)}
                </Typography>
              </Tooltip>
            );
          })}
      </Stack>
      <ModalDialog
        isOpen={open}
        handleClose={() => setOpen(false)}
        content={
          <AboutFooterForm
            about={about}
            open={open}
            setOpen={setOpen}
            setAbout={setAbout}
          />
        }
        title="Edit Profile"
      />
    </Box>
  );
};

const AboutFooterForm = ({ about, open, setOpen, setAbout }) => {
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [aboutDetails, setAboutDetails] = useState({});

  const [gpaError, setGpaError] = useState('');
  const [gpaScaleError, setGpaScaleError] = useState('');
  const [classRankError, setClassRankError] = useState('');
  const [classSizeError, setClassSizeError] = useState('');

  const errors = [
    setGpaError,
    setGpaScaleError,
    setClassRankError,
    setClassSizeError,
  ];

  useEffect(() => {
    if (about) {
      const { gpa, gpaScale, classRank, classSize } = about;
      setAboutDetails({ gpa, gpaScale, classRank, classSize });
    }
  }, [about]);

  const clearErrors = () => errors.forEach((error) => error(''));

  const handleChange = (e, type) => {
    clearErrors();
    const { value } = e.target;
    setAboutDetails((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    clearErrors();
    if (validate()) {
      setLoading(true);

      const {
        message: { error, body },
      } = await postAboutMeData({ ...aboutDetails }, cookies);
      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        let {
          data: { attributes },
        } = await body;

        setAbout((prev) => {
          return {
            ...prev,
            ...attributes,
          };
        });
        enqueueSnackbar('Favorites Updated', {
          variant: 'success',
        });
      }
      setLoading(false);
      setOpen(false);
    }
  };

  const validate = () => {
    const { message, type, pass } = validateAboutMe({ ...aboutDetails });
    console.log({ message, type, pass });
    switch (type) {
      case 'gpa':
        setGpaError(message);
        return pass;
      case 'gpaScale':
        setGpaScaleError(message);
        return pass;
      case 'classRank':
        setClassRankError(message);
        return pass;
      case 'classSize':
        setClassSizeError(message);
        return pass;
      default:
        return pass;
    }
  };

  const formProps = [
    {
      label: 'GPA',
      value: aboutDetails.gpa,
      event: 'gpa',
      error: gpaError,
    },
    {
      label: 'GPA Scale',
      value: aboutDetails.gpaScale,
      event: 'gpaScale',
      error: gpaScaleError,
    },
    {
      label: 'Class Rank',
      value: aboutDetails.classRank,
      event: 'classRank',
      error: classRankError,
    },
    {
      label: 'Class Size',
      value: aboutDetails.classSize,
      event: 'classSize',
      error: classSizeError,
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      autoComplete="off"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Stack spacing={2}>
        {formProps.map((stateObj) => {
          return (
            <TextField
              key={stateObj.label}
              id="filled-multiline-flexible"
              label={stateObj.label}
              multiline
              maxRows={4}
              value={stateObj.value}
              onChange={(event) => handleChange(event, stateObj.event)}
              variant="filled"
              helperText={stateObj.error}
              error={!!stateObj.error}
            />
          );
        })}
      </Stack>
      <Button sx={{ mx: 5, my: 2 }} type="submit" variant="contained">
        {loading ? 'loading' : 'Submit'}
      </Button>
    </Box>
  );
};

export default Footer;
