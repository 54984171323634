import React from 'react';
import { StudentExamReport } from 'components/counselor/studentExamReport';
import { useAuthUser } from '../../store/UserContext';

const ExamReportsPage = () => {
  const user = useAuthUser();

  if (!user?.id) return <></>;

  return <StudentExamReport sId={user?.id} />;
};

export default ExamReportsPage;
