const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
const myHeaders = (cookies) => {
  const headers = new Headers();
  headers.append('token', cookies.tokens.token);
  headers.append('Content-Type', 'application/json');
  return headers;
};

export const getColleges = async (cookies, page, searchQuery) => {
  let url = `/colleges/all?currentPage=${page}`;

  if (searchQuery !== '') {
    let param = isNaN(searchQuery) ? 'name' : 'ceeb';
    url = url + `&${param}=${searchQuery}`;
  }

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'GET',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);

  if (response.status === 404) {
    return false;
  }
  return await response.json();
};

export const addNewCollege = async (cookies, data) => {
  let url = `/colleges/college`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'POST',
    headers: myHeaders(cookies),
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);
  return await response.json();
};

export const deleteCollege = async (cookies, ceeb) => {
  let url = `/colleges/${ceeb}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'DELETE',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);
  if (response.status === 204) {
    return true;
  } else {
    return await response.json();
  }
};

export const editCollege = async (cookies, data) => {
  let url = `/colleges/${data.ceeb_code}`;
  delete data.ceeb_code;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'PATCH',
    headers: myHeaders(cookies),
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);
  return await response.json();
};

export const addDeadlines = async (cookies, data, ceeb) => {
  let url = `/colleges/deadlines`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  data.ceeb = ceeb;

  const configObj = {
    method: 'POST',
    headers: myHeaders(cookies),
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);
  return await response.json();
};

export const editDeadlines = async (cookies, data, ceeb) => {
  let url = `/colleges/${ceeb}/deadlines`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'PATCH',
    headers: myHeaders(cookies),
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) return true;

  return await response.json();
};

export const deleteDeadlines = async (cookies, ceeb) => {
  let url = `/colleges/${ceeb}/deadlines`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const configObj = {
    method: 'DELETE',
    headers: myHeaders(cookies),
  };

  const response = await fetch(url, configObj);
  if (response.status === 204) {
    return true;
  } else {
    return await response.json();
  }
};
