import React from 'react';
import { Footer as ExamsFooter } from './Footer';
import { capitalize } from '../../../../utils/capitalize';

// MUI IMPORTS
import { Stack, Divider, Typography, Box, Grid } from '@mui/material';

export const Card = ({
  id,
  setExams,
  attributes: { type, dateTaken, scores },
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: '2px solid',
        borderColor: '#455FE8',
        borderRadius: '17px',
      }}
    >
      <Grid container item spacing={1} sx={{ p: 2, width: '100%' }}>
        <Grid container item xs={4} align="center" justify="center">
          <Typography sx={{ my: 'auto' }} variant="h6" component="h6">
            {type}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              borderColor: '#8F8D93',
              borderLeftWidth: '3px',
              borderRightWidth: '0',
              height: '80%',
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <Stack direction="column" spacing={1}>
            {scores &&
              Object.entries(scores).map(([k, v]) => {
                return (
                  <Typography
                    key={`${k}${v}`}
                    gutterBottom
                    variant="body"
                    component="p"
                  >
                    {capitalize(k)} :{' '}
                    <Box
                      fontWeight="fontWeightBold"
                      sx={{ color: '#455FE8' }}
                      display="inline"
                    >
                      {v}
                    </Box>
                  </Typography>
                );
              })}
          </Stack>
        </Grid>
      </Grid>
      <ExamsFooter id={id} setExams={setExams} dateTaken={dateTaken} />
    </Box>
  );
};
