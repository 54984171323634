import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import React, { useState, useEffect } from 'react';
import { studentTasks, editTask } from './functions/requests';

//MUI IMPORTS
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import DatePicker from '@mui/lab/DatePicker';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableContainer from '@mui/material/TableContainer';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export default function StudentTasks({ id }) {
  const [tasks, setTasks] = useState([]);
  const [cookie] = useCookies(['token']);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getStudentTasks = async () => {
      const response = await studentTasks(cookie, id);
      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        setTasks(response.data);
      }
    };
    getStudentTasks();
  }, [id, cookie, enqueueSnackbar]);

  const handleDueDateChange = (date, task) => {
    const newTasks = [...tasks];
    const newTask = newTasks.find((el) => el.id === task.id);
    newTask.dueDate = date.toISOString();
    enqueueSnackbar('Due Date changed, remember to save.', {
      variant: 'warning',
    });
    setTasks(newTasks);
  };

  const handleEdit = (e, task) => {
    const body = {
      due_date: task.dueDate,
      completed_date: task.completedDate,
    };
    const response = editTask(cookie, task.id, body);
    if (response.error) {
      enqueueSnackbar(response.message, { variant: 'error' });
    } else {
      enqueueSnackbar('Due Date Saved.', {
        variant: 'success',
      });
    }
  };

  const toggleComplete = (event, task) => {
    if (task.completedDate == null) {
      task.completedDate = new Date().toISOString();
      enqueueSnackbar('Task completed!', { variant: 'success' });
    } else {
      task.completedDate = null;
      enqueueSnackbar('Task set to Incomplete', { variant: 'warning' });
    }
    setTasks([...tasks]);
    const body = {
      due_date: task.dueDate,
      completed_date: task.completedDate,
    };
    editTask(cookie, task.id, body);
  };

  return (
    <Grid container>
      {tasks && (
        <>
          <Grid item xs={12}>
            <Typography>
              Tasks {tasks.filter((el) => el.completedDate).length}/
              {tasks.length}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TaskTable
              tasks={tasks}
              toggleComplete={toggleComplete}
              handleDueDateChange={handleDueDateChange}
              handleEdit={handleEdit}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function InlineDatePicker({ task, handleDueDateChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        minDate={new Date()}
        value={task.dueDate}
        onChange={(e) => handleDueDateChange(e, task)}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

export function TaskTable({
  tasks,
  toggleComplete,
  handleDueDateChange,
  handleEdit,
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="tasks table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Completed?</StyledTableCell>
            <StyledTableCell>Task</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Due Date</StyledTableCell>
            <StyledTableCell>Completed Date</StyledTableCell>
            <StyledTableCell>Save</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task) => {
            return (
              <StyledTableRow key={task.id}>
                <StyledTableCell>
                  <Checkbox
                    color="success"
                    onClick={(e) => toggleComplete(e, task)}
                    checked={task.completedDate !== null}
                  />
                </StyledTableCell>
                <StyledTableCell>{task.name}</StyledTableCell>
                <StyledTableCell>{task.description}</StyledTableCell>
                <StyledTableCell>
                  {task.completedDate == null ? (
                    <InlineDatePicker
                      task={task}
                      handleDueDateChange={handleDueDateChange}
                    />
                  ) : (
                    task.dueDate
                  )}
                </StyledTableCell>
                <StyledTableCell>{task.completedDate}</StyledTableCell>
                {task.completedDate == null ? (
                  <StyledTableCell>
                    <Button
                      onClick={(e) => handleEdit(e, task)}
                      variant="outlined"
                      size="small"
                      color="success"
                    >
                      Save
                    </Button>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>
                    <Button
                      onClick={(e) => handleEdit(e, task)}
                      variant="outlined"
                      size="small"
                      color="success"
                      disabled
                    >
                      Saved
                    </Button>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
