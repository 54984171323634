import React from 'react';
import Logo from '../../assets/VizeLogo_NoBackgroundNoTM_new.png';
import SignUpForm from '../forms/SignUpForm';

// MUI IMPORTS
import { Box, Grid } from '@mui/material';

const AdminSignUp = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Grid
        container
        justifyContent="center"
        spacing={0}
        style={{ height: '70%', width: '70%' }}
      >
        <img style={{ maxHeight: '64px' }} src={Logo} alt={'Vize Logo'} />
        <SignUpForm roles={['counselor', 'student']} />
      </Grid>
    </Box>
  );
};

export default AdminSignUp;
