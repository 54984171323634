import React, { useState } from 'react';
import { ResourceHOC } from './HOC';
import ListContainer from '../ListContainer';
import { isEmpty } from 'lodash';
import { Form as FavoriteForm } from './Form';

const FavoritesList = ({ about, isAboutLoading, aboutError, setAbout }) => {
  const empty = isEmpty(about) || isEmpty(about?.orderedFavorites);
  const [open, setOpen] = useState(false);

  return (
    <ResourceHOC
      isLoading={isAboutLoading}
      serverError={aboutError}
      title="Favorite"
      isEmpty={empty}
      setOpen={setOpen}
      setAbout={setAbout}
    >
      {about?.orderedFavorites && (
        <ListContainer
          arr={Object.values(about.orderedFavorites)}
          title="Favorite"
          isOpen={open}
          setIsOpen={setOpen}
          handleClick={() => setOpen(true)}
          handleClose={() => setOpen(false)}
          modalContent={
            <FavoriteForm
              setOpen={setOpen}
              favorites={about.orderedFavorites}
              setAbout={setAbout}
            />
          }
        />
      )}
    </ResourceHOC>
  );
};

export default FavoritesList;
