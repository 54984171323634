const signIn = (data) => {
  let url = `/auth/login`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const body = {
    email: data?.email,
    password: data?.password,
  };

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  return fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw new Error(
        `Unable to process API request to: ${url} REASON: ${e.message}`,
      );
    });
};

export default signIn;
