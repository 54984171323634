import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  sm: {
    height: '12px',
  },
  md: {
    height: '18px',
  },
  lg: {
    height: '24px',
  },
});

// default spacer with 10px height
const HeightSpacer = ({ sm, md, lg }) => {
  let sizeClass;
  const classes = useStyles();

  if (sm) sizeClass = classes.sm;
  if (md) sizeClass = classes.md;
  if (lg) sizeClass = classes.lg;

  if (!sm && !md && !lg) sizeClass = classes.sm;

  return <div className={`${classes.root} ${sizeClass}`} />;
};

export default HeightSpacer;
