//MUI IMPORTS
import { Typography, Stack } from '@mui/material';

// Split content into paragraphs by splitting on ####
const splitContent = (content) => {
  const paragraphs = content.split('####');
  return paragraphs;
};

// map over paragraphs and wrap matching text in a Typography component
const mapParagraphs = (paragraphs) => {
  return paragraphs.map((paragraph, index) => {
    return (
      <Typography key={index} variant="body1" component="p">
        {paragraph}
      </Typography>
    );
  });
};

const ParsedString = ({ content }) => {
  const paragraphs = splitContent(content);
  const mappedParagraphs = mapParagraphs(paragraphs);
  return <Stack>{mappedParagraphs}</Stack>;
};

export default ParsedString;
