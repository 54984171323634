import React from 'react';
import { getColleges } from '../../admin-colleges/functions/requests';
import SharedSearchComponent from '../reusable-components/SharedSearchComponent';

const CollegeSearch = ({ applyToAll, addedIds, setAddedIds }) => {
  const getOptionLabel = (option) => {
    return `${option?.attributes?.name}`;
  };

  return (
    <SharedSearchComponent
      applyToAll={applyToAll}
      label="Add colleges"
      callbackGet={getColleges}
      extraParameter={1}
      getOptionLabel={getOptionLabel}
      addedIds={addedIds}
      setAddedIds={setAddedIds}
    />
  );
};

export default CollegeSearch;
