import React from 'react';
import NullObjectComponent from './NullObject';

// MUI IMPORTS
import { Grid, Typography, Skeleton } from '@mui/material';

const LoadingComponent = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={20} />
      </Grid>
    </Grid>
  );
};

const ErrorComponent = ({ error }) => {
  return (
    <Typography variant="body1" color="error" component="p" pb={2}>
      {error}
      {/* Todo: Replace with error response */}
    </Typography>
  );
};

//Loading / Error/ Null Components can be imported or created custom in file.
export const ResourceHOC = ({
  isEmpty,
  isLoading,
  serverError,
  children,
  title,
  setAbout,
  setOpen,
}) => {
  if (isLoading) return <LoadingComponent />;
  if (serverError) return <ErrorComponent error={serverError} />;
  if (isEmpty) return <NullObjectComponent setAbout={setAbout} />;
  return children;
};
