import Joi from 'joi';
import {
  regstr,
  strMessages,
  errorReader,
} from 'components/student/functions/validation-helpers';

const courseName = Joi.string()
  .regex(regstr)
  .min(2)
  .max(30)
  .messages(strMessages('Course Name'))
  .required();

const gradeYear = Joi.string()
  .alphanum()
  .messages(strMessages('Grade'))
  .required();

const semester = Joi.string()
  .regex(/^[a-zA-Z0-9+\-,.]*$/)
  .messages(strMessages('Semester'));

const validateCourses = (data) => {
  const schema = Joi.object().keys({
    name: courseName,
    gradeYear: gradeYear,
    semester: semester,
  });

  const { error } = schema.validate(data);
  return errorReader(error);
};

export { validateCourses };
