import React from 'react';

// MUI IMPORTS
import { Grid, Box, Typography, CircularProgress } from '@mui/material';

const LoadingComponent = () => {
  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#fafafa',
          borderRadius: '0.25rem',
        }}
      >
        <CircularProgress />
      </Box>
    </Grid>
  );
};

const NullObjectComponent = () => {
  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#fafafa',
          borderRadius: '0.25rem',
        }}
      >
        <Typography variant="h6" component="p" pb={2}>
          No Data
        </Typography>
      </Box>
    </Grid>
  );
};

const ErrorComponent = ({ error }) => {
  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <Typography variant="h6" color="danger" component="p" pb={2}>
          {error}
        </Typography>
      </Box>
    </Grid>
  );
};

//Loading / Error/ Null Components can be imported or created custom in file.
export const ResourceHOC = ({ hasData, isLoading, serverError, children }) => {
  if (isLoading) return <LoadingComponent />;
  if (serverError) return <ErrorComponent error={serverError} />;
  if (hasData) return <NullObjectComponent />;
  return children;
};
