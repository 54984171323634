const getInitialsFromName = (name) => {
    if (typeof name === 'string') {
        const nameAsArray = name.split(' ');
        
        const reducedName = nameAsArray.reduce((acc, name) => {
            const uppercaseFirstLetter = name.charAt(0).toUpperCase();
            acc.push(uppercaseFirstLetter);
            return acc;
        }, [])

        const initials = reducedName.join('');
        return initials;
    }

    return '';
}

export default getInitialsFromName;