import React from 'react';
import { isEmpty } from 'lodash';
import { ResourceHOC } from './ResourceHOC';
import { Container as CourseWorkContainer } from './Container';
import { useFetch } from '../../profile/functions/hooks';

const courseWorkURL = '/users/course';

const CourseWork = () => {
  const {
    resource: courseWork,
    setResource: setCourseWork,
    isLoading: isCourseWorkLoading,
    serverError: courseWorkError,
  } = useFetch(courseWorkURL, (data) => {
    return {
      id: data.id,
      ...data.attributes,
    };
  });

  const hasData = isEmpty(courseWork);

  return (
    <ResourceHOC
      isLoading={isCourseWorkLoading}
      serverError={courseWorkError}
      hasData={hasData}
      title="CourseWork"
      handleNoData={setCourseWork}
    >
      {courseWork && (
        <CourseWorkContainer
          courseWork={courseWork}
          setCourseWork={setCourseWork}
        />
      )}
    </ResourceHOC>
  );
};

export default CourseWork;
