import React, { useState } from 'react';
import ModalDialog from '../ModalDialog';
import { EditProfileForm } from './EditProfileForm';

// MUI IMPORTS
import { Box, Typography, IconButton } from '@mui/material';

// REACTLY - ICONS
import { Location, EditSquare as EditIcon } from 'react-iconly';

const Profile = ({
  setProfile,
  first_name,
  last_name,
  pronoun,
  state,
  zip_code,
  city,
  ...profile
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton
          siz="small"
          sx={{ height: '25px' }}
          onClick={(e) => setOpen(true)}
        >
          <EditIcon set="bold" color="#455FE8" style={{ height: '25px' }} />
        </IconButton>{' '}
        <Typography
          variant="h6"
          component="p"
          sx={{ fontSize: '2.5em', inlineSize: '75%' }}
        >
          {first_name} {last_name}
          <Box
            component="span"
            display="inline"
            sx={{ fontWeight: 500, opacity: 0.5, mx: 2, fontSize: '.5em' }}
          >
            {pronoun && `(${pronoun})`}
          </Box>
        </Typography>
      </Box>
      {state && (
        <Typography
          variant="body"
          component="p"
          color="primary"
          sx={{ fontSize: '1.5em' }}
        >
          <Box component="span" display="inline" sx={{ color: 'black' }}>
            <Location set="bulk" />
          </Box>{' '}
          {city}, {state}
        </Typography>
      )}
      <ModalDialog
        isOpen={open}
        handleClose={() => setOpen(false)}
        content={
          <EditProfileForm
            profile={{
              first_name,
              last_name,
              pronoun,
              state,
              zip_code,
              city,
              ...profile,
            }}
            setProfile={setProfile}
            handleClose={() => setOpen(false)}
          />
        }
        title="Edit Profile"
      />
    </>
  );
};

export default Profile;
