import React, { useState } from 'react';

import CollegeContent from '../tasks-v2/CollegeContent';
import ModalDialog from '../ModalDialog';

// MUI IMPORTS
import { Box, Chip } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const TaskChip = ({ id, importance, dueDate, completed, notes, name }) => {
  const [open, openSet] = useState(false);

  const truncateString = (str) => {
    let ns = str.substring(0, 5);
    return `${ns}...`;
  };

  return (
    <Box key={id}>
      <Tooltip title={name} arrow>
        <Chip
          sx={{ width: '100%' }}
          onClick={() => openSet(true)}
          icon={completed ? <AssignmentTurnedInIcon /> : <AssignmentLateIcon />}
          color={completed ? 'primary' : 'secondary'}
          label={truncateString(name)}
        />
      </Tooltip>
      <ModalDialog
        isOpen={open}
        handleClose={() => openSet(false)}
        content={<CollegeContent why={importance} how={notes} />}
        title={name}
      />
    </Box>
  );
};

export default TaskChip;
