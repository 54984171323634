import * as React from 'react';
import StudentTasks from './StudentTasks';
import StudentDetails from './StudentDetails';

//MUI IMPORTS
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import Paper from '@mui/material/Paper';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Container from '@mui/material/Container';

export default function StudentLayout({ student }) {
  const [value, setValue] = React.useState('1');
  const id = student.id;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ mb: 5 }}>
      <Box component={Paper} sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label={`${student.firstName} ${student.lastName}`}
                disabled
              />
              <Tab label="Details" value="1" />
              <Tab label="Tasks" value="2" />
              <Tab label="Action Items" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <StudentDetails student={student} />
          </TabPanel>
          <TabPanel value="2">
            <StudentTasks id={id} />
          </TabPanel>
          <TabPanel value="3">
            A space for other actionable items for the student
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
}
