import React from 'react';
import { Footer as CourseWorkFooter } from './Footer';

// MUI IMPORTS
import { Divider, Typography, Box, Grid } from '@mui/material';

export const Card = ({
  id,
  setCourseWork,
  name,
  gradeYear,
  semesterGrades: { semester1, semester2 },
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: '2px solid',
        borderColor: '#455FE8',
        borderRadius: '17px',
      }}
    >
      <Grid container item spacing={1} sx={{ p: 1, width: '100%' }}>
        <Grid item xs={3} lg={12}>
          <Typography variant="h6" component="h6" align="center" sx={{ mt: 2 }}>
            {name}
          </Typography>
          <Typography component="p" align="center">
            {gradeYear}
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ display: { xs: 'block', md: 'none' } }}>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              borderColor: '#8F8D93',
              borderLeftWidth: '3px',
              borderRightWidth: '0',
              height: '80%',
            }}
          />
        </Grid>
        <Grid item xs={4} lg={12}>
          <Typography variant="h6" component="p" align="center">
            First Semester:
            <Box fontWeight="fontWeightBold" sx={{ color: '#455FE8' }}>
              {semester1}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={4} lg={12}>
          <Typography variant="h6" component="p" align="center">
            Second Semester:
            <Box fontWeight="fontWeightBold" sx={{ color: '#455FE8' }}>
              {semester2}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <CourseWorkFooter
        id={id}
        setCourseWork={setCourseWork}
        name={name}
        gradeYear={gradeYear}
        semester1={semester1}
        semester2={semester2}
      />
    </Box>
  );
};
