import React, { useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

//MUI imports
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const AssignTask = ({
  taskId,
  addedIds,
  setAddedIds,
  applyToAll,
  callbackPost,
}) => {
  const [cookies] = useCookies('tokens');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const submitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      try {
        if (taskId && (addedIds.length !== 0 || applyToAll)) {
          const ids = addedIds.map((value) => {
            return value?.id;
          });
          const response = await callbackPost(taskId, cookies, ids, applyToAll);
          if (response.error) {
            enqueueSnackbar(response.message, { variant: 'error' });
          } else {
            enqueueSnackbar('Done', { variant: 'success' });
            setAddedIds([]);
          }
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [
      cookies,
      taskId,
      applyToAll,
      callbackPost,
      addedIds,
      setAddedIds,
      enqueueSnackbar,
    ],
  );

  return (
    <Box m={1} p={2}>
      <Button type="submit" variant="contained" onClick={submitHandler}>
        {loading ? 'Processing...' : 'Apply'}
      </Button>
    </Box>
  );
};

export default AssignTask;
