import { useCookies } from 'react-cookie';
import OpenRoute from './routes/OpenRoute';
import jwtDecoder from './utils/jwtDecoder';
import { UserContext } from 'store/UserContext';
import PrivateRoute from './routes/PrivateRoute';
import AdminNavbar from './components/admin/Navbar';
import { Switch, useLocation } from 'react-router-dom';
import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  studentRoutes,
  openRoutes,
  adminRoutes,
  counselorRoutes,
  examAdminRoutes,
  accountAdminRoutes,
} from './routes/routes';
import { DrawerAndNav as StudentNavbar } from './components/student/DrawerAndNav';
import Fade from '@mui/material/Fade';
import { TransitionGroup } from 'react-transition-group';
import { getAdditionalUserData } from './components/student/profile/functions/requests';
import { syncAllTasks } from './components/student/tasks-v2/functions/requests';
import { syncExamAdminUserAccess } from './components/exam-admin/functions/requests';
import NotificationsProvider from 'store/NotificationsContext';
import useUserEntity from 'hooks/useUserEntity';

export default function App() {
  const [cookies] = useCookies('tokens');
  const [cookie] = useCookies(['tokens']);
  const [appLoading, setAppLoading] = useState(true);
  const [user, setUser] = useState({ role: 'not-logged-in' });
  const entity = useUserEntity({ cookie, userId: user?.id });
  const location = useLocation();

  const setUserFromToken = useCallback(async (token) => {
    let u = jwtDecoder(token).user;
    try {
      const userData = await getAdditionalUserData({ tokens: { token } });

      // sync tasks for students
      if (u.role === 'student') {
        await Promise.all([
          syncAllTasks({ tokens: { token } }, 'standard'),
          syncAllTasks({ tokens: { token } }, 'college'),
        ]);
      }

      // sync user access for exam admins
      if (u.role === 'exam_admin') {
        await syncExamAdminUserAccess({ tokens: { token } });
      }

      const {
        body: {
          data: { attributes },
        },
      } = userData.message;
      if (attributes) {
        const { first_name, last_name, email } = attributes;
        u = { ...u, first_name, last_name, email };
      }
      setUser(u);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useLayoutEffect(() => {
    const setUserFromCookies = async () => {
      try {
        if (!!cookies?.tokens?.token) {
          await setUserFromToken(cookies?.tokens?.token);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setAppLoading(false);
      }
    };

    setUserFromCookies();
  }, [cookies, setUserFromToken]);

  useEffect(() => {
    if (!!cookies?.tokens?.token) {
      setUserFromToken(cookies?.tokens?.token);
    }
  }, [cookies, setUserFromToken]);

  // TODO: add giant loading spinner when loading...
  if (appLoading) return <></>;

  return (
    <UserContext.Provider value={{ user, setUserFromToken, entity }}>
      <Switch location={location}>
        {adminRoutes.map((route) => {
          return (
            <PrivateRoute
              key={route.path}
              path={route.path}
              gaurd={user.role === 'admin'}
            >
              <AdminNavbar>
                <route.component />
              </AdminNavbar>
            </PrivateRoute>
          );
        })}

        {studentRoutes.map((route) => {
          return (
            <PrivateRoute
              key={route.path}
              path={route.path}
              gaurd={user.role === 'student'}
            >
              <NotificationsProvider>
                <StudentNavbar userRole={user.role}>
                  <TransitionGroup>
                    <Fade in={true} {...{ timeout: 1000 }}>
                      <div>
                        <route.component />
                      </div>
                    </Fade>
                  </TransitionGroup>
                </StudentNavbar>
              </NotificationsProvider>
            </PrivateRoute>
          );
        })}

        {counselorRoutes.map((route) => {
          return (
            <PrivateRoute
              key={route.path}
              path={route.path}
              gaurd={user.role === 'counselor'}
            >
              <StudentNavbar userRole={user.role}>
                <TransitionGroup>
                  <Fade in={true} {...{ timeout: 1000 }}>
                    <div>
                      <route.component />
                    </div>
                  </Fade>
                </TransitionGroup>
              </StudentNavbar>
            </PrivateRoute>
          );
        })}

        {accountAdminRoutes.map((route) => {
          return (
            <PrivateRoute
              key={route.path}
              path={route.path}
              gaurd={user.role === 'account_admin'}
            >
              <StudentNavbar userRole={user.role}>
                <TransitionGroup>
                  <Fade in={true} {...{ timeout: 1000 }}>
                    <div>
                      <route.component />
                    </div>
                  </Fade>
                </TransitionGroup>
              </StudentNavbar>
            </PrivateRoute>
          );
        })}

        {examAdminRoutes.map((route) => {
          return (
            <PrivateRoute
              key={route.path}
              path={route.path}
              gaurd={user.role === 'exam_admin'}
            >
              <StudentNavbar userRole={user.role}>
                <TransitionGroup>
                  <Fade in={true} {...{ timeout: 1000 }}>
                    <div>
                      <route.component />
                    </div>
                  </Fade>
                </TransitionGroup>
              </StudentNavbar>
            </PrivateRoute>
          );
        })}
        {openRoutes.map((route) => {
          return (
            <OpenRoute key={route.path} path={route.path}>
              <route.component role={user.role} />
            </OpenRoute>
          );
        })}
      </Switch>
    </UserContext.Provider>
  );
}
