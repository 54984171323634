import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const ReminderLayout = () => {
  return (
    <Box
      sx={{
        width: 300,
        height: 'auto',
        backgroundColor: 'primary',
        padding: '12px',
      }}
    >
      <TextField
        id="filled-multiline-flexible"
        label="Title"
        placeholder="Title"
        value={''}
        onChange={() => {
          console.log('value changed...');
        }}
        variant="filled"
        type="text"
      />
      <TextField
        id="filled-multiline-flexible"
        label="Description"
        value={''}
        placeholder="Description"
        onChange={() => {
          console.log('value changed...');
        }}
        variant="filled"
        type="text"
      />
      <TextField
        id="filled-multiline-flexible"
        label="Due Date"
        value={''}
        onChange={() => {
          console.log('value changed...');
        }}
        placeholder="Due Date"
        variant="filled"
        type="text"
      />
    </Box>
  );
};

export default ReminderLayout;
