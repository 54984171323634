import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { clear } from './functions/utils';
import { patchActivity } from './functions/requests';
import { validateActivities } from './functions/validations';
import { useSnackbar } from 'notistack';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const Edit = ({
  id,
  title,
  description,
  startYear,
  endYear,
  weeksPerYear,
  hoursPerWeek,
  setActivities,
  setOpen,
}) => {
  const [activity, setActivity] = useState({
    title,
    description,
    startYear,
    endYear,
    weeksPerYear,
    hoursPerWeek,
  });

  useEffect(() => {
    setActivity({
      description,
      endYear,
      hoursPerWeek,
      startYear,
      title,
      weeksPerYear,
    });
  }, [description, endYear, hoursPerWeek, startYear, title, weeksPerYear]);

  const handleChange = (value, label) => {
    setActivity((prev) => {
      return { ...prev, [label]: value };
    });
  };

  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState('');
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [startYearError, setStartYearError] = useState('');
  const [endYearError, setEndYearError] = useState('');
  const [weeksPerYearError, setWeeksPerYearError] = useState('');
  const [hoursPerWeekError, setHoursPerWeekError] = useState('');

  const errorSetters = [
    setTitleError,
    setDescriptionError,
    setStartYearError,
    setEndYearError,
    setWeeksPerYearError,
    setHoursPerWeekError,
  ];

  const validate = (data) => {
    const { message, type, pass } = validateActivities(data);
    switch (type) {
      case 'title':
        setTitleError(message);
        return pass;
      case 'description':
        setDescriptionError(message);
        return pass;
      case 'startYear':
        setStartYearError(message);
        return pass;
      case 'endYear':
        setEndYearError(message);
        return pass;
      case 'weeksPerYear':
        setWeeksPerYearError(message);
        return pass;
      case 'hoursPerWeek':
        setHoursPerWeekError(message);
        return pass;

      default:
        return pass;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clear(errorSetters);
    if (validate(activity)) {
      setLoading(true);
      handlePatch({
        ...activity,
        startYear: String(activity.startYear),
        endYear: String(activity.endYear),
      });
    }
  };

  const handlePatch = async (preData) => {
    try {
      const {
        message: { error, body },
      } = await patchActivity(preData, id, cookies);

      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setLoading(false);
        throw errorMessage;
      } else {
        enqueueSnackbar('Activity Successfully Edited.', {
          variant: 'success',
        });
        setOpen((prev) => !prev);
        setActivities((prev) => {
          const newActivities = prev.map((el) => {
            if (el.id === id) {
              return { id, ...activity };
            } else {
              return el;
            }
          });
          return newActivities;
        });
      }
    } catch (error) {
      console.error('error:', error);
    } finally {
      setLoading(false);
    }
  };

  const formProps = [
    {
      label: 'Title',
      value: activity.title,
      change: 'title',
      error: titleError,
    },
    {
      label: 'Description',
      value: activity.description,
      change: 'description',
      error: descriptionError,
    },
    {
      label: 'Weeks per year',
      value: activity.weeksPerYear,
      change: 'weeksPerYear',
      error: weeksPerYearError,
    },
    {
      label: 'Hours per week',
      value: activity.hoursPerWeek,
      change: 'hoursPerWeek',
      error: hoursPerWeekError,
    },
  ];

  const yearFromNowTo50YearsAgo = () => {
    const years = [];
    for (let i = 2022; i >= 1972; i--) {
      years.push(i);
    }

    return years;
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      autoComplete="off"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          height: '30%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            mx: 2,
          }}
          spacing={2}
        >
          {formProps.map((stateObj, index) => {
            return (
              <TextField
                key={index}
                id="filled-multiline-flexible"
                label={stateObj.label}
                multiline
                maxRows={4}
                value={stateObj.value}
                onChange={(event) =>
                  handleChange(event.target.value, stateObj.change)
                }
                variant="filled"
                helperText={stateObj.error}
                error={!!stateObj.error}
              />
            );
          })}
          <FormControl
            variant="filled"
            sx={{ m: 1, minWidth: 120 }}
            error={!!startYearError}
          >
            <InputLabel id="grade-select-label">Start Year</InputLabel>
            <Select
              labelId="start-year-select-label"
              id="start-year-select"
              value={activity.startYear}
              label="startYear"
              MenuProps={{
                style: {
                  maxHeight: 200,
                },
              }}
              onChange={(event) =>
                handleChange(event.target.value, 'startYear')
              }
            >
              {yearFromNowTo50YearsAgo().map((t) => {
                return (
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                );
              })}
            </Select>
            {startYearError && (
              <FormHelperText>{startYearError}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="filled"
            sx={{ m: 1, minWidth: 120 }}
            error={!!endYearError}
          >
            <InputLabel id="end-year-label">End year</InputLabel>
            <Select
              labelId="end-year-select-label"
              id="end-year-select"
              value={activity.endYear}
              label="endYear"
              MenuProps={{
                style: {
                  maxHeight: 200,
                },
              }}
              onChange={(event) => handleChange(event.target.value, 'endYear')}
            >
              {yearFromNowTo50YearsAgo().map((t) => {
                return (
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                );
              })}
            </Select>
            {endYearError && <FormHelperText>{endYearError}</FormHelperText>}
          </FormControl>
        </Stack>
      </Box>
      <Button sx={{ mx: 5, my: 2 }} type="submit" variant="contained">
        {loading ? 'loading' : 'Submit'}
      </Button>
    </Box>
  );
};
