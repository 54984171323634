import Form from './Form';
import React, { useEffect } from 'react';
import AnalyticsService from '../../../analyticsService';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Layout = () => {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Student edit-password' });
  }, []);

  return (
    <Box sx={{ flexGrow: 'auto' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form />
        </Grid>
      </Grid>
    </Box>
  );
};
