const addCounselorToEntity = async (email, entityId, cookie) => {
  let url = `/admin/entity/${entityId}/user`;

  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ email }),
  };

  const response = await fetch(url, requestOptions);

  if (response.status === 201 || response.status === 200) {
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  return { error: `Unable to add ${email} to entity` };
};

export default addCounselorToEntity;
