import mixpanel from 'mixpanel-browser';

class AnalyticsService {
  constructor() {
    this._init = false;
  }
  init() {
    this._init = true;
    return this._init;
  }
  static getInstance() {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance;
  }
  async track(eventName, data) {
    // make sure your mixpanel class is initialized
    if (!this._init) {
      await this.initService();
    }
    // call mixpanel's track function
    mixpanel.track(eventName, data);
  }
  async initService() {
    if (!this._init) {
      try {
        await mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
      } catch (e) {
        console.log('unable to init mixpanel on web');
        console.log(e);
      }
      this._init = true;
    }
  }
}
export default AnalyticsService.getInstance();
