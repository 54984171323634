import React from 'react';
import { LoadingComponent } from '../Loading';
import { Container as ExamsContainer } from './Container';
// MUI IMPORTS
import { Typography } from '@mui/material';

const NullObjectComponent = ({ title, handleClick }) => {
  return (
    <>
      <Typography variant="body1" color="error">
        Currently no {title}.
      </Typography>
      <ExamsContainer exams={[]} setExams={handleClick} />
    </>
  );
};

const ErrorComponent = ({ error }) => {
  return (
    <Typography variant="body1" color="error" component="p" pb={2}>
      {error}
      {/* Todo: Replace with error response */}
    </Typography>
  );
};

//Loading / Error/ Null Components can be imported or created custom in file.
export const HOC = ({
  hasData,
  isLoading,
  serverError,
  children,
  title,
  handleNoData,
}) => {
  if (isLoading) return <LoadingComponent />;
  if (serverError) return <ErrorComponent error={serverError} />;
  if (hasData)
    return <NullObjectComponent title={title} handleClick={handleNoData} />;
  return children;
};
