const addCounselorToSession = (sessionId, counselorId, cookie) => {
  let url = '/sessions/request/counselor';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const body = {
    counselorId,
    sessionRequestId: sessionId,
  };

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  return fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw new Error(
        `Unable to process API request to: ${url} REASON: ${e.message}`,
      );
    });
};

export default addCounselorToSession;
