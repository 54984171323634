import React from 'react';
import { Route } from 'react-router-dom';

function OpenRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        return children;
      }}
    />
  );
}

export default OpenRoute;
