import React from 'react';
import ModalDialog from '../ModalDialog';

// MUI IMPORTS
import {
  List,
  Typography,
  ListItem,
  IconButton,
  ListItemText,
} from '@mui/material';

// REACTLY - ICONS
import { EditSquare as EditIcon } from 'react-iconly';

const ListContainer = ({
  arr,
  title,
  handleClick,
  modalContent,
  handleClose,
  isOpen = false,
}) => {
  return (
    <>
      <Typography variant="h6" component="h6" noWrap={true}>
        <IconButton
          siz="small"
          sx={{ height: '25px' }}
          onClick={(e) => handleClick(e)}
        >
          <EditIcon set="bold" color="#455FE8" style={{ height: '25px' }} />
        </IconButton>{' '}
        {title}(s):
      </Typography>
      <List sx={{ overflowY: 'auto', maxHeight: '25vh' }}>
        {arr &&
          arr.map((el, index) => (
            <ListItem key={el + index}>
              <ListItemText primary={el} />
            </ListItem>
          ))}
      </List>
      <ModalDialog
        isOpen={isOpen}
        handleClose={handleClose}
        content={modalContent}
        title={`${title}:(s)`}
      />
    </>
  );
};

export default ListContainer;
