import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { validateOrderedMajorChoices } from '../functions/validations';
import { postAboutMeData } from '../functions/requests';
//MUI Imports
import { TextField, Box, Stack, Button } from '@mui/material';

export const Form = ({ majors, setAbout, setOpen }) => {
  const [orderedMajorChoices, setOrderedMajorChoices] = useState({
    major1: '',
    major2: '',
    major3: '',
  });

  useEffect(() => {
    if (majors) {
      setOrderedMajorChoices(majors);
    }
  }, [majors]);

  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies('tokens');
  const [loading, setLoading] = useState(false);
  const [major1Error, setMajor1Error] = useState('');
  const [major2Error, setMajor2Error] = useState('');
  const [major3Error, setMajor3Error] = useState('');

  const clearErrors = () =>
    [setMajor1Error, setMajor2Error, setMajor3Error].forEach((setError) =>
      setError(''),
    );

  const handleChange = (e, key) => {
    clearErrors();
    setOrderedMajorChoices((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    clearErrors();
    if (validate(orderedMajorChoices)) {
      setLoading(true);
      const {
        message: { error, body },
      } = await postAboutMeData({ orderedMajorChoices }, cookies);
      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        let {
          data: { attributes },
        } = await body;
       
        setAbout(attributes);
        enqueueSnackbar('Majors Updated', {
          variant: 'success',
        });
      }
      setLoading(false);
      setOpen(false);
    }
  };

  const validate = (data) => {
    const { message, type, pass } = validateOrderedMajorChoices(data);
    switch (type) {
      case 'major1':
        setMajor1Error(message);
        return pass;
      case 'major2':
        setMajor2Error(message);
        return pass;
      case 'major3':
        setMajor3Error(message);
        break;
      default:
        return pass;
    }
  };

  const formProps = [
    {
      label: 'Major 1',
      value: orderedMajorChoices.major1,
      event: 'major1',
      error: major1Error,
    },
    {
      label: 'Major 2',
      value: orderedMajorChoices.major2,
      event: 'major2',
      error: major2Error,
    },
    {
      label: 'Major 3',
      value: orderedMajorChoices.major3,
      event: 'major3',
      error: major3Error,
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        {formProps.map((stateObj) => {
          const { label, value, event, error } = stateObj;
          return (
            <TextField
              key={label}
              id="filled-multiline-flexible"
              label={label}
              multiline
              maxRows={4}
              value={value}
              onChange={(e) => handleChange(e, event)}
              variant="filled"
              helperText={error}
              error={!!error}
            />
          );
        })}
      </Stack>
      <Button sx={{ my: 2, width: '100%' }} type="submit" variant="contained">
        {loading ? 'loading' : 'Submit'}
      </Button>
    </Box>
  );
};
