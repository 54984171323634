import React, { useState } from 'react';
import ModalDialog from '../../ModalDialog';
import { New as NewActivityForm } from './New';
import ActivityCard from './Card';
import AddProfileDetail from '../AddProfileDetail';

// MUI IMPORTS
import { Box, List, ListItem, ListSubheader, Fade } from '@mui/material';

const ActivitiesContainer = ({ activities, setActivities }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          '& ul': { padding: 0 },
          overflowY: 'scroll',
          maxHeight: '85vh',
          mb: 2,
        }}
        subheader={<li />}
      >
        <ListSubheader>
          <AddProfileDetail onClick={handleClickOpen}>
            + Add Activity
          </AddProfileDetail>
        </ListSubheader>
        {activities.map((item, index) => {
          return (
            <Fade key={index} in={true} {...{ timeout: 1000 + 700 * index }}>
              <ListItem>
                <ActivityCard {...item} setActivities={setActivities} />
              </ListItem>
            </Fade>
          );
        })}
        <Box sx={{ height: '100px' }}></Box>
      </List>
      <ModalDialog
        isOpen={open}
        handleClose={handleClose}
        content={
          <NewActivityForm setOpen={setOpen} setActivities={setActivities} />
        }
        title="Add Activity"
        footerImg={true}
      />
    </>
  );
};

export default ActivitiesContainer;
