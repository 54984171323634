import Joi from 'joi';
import {
  strMessages,
  // regstr,
  errorReader,
} from '../../functions/validation-helpers';

// TODO: Change on Production
// const strongPasswordRegex = RegExp(
//   "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
// );

// const strongPasswordMessage = Object.assign(strMessages("Password"), {
//   "string.pattern.base": `Password must be strong. At least one upper case alphabet. At least one lower case alphabet. At least one digit. At least one special character. Minimum eight in length`,
// });

const password = Joi.string()
  .min(8)
  .messages(strMessages('Password'))
  .required();

const validatePasswordEdit = (data) => {
  const schema = Joi.object().keys({
    password: password,
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

export { validatePasswordEdit };
