import React from 'react';

//MUI IMPORTS
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import { useHistory } from 'react-router-dom';

const SnapshotButton = ({ studentId, hoveredStudent }) => {
  const history = useHistory();

  return (
    <TableCell>
      {hoveredStudent === studentId ? 'View Snapshot' : ''}
      <Button
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/admin/students/${studentId}/snapshot`)}
        variant="contained"
      >
        View Snapshot
      </Button>
    </TableCell>
  );
};

const StudentTable = ({ students, showStudent }) => {
  return (
    <Container>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((data) => {
              const id = data.id;
              const student = data.attributes;
              return (
                <TableRow
                  onClick={() => showStudent(student, id)}
                  hover
                  key={id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {student.firstName}
                  </TableCell>
                  <TableCell>{student.lastName ?? ''}</TableCell>
                  <TableCell>{student.email ?? ''}</TableCell>
                  <SnapshotButton studentId={id} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default StudentTable;
