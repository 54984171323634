import React from 'react';

const CollegeLogo = ({ ceeb_code, name }) => {
  return (
    <img
      style={{ maxWidth: '100%', height: 'auto' }}
      alt={`College logo for ${name}`}
      src={`${process.env.PUBLIC_URL}/colleges/${ceeb_code}.png`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = `${process.env.PUBLIC_URL}/college_building.jpeg`;
      }}
    />
  );
};

export default CollegeLogo;
