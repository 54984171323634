import React from 'react';
import SurveyIcon from '../../assets/college-match-survey-icon.png';

const CollegeMatchSurveyIcon = () => {
  return (
    <img src={SurveyIcon} alt={'Survey Icon'} width={'25px'} height={'25px'} />
  );
};

export default CollegeMatchSurveyIcon;
