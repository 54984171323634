import { useCookies } from 'react-cookie';
import { UserContext } from 'store/UserContext';
import React, { useContext, useEffect, useState } from 'react';
import { loginValidation } from 'utils/joiValidation';
import { useHistory, useLocation } from 'react-router';
import { checkAuthorized } from './functions/checkAuth';
import signIn from '../components/userManagmentFunctions/sign-in';
import Logo from '../assets/VizeLogo_NoBackgroundNoTM_new.png';
import { useSnackbar } from 'notistack';

// MUI IMPORTS
import { Button, TextField, Box, Grid, Typography } from '@mui/material';

const navigateUser = {
  student: '/dashboard',
  counselor: '/counselor/counselor-students',
  account_admin: '/account-admin/account-admin-students',
  admin: '/admin/students',
  exam_admin: '/exam-admin/students',
};

export default function SignIn({ setStatus }) {
  const history = useHistory();
  const location = useLocation();
  const { user, setUserFromToken } = useContext(UserContext);
  const [, setCookie] = useCookies(['tokens']);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user.role !== 'not-logged-in') {
      const { from } = location.state || {
        from: { pathname: navigateUser[user.role] },
      };
      if (checkAuthorized(user.role, from.pathname)) {
        history.replace(from);
      } else {
        history.push(navigateUser[user.role]);
      }
    }
  }, [user, history, location]);

  const validate = (data) => {
    const { message, type, pass } = loginValidation(data);
    switch (type) {
      case 'password':
        setPasswordError(message);
        return pass;
      case 'email':
        setEmailError(message);
        return pass;
      default:
        return pass;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailError('');
    setPasswordError('');
    const data = new FormData(event.currentTarget);
    if (
      validate({ email: data.get('email'), password: data.get('password') })
    ) {
      handleLogin({ email: data.get('email'), password: data.get('password') });
    }
  };

  const handleLogin = async (data) => {
    const response = await signIn(data);
    const content = await response.json();
    const { status, statusText } = response;
    const { token, refreshToken } = content;
    if (status === 200) {
      setCookie('tokens', { token, refreshToken });
      await setUserFromToken(token);
    } else {
      enqueueSnackbar(`${statusText}: invalid email or password`, {
        variant: 'error',
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          mt: 10,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{ maxHeight: '120px', marginTop: 100 }}
          src={Logo}
          alt={'Vize Logo'}
        />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 10 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            error={!!emailError}
            helperText={emailError}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={!!passwordError}
            helperText={passwordError}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                component="p"
                variant="body1"
                color="primary.main"
                onClick={() => setStatus('rp')}
                sx={{ mt: 2, cursor: 'pointer' }}
              >
                Forgot Password?
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography
                component="p"
                variant="body1"
                color="primary.main"
                onClick={() => setStatus('su')}
                sx={{ mt: 2, cursor: 'pointer' }}
              >
                Don't have an account? Sign Up
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
