import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import GuidesAccordion from './Accordion';

// MUI IMPORTS
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomTabLabel from './CustomTabLabel';
import Typography from '@mui/material/Typography';
import DownloadButton from './DownloadButton';

const howThisWorksV2 = require('./constants/how_this_works_v2.json');
const narrativePlanningV2 = require('./constants/narrative_planning_v2.json');
const collegePlanningV2 = require('./constants/college_planning_v2.json');
const applicationEssaysV2 = require('./constants/essays_v2.json')
const ecpv2 = require('./constants/ecp_v2.json');
const lorsV2 = require('./constants/lors_v2.json');
const interviewPlanningV2 = require('./constants/interview_planning_v2.json');
const testingV2 = require('./constants/testing_v2.json');

const useStyles = makeStyles(() => ({
  rootTabs: {
    overflow: 'initial',
  },
  root: {
    width: '100%',
    height: 'auto',
    border: '1px solid #E4EAF3',
    borderRadius: '15px',
    background: '#FFFFFF',
    marginBottom: '24px',
    letterSpacing: '0.01em',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '23px',
    color: '#3F4957',
    alignItems: 'flex-start'
  },
  selected: {
    color: '#455FE8!important',
  },
  indicator: {
    display: 'none',
  },
  rootTabPanel: {
    maxWidth: '1011px',
    maxHeight: '1044px',
    width: '80%',
    height: 'auto',
    marginLeft: '64px',
    marginTop: '2px',
    padding: '24px 40px',
    background: 'white',
    borderRadius: '12px',
    boxShadow: '0px 3px 25px rgba(129, 136, 166, 0.14)',
  },
  boxContainer: {
    overflowY: 'auto',
    maxHeight: '1008px',
  },
}));

const TabPanel = (props) => {
  const classes = useStyles();

  const { children, value, index, rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.rootTabPanel}
      {...rest}
    >
      {value === index && (
        <Box className={classes.boxContainer}>{children}</Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const tabs = [
  {
    title: 'How All This Works',
    description: 'A big picture guide to college admissions',
    content: howThisWorksV2.content,
    html: true
  },
  {
    title: 'Narrative Planning',
    description: 'Learn how to construct a coherent theme to your application',
    content: narrativePlanningV2.content,
    html: true
  },
  {
    title: 'College Planning',
    description: 'What you need to think about and when to think about it',
    content: collegePlanningV2.content,
    html: true
  },
  {
    title: 'Application Essays',
    description: 'How to plan and execute essays that reflect the best in you',
    content: applicationEssaysV2.content,
    html: true
  },
  {
    title: 'Extracurricular Planning',
    description: 'What to think about when you’re planning your activities',
    content: ecpv2.content,
    html: true
  },
  {
    title: 'Letters of Recommendation',
    description: 'How to ensure that your LORs reflect the best in you',
    content: lorsV2.content,
    html: true
  },
  {
    title: 'Interview Planning',
    description:
      'Learn how to make sure that you’re fully prepared for an interview',
    content: interviewPlanningV2.content,
    html: true
  },
  {
    title: 'Testing',
    description: 'How to plan for SATs, ACTs, and AP tests',
    content: testingV2.content,
    html: true
  },
];

const GuidesTabs = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        sx={{ borderRight: 'none' }}
        classes={{
          root: classes.rootTabs,
          indicator: classes.indicator,
        }}
      >
        {tabs.map((tab, i) => {
          return (
            <Tab
              key={i}
              classes={{ root: classes.root, selected: classes.selected }}
              label={
                <CustomTabLabel
                  title={tab.title}
                  description={tab.description}
                />
              }
              {...a11yProps(i)}
            />
          );
        })}
      </Tabs>
      {tabs.map((tab, i) => {
        const isHtml = tab?.html;       
        return (
          <TabPanel key={i} value={value} index={i}>
            <Typography paragraph={true} variant="h6">
              {`${tab.title}: ${tab.description}`}
            </Typography>
            
            {!isHtml ? tab.content?.map((a, i) => (
              <GuidesAccordion key={i} title={a.title} content={[a?.content]} />
            )): <div dangerouslySetInnerHTML={{__html: tab.content}} />}
            <DownloadButton link="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default GuidesTabs;
