import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { updatePassword } from './functions/requests';
import { validatePasswordEdit } from './functions/validations';

// MUI IMPORTS
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function Form() {
  const [error, setError] = useState('');
  const [cookies] = useCookies('tokens');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');

  const validate = (data) => {
    if (password !== rePassword) {
      setPasswordError('Passwords do not match.');
    } else if (password === oldPassword) {
      setOldPasswordError(
        'New Password has to be different from the Old Password.',
      );
    } else {
      const { message, type, pass } = validatePasswordEdit(data);
      switch (type) {
        case 'password':
          setPasswordError(message);
          return pass;
        default:
          return pass;
      }
    }
  };

  const clearErrors = () => {
    setError('');
    setPasswordError('');
    setOldPasswordError('');
  };

  const handleChange = (event, type) => {
    const value = event.target.value;
    clearErrors();
    const stateMap = {
      password: () => setPassword(value),
      rePassword: () => setRePassword(value),
      oldPassword: () => setOldPassword(value),
    };
    stateMap[type](event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clearErrors();
    setMessage('');

    const data = {
      password,
    };

    if (validate(data)) {
      setLoading(true);
      const body = {
        password: oldPassword,
        newPass: password,
      };
      handleEditRequest(body);
    }
  };

  const handleEditRequest = async (data) => {
    try {
      const {
        message: { error, body },
      } = await updatePassword(data, cookies);
      if (error) {
        setError(body);
      } else {
        setMessage(body);
        setPassword('');
        setRePassword('');
        setOldPassword('');
      }
    } catch (error) {
      console.error('error:', error);
    } finally {
      setLoading(false);
    }
  };

  const formOne = [
    {
      label: 'Old Password',
      value: oldPassword,
      event: 'oldPassword',
      error: oldPasswordError,
    },
    {
      label: 'New Password',
      value: password,
      event: 'password',
      error: passwordError,
    },
    {
      label: 'Retype New Password',
      value: rePassword,
      event: 'rePassword',
      error: null,
    },
  ];

  return (
    <Paper
      sx={{
        width: 'auto',
      }}
    >
      {message && (
        <Typography align="center" variant="h5">
          {message}
        </Typography>
      )}
      <Typography align="center" variant="h5">
        {' '}
      </Typography>
      {error && <p>{error}</p>}
      {message && <p>{message}</p>}
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        noValidate
        autoComplete="off"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 4,
            height: '30%',
            display: 'auto',
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={{
              width: 'auto',
              mx: 2,
              direction: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            spacing={2}
          >
            {formOne.map((stateObj) => {
              return (
                <TextField
                  key={stateObj.label}
                  required
                  id="filled-multiline-flexible"
                  label={stateObj.label}
                  multiline
                  maxRows={4}
                  value={stateObj.value}
                  onChange={(event) => handleChange(event, stateObj.event)}
                  variant="filled"
                  helperText={stateObj.error}
                  error={!!stateObj.error}
                />
              );
            })}
          </Stack>
          <Stack
            sx={{
              width: '25ch',
            }}
            spacing={2}
          ></Stack>
        </Box>
        <Button sx={{ mx: 15, my: 2 }} type="submit" variant="contained">
          {loading ? 'loading' : 'Submit'}
        </Button>
      </Box>
    </Paper>
  );
}
