import React, { useState, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationsMenu } from './notifications/NotificationUi';
import { MessagesIconNav } from 'components/messaging/MessagingComponent';

// REACTLY - ICONS
import { Document, User, Category, ArrowLeft } from 'react-iconly';

import Logo from '../../assets/edVIZE_transparent.png';
import ReminderLayout from './reminders/ReminderLayout';
import NavStudentBadge from './NavStudentBadge';
import ReportIconComponent from 'components/icons/ReportsIcon';

// MUI IMPORTS
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ScheduleIcon from '../../assets/schedule-icon.svg';
import TasksIconComponent from '../../components/icons/TasksIcon';
import DiplomaIconComponent from 'components/icons/DiplomaIcon';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CollegeMatchSurveyIcon from 'components/icons/college-match-survey';
import UserAvatar from './UserAvatar';
import { AvatarAccountMenu } from './push-notifications/pushNotifications';
import StudentsIcon from 'components/icons/students-icon';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const AppbarIconGroup = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  alignItems: 'center',
}));

const drawerWidth = 280;

// CONSTANTS
const pages = [
  { name: 'Dashboard', path: '/dashboard', icon: Category },
  { name: 'Profile', path: '/profile', icon: User },
  { name: 'Tasks', path: '/tasks', icon: TasksIconComponent },
  {
    name: 'Notifications',
    path: '/notifications',
    icon: NotificationsNoneIcon,
  },
  { name: 'Messages', path: '/messages', icon: MessageIcon },
  {
    name: 'College Match',
    path: '/college-match/Layout',
    icon: CollegeMatchSurveyIcon,
  },
  { name: 'My College List', path: '/colleges', icon: DiplomaIconComponent },
  { name: 'Guides', path: '/guides', icon: Document },
  // { name: 'Requests', path: '/access-requests', icon: PersonAddAlt1Icon },
  { name: 'ACT/SAT Tests', path: '/exam-reports', icon: ReportIconComponent },
  // { name: 'Store', path: 'paywall', icon: LocalGroceryStoreIcon },
];

const counselorPages = [
  {
    name: 'Profile',
    path: '/counselor/profile',
    icon: User,
  },
  {
    name: 'My Students',
    path: '/counselor/counselor-students',
    icon: StudentsIcon,
  },
  {
    name: 'Tasks',
    path: '/counselor/counselor-tasks',
    icon: TasksIconComponent,
  },
  // TODO: add these back in when the pages are more fleshed out
  // {
  //   name: 'Student Exam Entry',
  //   path: '/counselor/exam-entry-counselor',
  //   icon: StudentExamEntryIcon,
  // },
  // {
  //   name: 'Graded Practice Tests',
  //   path: '/counselor/graded-practice-tests',
  //   icon: DiplomaIconComponent,
  // },
  // {
  //   name: 'Request Access',
  //   path: '/counselor/request-access',
  //   icon: PersonAddAlt1Icon,
  // },
];

const accountAdminPages = [
  {
    name: 'Profile',
    path: '/account-admin/profile',
    icon: User,
  },
  {
    name: 'My Students',
    path: '/account-admin/account-admin-students',
    icon: StudentsIcon,
  },
  {
    name: 'Account',
    path: '/account-admin/account',
    icon: PersonAddAlt1Icon,
  },
  {
    name: 'Tasks',
    path: '/account-admin/account-admin-tasks',
    icon: TasksIconComponent,
  },
  // {
  //   name: 'Request Access',
  //   path: '/account-admin/request-access',
  //   icon: PersonAddAlt1Icon,
  // },
  // {
  //   name: 'Create User',
  //   path: '/account-admin/register-user',
  //   icon: User,
  // },
];

const examAdminPages = [
  {
    name: 'My Profile',
    path: '/exam-admin/profile',
    icon: User,
  },
  {
    name: 'Students',
    path: '/exam-admin/students',
    icon: StudentsIcon,
  },
];

export function DrawerAndNav({ children, userRole }) {
  const [, , removeCookie] = useCookies(['tokens']);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const validPages = useMemo(() => {
    let accessPages;

    switch (userRole) {
      case 'counselor':
        accessPages = counselorPages;
        break;
      case 'account_admin':
        accessPages = accountAdminPages;
        break;
      case 'exam_admin':
        accessPages = examAdminPages;
        break;
      default:
        accessPages = pages;
    }
    return accessPages;
  }, [userRole]);

  const handleLogOut = () => {
    removeCookie('tokens');
    window.location.reload();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ border: 0, elevation: 0 }}>
      <Box
        sx={{
          ml: 4,
          mt: 3,
          mb: 3,
          width: 'auto',
          height: '74px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundImage: `url(${Logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'left',
        }}
      />
      <NavStudentBadge />
      <List sx={{ marginLeft: '20px' }}>
        {validPages.map((page) => {
          return (
            <ListItem
              button
              key={page.name}
              sx={{ mx: 'auto' }}
              component={RouterLink}
              to={page.path}
            >
              <page.icon color="#1F1F1F" sx={{ border: '1.5px' }} />
              <ListItemText sx={{ ml: 1 }} primary={page.name} />
            </ListItem>
          );
        })}
        <ListItem button onClick={() => window.open('mailto:contact@vize.io')}>
          <img src={ScheduleIcon} alt="Schedule help through email" />
          <ListItemText sx={{ ml: 1 }} primary={'Contact edVIZE'} />
        </ListItem>
        <ListItem sx={{ marginTop: '.75rem' }}></ListItem>
        <ListItem button onClick={handleLogOut}>
          <ArrowLeft
            color="#1F1F1F"
            sx={{
              border: '1.5px',
            }}
          />
          <ListItemText sx={{ ml: 1 }} primary={'Log Out'} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          border: 'none',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'white',
        }}
      >
        <Toolbar sx={{ mr: 2, display: { sm: 'none' } }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" color="primary">
            VIZE
          </Typography>
        </Toolbar>
        <Toolbar sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
          <AppbarIconGroup>
            <Stack spacing={2} direction="row" alignItems={'center'}>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ReminderLayout />
              </Popover>
              {/* //TODO: implement Later
               <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  margin: '5px',
                  backgroundColor: '#1F1F1F',
                  height: '20px',
                  alignSelf: 'center',
                }}
              />
              <Notification set="light" color="#1F1F1F" /> */}
              <MessagesIconNav />
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  margin: '5px',
                  backgroundColor: '#1F1F1F',
                  height: '20px',
                  alignSelf: 'center',
                }}
              />
              {userRole === 'student' ? (
                <>
                  <NotificationsMenu />{' '}
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      margin: '5px',
                      backgroundColor: '#1F1F1F',
                      height: '20px',
                      alignSelf: 'center',
                    }}
                  />{' '}
                </>
              ) : (
                <></>
              )}
              {/* {userRole === 'student' ? <AvatarAccountMenu /> : <UserAvatar />} */}
              <UserAvatar />
              {/* <AvatarAccountMenu /> */}
              {/*  //TODO: implement Later
               <ChevronDown set="light" color="#1F1F1F" /> */}
            </Stack>
          </AppbarIconGroup>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          borderRight: 0,
        }}
        aria-label="side-bar-nav"
      >
        <Drawer
          elevation={0}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            border: 0,
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: '100vh',
          overflowY: 'scroll',
        }}
      >
        <Toolbar sx={{ border: 'none' }} /> {children}
      </Box>
    </Box>
  );
}
