import React from 'react';
import Footer from './Footer';

// MUI IMPORTS
import { Stack, Divider, Typography, Box, Grid } from '@mui/material';

const ActivityCard = ({
  id,
  description,
  endYear,
  hoursPerWeek,
  startYear,
  title,
  weeksPerYear,
  setActivities,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: '2px solid',
        borderColor: '#455FE8',
        borderRadius: '17px',
      }}
    >
      <Grid container item spacing={1} sx={{ p: 2, width: '100%' }}>
        <Grid item xs={6}>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>
          <Typography component="p">{description}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              borderColor: '#8F8D93',
              borderLeftWidth: '3px',
              borderRightWidth: '0',
              height: '80%',
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" spacing={3}>
            <Typography variant="h6" component="p" align="center">
              {hoursPerWeek}
              <Box
                component="span"
                fontWeight="fontWeightBold"
                sx={{ color: '#455FE8' }}
              >
                H/W
              </Box>
            </Typography>
            <Typography variant="h6" component="p" align="center">
              {weeksPerYear}
              <Box
                component="span"
                fontWeight="fontWeightBold"
                sx={{ color: '#455FE8' }}
              >
                W/Y
              </Box>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Footer
        id={id}
        title={title}
        description={description}
        endYear={endYear}
        startYear={startYear}
        hoursPerWeek={hoursPerWeek}
        weeksPerYear={weeksPerYear}
        setActivities={setActivities}
      />
    </Box>
  );
};

export default ActivityCard;
