import React from 'react';
import { makeStyles } from '@material-ui/core';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid #E4EAF3',
    border: 'none',
    letterSpacing: '0.01em',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '23px',
    color: '#3F4957',
    borderRadius: '0px!important',
    boxShadow: 'none!important',
  },
  contentGutters: {
    '& p': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '18px',
      color: '#3F4957',
      letterSpacing: '0.04em',
    },
  },
  rootAccordionDetails: {
    '& p': {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '150%',
      color: '#3F4957',
      letterSpacing: '0.02em',
    },
  },
}));

const GuidesAccordion = ({ title, content }) => {
  const classes = useStyles();

  return (
    <div>
      <Accordion classes={{ root: classes.root }}>
        {title && (
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{ contentGutters: classes.contentGutters }}
          >
            <Typography>{title || ''}</Typography>
          </AccordionSummary>
        )}
        <AccordionDetails classes={{ root: classes.rootAccordionDetails }}>
          <Typography>{content}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GuidesAccordion;
