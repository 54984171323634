const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

export const requestStudentAccess = async (cookies, email) => {
  let url = `/users/request-access`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ email, relation: 'counselor' }),
  };

  const response = await fetch(url, configObj);

  if (response.status === 201) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unable to request access'; // potentially invalid student
  }

  if (response.status === 422) {
    return 'invalid parameters';
  }

  return 'server error';
};

export const getDateTakenResults = async (
  cookies,
  studentId,
  startDate,
  testType,
  limit,
) => {
  let url = `/exams/calculations?startDate=${startDate}&testType=${testType}`;
  if (limit) {
    url += `&limit=${limit}`;
  }

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', studentId);

  const configObj = {
    method: 'POST',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unable to request access';
  }

  return 'server error';
};

export const getStudentExamById = async (cookies, studentId, testType) => {
  let url = `/exams/student/${studentId}?type=${testType}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', studentId);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 401) {
    return { error: 'Unable to request access' };
  }

  if (response.status === 404) {
    return { error: 'No tests found' };
  }

  return { error: 'server error' };
};

export const scoreExamById = async (cookies, studentId, id) => {
  let url = `/exams/${id}/score`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', studentId);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200 || response.status === 304) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unable to request access';
  }

  return { error: 'server error' };
};

export const auditExamById = async (cookies, studentId, id) => {
  let url = `/exams/${id}/audit`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', studentId);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200 || response.status === 304) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unable to request access';
  }

  return { error: 'server error' };
};

export const getExamById = async (cookies, studentId, id) => {
  let url = `/exams/${id}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('studentid', studentId);

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200 || response.status === 304) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unable to request access';
  }

  return 'server error';
};

export const getUserPreferences = async (cookies) => {
  let url = '/user-preferences';

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200 || response.status === 304) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unable to request access';
  }

  return 'server error';
};

export const updateUserPreferenceById = async (
  cookies,
  userPreferenceId,
  type,
  feature,
  enabled,
) => {
  let url = `/user-preferences/${userPreferenceId}`;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify({ enabled }),
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) {
    return true;
  }

  if (response.status === 401) {
    return 'Unable to request access';
  }

  return 'server error';
};

export const createUserPreference = async (cookies, type, feature, enabled) => {
  let url = '/user-preferences';

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ type, feature, enabled }),
  };

  const response = await fetch(url, configObj);

  if (response.status === 201 || response.status === 304) {
    return await response.json();
  }

  if (response.status === 401) {
    return 'Unable to request access';
  }

  return 'server error';
};
