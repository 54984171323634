export const updateProfile = async (data, cookies) => {
  let url = '/users/profile';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 204 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status < 299 && status > 204) {
    message = { error: true, body: response.text() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const updatePassword = async (data, cookies) => {
  let url = '/auth/update-pass';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);
  const body = await response.json();

  return { response, body };
};

export const getUserProfile = async (cookies) => {
  let url = '/users/profile';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 204 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status < 299 && status > 204) {
    message = { error: true, body: response.text() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const getAboutMeData = async (cookies) => {
  let url = '/users/about';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 204 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const postAboutMeData = async (data, cookies) => {
  let url = '/users/about';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 204 || status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const getHighSchoolData = async (cookies) => {
  let url = '/users/high-school';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const putHighSchoolData = async (data, cookies) => {
  let url = '/users/high-school';
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(url, configObj);

  const { status } = response;
  let message = { error: false, body: null };

  if (status === 200) {
    message = { error: false, body: response.json() };
  }
  if (status > 299) {
    message = { error: true, body: response.text() };
  }
  return { message };
};

export const getPendingAccessRequests = async (cookies) => {
  let url = `/users/pending-requests`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 500) return 'No pending requests.'; // need to update API to 404
  if (response.status === 200) return await response.json();

  return 'Server error.';
};

export const getCurrentAccess = async (cookies) => {
  let url = `/users/list-access`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 500) return 'No current access.'; // need to update API to 404
  if (response.status === 200) return await response.json();

  return 'Server error.';
};

export const approveAccessRequest = async (cookies, id) => {
  let url = `/users/request-access/${id}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
    body: JSON.stringify({ state: 'active' }),
  };

  const response = await fetch(url, configObj);

  if (response.status === 422) return 'Invalid parameters';
  if (response.status === 401) return 'Unable to approve request';
  if (response.status === 200) return await response.json();

  return 'Server error.';
};

export const getStudentNotifications = async (cookies) => {
  let url = `/notifications`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) return await response.json();

  return 'Server error.';
};

export const patchUpdateHasSeenStudentNotification = async (
  notificationId,
  cookies,
) => {
  let url = `/notifications/${notificationId}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) return true;

  return 'Server error.';
};

export const getUserMessages = async (cookies, sendeeId) => {
  let url = `/messages/sendee/${sendeeId}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 200) return await response.json();

  return 'Server error.';
};

export const postUserMessages = async (cookies, formData) => {
  let url = `/messages`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(formData),
  };

  const response = await fetch(url, configObj);

  if (response.status === 201) return await response.json();

  return 'Server error.';
};

export const patchUpdateHasSeenMessages = async (messagesId, cookies) => {
  let url = `/messages/${messagesId}`;
  const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }

  const myHeaders = new Headers();
  myHeaders.append('token', cookies.tokens.token);
  myHeaders.append('Content-Type', 'application/json');

  const configObj = {
    method: 'PATCH',
    headers: myHeaders,
  };

  const response = await fetch(url, configObj);

  if (response.status === 204) return true;

  return 'Server error.';
};
