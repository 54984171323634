import React, { useState, useEffect } from 'react';
function AvailableSessions() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setTimeout(async () => {
          const response = await fetch(
            'https://jsonplaceholder.typicode.com/posts',
          );
          const jsonResponse = await response.json();
          setSessions(jsonResponse);
          setLoading(false);
        }, 3000);
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, []);

  const reloadData = async () => {
    try {
      setLoading(true);
      setTimeout(async () => {
        const response = await fetch(
          'https://jsonplaceholder.typicode.com/posts',
        );
        const jsonResponse = await response.json();
        setSessions(jsonResponse);
        setLoading(false);
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <button onClick={() => setSessions([])}>Reset</button>
      <button onClick={() => reloadData()}>Reload Data</button>
      {loading && <p>loading...</p>}
      {sessions.map((session) => {
        return (
          <div key={session.title}>
            <p>{session.title}</p>
            <p>{session.body}</p>
          </div>
        );
      })}
    </>
  );
}

export default AvailableSessions;
