import React from 'react';

const PageContainer = ({ title, firstColumn, secondColumn }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '2%' }}>
      <h1>{title}</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '2%',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '48%' }}>{firstColumn}</div>
        <div style={{ width: 16 }} />
        <div style={{ width: '48%' }}>{secondColumn}</div>
      </div>
    </div>
  );
};

export default PageContainer;
