import React from 'react';
import SharedSearchComponent from '../reusable-components/SharedSearchComponent';
import getUsersByNameOrRole from '../../functions/getUsersByNameOrRole';

const UserSearch = ({ applyToAll, addedIds, setAddedIds }) => {
  const getOptionLabel = (option) => {
    const { firstName, lastName, email } = option?.attributes;
    return `${firstName} ${lastName} ${email}`;
  };

  return (
    <SharedSearchComponent
      applyToAll={applyToAll}
      label="Add students"
      callbackGet={getUsersByNameOrRole}
      extraParameter="student"
      getOptionLabel={getOptionLabel}
      addedIds={addedIds}
      setAddedIds={setAddedIds}
    />
  );
};

export default UserSearch;
