import Form from './Form';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import ModalDialog from '../../ModalDialog';
import { deleteCourse, patchCourse } from './functions/requests';

// MUI IMPORTS
import {
  Stack,
  IconButton,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const Footer = ({
  id,
  setCourseWork,
  name,
  gradeYear,
  semester1,
  semester2,
}) => {
  const [cookies] = useCookies('tokens');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    const { error, body } = await deleteCourse(id, cookies);
    if (error) {
      enqueueSnackbar(body, {
        variant: 'error',
      });
    } else {
      enqueueSnackbar('Course Work Deleted', {
        variant: 'warning',
      });
      setCourseWork((prev) => prev.filter((course) => course.id !== id));
      setAnchorEl(false);
    }
  };

  const handlePatch = async (course) => {
    const { name, gradeYear, semester1, semester2 } = course;
    const patchData = {
      name,
      gradeYear,
      semesterGrades: { semester1, semester2 },
    };
    setLoading(true);
    try {
      const {
        message: { error, body },
      } = await patchCourse(patchData, id, cookies);

      if (error) {
        const errorMessage = await body;
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setLoading(false);
      } else {
        enqueueSnackbar('Course Work Successfully Edited.', {
          variant: 'success',
        });
        setOpenModal((prev) => !prev);
        setCourseWork((prev) => {
          const newCourseWork = prev.map((el) => {
            if (el.id === id) {
              return patchData;
            } else {
              return el;
            }
          });
          return newCourseWork;
        });
      }
    } catch (error) {
      console.error('error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#455FE8',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        color: 'white',
        p: 1,
      }}
    >
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <span></span>
        <IconButton
          onClick={handleClick}
          aria-label="expand more options"
          sx={{ color: 'white' }}
        >
          <MoreHorizIcon sx={{ transform: 'scale(2)' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleOpenModal}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Stack>
      <ModalDialog
        isOpen={openModal}
        handleClose={handleCloseModal}
        content={
          <Form
            setOpen={setOpenModal}
            initialCW={{ name, gradeYear, semester1, semester2 }}
            setCourseWork={setCourseWork}
            loading={loading}
            setLoading={setLoading}
            handleRequest={handlePatch}
            id={id}
          />
        }
        title="Edit Course Work"
      />
    </Box>
  );
};
