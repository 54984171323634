import React from 'react';
import { isEmpty } from 'lodash';
import { HOC } from './HOC';
import { Container as ExamsContainer } from './Container';
import { useFetch } from '../../profile/functions/hooks';

const examsURL = '/users/test?currentPage=1&pageSize=99';

const Exams = () => {
  const {
    resource: exams,
    setResource: setExams,
    isLoading: isExamsLoading,
    serverError: examsError,
  } = useFetch(examsURL, (data) => {
    const { attributes, id } = data;
    return { attributes, id };
  });

  const hasData = isEmpty(exams);

  return (
    <HOC
      isLoading={isExamsLoading}
      serverError={examsError}
      hasData={hasData}
      title="Exams"
      handleNoData={setExams}
    >
      {exams && <ExamsContainer exams={exams} setExams={setExams} />}
    </HOC>
  );
};

export default Exams;
