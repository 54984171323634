import { useCookies } from 'react-cookie';
import React, { useState } from 'react';
import deleteUser from './admin/functions/deleteUser';
import { Box, Button, Grid, LinearProgress } from '@material-ui/core';
import ErrorMessage from './ErrorMessage';

const ConfirmationModal = ({ onCancel, student }) => {
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteConfirmationHandler = async () => {
    try {
      setError(false);
      setLoading(true);
      const response = await deleteUser(student.id, cookie);
      if (response.status === 204) {
        onCancel('success');
      } else if (response.status === 409) {
        const jsonResponse = await response.json();
        setError(jsonResponse.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <h1>Delete Account</h1>
          <Grid container spacing={3}>
            {error && <ErrorMessage message={error} />}
            {loading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}

            <Grid item xs={12}>
              <p>
                Are you sure you want to delete the account for{' '}
                {student?.attributes?.firstName} {student?.attributes?.lastName}{' '}
                with email <strong>{student?.attributes?.email}</strong>?
              </p>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => deleteConfirmationHandler()}
                >
                  CONFIRM
                </Button>
                <Button variant="contained" onClick={() => onCancel()}>
                  CANCEL
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
