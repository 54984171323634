import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ErrorMessage from '../ErrorMessage';
import StyledLoadingIndicator from '../StyledLoadingIndicator/StyledLoadingIndicator';
import getUsersByNameOrRole from './functions/getUsersByNameOrRole';

const Student = ({ student, index }) => {
  const { firstName, lastName, email } = student?.attributes;

  return (
    <>
      return
      <p>
        {firstName} {lastName}, {email}
      </p>
    </>
  );
};

const AdminAllStudentsList = (nameAndRole) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);

  // TODO: make this API paginated by last name
  useEffect(() => {
    const getStudents = async () => {
      try {
        setErrorMessage(false);
        setLoading(true);
        const response = await getUsersByNameOrRole(cookie, 'student');
        const jsonResponse = await response.json();

        // TODO: add error handling
        if (response.status === 200) {
          setAllStudents(
            jsonResponse?.data.sort((a, b) =>
              a?.attributes?.lastName > b?.attributes?.lastName
                ? 1
                : b?.attributes?.lastName > a?.attributes?.lastName
                ? -1
                : 0,
            ),
          );
        }
      } catch (err) {
        console.log(err);
        setErrorMessage('Unable to retrieve list of students');
      } finally {
        setLoading(false);
      }
    };

    getStudents();
  }, [cookie]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h1>Admin All Students List</h1>

      {errorMessage && <ErrorMessage message={errorMessage} />}
      {loading && <StyledLoadingIndicator />}

      {allStudents.map((student, index) => (
        <Student key={student.id} student={student} />
      ))}
    </div>
  );
};

export default AdminAllStudentsList;
