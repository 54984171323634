export const convertIsoStringToReadableDate = (iso) => {
  if (typeof iso === 'string' && iso.length !== 0) {
    try {
      const date = new Date(iso);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      return `${year}-${month}-${dt}`;
    } catch (e) {
      console.error(e);
      return iso;
    }
  }
};
