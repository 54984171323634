export const convertTimeStampToReadableDate = (timestamp) => {
  if (typeof timestamp === 'number') {
    try {
      return new Date(timestamp).toLocaleDateString('en-Us', {
        timeZone: process.env.REACT_APP_TZ,
      });
    } catch (e) {
      console.error(e);
      return timestamp;
    }
  }
};
