import React from 'react';
import { useState, useEffect } from 'react';
import satTest from './sat-exam-entry';
import actTest from './act-exam-entry';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { postExamEntry, patchExamEntry } from './functions';
import useQuery from '../../hooks/useQuery';
import DateTaken from '../student/profile-details/exam/DateTaken';
import FormHelperText from '@material-ui/core/FormHelperText';

//MUI IMPORTS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { subtractDays } from '../../utils/dates';
import {
  Box,
  Grid,
  Stack,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
} from '@mui/material';

const Bold = ({ children }) => {
  return (
    <Box sx={{ display: 'inline', fontWeight: 'bold' }} inline>
      {children}
    </Box>
  );
};

const SingleQuestion = ({ question, handleOnChange, sectionIndex }) => {
  // return UI for a single answer question
  return (
    <Grid container item xs={2}>
      <Typography textAlign="right" style={{ width: '30px' }}>
        {`${question.key}: `}
      </Typography>
      <input
        onChange={(e) => {
          // from e, how do we pass in the value
          // from question, how do pass in the question key
          handleOnChange(sectionIndex, question, e.target.value);
        }}
        value={question.value}
        type="text"
        style={{ width: '25%' }}
        maxLength={1}
      ></input>
    </Grid>
  );
};

const MultiQuestion = ({ question, handleOnChange, sectionIndex }) => {
  // return UI for multiple answer question
  return (
    <Grid container item xs={2}>
      <Typography textAlign="left">
        {`${question.key}: `}
        {question.answers.map((answer, i) => {
          return (
            <input
              onChange={(e) => {
                // from e, how do we pass in the value
                // from question, how do pass in the question key
                handleOnChange(
                  sectionIndex,
                  question,
                  e.target.value,
                  answer.key,
                );
              }}
              value={answer.value}
              type="text"
              style={{ width: '20%' }}
              maxLength={1}
            ></input>
          );
        })}
      </Typography>
    </Grid>
  );
};

const TestQuestions = ({ questions, title, handleOnChange, sectionIndex }) => {
  // loop through question in questions
  // rendercomponent based on type, multi or single
  return (
    <React.Fragment key={title}>
      {questions.map((question, i) => {
        if (question.type === 'single') {
          return (
            <SingleQuestion
              sectionIndex={sectionIndex}
              handleOnChange={handleOnChange}
              key={question.key}
              question={question}
            />
          );
        }

        if (question.type === 'multi') {
          return (
            <MultiQuestion
              sectionIndex={sectionIndex}
              handleOnChange={handleOnChange}
              key={question.key}
              question={question}
            />
          );
        }
      })}
    </React.Fragment>
  );
};

const TestSection = ({ section, handleOnChange, sectionIndex }) => {
  return (
    <Box m={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aira-controls="sat-exam-section-1"
          id="sat-exam-section-1"
          sx={{ backgroundColor: '#455FE8' }}
        >
          <Typography sx={{ color: '#fafafa' }}>
            <Bold>{section.title}</Bold>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <TestQuestions
              sectionIndex={sectionIndex}
              handleOnChange={handleOnChange}
              questions={section.questions}
              title={section.title}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const TestSections = ({ test, handleOnChange }) => {
  return (
    <>
      {test.sections.map((section, i) => {
        return (
          <TestSection
            sectionIndex={i}
            handleOnChange={handleOnChange}
            key={section.title}
            section={section}
          />
        );
      })}
    </>
  );
};

export const ExamRadioButtonChange = ({ defaultState, editStudentId }) => {
  const [testType, setTestType] = useState(() => {
    if (defaultState) {
      const { type } = defaultState;
      return type;
    }
    return 'sat';
  });
  const [examCode, setExamCode] = useState(() => {
    if (defaultState) {
      const { examCode } = defaultState;
      return examCode;
    }
    return 'May 2017';
  });

  const [examCodeOptions, setExamCodeOptions] = useState([]);
  // loading state
  const [isLoading, setIsLoading] = useState(false);
  // formSubmitted
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dateTaken, setDateTaken] = useState(() => {
    if (defaultState) {
      const { dateTaken } = defaultState;
      return new Date(dateTaken);
    }
    return subtractDays(1);
  });
  const [dateError, setDateError] = useState('');

  const [formState, setFormState] = useState(() => {
    if (defaultState) {
      const {
        data: { sections },
      } = defaultState;
      return { sections };
    }

    const sectionsWithValue = satTest.sections.map((section) => {
      const questionsWithValue = section.questions.map((question) => {
        if (question.type === 'single') {
          return { ...question, value: '' };
        }

        if (question.type === 'multi') {
          const answersWithValue = question.answers.map((answer) => {
            return { ...answer, value: '' };
          });

          return { ...question, answers: answersWithValue };
        }
      });

      return { ...section, questions: questionsWithValue };
    });

    return { sections: sectionsWithValue };
  });

  const [cookies] = useCookies('tokens');
  const query = useQuery();

  const handleExamChange = (evt) => {
    setExamCode(evt.target.value);
  };

  const handleOnChange = (
    sectionIndex,
    question,
    questionNewValue,
    answerKey,
  ) => {
    setFormState((prevState) => {
      const newState = { ...prevState };

      const questionIndex = question.key - 1;

      if (question.type === 'single') {
        newState.sections[sectionIndex].questions[questionIndex].value =
          questionNewValue;
      }

      if (question.type === 'multi') {
        const answerIndex = answerKey - 1;
        newState.sections[sectionIndex].questions[questionIndex].answers[
          answerIndex
        ].value = questionNewValue;
      }

      return newState;
    });
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async () => {
    setIsLoading(true);
    const answersMissing = false;

    if (answersMissing) {
      // show snackbar and return;
      enqueueSnackbar('Make sure all boxes have an entry', {
        variant: 'error',
      });
      setIsLoading(false);
    } else {
      // get studentId query param ex: query.get("name")
      // make an API call to submit the form
      const studentId = query.get('studentId') || editStudentId;
      if (!studentId) {
        enqueueSnackbar('Missing student id!', {
          variant: 'error',
        });
        setIsLoading(false);
        return;
      }

      // returns just the questions for the API call
      const parsedSections = formState.sections.map((section) => {
        const { questions } = section;
        return { questions };
      });

      const body = { sections: parsedSections, dateTaken, examCode, testType };
      let response;

      if (defaultState) {
        response = await patchExamEntry(
          cookies,
          body,
          studentId,
          defaultState.id,
        );
      } else {
        response = await postExamEntry(cookies, body, studentId);
      }

      if (typeof response === 'string') {
        enqueueSnackbar(response, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Exam entry added!', {
          variant: 'success',
        });
        setFormSubmitted(true);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    // TODO: fetch exam code options via API eventually, for now hard code
    const options = [
      'May 2017',
      'April 2018',
      'October 2018',
      'April 2019',
      'March 2020',
      'March 2021',
      'March 2022',
      'May 2021',
      'April 2021',
      'April 2022',
      'October 2021',
      'May 2022',
      'May 2022 International',
      'October 2022',
    ];
    setExamCodeOptions(options);
  }, []);

  useEffect(() => {
    let options = [];
    // TODO: fetch exam code options via API eventually, for now hard code
    if (testType === 'sat') {
      options = [
        'May 2017',
        'April 2018',
        'October 2018',
        'April 2019',
        'March 2020',
        'March 2021',
        'March 2022',
        'May 2021',
        'April 2021',
        'April 2022',
        'October 2021',
        'May 2022',
        'May 2022 International',
        'October 2022',
      ];
    }
    if (testType === 'act') {
      options = [
        '2018 Code A09',
        '2018 Code 61D',
        '2018 Code 68A',
        '1996 Code 52C',
        '2020 Code C02',
        '2021 Code D05',
        '2021 Code E23',
        '2021 Code Z04',
        '2022 Code F07',
        '2022 Code E25',
        '2022 Code E26',
      ];
    }
    if (options.length > 0) setExamCodeOptions(options);
  }, [testType]);

  useEffect(() => {
    let newFormState = { sections: [] };
    // TODO: fetch exam code options via API eventually, for now hard code
    if (testType === 'sat') {
      const sectionsWithValue = satTest.sections.map((section) => {
        const questionsWithValue = section.questions.map((question) => {
          if (question.type === 'single') {
            return { ...question, value: '' };
          }

          if (question.type === 'multi') {
            const answersWithValue = question.answers.map((answer) => {
              return { ...answer, value: '' };
            });

            return { ...question, answers: answersWithValue };
          }
        });

        return { ...section, questions: questionsWithValue };
      });

      newFormState = { sections: sectionsWithValue };
    }

    if (testType === 'act') {
      const sectionsWithValue = actTest.sections.map((section) => {
        const questionsWithValue = section.questions.map((question) => {
          if (question.type === 'single') {
            return { ...question, value: '' };
          }

          if (question.type === 'multi') {
            const answersWithValue = question.answers.map((answer) => {
              return { ...answer, value: '' };
            });

            return { ...question, answers: answersWithValue };
          }
        });

        return { ...section, questions: questionsWithValue };
      });

      newFormState = { sections: sectionsWithValue };
    }
    if (Object.keys(newFormState).length > 0) setFormState(newFormState);
  }, [testType]);

  return (
    <Box>
      <RadioGroup
        value={testType}
        onChange={(evt) => setTestType(evt.target.value)}
        row
        justifyContent="center"
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="sat" control={<Radio />} label="SAT" />
        <FormControlLabel value="act" control={<Radio />} label="ACT" />
      </RadioGroup>
      <Typography>
        <Bold>Which test code would you like to enter?</Bold>
      </Typography>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="exam-code">Exam Code</InputLabel>
        <Select label="Exam Code" value={examCode} onChange={handleExamChange}>
          {examCodeOptions.map((option) => {
            return <MenuItem value={option}>{option}</MenuItem>;
          })}
        </Select>
      </FormControl>{' '}
      <DateTaken
        setError={setDateError}
        date={dateTaken}
        maxDate={subtractDays(1)}
        setDate={setDateTaken}
      />
      {dateError && <FormHelperText error>{dateError}</FormHelperText>}
      <Box width="1000px" overFlow="true">
        {testType === 'sat' ? (
          <TestSections handleOnChange={handleOnChange} test={formState} />
        ) : (
          <></>
        )}
        {testType === 'act' ? (
          <TestSections handleOnChange={handleOnChange} test={formState} />
        ) : (
          <></>
        )}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          paddingTop={3}
        >
          <Button
            disabled={formSubmitted || isLoading}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default function ExamEntry() {
  return (
    <Box>
      <Box>
        <Typography variant="h1" component="h2" mb={2}>
          Exam Entry
        </Typography>

        <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography textAlign="left">
            To enter a test for a student, please enter their exact answers by
            section below after selecting a test type (SAT or ACT) and its test
            code below. Entries are not case-sensitive and tabbing between
            fields will proceed in order to expedite the process. Free response
            entries for the SAT will accept answers in the same way that a real
            answer sheet would: entries can be right- or left-justified,
            fractions will be read as improper fractions and not mixed numbers,
            and either decimal or fractional answers are acceptable (decimal
            answers with more than four digits must use all four fields but will
            accept both rounded and unrounded answers).
          </Typography>
        </Grid>

        <Grid container item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography textAlign="left">
            When you’re finished entering a student’s answers, click the Submit
            button below and a full report will be accessible via the Students
            tab on the left sidebar. If you encounter any issues, please feel
            free to email us at{' '}
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('mailto:help@vize.io')}
            >
              help@vize.io
            </Link>{' '}
            and we’ll get back to you right away!
          </Typography>
        </Grid>

        <Typography>
          <Bold>Which test type is it?</Bold>
        </Typography>

        <ExamRadioButtonChange />
      </Box>
    </Box>
  );
}
