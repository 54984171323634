import React, { useState, useEffect } from 'react';
import { middlewareFilterAndSort } from '../tasks-v2/functions/filtering_and_sorting';
import { useFetchTasks } from '../tasks-v2/functions/hooks';
import { ResourceHOC } from 'components/ResourceHOC';
import AnalyticsService from '../../../analyticsService';
import TaskCard from '../tasks-v2/TaskCard';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { updateComplete } from '../tasks-v2/functions/requests';
import { isEmpty } from 'lodash';

// MUI IMPORTS
import { Grid } from '@mui/material';

export const AllDashboardTasks = ({
  tasks,
  taskError,
  taskLoading,
  setTasks,
}) => {
  const [cookies] = useCookies(['tokens']);
  const { enqueueSnackbar } = useSnackbar();

  const handleComplete = async (e) => {
    const taskId = e.target.value;
    let task = tasks.find((task) => task.id === Number(taskId));

    const response = await updateComplete(cookies, task);

    if (response) {
      const status = task.completed ? 'upcoming' : 'completed';
      enqueueSnackbar(`Task set to ${status}`, {
        variant: 'success',
      });
      setTasks((prevTasks) => {
        const newTasks = prevTasks.map((task) => {
          if (task.id === Number(taskId)) {
            return {
              ...task,
              completed: !task.completed,
              completedDate: !task.completed ? new Date() : null,
            };
          }
          return task;
        });
        return newTasks;
      });
    } else {
      enqueueSnackbar('Error completing task', {
        variant: 'error',
      });
    }
  };

  return (
    <ResourceHOC
      isLoading={taskLoading}
      serverError={taskError}
      hasData={isEmpty(tasks)}
    >
      {tasks &&
        tasks.slice(0, 3).map((task) => {
          return (
            <Grid item key={task.id} xs={12}>
              <TaskCard
                variant={'version2'}
                handleComplete={handleComplete}
                {...task}
              />
            </Grid>
          );
        })}
    </ResourceHOC>
  );
};

export const TaskDashboardUi = () => {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'Tasks' });
  }, []);

  const taskUrl = `/v2/tasks/all`;
  const [tasks, setTasks, taskLoading, taskError] = useFetchTasks(taskUrl);
  const [filters, setFilters] = useState([]);

  const [type, setType] = useState('allType');
  const [status, setStatus] = useState('allStatus');
  const [date, setDate] = useState('dateAsc');

  const [sorter, setSorter] = useState(date);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    setFilters([type, status]);
  }, [type, status]);

  const [mutatedTasks, setMutatedTasks] = useState([]);

  useEffect(() => {
    if (tasks) {
      setMutatedTasks(middlewareFilterAndSort(tasks, filters, sorter, search));
    }
  }, [tasks, filters, sorter, search]);

  let contentRender = !!tasks;

  return (
    <>
      {contentRender && (
        <Grid container item xs={12} spacing={1}>
          <AllDashboardTasks
            setTasks={setTasks}
            tasks={mutatedTasks}
            taskLoading={taskLoading}
            taskError={taskError}
          />
        </Grid>
      )}
    </>
  );
};
