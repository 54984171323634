export const replace = (array, oldItem, newItem) =>
  array.map((item) => (item === oldItem ? newItem : item));

export const clear = (arr) => {
  arr.forEach((setter) => setter(''));
};

export const clearHC = (arr) => {
  const ev = { target: { value: '' } };
  arr.forEach((setter) => setter(ev));
};
