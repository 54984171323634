import React, { useState } from 'react';
import ListContainer from '../ListContainer';
import { useFetch } from '../functions/hooks';
import { ResourceHOC } from './ResourceHOC';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

const CollegeList = () => {
  const history = useHistory();

  const [SCPage] = useState(1);
  const savedCollegesURL = `/users/saved-colleges?currentPage=${SCPage}&pageSize=10`;

  const {
    resource: savedColleges,
    //setResource: setSavedColleges,
    isLoading: isSavedCollegesLoading,
    serverError: savedCollegesError,
  } = useFetch(savedCollegesURL, (el) => el.attributes.name);

  const hasData = isEmpty(savedColleges);
  const handleNoData = () => {
    console.log('No Data');
  };

  return (
    <ResourceHOC
      isLoading={isSavedCollegesLoading}
      serverError={savedCollegesError}
      hasData={hasData}
      title="My College"
      handleNoData={handleNoData}
    >
      {savedColleges && (
        <ListContainer
          arr={savedColleges}
          title="My College"
          handleClick={() => history.push('/colleges')}
        />
      )}
    </ResourceHOC>
  );
};

export default CollegeList;
