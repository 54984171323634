import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import debounce from '../utils/debounce';
import getUsersByNameOrRole from './admin/functions/getUsersByNameOrRole';
import getStudentSnapshot from './userManagmentFunctions/getStudentSnapshot';
import StudentList from './StudentList';
import UserSearch from './admin/UserSearch';
import SelectedStudent from './admin/SelectedStudent';
import { useCookies } from 'react-cookie';
// import ErrorMessage from './ErrorMessage';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
    maxWidth: '90%',
    margin: '12px auto',
  },
});

const formattedDate = (date) => {
  const [formatted] = date.toLocaleDateString('en-US').split(',');
  return formatted;
};

const keyedAdditionalDataOrder = [
  { key: 'ordered_major_choices', label: 'Major Choices' },
  { key: 'college_list', label: 'College List' },
  { key: 'current_task', label: 'Current Task' },
  { key: 'incomplete_tasks', label: 'Overdue' },
  { key: 'ordered_favorites', label: 'Hooks' },
  { key: 'profile_notes', label: 'Profile Notes' },
];

const mapStudentAdditionalDataForDisplay = (student) => {
  const studentMap = new Map();
  const { incomplete_tasks } = student?.attributes;
  const currentTask = incomplete_tasks?.unshift();

  keyedAdditionalDataOrder.forEach(({ key, label }) => {
    let setValue;

    const { ordered_favorites, ordered_major_choices } = student?.attributes;
    const { collegeList } = student?.relationships;

    if (key === 'current_task') {
      setValue = currentTask;
    }

    if (key === 'college_list' && collegeList?.length > 0) {
      setValue = collegeList.join(', ');
    }

    if (key === 'ordered_favorites' && ordered_favorites) {
      setValue = Object.values(ordered_favorites)?.join(', ');
    }

    if (key === 'ordered_major_choices' && ordered_major_choices) {
      setValue = Object.values(ordered_major_choices)?.join(', ');
    }

    studentMap.set(label, setValue);
  });

  return studentMap;
};

const keyedArrayOrder = [
  { key: 'first_name', label: 'First Name' },
  { key: 'last_name', label: 'Last Name' },
  { key: 'year', label: 'Year', value: 'Grade?' },
  {
    key: 'date_of_report',
    label: 'Date of Report',
    value: formattedDate(new Date()),
  },
  { key: 'pronoun', label: 'Pronouns' },
  { key: 'gender', label: 'Gender' },
  { key: 'email', label: 'Email' },
  { key: 'role', label: 'Role' },
  { key: 'city', label: 'City' },
  { key: 'state', label: 'State' },
  { key: 'high_school_name', label: 'High School' },
  { key: 'high_school_size', label: 'HS Size' },
  { key: 'high_school_type', label: 'HS Type' },
  { key: 'class_rank', label: 'Class Rank' },
  { key: 'gpa', label: 'GPA' },
  { key: 'gpa_scale', label: 'Scale' },
];

const mapStudentGeneralDataForDisplay = (student) => {
  const studentMap = new Map();

  keyedArrayOrder.forEach(({ key, label, value }) => {
    let setValue = value;
    if (!value) {
      setValue = student[key];
    }

    if (key === 'class_rank') {
      setValue = `${student[key]} out of ${student['class_size']}`;
    }

    studentMap.set(label, setValue);
  });

  return studentMap;
};

const StudentSnapshot = () => {
  // used for setting the current user from the search input
  const [usersList, setUsersList] = useState([]);
  // const [modalActive, setModalActive] = useState(false)
  const [setLoading] = useState(false);
  // TODO: move this to a context/hook
  const [cookie] = useCookies(['tokens']);
  // selected user from search input
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [snapshotData, setSnapshotData] = useState(null);
  const [userSearch, setUserSearch] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const fetchStudentSnapshotData = async () => {
      try {
        setLoading(true);
        const response = await getStudentSnapshot(selectedStudent?.id, cookie);
        const jsonResponse = await response.json();
        const { data } = jsonResponse;
        setSnapshotData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (selectedStudent) {
      fetchStudentSnapshotData();
    }
  }, [selectedStudent, cookie, setLoading]);

  useEffect(() => {
    const fetchUsersByInput = async () => {
      try {
        setLoading(true);
        const response = await getUsersByNameOrRole(userSearch, cookie);
        const jsonResponse = await response.json();
        const users = jsonResponse?.data;
        setUsersList(users);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (userSearch && userSearch !== '') {
      fetchUsersByInput();
    }
  }, [userSearch, cookie, setLoading]);

  const generalDataTable = useCallback(() => {
    return mapStudentGeneralDataForDisplay(snapshotData?.attributes);
  }, [snapshotData]);

  const additionalDataTable = useCallback(() => {
    return mapStudentAdditionalDataForDisplay(snapshotData);
  }, [snapshotData]);

  const coursesDataTable = useCallback(() => {
    const mapKeys = ['9th', '10th', '11th', '12th', 'Other'];
    const groupedMap = new Map();

    mapKeys.forEach((key) => groupedMap.set(key, []));

    const courses = snapshotData?.relationships?.courses;
    courses.forEach((course) => {
      const { grade_year } = course;
      const currentMapValues = groupedMap.get(grade_year);
      groupedMap.set(grade_year, [...currentMapValues, course]);
    });

    return groupedMap;
  }, [snapshotData]);

  const testsDataTable = useCallback(() => {
    const mapKeys = ['PSAT', 'SAT', 'ACT'];
    const groupedMap = new Map();
    const initialValues = {
      orderedHeadings: [],
      data: [],
    };

    mapKeys.forEach((key) => groupedMap.set(key, initialValues));

    const tests = snapshotData?.relationships?.tests;
    tests.forEach((test) => {
      const { type, scores } = test;
      if (mapKeys.includes(type)) {
        const currentMapValues = groupedMap.get(type);
        const orderedHeadings = Object.keys(scores);
        if (type.includes('SAT')) {
          // creates spacer columns
          orderedHeadings.unshift('', '');
        }
        groupedMap.set(type, {
          ...currentMapValues,
          orderedHeadings,
          data: [...currentMapValues.data, test],
        });
      }
    });

    return groupedMap;
  }, [snapshotData]);

  const setUserSearchHandler = (value) => {
    if (value === '') {
      setSelectedStudent(null);
      setUsersList([]);
    }

    if (value?.length > 3) {
      setUserSearch(value);
    }
  };

  // debounced set user search handler
  const onSearchHandler = debounce(
    (currentValue) => setUserSearchHandler(currentValue),
    400,
  );

  const onSelectedStudent = (currentValue) => {
    setSelectedStudent(currentValue);
  };

  return (
    <>
      <div className="main">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <UserSearch onHandleSearchInput={onSearchHandler} />
          </Grid>
          {usersList?.length > 0 && (
            <Grid item xs={12}>
              <StudentList
                students={usersList}
                onStudentSelected={onSelectedStudent}
                clearList={() => {
                  setUsersList([]);
                  setUserSearch('');
                  setSnapshotData(null);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <h1>Student Snapshot</h1>
          </Grid>
          {selectedStudent && (
            <Grid item xs={12} md={6}>
              <Button variant="outlined">Print Snapshot</Button>
            </Grid>
          )}
          {selectedStudent && (
            <Grid item xs={12}>
              <SelectedStudent
                student={selectedStudent}
                handleDelete={() => setSelectedStudent(null)}
              />
            </Grid>
          )}
          {selectedStudent && snapshotData && (
            <Grid item xs={12}>
              <h2>Information</h2>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="student general information table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>General</strong>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...generalDataTable()].map(([key, value]) => {
                      return (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {key}
                          </TableCell>
                          <TableCell align="right">{value}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {snapshotData && selectedStudent && (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="student additional information table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Additional</strong>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...additionalDataTable()].map(([key, value]) => {
                      return (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {key}
                          </TableCell>
                          <TableCell align="right">{value}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {snapshotData?.relationships?.honors && (
            <Grid item xs={12}>
              <h2>Honors</h2>
              {snapshotData?.relationships?.honors.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="student honors information table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Honors Type</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>Title</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>Date Received</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[...snapshotData?.relationships?.honors].map(
                        ({ date_received, title, type }) => {
                          return (
                            <TableRow key={`${title}-${type}`}>
                              <TableCell component="th" scope="row">
                                {type}
                              </TableCell>
                              <TableCell align="right">{title}</TableCell>
                              <TableCell align="right">
                                {formattedDate(new Date(date_received))}
                              </TableCell>
                            </TableRow>
                          );
                        },
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No honors listed.</Typography>
              )}
            </Grid>
          )}
          {snapshotData && selectedStudent && (
            <Grid item xs={12}>
              <h2>Courses</h2>
              {snapshotData.relationships?.courses.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="student additional information table"
                  >
                    {[...coursesDataTable()].map(([key, value]) => {
                      return (
                        <React.Fragment key={key}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <strong>{key}</strong>
                              </TableCell>
                              <TableCell align="right">S1</TableCell>
                              <TableCell align="right">S2</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {value.map(({ name, semester_grades }) => {
                              return (
                                <TableRow key={`${key}-${name}`}>
                                  <TableCell component="th" scope="row">
                                    {name}
                                  </TableCell>
                                  <TableCell align="right">
                                    {semester_grades['1']}
                                  </TableCell>
                                  <TableCell align="right">
                                    {semester_grades['2']}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                          <Box mt={3} />
                        </React.Fragment>
                      );
                    })}
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No Courses listed.</Typography>
              )}
            </Grid>
          )}
          {snapshotData && selectedStudent && (
            <Grid item xs={12}>
              <h2>Extra Curriculars</h2>
              {snapshotData.relationships?.ecs.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="student extra curricular information table"
                  >
                    {snapshotData.relationships?.ecs.map((ec) => {
                      return (
                        <React.Fragment key={ec.id}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Title</TableCell>
                              <TableCell align="right">
                                Weeks Per Year
                              </TableCell>
                              <TableCell align="right">
                                Hours Per Week
                              </TableCell>
                              <TableCell align="right">Years</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {ec?.title}
                              </TableCell>
                              <TableCell align="right">
                                {ec?.weeks_per_year}
                              </TableCell>
                              <TableCell align="right">
                                {ec?.hours_per_week}
                              </TableCell>
                              <TableCell align="right">
                                {ec?.start_year} - {ec?.end_year}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <Box mt={3} />
                        </React.Fragment>
                      );
                    })}
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No Extra Curriculars listed.</Typography>
              )}
            </Grid>
          )}
          {snapshotData && selectedStudent && (
            <Grid item xs={12}>
              <h2>Tests</h2>
              {snapshotData.relationships?.tests.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="student additional information table"
                  >
                    {[...testsDataTable()].map(([key, value]) => {
                      return (
                        <React.Fragment key={`tests-${key}`}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <strong>{key}</strong>
                              </TableCell>
                              {value.orderedHeadings.map((heading) => {
                                return (
                                  <TableCell key={heading} align="right">
                                    {heading}
                                  </TableCell>
                                );
                              })}
                              <TableCell align="right">Comp</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {value.data.map((data) => {
                              return (
                                <TableRow key={data.id}>
                                  <TableCell component="th" scope="row">
                                    {formattedDate(new Date(data.date_taken))}
                                  </TableCell>
                                  {value.orderedHeadings.map((record) => {
                                    const score =
                                      record !== ''
                                        ? data.scores[record]
                                        : null;
                                    console.log(426, score);
                                    return (
                                      <TableCell align="right">
                                        {score}
                                      </TableCell>
                                    );
                                  })}
                                  <TableCell component="th" scope="row">
                                    {Object.values(data.scores).reduce(
                                      (acc, record) => acc + record,
                                      0,
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                          <Box mt={3} />
                        </React.Fragment>
                      );
                    })}
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No test data listed.</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default StudentSnapshot;
