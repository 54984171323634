import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { uniqBy } from 'lodash';
const { REACT_APP_API_DOMAIN, NODE_ENV } = process.env;

const setHeaders = (cookie) => {
  const myHeaders = new Headers();
  myHeaders.append('token', cookie.tokens.token);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};

const setUrl = (url) => {
  if (NODE_ENV !== 'development') {
    url = REACT_APP_API_DOMAIN + url;
  }
  return url;
};

export const useFetch = (url) => {
  const [cookie] = useCookies(['token']);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    const uri = setUrl(url);
    const myHeaders = setHeaders(cookie);
    const configObj = {
      method: 'GET',
      headers: myHeaders,
    };

    setIsLoading(true);
    const getData = async () => {
      try {
        const res = await fetch(uri, configObj);
        const json = await res.json();
        const {
          body: { data },
        } = json;

        setResponse(data);

        setIsLoading(false);
      } catch (e) {
        setServerError(
          `Unable to process API request to: ${url} REASON: ${e.message}`,
        );
      }
    };
    getData();
  }, [url, cookie]);

  return [response, isLoading, serverError];
};

export const useInfiniteScroll = (page) => {
  const allCollegesURL = `/colleges/all?currentPage=${page}`;
  const [cookie] = useCookies(['token']);
  const [allColleges, setAllColleges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const uri = setUrl(allCollegesURL);
    const myHeaders = setHeaders(cookie);
    const configObj = {
      method: 'GET',
      headers: myHeaders,
    };

    setIsLoading(true);
    const getColleges = async () => {
      try {
        const res = await fetch(uri, configObj);
        const json = await res.json();
        const {
          body: { data, pageCount },
        } = json;
        setAllColleges((prevState) => uniqBy([...prevState, ...data], 'id'));
        setHasMore(pageCount >= page);
      } catch (e) {
        setServerError(
          `Unable to process API request to: ${allCollegesURL} REASON: ${e.message}`,
        );
      } finally {
        setIsLoading(false);
      }
    };
    getColleges();
  }, [allCollegesURL, page, cookie]);

  return { allColleges, isLoading, serverError, hasMore };
};
