export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export function subtractDays(numOfDays, date = new Date()) {
  date.setDate(date.getDate() - numOfDays);

  return date;
}

export function formatDateISO(date) {
  return date.toISOString();
}

export function formatDate(date) {
  return date.toLocaleDateString('en-CA');
}
