import React from 'react';
import TaskChip from './TaskChip';
import { isBefore, isAfter, isSameDay, startOfMonth } from 'date-fns';

//MUI IMPORTS
import { Grid, Stack, Typography, Chip, Box } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const Day = ({ day, startDate, endDay }) => {
  const sd = new Date(startOfMonth(startDate));
  const ed = new Date(endDay);
  const dayDate = new Date(day.day);
  const bgc =
    (isBefore(dayDate, sd) && !isSameDay(dayDate, sd)) ||
    (isAfter(dayDate, ed) && !isSameDay(dayDate, ed))
      ? '#BDD1F9'
      : isBefore(dayDate, new Date())
      ? '#D3D3D3'
      : '#FFFFFF';

  const dayStyles = {
    backgroundColor: bgc,
    width: '100%',
    position: 'relative',
    height: '10em',
    border: '1px solid',
    borderColor: 'gray',
  };

  return (
    <Grid container item xs={4} md={1} sx={dayStyles}>
      <Grid item xs={12} sx={{ height: '2rem' }}>
        <DayNumber>{day.dateKey.split('-')[2]}</DayNumber>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1} p={1}>
          {day.tasks?.length > 0 &&
            day.tasks.map((task, i) => {
              if (i === 2) {
                return (
                  <Box key={task.id + i}>
                    <Chip
                      icon={<KeyboardDoubleArrowDownIcon />}
                      label="Scroll"
                    />

                    <TaskChip {...task} />
                  </Box>
                );
              }
              return <TaskChip key={task.id + i} {...task} />;
            })}
        </Stack>
      </Grid>
    </Grid>
  );
};

const DayNumber = ({ children }) => {
  return (
    <Typography
      variant="h6"
      sx={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: 'rgba(69, 95, 232, 1)',
        backgroundColor: 'textPrimary',
        borderRadius: '4px',
        textDecoration: 'none',
        textAlign: 'center',
        position: 'absolute',
        top: '5px',
        right: '10px',
      }}
    >
      {children}
    </Typography>
  );
};

export default Day;
