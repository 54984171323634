import Joi from 'joi';
import {
  errorReader,
  // dateMessages,
  regstr,
  strMessages,
} from '../../../functions/validation-helpers';

const title = Joi.string()
  .regex(regstr)
  .min(2)
  .max(30)
  .messages(strMessages('Title'))
  .required();

const description = Joi.string()
  .max(255)
  .regex(regstr)
  .messages(strMessages('Description'))
  .required();

const dateReceived = Joi.date().required();

const type = Joi.string()
  .regex(regstr)
  .min(2)
  .max(30)
  .valid('Local', 'National', 'International', 'School')
  .messages({
    ...strMessages('Type'),
    'any.only': "It must be 'Local', 'National', 'International', 'School'",
  })
  .required();

const validateHonors = (data) => {
  const schema = Joi.object().keys({
    title: title,
    type: type,
    description: description,
    dateReceived: dateReceived,
  });
  const { error } = schema.validate(data);
  return errorReader(error);
};

export { validateHonors };
