import React, { useEffect } from 'react';
import { ReactComponent as AboutSVG } from '../assets/about.svg';
import { ReactComponent as About1SVG } from '../assets/about1.svg';
import { ReactComponent as About2SVG } from '../assets/about2.svg';
import { ReactComponent as About5SVG } from '../assets/about5.svg';

import QuizIcon from '@mui/icons-material/Quiz';
import MicNoneIcon from '@mui/icons-material/MicNone';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AnalyticsService from '../analyticsService';

//MUI Imports
import {
  Grid,
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@mui/material';

const StyledPaper = ({ sx, children }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        ...sx,
        width: '100%',
        borderRadius: '15px',
        border: '1px solid',
        borderColor: '#1A3AD5',
        mb: 3,
      }}
    >
      <TopDivider color="main" />
      <Box sx={{ p: 3 }}>{children}</Box>
      <BottomDivider />
    </Paper>
  );
};

const BottomDivider = ({ sx, color }) => {
  return (
    <Box
      sx={{
        ...sx,
        borderBottomLeftRadius: '14px',
        borderBottomRightRadius: '14px',
        backgroundColor: color === 'main' ? '#1A3AD5' : '#BDD1F9',
        width: '100%',
        height: '20px',
        py: 0,
        my: 0,
      }}
    />
  );
};
const TopDivider = ({ sx, color }) => {
  return (
    <Box
      sx={{
        ...sx,
        borderTopLeftRadius: '14px',
        borderTopRightRadius: '14px',
        backgroundColor: color === 'main' ? '#1A3AD5' : '#BDD1F9',
        width: '100%',
        height: '20px',
        py: 0,
        my: 0,
      }}
    />
  );
};

const Bold = ({ children, color }) => {
  let c = color === 'main' ? 'primary.main' : 'black';
  return (
    <Box sx={{ color: c, display: 'inline', fontWeight: 'bold' }} inline>
      {children}
    </Box>
  );
};

const PTag = ({ children }) => {
  return (
    <Typography component="p" variant="body1" sx={{ pb: 2 }}>
      {children}
    </Typography>
  );
};

function About() {
  useEffect(() => {
    AnalyticsService.track('Page Loaded', { title: 'About' });
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} order={{ xs: 1 }}>
          <Typography component="h1" variant="h1">
            About Us
          </Typography>
        </Grid>
        {/* Start of About Grid Items */}
        <Grid item xs={12} lg={6} order={{ xs: 3, lg: 2 }}>
          <StyledPaper>
            <Typography component="h6" variant="h6" sx={{ pb: 3 }}>
              About Us
            </Typography>
            <PTag>
              <Bold>Hi, I’m Jeremy,</Bold> Lead Counselor, CSO, and Co-Founder
              of <Bold color="main">VIZE</Bold>. Welcome! I’d like to extend a
              personal thank you for signing up for the{' '}
              <Bold color="main">VIZE</Bold> platform, and to tell you a bit
              about how <Bold color="main">VIZE</Bold> came to be, what we’re
              trying to accomplish, and how you can help.
            </PTag>
            <PTag>
              <Bold>The</Bold> <Bold color="main">VIZE</Bold>{' '}
              <Bold>project began in 2021,</Bold> when Eyal (now{' '}
              <Bold color="main">VIZE's</Bold> CEO) reached out to me, his
              former college counselor, to pitch an idea: broadening access to
              elite college advising resources by leveraging technology to
              automate as much of the process as possible. Eyal was surprised to
              hear that I had been mulling over the very same idea, and we got
              to work immediately. Alex (now Chief Architect) joined the{' '}
              <Bold color="main">VIZE</Bold> team as our third Co-Founder and
              technological guru, and we began to develop what you see here:{' '}
              <Bold>the</Bold>
              <Bold color="main"> VIZE</Bold>
              <Bold> platform.</Bold>
            </PTag>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 2, lg: 3 }}>
          <Box sx={{ display: { xs: 'flex', md: 'block' } }}>
            <AboutSVG width={550} height={400} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 5, lg: 4 }}>
          <Typography component="h6" variant="h1" align="center">
            Our Mission
          </Typography>
          <Box sx={{ m: 5 }}>
            <About1SVG width={400} height={400} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 5, lg: 4 }}>
          <StyledPaper>
            <Typography component="h6" variant="h6" sx={{ pb: 3 }}>
              Our Mission
            </Typography>
            <PTag>
              <Bold>Our mission is simple:</Bold> we know from experience how
              difficult, grueling, and unfair the college application process is
              currently, and we’re going to do everything we can to help change
              that.
            </PTag>
            <PTag>
              <Bold>I’ve been an academic tutor,</Bold> test prep coach, and
              college counselor to all kinds of students over the past 11 years:
              everyone from sons and daughters of CEOs and famous actors and
              actresses to students just like you, and everyone in between.
            </PTag>
            <PTag>
              <Bold>I genuinely love teaching,</Bold> and thus my private
              practice, but there’s one thing I’ve never loved: that academic
              resources like me are not available to everyone, and as a result
              some students begin their path to college far, far ahead of their
              peers. That’s simply not fair. In my private practice, I’ve been
              fortunate enough to be able to offer a couple roster spots for
              free, but two or three students each year wasn’t going to do much
              to change the college application landscape.
            </PTag>
            <PTag>
              <Bold>The hope of every member</Bold> of the{' '}
              <Bold color="main">VIZE</Bold> team is that this project just
              might. By implementing the same algorithms and databases that I’ve
              used in my private practice for over a decade, the{' '}
              <Bold color="main">VIZE</Bold> platform is able to automate and
              make simple the most complicated parts of the college application
              process (all of them). And more importantly, we’re able to offer
              that guidance at a small fraction of the cost of a private college
              counselor, many of whom cost tens of thousands of dollars and
              often begin guiding families to top programs as early as
              elementary school.
            </PTag>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 7, lg: 6 }}>
          <StyledPaper>
            <Typography component="h6" variant="h6" sx={{ pb: 3 }}>
              Our Promise
            </Typography>
            <PTag>
              <Bold>Just as in my private practice,</Bold> I care deeply about
              both the ethics and quality of my work. Many counselors and
              services will try to turn their clients into cookie cutter
              versions of applicants that look more like robots built for
              Harvard than their actual selves, but{' '}
              <Bold color="main">VIZE</Bold> will never do that. We’ll learn
              about <Bold>YOU</Bold> through your student profile and make
              suggestions that fit <Bold>YOUR</Bold> unique passions and
              interests. And we’ll do all of that through conversational,
              approachable language and guiding questions to make things as easy
              on you as possible.
            </PTag>
            <PTag>
              <Bold>I know how valuable the advice</Bold> of people like me can
              be along nearly every student’s path to college, but I also know
              how few students have had access to us. That’s what{' '}
              <Bold color="main">VIZE</Bold> is going to change, with your help.
            </PTag>
            <PTag>
              <Bold>I’ve written or edited personally</Bold> every single word
              you see on the <Bold color="main">VIZE</Bold> platform, and stake
              my professional reputation on the quality of advice you’ll
              receive. It’ll be stressful, for sure. I tell all of my private
              clients this, too: you’ll want to cry and throw things at some
              point in the process. I will, too. But that’s ok, because we’re
              all going to try our hardest to help you achieve your most
              ambitious college goals.
            </PTag>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ mb: 3 }} order={{ xs: 6, lg: 7 }}>
          <Typography component="h6" variant="h1" align="center">
            Our Promise
          </Typography>
          <Box sx={{ m: 5 }}>
            <About2SVG width={400} height={400} />
          </Box>
        </Grid>
        {/* End of About */}
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography component="h6" variant="h1" align="center">
            Applying to college can be tough.
          </Typography>
          <Typography component="h6" variant="h1" align="center">
            <Bold>Hi, we’re</Bold> <Bold color="main">VIZE.</Bold>{' '}
            <Bold>We can help.</Bold>
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Box
            sx={{
              width: '100%',
              bgcolor: '#BDD1F9',
              borderRadius: '35px',
              p: 5,
            }}
          >
            <About5SVG width={500} height={400} />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Typography component="h6" variant="h1" align="center">
            What<Bold color="main"> VIZE </Bold>Offers
          </Typography>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ p: { lg: 5 } }}>
              <StyledPaper2>
                <AlignItemsList items={listItems} />
              </StyledPaper2>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ my: 5 }}>
          <Typography component="h6" variant="h1" align="center">
            In addition to our freely available guides and newsletter, each{' '}
            <Bold color="main">VIZE</Bold> user is welcome to supplement their
            experience with any of the following paid services:
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

const listItems = [
  {
    title: 'College Planning:',
    body: (
      <PTag>
        A general timeline of what to think about during each year of high
        school.
      </PTag>
    ),
    icon: <AccountBalanceIcon />,
  },
  {
    title: 'Extracurricular Planning',
    body: (
      <PTag>
        How to find and move up in activities <Bold>YOU</Bold> enjoy, and how to
        ensure that they display your leadership and initiative.
      </PTag>
    ),
    icon: <SportsHandballIcon />,
  },
  {
    title: 'Testing',
    body: (
      <PTag>
        While we finish up the <Bold color="main">VIZE</Bold> <Bold>SAT</Bold>{' '}
        and <Bold>ACT</Bold> programs, we’ll provide you with general timelines
        and guidance regarding <Bold>SAT</Bold>, <Bold>ACT</Bold>, and{' '}
        <Bold>AP</Bold> tests and how to prepare for each.
      </PTag>
    ),
    icon: <QuizIcon />,
  },
  {
    title: 'Application Essays',
    body: (
      <PTag>
        How to plan, create, and polish essays that reflect the best in you and
        highlight your most memorable characteristics.
      </PTag>
    ),
    icon: <FilePresentIcon />,
  },
  {
    title: 'Interview Planning',
    body: (
      <PTag>
        We’ll walk you through how to plan for both virtual and in-person
        interviews, including common questions.
      </PTag>
    ),
    icon: <MicNoneIcon />,
  },
  {
    title: 'Letters of Recommendation',
    body: (
      <PTag>
        We’ll help you make sure that your recommendations come from the right
        people and say the right things about you and your accomplishments.
      </PTag>
    ),
    icon: <MailOutlineIcon />,
  },
];

const AlignItemsList = ({ items }) => {
  return (
    <List sx={{ width: '100%' }}>
      {items.map((item, index) => {
        const { body, alt, icon, title } = item;
        return (
          <>
            <ListItem key={index} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  alt={alt}
                  sx={{
                    color: 'primary.main',
                    border: '2px solid',
                    borderColor: '#BDD1F9',
                    bgcolor: 'white',
                  }}
                >
                  {icon}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Bold>{title}</Bold>}
                secondary={
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {body}
                  </Typography>
                }
              />
            </ListItem>
            {index !== items.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </>
        );
      })}
    </List>
  );
};

const StyledPaper2 = ({ sx, children, title }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        ...sx,
        width: '100%',
        borderRadius: '15px',
        border: '1px solid',
        borderColor: '#1A3AD5',
        mb: 3,
      }}
    >
      <Box
        sx={{
          ...sx,
          borderTopLeftRadius: '14px',
          borderTopRightRadius: '14px',
          backgroundColor: '#1A3AD5',
          width: '100%',
          height: '50px',
          py: 0,
          my: 0,
        }}
      >
        <Typography
          component="h6"
          variant="h6"
          sx={{ p: 1, color: 'white' }}
          align="center"
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>{children}</Box>
    </Paper>
  );
};

export default About;
