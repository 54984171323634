import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//MUI IMPORTS
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Button from '@mui/material/Button';
import CollegeCard from '../CollegeCard';
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';

export const DashboardSurveyResults = () => {
  const [surveyResults, setSurveyResults] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const getSurveyResults = async () => {
      const storage = localStorage.getItem('surveyResults');

      if (storage) {
        const results = JSON.parse(storage);
        setSurveyResults(results);
      } else {
        return;
      }
    };

    getSurveyResults();
  }, []);

  return (
    <>
      {surveyResults.length === 0 ? (
        <Grid container>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                history.push('/college-survey/Layout');
              }}
            >
              Take the VIZE match survey!
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          {surveyResults.slice(0, 3).map((college, index) => {
            const { attributes } = college;
            return (
              <Grid>
                <CollegeCard
                  variant="version2"
                  name={attributes.name}
                  state={attributes.state}
                  status={attributes.status}
                  ceebCode={attributes.ceeb_code}
                  location={attributes.location}
                  handleCollegeAdd={(data) => console.log(data)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

const CollegeResultModal = () => {
  const [open, setOpen] = useState(false);
  const [surveyResults, setSurveyResults] = useState([]);

  const handleClose = () => setOpen(false);

  const submitButtonHandler = async () => {
    const storage = localStorage.getItem('surveyResults');

    const results = JSON.parse(storage);
    setSurveyResults(results);
    setOpen(true);
    return;
  };

  return (
    <Grid>
      <Button sx={{ m: 1 }} variant="contained" onClick={submitButtonHandler}>
        View College Match Results
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="college-match-results"
        PaperProps={{
          sx: {
            minWidth: '50rem',
            maxHeight: '70vh',
            py: 1,
          },
        }}
      >
        <Box
          sx={{
            zIndex: '10',
            mt: 2,
            mb: 0,
            backgroundColor: '#1A3AD5',
            position: 'relative',
          }}
        >
          <Stack direction="row" justify="space-between" align="center">
            <Typography
              color="white"
              variant="h3"
              component="h5"
              sx={{ mr: 2, transform: 'scale(.7)' }}
            >
              Here are your college match results!
            </Typography>
            <Avatar
              sx={{
                width: '5rem',
                height: '5rem',
                borderRadius: '50%',
                border: '2px solid',
                borderColor: '#1A3AD5',
                backgroundColor: 'white',
                position: 'absolute',
                top: '-.75rem',
                transform: 'scale(1.25)',
                right: '2rem',
                zIndex: '10',
              }}
            >
              <SchoolOutlinedIcon
                sx={{ color: 'black', transform: 'scale(2.5)' }}
              />
            </Avatar>
          </Stack>
        </Box>
        <DialogContent>
          {surveyResults.map((college, index) => {
            const { attributes } = college;
            return (
              <Grid pt={4}>
                <CollegeCard
                  name={attributes.name}
                  state={attributes.state}
                  status={attributes.status}
                  ceebCode={attributes.ceeb_code}
                  location={attributes.location}
                  handleCollegeAdd={(data) => console.log(data)}
                />
              </Grid>
            );
          })}
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <Avatar
            sx={{
              mr: 2,
              width: '4rem',
              height: '4rem',
              borderRadius: '50%',
              backgroundColor: '#1A3AD5',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <Typography variant="h1" style={{ cursor: 'pointer' }}>
              X
            </Typography>
          </Avatar>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CollegeResultModal;
